import styled from 'styled-components';

const UserProfileEditStyled = styled.div`
	display: flex;
	flex-direction: column;
`;
const CoverSection = styled.div`
	display: flex;
	flex-direction: column;
	@media screen and (min-width: 768px) {
		flex-direction: row;
	}
`;
const MainInfoUser = styled.div`
	display: flex;
	flex-direction: column;
	@media screen and (min-width: 768px) {
		flex-direction: row;
	}
`;

const SecondaryInfoUser = styled.div`
	display: flex;
	flex-direction: column;
	max-width: 670px;
	width: 100%;
	@media screen and (min-width: 768px) {
		
	}
`;

const Cover = styled.div`
	width: 314px;
	height: 114px;
	margin: 0 auto;
	background-color: #b3b3b3;
	> img {
		width: 100%;
		height: 100%;
		object-fit: contain;
	}
	@media screen and (min-width: 768px) {
		width: 553px;
		height: 200px;
	}
	@media screen and (min-width: 992px) {
		width: 590px;
	}
	@media screen and (min-width: 1200px) {
		width: 800px;
	}
`;

const CoverOptions = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	padding: 5px;
	@media screen and (min-width: 1200px) {
		width: 150px;
	}
`;
const SaveChangesTop = styled.div`
	display: none;
	@media screen and (min-width: 768px) {
		display: block;
	}
`
const ProfilePhoto = styled.div`
	@media screen and (min-width: 1200px) {
		width: 170px;
	}
	> img {
		border-radius: 50%;
		width: 140px;
		height: 140px;
		object-fit: contain;
		background-color: gray;
		display: block;
		margin: 20px auto;
	}
	> button{
		margin: 0 auto;
	}
`;
const MainForm = styled.div`
	display: flex;
	flex-direction: column;
	margin: 0 auto;
	@media screen and (min-width: 768px) {
		margin: 0;
	}
	@media screen and (min-width: 1200px) {
		width: ${props => props.desktop || '100%'};
	}
`;
MainForm.Row = styled.div`
	display: flex;
	align-items: center;
	width: 100%;
	flex-direction: column;
	input,
	div[role='combobox'] {
		width: 238px;
		height: 32px;
		margin: 5px 5px;
		border: 1px solid var(--wbn-sidebar-color-line);
		border-radius: 20px;
	}
	> label {
		margin-left: 5px;
		align-self: flex-start;
		@media screen and (min-width: 600px) {
			margin-left: 50px;
		}
		width: 108px;
	}
	@media screen and (min-width: 600px) {
		flex-direction: row;
	}
`;

const BriefDescription = styled.div`
	textarea {
		width: 100%;
	}
`;

const PhoneWrapper = styled.div`
	width: 238px;
	margin: 5px 5px;
	> .react-tel-input {
		border-radius: 20px;
		> input {
			border-radius: 20px;
			height: 32px;
			width: 100%;
			border: 1px solid var(--wbn-sidebar-color-line_hover);
		}
		> .flag-dropdown {
			border: 1px solid var(--wbn-sidebar-color-line_hover);
		}
	}
`;
const ConfirmationButtons = styled.div`
	display: flex;
	justify-content: center;
	> button {
		margin: 5px;
	}
`;
export {
	UserProfileEditStyled,
	CoverSection,
	MainInfoUser,
	BriefDescription,
	SecondaryInfoUser,
	Cover,
	CoverOptions,
	SaveChangesTop,
	ProfilePhoto,
	MainForm,
	PhoneWrapper,
	ConfirmationButtons
};
