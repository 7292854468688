import React from 'react';
import BusinessPlaceholder from 'images/img_profile_empty.svg';
import { Icon } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import { Button } from 'components/atoms';
import { ButtonSection } from './style';


function getProfilePhoto(user) {
  if (user.profilePhoto) {
    if (user.profilePhoto.versions) {
      let ind = user.profilePhoto.versions.length > 1 ? 1 : 0;
      return user.profilePhoto.versions[ind].url;
    }
    return user.profilePhoto.url;
  }
  return BusinessPlaceholder;
}

function ContentCardComponentUser({ user, history }) {

  return (
    <div className="container-home__card-comments container-home__card-comments-search">
      <div className="container-card-comments-search">
        <div className="container-card-comments-search-img">
          <Link to={`/profile/${user.userID}`}>
            <img src={getProfilePhoto(user)} alt={`WBNB2B User ${user.firstName} ${user.lastName} profile`} />
          </Link>
        </div>
        <div className="container-card-comments-search-header">
          <div className="container-card-comments-search-header-title">
            <h2>
              <Link to={`/profile/${user.userID}/`}>{user.firstName} {user.lastName}</Link>
            </h2>
          </div>
          <div className="container-card-comments-search-header-tab">
            <p>
              {user.country?.countryName || '---'} / {user.state?.stateName || '---'} / {user.city || '---'}
            </p>
          </div>
          <div className="container-card-comments-search-header-tab">
            <p>
              {user.jobPosition?user.jobPosition.name + ' at':''} {user.companyName}
            </p>
          </div>
          <div className="container-card-comments-search-header-text">
            <p>{user.briefPresentation}</p>
          </div>
          <div className="container-card-comments-search-header-certificate">
            <div className="container-card-comments-search-header-certificate-likes">
              <Icon name="thumbs up" /> <span>{user.reactions?.reactionCount?.like || 0} likes</span>
            </div>
            {/* <div className="container-card-comments-search-header-certificate-images">
							<div>
								<MembershipBadge membershipType={subscription} />
							</div>
							<div>
								{verified ? <img src={verifiedSvg} alt="WBNB2B Verified Badge BUSINESS B2B" /> : null}
							</div>
						</div> */}
          </div>
        </div>
        <div className="container-card-comments-search-footer">
          {/* <BadgeSectionDesktop>
						<BadgeWrapper>
							<MembershipBadge membershipType={subscription} />
						</BadgeWrapper>
						<BadgeWrapper>
							{verified ? <img src={verifiedSvg} alt="WBNB2B Verified Badge BUSINESS B2B" /> : null}
						</BadgeWrapper>
					</BadgeSectionDesktop> */}
          <ButtonSection>
            <Button flavor='secondary' onClick={() => history.push('/profile/' + user.userID)}>Go Profile</Button>
          </ButtonSection>
        </div>
      </div>
    </div>
  );
}

export default ContentCardComponentUser;
