import {
	GET_LIST_MY_BUSINESS_WATCHER,
	GET_LIST_MY_BUSINESS_SUCCESS,
	GET_LIST_MY_BUSINESS_FAILED
} from './constants';

const initialState = {
	loading: true,
	data: []
};

export default (state = initialState, action) => {
	switch (action.type) {
		case GET_LIST_MY_BUSINESS_WATCHER:
			return {
				loading: true,
				data: []
			};
		case GET_LIST_MY_BUSINESS_SUCCESS:
			return {
				loading: false,
				data: action.payload
			};

		case GET_LIST_MY_BUSINESS_FAILED:
			return {
				loading: false,
				data: []
			};

		default:
			return state;
	}
};
