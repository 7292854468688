import { CREATE_BUSINESS_WATCHER, CREATE_BUSINESS_SUCCESS, CREATE_BUSINESS_FAILED } from './constants';

const initialState = {
	loading: false,
	data: [],
	error: null
};

export default (state = initialState, action) => {
	switch (action.type) {
		case CREATE_BUSINESS_WATCHER:
			return {
				loading: true,
				data: []
			};
		case CREATE_BUSINESS_SUCCESS:
			return {
				loading: false,
				data: action.payload
			};

		case CREATE_BUSINESS_FAILED:
			return {
				loading: false,
				data: action.payload,
				error: true
			};

		default:
			return state;
	}
};
