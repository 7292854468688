import React from 'react';

const Automation = ({ fill = "white" }) => {
	return (
		<svg id="Capa_1" data-name="Capa 1"
			xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
			<title>ICONS</title>
			<ellipse style={{fill: "none"}} cx="8.34" cy="11.61" rx="1.71" ry="1.68" />
			<ellipse style={{fill: "none"}} cx="8.34" cy="11.61" rx="1.71" ry="1.68" />
			<ellipse style={{fill: "none"}} cx="15.83" cy="11.61" rx="1.71" ry="1.68" />
			<ellipse style={{fill: "none"}} cx="15.83" cy="11.61" rx="1.71" ry="1.68" />
			<polygon style={{fill}} points="9.89 20.21 9.89 22.55 10.31 22.66 10.31 20.41 9.89 20.21" />
			<polygon style={{fill}} points="14.46 20.21 14.53 22.39 14.13 22.66 14.05 20.41 14.46 20.21" />
			<path style={{fill}} d="M10.92,7.54l-.43-.47A.21.21,0,0,0,10.34,7H7.83a.52.52,0,0,1-.47-.27L6.6,5.41A6.69,6.69,0,0,0,5.15,10l.5,5c0,.25.09.48.15.71l.48-.53A.55.55,0,0,1,6.72,15L9,15.19a.19.19,0,0,0,.16-.06l1.73-1.89a.21.21,0,0,0,.05-.13V7.67A.21.21,0,0,0,10.92,7.54ZM8.34,13.29a1.68,1.68,0,1,1,1.71-1.68A1.7,1.7,0,0,1,8.34,13.29Z" />
			<path style={{fill}} d="M17.51,5.29,16.76,6.6a.5.5,0,0,1-.44.25H13.81a.14.14,0,0,0-.12.06l-.45.48a.21.21,0,0,0,0,.11v5.39a.21.21,0,0,0,0,.11L15,14.88a.17.17,0,0,0,.14.06l2.3-.17a.54.54,0,0,1,.42.16l.47.52a7,7,0,0,0,.15-.69l.49-5A6.56,6.56,0,0,0,17.51,5.29Zm-1.68,8a1.68,1.68,0,1,1,1.71-1.68A1.69,1.69,0,0,1,15.83,13.29Z" />
			<path style={{fill}} d="M18.13,16.11l-.58-.62a.2.2,0,0,0-.15-.06l-2.46.18a.54.54,0,0,1-.44-.17l-1.83-2a.52.52,0,0,1-.14-.35V7.45a.52.52,0,0,1,.14-.35l.47-.51a.54.54,0,0,1,.4-.17h2.67a.17.17,0,0,0,.16-.09l.87-1.5A6.67,6.67,0,0,0,12.81,2.5l-.72,0-.73,0A6.64,6.64,0,0,0,6.93,4.84h0l.87,1.49a.2.2,0,0,0,.18.1h2.64a.58.58,0,0,1,.42.18l.46.49a.55.55,0,0,1,.14.37v5.65a.54.54,0,0,1-.14.36l-1.82,2a.54.54,0,0,1-.46.18L6.8,15.43a.29.29,0,0,0-.18.07l-.56.61L5.89,16c.74,2.37,3.42,4,6.11,4h0c2.69,0,5.63-1.64,6.26-4Zm-9-12.48a5.39,5.39,0,0,1,2.95-.77A6,6,0,0,1,15,3.61s-.45,1.29-3,1.29C10,4.91,9.46,4.36,9.14,3.63Z" />
			<path style={{fill}} d="M10.79,20.57V22a3.55,3.55,0,0,0,1.39.2c1.14,0,1.39-.2,1.39-.2V20.57s-.29.27-1.45.27S10.79,20.57,10.79,20.57Z" />
			<path style={{fill}} d="M4.6,14.43h0a.4.4,0,0,1-.44-.35,5.28,5.28,0,0,1-.7-1.86c-.26-1.88.47-2.18.69-2.2h0a.4.4,0,0,1,.44.35L5,14A.39.39,0,0,1,4.6,14.43Z" />
			<path style={{fill}} d="M19.49,14.43h0a.4.4,0,0,0,.44-.35,5.07,5.07,0,0,0,.7-1.86c.27-1.88-.47-2.18-.69-2.2h0a.4.4,0,0,0-.44.35L19.13,14A.4.4,0,0,0,19.49,14.43Z" />
			<path style={{fill}} d="M14.81,21.84c1.68.19,2.84.57,2.84,1,0,.63-2.32,1.14-5.18,1.14S7.3,23.49,7.3,22.86c0-.39.89-.74,2.25-.94,0,0-.07.95,2.63.93S14.81,21.84,14.81,21.84Z" />
			<path style={{fill}} d="M12.58,0h-1a.35.35,0,0,0-.35.35v.94a.35.35,0,0,0,.35.35h1a.35.35,0,0,0,.34-.35V.39A.35.35,0,0,0,12.58,0Zm-.94,1A.14.14,0,0,1,11.5.86a.13.13,0,0,1,.14-.13.13.13,0,0,1,.13.13A.13.13,0,0,1,11.64,1Zm.9-.26a.24.24,0,0,1,0,.08.07.07,0,0,1,0,0,.13.13,0,0,1,.07.12v.11a.24.24,0,0,1-.06.18.24.24,0,0,1-.17.06h-.47V1.09h.41s.06,0,.06-.05a.05.05,0,0,0,0,0s0,0,0,0h-.41V.74h.41s.06,0,.06-.05a.05.05,0,0,0,0,0s0,0,0,0h-.41V.39h.47a.24.24,0,0,1,.17.07.22.22,0,0,1,.06.17Z" />
			<path style={{fill}} d="M11.69.94h-.1v0a0,0,0,0,0,0,0l0,0s0,0,0,0,0,0,0,0a0,0,0,0,0,0,0h0v0h0s0,0,0,0h.08a0,0,0,0,1,0,0v0h0l0,0h.06Z" />
			<rect style={{fill}} x="11.77" y="1.46" width="0.63" height="1.15" rx="0.08" ry="0.08" />
		</svg>
	)
}

export default Automation;
