import React, { useState } from 'react';
import Context from './context';


const MyProvider = ({ children }) => {
	const [socialActor, setSocialActor] = useState(
		localStorage.getItem('socialActor') || localStorage.getItem('userID')
	);
	const setCurrentSocialActor = React.useCallback(currentSocialActor => {
		setSocialActor(currentSocialActor);
	}, []);
	return (
		<Context.Provider
			value={{
				socialActor: socialActor,
				setSocialActor: setCurrentSocialActor
			}}
		>
			{children}
		</Context.Provider>
	);
};

export default MyProvider;
