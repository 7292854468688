import React from 'react';
import WBNMapSource from 'components/atoms/wbn-map-image';
import {
  World,
  WorldHeader,
  WorldHeaderTitle,
} from './styles'

const WBNMap = () => {
  return (
    <World>
      <WorldHeader>
        <WorldHeaderTitle>
          WBN THE INDUSTRIAL B2B SOCIAL NETWORK
        </WorldHeaderTitle>
        <p>
          WBN offers, in few clicks, to find the right industrial partner, growing fast, in the most
          competitive markets. For twenthie century industries was almost obligatory to exhibit at fairs to
          find new distributors and customers, expending minimum US$20 000 per each exhibition. WBN offers to
          create your own "Global Distribution Network" from your office and facilities...  “We are the
          biggest trade show in the world… and never closes”
				</p>
      </WorldHeader>
      <WBNMapSource />
    </World>
  );
};

export default WBNMap;
