import { POST_IMAGE_S3_WATCHER, POST_IMAGE_S3_SUCCESS, POST_IMAGE_S3_FAILED } from './constants';

const initialState = {
	isLoadingUploadS3Image: true,
	data: []
};

export default (state = initialState, action) => {
	switch (action.type) {
		case POST_IMAGE_S3_WATCHER:
			return {
				isLoadingUploadS3Image: true,
				data: {}
			};
		case POST_IMAGE_S3_SUCCESS:
			return {
				isLoadingUploadS3Image: false,
				data: action.payload
			};

		case POST_IMAGE_S3_FAILED:
			return {
				isLoadingUploadS3Image: false,
				data: {}
			};

		default:
			return state;
	}
};
