import React from 'react';
import ReactMedia from 'react-media';
import { breakpoints } from 'breakpoints';

const queries = {
	xs: `(max-width: ${breakpoints.md - 1}px)`,
	md: `(min-width: ${breakpoints.md}px) and (max-width: ${breakpoints.lg - 1}px)`,
	lg: `(min-width: ${breakpoints.lg}px)`
};

export default function Media({ xs = null, md = null, lg = null }) {
	return (
		<ReactMedia queries={queries}>
			{matches => {
				if (matches.xs) {
					return xs;
				}

				if (matches.md) {
					return md;
				}

				if (matches.lg) {
					return lg;
				}

				return null;
			}}
		</ReactMedia>
	);
}
