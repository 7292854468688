import React, { useState, useEffect } from 'react';
import { Icon } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import MenuMobile from 'components/menu-mobile';
import MenuDesktop from 'components/menu-desktop';
import SearchSection from '../search-section';
import { Logo as LogoImage, altImage } from '../domain-components/domain_logo';

import auth from 'auth';
import {
  DomainLogo,
  ContainerLeft,
  ContainerHeader,
} from './styles';
import Media from 'react-media';

const IconStyled = styled(Icon)`
  font-size: 20px !important;
  display: flex !important;
  justify-content: center;
  align-items: center;
  margin-right: 8px !important;
`;


const Header = ({ isSearch = false, history }) => {
  const [showMenu, setShowMenu] = useState(false);
  const [isLogged, setIsLogged] = useState(false);

  useEffect(() => {
    const getProfile = async () => {
      if (auth.hasTokenValid()) {
        return setIsLogged(true);
      }

      await auth.refreshToken();
      setIsLogged(auth.hasTokenValid());
    }

    getProfile();
  }, []);

  return (
    <>
      <ContainerHeader>
        <ContainerLeft>
          <Media query="(max-width: 991px)" render={() => <IconStyled
            name="sidebar"
            onClick={() => setShowMenu(!showMenu)}
          />} />

          <Link to="/">
            <DomainLogo src={LogoImage} alt={altImage} />
          </Link>
        </ContainerLeft>
        {isSearch ? <SearchSection history={history} /> : null}
        <MenuDesktop isLogged={isLogged} />
      </ContainerHeader>
      {showMenu && <MenuMobile setShowMenu={setShowMenu} />}
    </>
  );
};

export default Header;
