import { createBreakpoint } from 'styled-components-breakpoint';

export const breakpoints = {
	xs: 0,
	sm: 768,
	md: 992,
	lg: 1200
};

export default createBreakpoint(breakpoints);
