import React from 'react';
import styled from 'styled-components';

const LogoNetwork = styled.div`
	padding: 4px 1px;
	:hover {
		opacity: 0.8;
	}
`;

const WrapperMessageNetwork = styled.p`
	border-radius: 10px;
	background-color: #b1b1b1;
	padding: 12px 12px;
	color: white;
	text-align: center;
`
const logos = [
	{ picture: 'Italian_business_wbnb2b_territorial_logo.png', domain: 'italianbusinessguide.com' },
	{ picture: 'California_business_wbnb2b_territorial_logo.png', domain: 'californiamanufacturingguide.com' },
	{ picture: 'German_business_wbnb2b_territorial_logo.png', domain: 'germanybusinessguide.com' },
	{ picture: 'Indian_business_wbnb2b_territorial_logo.png', domain: 'indiamanufacturingguide.com' },
	{ picture: 'New_York_business_wbnb2b_territorial_logo.png', domain: 'newyorkmanufacturingguide.com' },
	{ picture: 'Chile_business_wbnb2b_territorial_logo.png', domain: 'chilebusinessguide.com' },
	{ picture: 'China_business_wbnb2b_territorial_logo.png', domain: 'chinamanufacturingguide.com' },
	{ picture: 'USA_business_wbnb2b_territorial_logo.png', domain: 'usamanufacturingguide.com' },
	{ picture: 'France_business_wbnb2b_territorial_logo.png', domain: 'francebusinessguide.com' },
	{ picture: 'Peru_business_wbnb2b_territorial_logo.png', domain: 'peruvianbusinessguide.com' },
	{ picture: 'Atlanta_business_wbnb2b_territorial_logo.png', domain: 'atlantamanufacturingguide.com' },
	{ picture: 'Canada_business_wbnb2b_territorial_logo.png', domain: 'canadamanufacturingguide.com' },
	{ picture: 'Japan_business_wbnb2b_territorial_logo.png', domain: 'japanbusinessguide.com' },
	{ picture: 'Dubai_business_wbnb2b_territorial_logo.png', domain: 'dubaimanufacturing.com' },
	{ picture: 'Africa_business_wbnb2b_territorial_logo.png', domain: 'africamanufacturingguide.com' },
	{ picture: 'Miami_business_wbnb2b_territorial_logo.png', domain: 'miamimanufacturingguide.com' },
	{ picture: 'Mexico_business_wbnb2b_territorial_logo.png', domain: 'mexicobusinessguide.com' },
	{ picture: 'Texas_business_wbnb2b_territorial_logo.png', domain: 'texasmanufacturingguide.com' },
	{ picture: 'Egypt_business_wbnb2b_territorial_logo.png', domain: 'egyptmanufacturing.com' },
	{ picture: 'Brazil_business_wbnb2b_territorial_logo.png', domain: 'brazilbusinessguide.com' },
	{ picture: 'Russia_business_wbnb2b_territorial_logo.png', domain: 'russiabusinessguide.com' },
	{ picture: 'Indonesia_business_wbnb2b_territorial_logo.png', domain: 'indonesiamanufacturing.com' },
	{
		picture: 'United_Kingdom_business_wbnb2b_territorial_logo.png',
		domain: 'unitedkingdommanufacturing.com'
	},
	{ picture: 'Australia_business_wbnb2b_territorial_logo.png', domain: 'australianbusinessguide.com' },
	{ picture: 'Saudi_Arabia_business_wbnb2b_territorial_logo.png', domain: 'saudiarabiabusinessguide.com' }
];
const WBNNetwork = () => {
	return (
		<div>
			<WrapperMessageNetwork>
				WBN has quantity 25 B2B web platforms and representatives in China, Australia, Japan, Indonesia, UAE,
				Saudi Arabia, India, Africa, Egypt, Russia, Germany, United Kingdom, Italy, France, United States of
				America, Miami, Texas, California, Atlanta, New York, Mexico, Canada, Brazil, Chile and Peru to
				support everyone everywhere in the globe.
			</WrapperMessageNetwork>
			<div
				style={{
					display: 'flex',
					flexWrap: 'wrap',
					justifyContent: 'space-between',
					maxWidth: '880px',
					minHeight: '202px',
					margin: '0 auto'
				}}
			>
				{logos.map((element, index) => {
					return (
						<LogoNetwork key={index}>
							<a href={`https://${element.domain}`} target="_blank" rel="noopener noreferrer">
								<img
									src={`/territorial_logo/${element.picture}`}
									alt={`WBNB2B Industrial Marketing ${element.picture
										.split('_')
										.slice(0, -1)
										.join(' ')} ${element.domain.split('.')[0]}}`}
								/>
							</a>
						</LogoNetwork>
					);
				})}
			</div>
		</div>
	);
};

export default WBNNetwork;
