import styled, { css } from 'styled-components';

const truncateStyle = css`
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
`;

export const Trigger = styled.div`
	-webkit-appearance: none !important;
	align-items: center;
	appearance: none !important;
	display: flex;
	height: 100%;
	padding: 0 16px;

	i {
		margin-left: 10px;
	}
`;

export const Input = styled.input`
	appearance: none;
	background: transparent;
	border: none;
	flex: 1;
	font-size: 14px;
	height: 100%;
	outline: none;
	width: 100%;
`;

export const Placeholder = styled.span`
	color: gray;
	font-size: 14px;
	left: 16px;
	right: 25px;
	position: absolute;
	${truncateStyle}
`;
