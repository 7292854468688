import {
	GET_INDUSTRY_CATEGORIES_WATCHER,
	GET_INDUSTRY_CATEGORIES_SUCCESS,
	GET_INDUSTRY_CATEGORIES_FAILED
} from './constants';

const initialState = {
	isLoadingCategories: true,
	data: []
};

export default (state = initialState, action) => {
	switch (action.type) {
		case GET_INDUSTRY_CATEGORIES_WATCHER:
			return {
				isLoadingCategories: true,
				data: []
			};
		case GET_INDUSTRY_CATEGORIES_SUCCESS:
			return {
				isLoadingCategories: false,
				data: action.payload
			};

		case GET_INDUSTRY_CATEGORIES_FAILED:
			return {
				isLoadingCategories: false,
				data: []
			};

		default:
			return state;
	}
};
