import React from 'react';
import { Link } from 'react-router-dom';
import { map } from 'lodash';
import options from './optionsFooter.json';
import './styles.css';

const FooterNew = () => {
	return (
		<div className="container-footer-new">
			<div className="container-footer-new__top">
				<div className="container-footer-new__top--social-network">
					<p>For information and customer services: USA +1 (305) 833 5700 EUROPE +39.333.6371.644</p>
				</div>
				<div className="container-footer-new__links">
					{map(options, ({ title, list }, idx) => {
						return (
							<div key={idx} className="container_new__top--options">
								<h4 className="container_new__top--options__title">{title}</h4>
								<ul>
									{map(list, (option, index) => {
										return (
											<li key={index}>
												<Link to={option.path}>{option.text}</Link>
											</li>
										);
									})}
								</ul>
							</div>
						);
					})}
				</div>
			</div>
			<div className="container-footer__social-network">
				<p>
					WBN the B2B social network - Your gateway to the global manufacturing suppliers{' '}
					{process.env.REACT_APP_OLD_PAGE ? (
						<span>
							Upgraded from: <a href="/index.htm">Our platform</a>
						</span>
					) : null}
				</p>
			</div>
			<div className="container-footer__language">
				<div>
					<Link to="/">English</Link>
					<Link to="/">Español</Link>
					<Link to="/">Italiano</Link>
					<Link to="/">Chinese</Link>
				</div>
				<div>
					<p>
						2000 - 2020 The Industrial B2B Export Platform for Buyers, Distributors, Suppliers and
						Manufacturers
					</p>
					<p>Web Designed and Developed by Worldwide Business Network Inc. USA.</p>
				</div>
			</div>
		</div>
	);
};

export default FooterNew;
