import React from 'react';

const WBN_STANDARD = ({ fill = "white" }) => {
	return (
		<svg id="f00bb59f-7a12-4e81-84d6-19954d436f37" data-name="Capa 1" 
			xmlns="http://www.w3.org/2000/svg" viewBox="0 0 144.74 144.74">
			<title>WBN_</title>
			<circle style={{fill: fill}} cx="72.37" cy="72.37" r="62.95"/>
			<path style={{fill: fill}} d="M21.2,21.2A72.37,72.37,0,1,0,123.55,123.54,72.37,72.37,0,1,0,21.2,21.2ZM24.7,120A67.42,67.42,0,0,1,120,24.7,67.42,67.42,0,0,1,24.7,120Z"/>
			<path style={{fill: "none"}} d="M44.94,79.21v-1a4.77,4.77,0,0,1,.83-1.25,10.55,10.55,0,0,1,1.35-1.27,7.65,7.65,0,0,0,1.1-1,1.35,1.35,0,0,0,.31-.81.86.86,0,0,0-1-1,1,1,0,0,0-.72.26,1.34,1.34,0,0,0-.33.77H45a2.51,2.51,0,0,1,.75-1.67,2.66,2.66,0,0,1,1.76-.54,2.77,2.77,0,0,1,1.86.55A1.92,1.92,0,0,1,50,73.74a2.2,2.2,0,0,1-.2.94,3.25,3.25,0,0,1-.53.78,5.71,5.71,0,0,1-.72.67l-.77.61c-.24.2-.47.4-.67.6a2,2,0,0,0-.45.65h3.46v1.22Z"/>
			<path style={{fill: "white"}} d="M38.45,29.59l9.63-5,1.41,2.71-5.72,3,1.14,2.2,4.89-2.54,1.32,2.55L46.23,35l2.68,5.15L45,42.2Z"/>
			<path style={{fill: "white"}} d="M57.43,36.36,55.55,22.27l7.25-1a10.43,10.43,0,0,1,3.13-.06,3.26,3.26,0,0,1,1.89,1.05,3.94,3.94,0,0,1,1,2.19,3.82,3.82,0,0,1-1.42,3.66,5.67,5.67,0,0,1-1.45.76,4,4,0,0,1,1.2.37,4.51,4.51,0,0,1,.79.64,5.5,5.5,0,0,1,.73.8l2.65,3.8-4.92.65-2.9-4a3.38,3.38,0,0,0-.93-1A1.76,1.76,0,0,0,61.42,30l-.38,0,.76,5.71Zm3.25-9,1.83-.24a6.42,6.42,0,0,0,1.13-.35,1.14,1.14,0,0,0,.65-.53,1.34,1.34,0,0,0,.17-.86,1.36,1.36,0,0,0-.58-1,2.65,2.65,0,0,0-1.67-.14l-1.91.25Z"/>
			<path style={{fill: "white"}} d="M75.81,20.81l11.6,2-.51,3-7.26-1.23-.38,2.22L86,27.91l-.49,2.86-6.73-1.15-.47,2.76,7.47,1.27-.54,3.18-11.81-2Z"/>
			<path style={{fill: "white"}} d="M94.92,25.44l10.28,5.74-1.48,2.65-6.44-3.59-1.1,2,6,3.33-1.41,2.53-6-3.33L93.4,37.19,100,40.88l-1.56,2.81L88,37.85Z"/>
			<path style={{fill: "white"}} d="M39.5,113.9V103.44h2.08l2.48,7.41c.23.69.39,1.2.5,1.55.12-.38.3-.94.56-1.68l2.5-7.28h1.86V113.9H48.15v-8.75l-3,8.75H43.86l-3-8.9v8.9Z"/>
			<path style={{fill: "white"}} d="M52.94,113.9V103.44h7.57v1.23H54.33v3.21h5.79v1.23H54.33v3.56h6.42v1.23Z"/>
			<path style={{fill: "white"}} d="M63.82,113.9V103.44h2.09l2.47,7.41c.23.69.4,1.2.5,1.55.12-.38.31-.94.56-1.68l2.5-7.28h1.87V113.9H72.47v-8.75l-3,8.75H68.18l-3-8.9v8.9Z"/>
			<path style={{fill: "white"}} d="M77.18,113.9V103.44h3.93a4.85,4.85,0,0,1,1.92.32,2.47,2.47,0,0,1,1.14,1,2.58,2.58,0,0,1,.41,1.38,2.34,2.34,0,0,1-.37,1.26,2.68,2.68,0,0,1-1.1,1,2.66,2.66,0,0,1,1.46,1,2.51,2.51,0,0,1,.51,1.58,3,3,0,0,1-.31,1.37,2.61,2.61,0,0,1-.76,1,3.3,3.3,0,0,1-1.15.52,7.34,7.34,0,0,1-1.69.17Zm1.39-6.06h2.26a4.63,4.63,0,0,0,1.32-.13,1.43,1.43,0,0,0,.8-.52,1.46,1.46,0,0,0,.26-.91,1.75,1.75,0,0,0-.24-.92,1.31,1.31,0,0,0-.72-.54,5.82,5.82,0,0,0-1.59-.15H78.57Zm0,4.83h2.6a6.64,6.64,0,0,0,1,0,2.17,2.17,0,0,0,.79-.29,1.43,1.43,0,0,0,.53-.58,1.79,1.79,0,0,0,.21-.88,1.75,1.75,0,0,0-.3-1,1.54,1.54,0,0,0-.83-.6,5.06,5.06,0,0,0-1.53-.18H78.57Z"/>
			<path style={{fill: "white"}} d="M88.22,113.9V103.44h7.56v1.23H89.6v3.21h5.79v1.23H89.6v3.56H96v1.23Z"/>
			<path style={{fill: "white"}} d="M99.16,113.9V103.44h4.64a6.18,6.18,0,0,1,2.13.28,2.34,2.34,0,0,1,1.16,1,2.94,2.94,0,0,1,.43,1.57,2.63,2.63,0,0,1-.72,1.88,3.64,3.64,0,0,1-2.22,1,3.87,3.87,0,0,1,.83.52,7.17,7.17,0,0,1,1.15,1.4l1.82,2.84h-1.74l-1.38-2.17c-.41-.63-.74-1.11-1-1.45a3.51,3.51,0,0,0-.71-.7,1.94,1.94,0,0,0-.63-.27,3.63,3.63,0,0,0-.77,0h-1.61v4.64Zm1.38-5.84h3a4.53,4.53,0,0,0,1.49-.2,1.6,1.6,0,0,0,.81-.63,1.73,1.73,0,0,0,.28-.94,1.57,1.57,0,0,0-.54-1.22,2.52,2.52,0,0,0-1.7-.47h-3.32Z"/>
			<path style={{fill: "white"}} d="M47.84,66a5.7,5.7,0,1,0,5.69,5.69A5.69,5.69,0,0,0,47.84,66Zm2.39,8.84H45.7v-.9a4.42,4.42,0,0,1,.72-1.08,9.77,9.77,0,0,1,1.17-1.1,6.06,6.06,0,0,0,.95-.91,1.15,1.15,0,0,0,.28-.71.75.75,0,0,0-.85-.84.82.82,0,0,0-.63.22,1.19,1.19,0,0,0-.29.67H45.78a2.19,2.19,0,0,1,.65-1.45A2.31,2.31,0,0,1,48,68.24a2.37,2.37,0,0,1,1.6.48,1.64,1.64,0,0,1,.56,1.34,1.83,1.83,0,0,1-.17.82,3.13,3.13,0,0,1-.46.68,5,5,0,0,1-.63.58l-.66.53a5.93,5.93,0,0,0-.58.52,1.76,1.76,0,0,0-.4.56h3Z"/>
			<path style={{fill: "white"}} d="M92.68,83.11q0,5.52-3,8.48t-8.49,3H58.29V83.11H78.4c1.85,0,2.77-.9,2.77-2.7a3.18,3.18,0,0,0-.78-2.13,2.48,2.48,0,0,0-2-.91H58.29V65.92H78.4c1.85,0,2.77-.9,2.77-2.71a3.18,3.18,0,0,0-.78-2.13,2.48,2.48,0,0,0-2-.91H58.29V48.73H81.22q5.46,0,8.46,3t3,8.46v5.73a7.85,7.85,0,0,1-.82,3.5,4,4,0,0,1-2.58,2.3,6.39,6.39,0,0,1,3.4,5.66Z"/>
		</svg>
	);
}

export default WBN_STANDARD;