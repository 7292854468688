import React from 'react';

const FashionAccesories = ({ fill = "white" }) => {
	return (
		<svg id="Capa_1" data-name="Capa 1"
			xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
			<title>ICONS</title>
			<path style={{fill}} d="M20.68,17.63a.84.84,0,0,1-.85-.83.85.85,0,1,1,.85.83Z" />
			<path style={{fill}} d="M19.09,19.33a.83.83,0,0,1-.85-.83.84.84,0,0,1,.84-.86.85.85,0,0,1,.86.86A.83.83,0,0,1,19.09,19.33Z" />
			<path style={{fill}} d="M22.15,12.45a.85.85,0,1,1,0-1.7.85.85,0,0,1,0,1.7Z" />
			<path style={{fill}} d="M16.49,11a.85.85,0,1,1,.7-1.55.85.85,0,0,1-.7,1.55Z" />
			<path style={{fill}} d="M15.13,12.93a.86.86,0,0,1-.85-.85.87.87,0,0,1,.87-.85.85.85,0,0,1,.84.87A.83.83,0,0,1,15.13,12.93Z" />
			<path style={{fill}} d="M12.71,13.78a.85.85,0,0,1-.85-.84.86.86,0,0,1,.87-.86.84.84,0,0,1,.83.87A.82.82,0,0,1,12.71,13.78Z" />
			<path style={{fill}} d="M10.25,12.33a.83.83,0,0,1,.84.87.85.85,0,0,1-.85.84.89.89,0,0,1-.85-.86A.85.85,0,0,1,10.25,12.33Z" />
			<path style={{fill}} d="M7.44,10.13a.82.82,0,0,1,.84.86.84.84,0,0,1-.85.84A.88.88,0,0,1,6.58,11,.85.85,0,0,1,7.44,10.13Z" />
			<path style={{fill}} d="M8.81,8a.83.83,0,0,1,.84.87.85.85,0,0,1-.85.84A.89.89,0,0,1,8,8.87.86.86,0,0,1,8.81,8Z" />
			<path style={{fill}} d="M10.1,17.64a.85.85,0,0,1-.85.84.87.87,0,0,1-.85-.84.85.85,0,0,1,.85-.86A.83.83,0,0,1,10.1,17.64Z" />
			<path style={{fill}} d="M12.33,19a.85.85,0,0,1-.85.84.89.89,0,0,1-.86-.85.85.85,0,0,1,.86-.85A.83.83,0,0,1,12.33,19Z" />
			<path style={{fill}} d="M21.79,15.06a.83.83,0,0,1-.86-.85.86.86,0,1,1,1.71,0A.84.84,0,0,1,21.79,15.06Z" />
			<path style={{fill}} d="M8,15.07a.84.84,0,0,1,.85-.86.85.85,0,0,1,.85.85.87.87,0,0,1-.86.86A.85.85,0,0,1,8,15.07Z" />
			<path style={{fill}} d="M21.25,6a.83.83,0,0,1,.86-.82.85.85,0,1,1,0,1.7A.84.84,0,0,1,21.25,6Z" />
			<path style={{fill}} d="M19.83,3.73a.85.85,0,0,1,1.7,0,.87.87,0,0,1-.87.85A.85.85,0,0,1,19.83,3.73Z" />
			<path style={{fill}} d="M17.51,2.23a.84.84,0,0,1,.87-.82.83.83,0,0,1,.83.85.85.85,0,1,1-1.7,0Z" />
			<path style={{fill}} d="M9.78,7a.84.84,0,0,1,.86-.82.85.85,0,1,1,0,1.7A.84.84,0,0,1,9.78,7Z" />
			<path style={{fill}} d="M12.1,6.15A.83.83,0,0,1,13,5.33a.85.85,0,1,1,0,1.7A.84.84,0,0,1,12.1,6.15Z" />
			<path style={{fill}} d="M14.86,6.42a.85.85,0,1,1,.83.88A.85.85,0,0,1,14.86,6.42Z" />
			<path style={{fill}} d="M16.56,8a.85.85,0,0,1,.87-.82.84.84,0,0,1,.83.85.85.85,0,1,1-1.7,0Z" />
			<path style={{fill}} d="M4.88,12.6a.85.85,0,0,1,1.7,0,.86.86,0,0,1-.87.85A.85.85,0,0,1,4.88,12.6Z" />
			<path style={{fill}} d="M2.68,13.64a.84.84,0,0,1,.87-.82.83.83,0,0,1,.83.85.85.85,0,1,1-1.7,0Z" />
			<path style={{fill}} d="M.73,12.05a.84.84,0,0,1,.87-.82.83.83,0,0,1,.83.85.85.85,0,1,1-1.7,0Z" />
			<path style={{fill}} d="M1,9.35a.85.85,0,0,1,.87-.82.84.84,0,0,1,.83.85.85.85,0,1,1-1.7,0Z" />
			<path style={{fill}} d="M2.16,7A.84.84,0,0,1,3,6.18a.85.85,0,1,1,0,1.7A.84.84,0,0,1,2.16,7Z" />
			<path style={{fill}} d="M3.86,5a.83.83,0,0,1,.86-.82A.84.84,0,0,1,5.56,5a.87.87,0,0,1-.87.85A.85.85,0,0,1,3.86,5Z" />
			<path style={{fill}} d="M5.83,3.37a.83.83,0,0,1,.86-.82.85.85,0,1,1,0,1.7A.84.84,0,0,1,5.83,3.37Z" />
			<path style={{fill}} d="M8.4,2.06a.85.85,0,0,1,1.7,0A.87.87,0,0,1,9.23,3,.85.85,0,0,1,8.4,2.06Z" />
			<path style={{fill}} d="M11.48,1.66a.83.83,0,0,1,.86-.82.84.84,0,0,1,.84.85.87.87,0,0,1-.87.85A.85.85,0,0,1,11.48,1.66Z" />
			<path style={{fill}} d="M14.13,1.67A.83.83,0,0,1,15,.85a.84.84,0,0,1,.84.85.87.87,0,0,1-.87.85A.85.85,0,0,1,14.13,1.67Z" />
			<path style={{fill}} d="M23.27,8.87a.86.86,0,0,1-.87.85.85.85,0,0,1,0-1.7A.85.85,0,0,1,23.27,8.87Z" />
			<polygon style={{fill}} points="15.16 16.75 12.86 18.17 12.86 22.7 15.16 24.12 17.39 22.7 17.39 18.17 15.16 16.75" />
		</svg>
	)
}

export default FashionAccesories;
