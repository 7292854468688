import React from 'react';
import Helmet from 'react-helmet';
import { Link } from 'react-router-dom';

import { WBNMap } from 'components/molecules';

import goGlobalImage from 'images/b2b-tips/tip-0/IMAGE_0.png';
import imageExport from 'images/b2b-tips/tip-0/export.png';

import image0 from 'images/b2b-tips/tip-0/1.png';
import image1 from 'images/b2b-tips/tip-0/2.jpg';
import image2 from 'images/b2b-tips/tip-0/3.jpg';
import image3 from 'images/b2b-tips/tip-0/4.jpg';
import image4 from 'images/b2b-tips/tip-0/5.jpg';
import image5 from 'images/b2b-tips/tip-0/6.jpg';
import image6 from 'images/b2b-tips/tip-0/7.jpg';

import {
  Section1,
  Secion1Title,
  Section1Description,
  SectionContainer,
  SectionBody2,
  SectionBodyImageLeft,
  SectionBodyImageRight,
  SecctionTitle,
} from '../style';

import {
  Section1Body,
  ReviewBody,
  ReviewImage,
  ReviewItem,
  TwoImages,
  InfoPhone,
} from './styles';


const tips = [
  {
    sectionTitle: 'EXPORT ABROAD - A GUIDE TO EXPORT ITALIAN PRODUCTS IN THE WORLD',
    link: '/b2b-tips/export-to-the-world-tip-0',
    tipTitle: 'EXPORT TO THE WORLD',
    tipDescription: 'SELL ABROAD - QUESTIONS, ANSWERS AND GUIDE TO EXPORT IN THE WORLD do I export in the world? How can I create a network of distributors in the world? How and where I can find professional representatives who can represent my products? My products are suitable for the export market? How can I make internationalization? How can I sell abroad? How can we get paid? Who pays the freight? Who are the international buyers? What is an industrial marketing plans?... Are some of the questions we ask ourselves as industrial business men, following note a detailed solution to be applied into our companies to Export and increase our sales abroad',
    image: image0,
  },
  {
    sectionTitle: 'DISCOVER HOW TO BE ATTRACTIVE FOR THE FOREIGN MARKET',
    link: '/b2b-tips/how-to-create-export-system-tip-1',
    tipTitle: 'HOW TO CREATE EXPORT SYSTEM',
    tipDescription: 'TO BE ATTRACTIVE TO THE INTERNATIONAL IMPORTERS it\'s not enough to have a high quality product, or the best product in the industry. In order to export it\'s necessary a perfect synergy "easy and direct" between production, product, distribution and end-customers of the target market. If you want to export you must consider, in addition to the characteristics of the product, to meet the demands and needs of consumers, to create a perfect business to business relationship with the local distributor, pre setting each procedure of supplies as pre-set price lists, in the customer\'s language, policy discounts, packaging, national and international logistics, after-sales service',
    image: image1,
  },
  {
    sectionTitle: 'WHAT IS INDUSTRIAL EXPORT MARKETING OR INTERNATIONAL MARKETING',
    link: '/b2b-tips/industrial-marketing-tip-2',
    tipTitle: 'INDUSTRIAL MARKETING',
    tipDescription: "INDUSTRIAL INTERNATIONAL MARKETING \"Marketing\" derives from \"Market-ing\" which means integrating and selling the product directly on the market, then the Industrial Marketing means the sale of industrial products in the market. The expert of \"Market Penetration\" should defined the best marketing strategy for an industrial product. You can not create and/or define a marketing plan if you don't know the industrial manufacturing procedures, and if you do not know the actual commercial and industrial situation of the markets in which you want to sell. Then the Industrial Marketing International is the synergy between product and market created by market and industrial expert",
    image: image2
  },
  {
    sectionTitle: 'CREATING YOUR NETWORK OF DISTRIBUTORS IN THE WORLD',
    link: '/b2b-tips/distribution-network-tip-3',
    tipTitle: 'DISTRIBUTION NETWORK',
    tipDescription: 'CREATION OF YOUR DISTRIBUTION CHAIN ABROAD The creation of your own network of distributors in the world comes as a consequence of the implementation of an operational "Program of global industrial marketing", which will be defined according to the main business objectives, of the characteristics of the product to be exported and the synergy with the market. It means (to avoid wasting money and time) you must be able to choose the best markets suited to your product. Furthermore affects the ability of the export manager, his knowledge of languages ​​(mainly English and Spanish) and the procedures of your export foreign office',
    image: image3
  },
  {
    sectionTitle: 'WHO ARE THE INDUSTRIAL INTERNATIONAL BUYERS',
    link: '/b2b-tips/international-buyers-tip-4',
    tipTitle: 'INTERNATIONAL BUYERS',
    tipDescription: "THE BUYER IS THE INDUSTRIAL INTERNATIONAL PURCHASING MANAGER the purchasing managers from abroad are called \"Buyer\" (English word used commercially in the world), the International Buyer is a professional who specializes in the technical and in recognition of the quality of products to buy, he follows the procedures authorized by his company including the \"price you have to pay for the purchase of the product\" he, normally, is still open to negotiate the terms of payment. It's very important that the exporter understands the language of the buyer (usually English) and its culture in order to interact optimally during negotiations",
    image: image4
  },
  {
    sectionTitle: 'WHAT IS A QUALIFIED INDUSTRIAL EXPORTER',
    link: '/b2b-tips/qualified-exporting-companies-tip-5',
    tipTitle: 'QUALIFIED EXPORTING COMPANIES',
    tipDescription: `QUALIFIED EXPORTING INDUSTRY is the company that has defined, in its staff, a structure that allows it to produce according to the technical specifications of the global market, while respecting the main technical standards, health, quality and safety of the product. Also implements an "operational plan of international marketing" in synergy with the objectives of the company management. The exporter has qualified personnel for export that interacts with international buyers in accordance with the principles of morality and diplomacy. The exporter during business negotiations program the purchase orders according to the delivery time, material purchases and own production capacity`,
    image: image5
  },
  {
    sectionTitle: 'PRODUCTS THAT YOU CAN EXPORT',
    link: '/b2b-tips/best-products-for-export-tip-6',
    tipTitle: 'BEST PRODUCTS FOR EXPORT',
    tipDescription: `PRODUCTS YOU CAN EXPORT TO THE WORLD MARKET As industrial fundamental principle: all products have been created according to the market demand, then everyone can be exported around the world. But there are well-defined parameters that restrict the export such as: the size of production, units used, product certifications, regulations of foreign countries, information on labels, the ingredients used for production, not least "the price and terms of sale". We must consider a professional market research according to your market target then you can decide the best marketing strategy to reach your goals. You have to define the quality according to your market target`,
    image: image6
  }
];


const Tip0 = () => {
  window.scrollTo(0, 0);

  return (
    <div>
      <Helmet>
        <title>{`${process.env.REACT_APP_SEO_FIRST} export platform, ${process.env.REACT_APP_SEO_FIRST} products for B2B export made in ${process.env.REACT_APP_SEO_SECOND} export products manufacturing suppliers, ${process.env.REACT_APP_SEO_FIRST} B2B export platform manufacturer supply for wholesale distribution, made in ${process.env.REACT_APP_SEO_SECOND} export products manufacturing distributor supplier US`}</title>
        <meta name="description" content={`${process.env.REACT_APP_SEO_FIRST} export trade platform for business suppliers, ${process.env.REACT_APP_SEO_FIRST} manufacturing export platform business products industry, made in ${process.env.REACT_APP_SEO_SECOND} export platform manufacturing vendors to the export global wholesalers and export distributors, ${process.env.REACT_APP_SEO_FIRST} business trade guide is part of WBN industrial b2b social network, made in ${process.env.REACT_APP_SEO_SECOND} export products manufacturer export business supply, ${process.env.REACT_APP_SEO_FIRST} products for wholesale trade businesses distributors in United States, Asia, Africa, Europe, South America, Latin America and Middle East distribution, WBN the industrial b2b social network for export wholesalers and manufacturing private label industries, made in ${process.env.REACT_APP_SEO_SECOND} products manufacturer supplier for wholesalers and vendors of the market, industrial products for wholesale worldwide distributors, ${process.env.REACT_APP_SEO_FIRST} products manufacturing for private label buyers and new brand for manufacturer, business to business manufacturing supplies and market distributors, ${process.env.REACT_APP_SEO_FIRST} product manufacturing industry looking for worldwide distributors mainly in Germany, Denmark, Austria, Portugal, Spain, France, Norway, Sweden in Europe, China, Japan, Korea, Vietnam in Asia, California, New York, Florida, Chicago Illinois and all the United States of America, South Arabia, Qatar, Oman, UAE, Middle East, Australia, North of Africa, South America and all Latin America`} />
        <meta name="keywords" content={`${process.env.REACT_APP_SEO_SECOND} export platform, ${process.env.REACT_APP_SEO_FIRST} b2b platform, ${process.env.REACT_APP_SEO_FIRST} manufacturing platform, ${process.env.REACT_APP_SEO_FIRST} b2b business, ${process.env.REACT_APP_SEO_FIRST} business supply, ${process.env.REACT_APP_SEO_FIRST} vendors platform, ${process.env.REACT_APP_SEO_FIRST} social network, ${process.env.REACT_APP_SEO_FIRST} business guide, ${process.env.REACT_APP_SEO_SECOND} manufacturer vendors`} />
      </Helmet>

      <Section1>
        <Secion1Title>ITALIAN INDUSTRIAL EXPORT PLATFORM</Secion1Title>
        <Section1Description>How can we export in the world? How can we create a network of distributors in the world?, How do we find professional representatives who can represent my products? My products are suitable for the export market? How can we make internationalization? How can we sell abroad? How can we get paid for export? Who pays the freight?... Are some of the questions we ask ourselves as entrepreneurs, following the answers</Section1Description>
      </Section1>

      <SectionContainer>
        <SecctionTitle>DISCOVER HOW TO BE ATTRACTIVE FOR THE FOREIGN MARKET</SecctionTitle>
        <Section1Body>
          <div>
            <p>ITALIAN INDUSTRIAL EXPORT PLATFORM</p>
            <p>HOW TO EXPORT WORLDWIDE: there are very few companies in the USA and the world able to offer help and concrete services to the export industry. At least many offer export services, but few are really able to do so. Key elements are knowledge of foreign markets for identifying appropriate synergy with distributors and the relationship with other international industries for each of the various markets of action. To export globally the local presence, knowledge of the language and culture of the foreign country are critical to understand and to communicate with the market and consumers knowing their main habits.</p>
          </div>
          <SectionBodyImageRight width="380px" height="315px">
            <img src={goGlobalImage} alt='Go global - Export with Us'></img>
          </SectionBodyImageRight>
        </Section1Body>
      </SectionContainer>

      {tips.map(tip =>
        <SectionContainer>
          <SecctionTitle>{tip.sectionTitle}</SecctionTitle>
          <SectionBody2>
            <SectionBodyImageLeft>
              <Link to={tip.link}>
                <img src={tip.image} alt={tip.tipTitle}></img>
              </Link>
            </SectionBodyImageLeft>
            <div>
              <Link to={tip.link}>{'Icon ' + tip.tipTitle + ' summary'}</Link>
              <p>
                {tip.tipDescription}...
                <Link to={tip.link}><u>View more</u></Link>
              </p>
            </div>
          </SectionBody2>
        </SectionContainer>
      )}

      <WBNMap />
    </div>
  );
};

export const TipSummary = (props) => {
  return (
    <ReviewBody>
      {tips.map(tip =>
        <ReviewItem>
          <ReviewImage>
            <Link to={tip.link}>
              <img src={tip.image} alt={tip.tipTitle}></img>
            </Link>
          </ReviewImage>
          <Link to={tip.link}>
            <u style={{ fontSize: '12px' }}>{tip.tipTitle}</u>
          </Link>
        </ReviewItem>
      )}
    </ReviewBody>
  );
}

export const TipsFooter = (props) => {
  return (
    <div>
      <SectionContainer>
        <SecctionTitle>DISCOVER HOW TO BE ATTRACTIVE FOR THE FOREIGN MARKET</SecctionTitle>
        <TipSummary />
      </SectionContainer>
      <SectionContainer>
        <SecctionTitle>TO START B2B EXPORTING GLOBAL CALL: +39.333.6371.644</SecctionTitle>
        <Section1Description>LAUNCH YOUR PRODUCTS AND START EXPORTING GLOBAL Thanks to the "International B2B Industrial Marketing" the search a new customer,
          distributors and wholesalers in the world no longer requires expensive and demanding trips abroad for export managers; even a lot less trhan fairs that now
          are prohibitive and, many times, without an economic return. Even intermediaries are not the solution you need to start talking directly with final B2B
          distributors. EXPORT DIRECTLY Until few years ago, exporting products and services to foreign countries required long periods of time, heavy and demanding
          costs for small and meduim-sized companies. Today, thanks to oyr network dedicated to export "Business to Business", exporting is within the reach of all companies.
        </Section1Description>

        <TwoImages>
          <div>
            <img src={goGlobalImage} alt="Go global" />
          </div>
          <div>
            <img src={imageExport} alt="Export now" />
          </div>
        </TwoImages>

        <div>
          <InfoPhone>
            <p>FOR INFORMATION AND CUSTOMER SERVICES CALL: +39-333.6371.644</p>
          </InfoPhone>
        </div>
      </SectionContainer>
    </div>
  );
};

export default Tip0;