import { useToasts } from 'react-toast-notifications';

const defaultOptions = {
	autoDismiss: true
};

export default function usePreDefinedToast() {
	const { addToast } = useToasts();

	function showSuccessToast(message = 'Saved successfully') {
		addToast(message, { ...defaultOptions, appearance: 'success' });
	}

	function showFailureToast(message = 'Something was wrong, try again please') {
		addToast(message, { ...defaultOptions, appearance: 'error' });
	}

	return { showSuccessToast, showFailureToast };
}
