import React from 'react';
import { useParams } from 'react-router-dom';
import useSWR from 'swr';
import {Link} from 'react-router-dom';
import Helmet from 'react-helmet';

import {
	Loader,
	SummaryCardBusiness,
	SocialMenuActions,
	InformationSection,
	SocialSummaryReactions,
	CommentSection
} from 'components';

import isBusinessEditable from 'utils/is-business-editable';
import { ProductStyle, TechnicalSpecs, SecondaryPictures, ProductVideo } from './style';
import Products from 'pages/business-profile/products';

function buildBusinessLink({businessID, general: {companyName}}) {
  return encodeURIComponent(encodeURIComponent((companyName + '-' + businessID).replace(/ /g, '-')));
}

const BusinessProduct = ({ product, business, businessSession, reactions, user }) => {
  const editable = isBusinessEditable(businessSession);
  
  let productsReq = useSWR(`product/by-business?bid=${product.businessID}`);

	return (
		<ProductStyle>
		  <Helmet>
        <title>{`${product.name}, ${product.description.slice(0 ,64)}, ${process.env.REACT_APP_SEO_FIRST}, WBNB2B`}</title>
				<meta
					name="description"
					content={`${product.name} - ${product.description} | Product Description | In WBNB2B you will find Manufacturers, Wholesalers, Importers and Exporters`} />
			</Helmet>
			<ProductStyle.MainInfo>
				<ProductStyle.ShortDescription>
					<div>
						{product.name} / <Link to={`/business/${buildBusinessLink(business)}`}>{business.general.companyName}</Link>
					</div>
					<div>{product.description}</div>
				</ProductStyle.ShortDescription>

				<ProductStyle.ProductHead>
					<ProductStyle.ProductPictureWrapper>
						<ProductStyle.ProductPicture>
							<ProductStyle.ProductPicture.Head>
								<img src={product.mainImage?.url} alt={product.description} />
							</ProductStyle.ProductPicture.Head>
							<ProductStyle.ProductPicture.Summary>
								<SocialSummaryReactions reactions={reactions} />
							</ProductStyle.ProductPicture.Summary>
							<ProductStyle.ProductPicture.Actions>
								<SocialMenuActions resourceID={product.productID} />
							</ProductStyle.ProductPicture.Actions>
						</ProductStyle.ProductPicture>
					</ProductStyle.ProductPictureWrapper>

					<ProductStyle.SummaryCardWrapper>
						<SummaryCardBusiness businessProfile={business} businessSession={editable} />
					</ProductStyle.SummaryCardWrapper>
				</ProductStyle.ProductHead>
				<ProductStyle.TechnicalSpecs>
					<InformationSection
						title="Specifications"
						leftContent={[
							{ text: 'Industry', displayValue: product.industry.name },
							{
								text: 'Product Name',
								displayValue: product.name
							},
							{ text: 'Lead Time', displayValue: product.leadTime }
						]}
						rightContent={[
							{ text: 'Category', displayValue: product.category.name },
							{ text: 'Subcategory', displayValue: product.subCategory.name },
							{ text: 'Stock Available', displayValue: product.stock ? 'Yes' : 'No' }
						]}
					/>
					<ProductStyle.TechnicalSpecs.List>
						<span>Tecnical Specifications</span>
						<TechnicalSpecs>
							{(product.specs || []).map(({key, value}) => {
								return (
									<TechnicalSpecs.Row key={key} style={{ display: 'flex' }}>
										<div>
											<strong>{key}:</strong>
										</div>
										<div>{value}</div>
									</TechnicalSpecs.Row>
								);
							})}
						</TechnicalSpecs>
					</ProductStyle.TechnicalSpecs.List>
				</ProductStyle.TechnicalSpecs>
				<ProductStyle.Multimedia>
					{product.images ? (
						<SecondaryPictures>
							{product.images.map((img, index) => {
								return (
									<SecondaryPictures.Wrapper key={index}>
										<img src={img.url} alt={`${product.name} ${index}`}></img>
									</SecondaryPictures.Wrapper>
								);
							})}
						</SecondaryPictures>
					) : null}
					{product.videos ? (
						<ProductVideo>
							{product.videos.map(video => {
								return (
									<ProductVideo.Content key={video.videoID}>
										<video src={video.url} controls/>
									</ProductVideo.Content>
								);
							})}
						</ProductVideo>
					) : null}
				</ProductStyle.Multimedia>
				<ProductStyle.CommentSection id="comments-section">
					<CommentSection resourceID={product.productID} user={user} resourceType="product" />
				</ProductStyle.CommentSection>
			</ProductStyle.MainInfo>
			<ProductStyle.OtherInformation>
				<ProductStyle.SummaryCardWrapper style={{ maxWidth: '236px' }}>
					<SummaryCardBusiness businessProfile={business} businessSession={editable} />
				</ProductStyle.SummaryCardWrapper>
			</ProductStyle.OtherInformation>
      {productsReq.data?<Products id="products" companyName={business.general.companyName} dataSource={productsReq.data.data.data}></Products>:<Loader/>}
		</ProductStyle>
	);
};

const BusinessProductComponent = () => {
  let { id: productID } = useParams();
  productID = productID.slice(Math.max(productID.length - 36, 0));

	const { data: product, error: errorProduct } = useSWR(`product/${productID}`);
	const { data: business, error: errorBusiness } = useSWR(
		() => 'business/profile/' + product.data?.data.businessID
	);
	const { data: reactions, error: errorReactions } = useSWR(`social/reaction/summary/${productID}`);
  const { data: user } = useSWR('user/profile');

  if (errorProduct || errorBusiness || errorReactions) return <div>Error loading data</div>;
  if (!product || !business || !reactions) return <Loader />;

	return (
		<BusinessProduct
			product={product.data?.data}
			business={business.data?.data}
			businessSession={business.data?.session}
			reactions={reactions.data?.data}
			user={user ? user.data?.data : null}
		/>
	);
};

export default BusinessProductComponent;
