import { put, call } from 'redux-saga/effects';
import { actionSuccess, actionFailed } from 'shared/actions';
import apiClient from 'api-client';
import { GET_LIST_MY_BUSINESS_SUCCESS, GET_LIST_MY_BUSINESS_FAILED } from './constants';

function listMyBusinessApi() {
	return apiClient({
		url: `${process.env.REACT_APP_API_URL}business/profile/my-list`
	});
}

export function* listMyBusinessSaga() {
	try {
		const {
			data: { data }
		} = yield call(listMyBusinessApi);

		yield put(actionSuccess(GET_LIST_MY_BUSINESS_SUCCESS, data));
	} catch (error) {
		yield put(actionFailed(GET_LIST_MY_BUSINESS_FAILED, []));
	}
}
