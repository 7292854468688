import React from 'react';
import Downshift from 'downshift';
import { reduce } from 'lodash';
import { Trigger, Input, Placeholder } from './style';
import Select from '../select';

// TODO: use react-window to virtualize the options list

function createIndexableDict(dataSource, keyBy) {
	const appendEntry = (dict, option, index) => {
		dict[option[keyBy]] = { ...option, index };
		return dict;
	};

	return reduce(dataSource, appendEntry, {});
}

export default function Autocomplete({ options, onChange, defaultValue, render, style, className }) {
	const memorizedOptions = React.useMemo(() => {
		return createIndexableDict(options, 'value');
	}, [options]);

	const initialItem = memorizedOptions[defaultValue];
	const initialHighlightedIndex = initialItem && initialItem.index;

	function stateReducer(state, changes) {
		switch (changes.type) {
			case Downshift.stateChangeTypes.clickButton:
				const highlightedItem = memorizedOptions[state.selectedItem.value];

				return {
					...state,
					...changes,
					highlightedIndex: highlightedItem && highlightedItem.index,
					inputValue: ''
				};
			default:
				return changes;
		}
	}

	return (
		<Downshift
			itemToString={item => (item ? item.text : '')}
			onChange={item => item && onChange(item)}
			initialSelectedItem={options[initialHighlightedIndex]}
			initialHighlightedIndex={initialHighlightedIndex}
			stateReducer={stateReducer}
		>
			{({
				getMenuProps,
				getRootProps,
				getItemProps,
				getInputProps,
				getToggleButtonProps,
				selectedItem,
				highlightedIndex,
				isOpen,
				inputValue
			}) => {
				return (
					<Select.Container {...getRootProps()} style={style} className={className}>
						<Trigger {...getToggleButtonProps()}>
							<Input {...getInputProps()} />
							{!inputValue && options[highlightedIndex] && (
								<Placeholder>{options[highlightedIndex].text}</Placeholder>
							)}
							<Select.Arrow />
						</Trigger>
						<Select.Options {...getMenuProps()} isOpen={isOpen}>
							{options
								.filter(option => !isOpen || !inputValue || new RegExp(inputValue, 'i').test(option.text))
								.map((option, index) => (
									<Select.Option
										{...getItemProps({ index, key: option.value, item: option })}
										isHighlighted={highlightedIndex === index}
										isSelected={selectedItem === option}
									>
										{render ? render(option) : option.text}
									</Select.Option>
								))}
						</Select.Options>
					</Select.Container>
				);
			}}
		</Downshift>
	);
}
