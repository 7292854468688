import { put, call } from 'redux-saga/effects';
import { actionSuccess, actionFailed } from 'shared/actions';
import apiClient from 'api-client';
import auth from 'auth';
import { CREATE_BUSINESS_SUCCESS, CREATE_BUSINESS_FAILED } from './constants';

function createBusinessApi({ data }) {
	return apiClient.post(`${process.env.REACT_APP_API_URL}business/profile/create`, {
		...data
	});
}

export function* createBusinessSaga(action) {
	try {
		// const response = yield call(createBusinessApi, action.payload);
		yield call(createBusinessApi, action.payload);
		console.log('creando businessss');
		window.location.replace('/my-business');

		yield put(actionSuccess(CREATE_BUSINESS_SUCCESS, {}));
	} catch (error) {
		const bodyError = {code: error.data.code};
		if (error.data.code === 401) {
			auth.redirectToLogin();
		}else{
			yield put(actionFailed(CREATE_BUSINESS_FAILED, bodyError));
		}
	}
}
