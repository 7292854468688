import styled from 'styled-components';
import breakpoints from 'breakpoints';

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	border: 1px solid var(--wbn-sidebar-color-line);
	border-radius: 10px;
	overflow: hidden;
	margin-top: 30px;

	> header {
		background-color: var(--wbn-primary-color);
		font-size: 12px;
		color: white;
		display: flex;
		height: 60px;
		align-items: center;
		justify-content: center;
		text-align: center;

		${breakpoints('md')`
      background-color: transparent;

      > span {
        background-color: var(--wbn-primary-color);
        padding: 5px 10px;
        border-radius: 5px;
      }
    `}
	}
`;

export const ContentContainer = styled.div`
	display: flex;
	flex-direction: column;
	padding: 32px;

	${breakpoints('md')`
    padding-top: 0;
    padding-bottom: 0;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;

    > * {
      flex: 1;
      max-width: calc(100% / 3);
      width: calc(100% / 3);
      margin: 10px;
    }
  `}

	${breakpoints('lg')`
    padding-left: 100px;
    padding-right: 100px;
  `}

	> *:not(:last-child) {
		margin-bottom: 20px;
	}
`;

export const ProductContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;

	img {
		object-fit: cover;
		height: 100%;
		width: 100%;
	}

	p {
		text-align: center;
	}
	span {
		text-align: center;
		cursor: pointer;
		text-decoration: underline;
	}
`;
