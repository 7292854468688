import React from 'react';
import { map, isEmpty } from 'lodash';
import { InformationSection } from 'components';
import { TableHeader, CertificateContainer } from './style';

function Certificate({ name, issueDate, expireDate, photo }) {
	return (
		<CertificateContainer>
			<div>
				{photo && (
					<img
						src={photo.url}
						alt="certificate"
						height={43}
						width={43}
						style={{ objectFit: 'cover', marginRight: 10 }}
					/>
				)}
				<span>{name}</span>
			</div>
			<div>
				<label>Issue Date: </label>
				{issueDate}
			</div>
			<div>
				<label>Expire Date: </label>
				{expireDate}
			</div>
		</CertificateContainer>
	);
}

export default function CertificatesTable({ certificates }) {
	if (isEmpty(certificates)) {
		return null;
	}

	return (
		<InformationSection title="Certificates" style={{ border: '1px solid white' }}>
			<div>
				<TableHeader>
					<span>Certificate Name</span>
					<span>Issue Date</span>
					<span>Expire Date</span>
				</TableHeader>
				<div>
					{map(certificates, cert => (
						<Certificate key={cert.id} {...cert} />
					))}
				</div>
			</div>
		</InformationSection>
	);
}
