import React from 'react';


const Business = ({fill = "white"}) => {
	return (
		<svg id="Capa_1" data-name="Capa 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
			<defs>
			</defs>
			<title>ICON_BUSINESS_SERVICES</title>
			<path style={{fill}} d="M18.6,22.7A4.45,4.45,0,0,1,15.17,22a2.56,2.56,0,0,1-.6-.87H10.39a3.29,3.29,0,0,1-2.58,1.62,4.63,4.63,0,0,1-1.31,0s-.46,0-.43.3.16.63,1.26.68A6.07,6.07,0,0,0,11,22.79c.37-.27.46-.21.53-.06s.33,1.35.93,1.27.58-1.27.58-1.27,0-.33.35,0,1.09,1.26,3.72,1.08S18.8,22.68,18.6,22.7Z" />
			<path style={{fill}} d="M8.82,10.86A1.89,1.89,0,0,0,9.2,8.54c-.56-.68-.7-.61-.82-1.07A4.68,4.68,0,0,1,8,8.33a2,2,0,0,0,0,2.53A.45.45,0,0,0,8.82,10.86Z" />
			<path style={{fill}} d="M14.32,9.74a1.5,1.5,0,0,1,1.36-1.28c.7.07.71.19,1.07.06a4.09,4.09,0,0,0-.41.6,1.59,1.59,0,0,1-1.67,1.13A.36.36,0,0,1,14.32,9.74Z" />
			<path style={{fill}} d="M5,9.3s-1.81-1.12.71-3a1.37,1.37,0,0,0,.19.84C6.17,7.55,6.08,9.15,5,9.3Z" />
			<path style={{fill}} d="M2.68,7.41S3.84,5.86,1.07,5.22A1.2,1.2,0,0,1,1.2,6C1.11,6.38,1.73,7.66,2.68,7.41Z" />
			<path style={{fill}} d="M8.35,6.06s-.53-1.28-1.94.19a.82.82,0,0,1,.51.2C7.14,6.65,8.16,6.74,8.35,6.06Z" />
			<path style={{fill}} d="M6.74,13.44s-.52-1.28-1.93.19a.83.83,0,0,1,.51.2C5.53,14,6.55,14.12,6.74,13.44Z" />
			<path style={{fill}} d="M16.9,6s2.07-.3.44-2.89a1.26,1.26,0,0,1-.48.68C16.5,4.05,16,5.5,16.9,6Z" />
			<path style={{fill}} d="M12.51,7.46s1.22-1.63-1.69-2.29A1.21,1.21,0,0,1,11,6C10.86,6.38,11.52,7.73,12.51,7.46Z" />
			<path style={{fill}} d="M13.48,2.44S14.7.81,11.79.15a1.21,1.21,0,0,1,.13.79C11.84,1.36,12.49,2.71,13.48,2.44Z" />
			<path style={{fill}} d="M20.09,8.44s1.52.77,1.65-1.72a1.07,1.07,0,0,1-.64.22C20.74,6.93,19.73,7.66,20.09,8.44Z" />
			<path style={{fill}} d="M22,11.16a1.78,1.78,0,0,0-1.36,1c-.14.28-.44,1.22-1.19,1s-1.35-.38-1.35-.38-2.28-.46-3.5.31-.27-1-.19-1.05a4.16,4.16,0,0,1,2.91-.9,2.42,2.42,0,0,0,3.29-1.45S18.46,8,17.18,10.77a4.62,4.62,0,0,0-4.22,2S11.79,8,16.55,7.37a3.46,3.46,0,0,0,2.12-1A3.89,3.89,0,0,1,19.73,6a1.73,1.73,0,0,0,1.6-2c0-.55.06-.43-.63-.29A2.57,2.57,0,0,0,18.41,5.9s-.88,1-1.68.85a3.32,3.32,0,0,0-2,.36c-.31.19-.69-.78-.11-1.2s1.56-1.77.26-3.67c-.22-.2,0-1-.8,0a2.85,2.85,0,0,0-.24,3.65,3.24,3.24,0,0,1-.58,1.83A3.66,3.66,0,0,0,12,9.44a3.83,3.83,0,0,0-1.51-2.33c-1.45-1.25-1-1.52-.89-2.26,0,0,1.72,0,1.54-2.19-.29-1,.44-1.7-.71-.86S8.29,3.62,8.93,5.54c0,0-1.25-3.17-3.46-2.91-.53.06-1.08-.48-.54.89A2.18,2.18,0,0,0,7.1,4.92c1.44.15,1.73,1.21,2,1.67a9,9,0,0,0,1.19,1.59c1.07,1,1.11,3,.93,4.5a8.06,8.06,0,0,1-1-.54c-.69-.42-1-.79-3.37-1a9.36,9.36,0,0,0-2.27.19c-.24,0-1,.32-1.23-.29-.44-.79,0-1-1.66-1.95C1,8.69,1,7.87.76,9.09A2.37,2.37,0,0,0,2.08,11.9a2.47,2.47,0,0,0,1.66.16,8.46,8.46,0,0,1,3.45-.5c1.18.1,3.58,1.13,3.42,3.16h3.58a2.66,2.66,0,0,1,2.13-1.44,6.8,6.8,0,0,1,2,0c2,.47,2.14-.37,2.75-.37.85,0,1.25-.37,1.86-1.12C23.28,11.39,23.85,10.92,22,11.16Z" />
			<path style={{fill}} d="M7.1,10.92s-2.92-1-3.26-2.24A3.44,3.44,0,0,1,4,6.4c.24-.56-.16-.31-.53,0A2.93,2.93,0,0,0,3.8,9.85,5.71,5.71,0,0,0,5.63,11,4.25,4.25,0,0,1,7.1,10.92Z" />
			<path style={{fill}} d="M12.55,11.71s-2.32,2-3.54,1.75a5.68,5.68,0,0,1-1.91-.79c-.64-.41-3.47-.27-.58.11.48.06,1.89,1.71,3.49,1.29A5.66,5.66,0,0,0,11.87,13,4.21,4.21,0,0,1,12.55,11.71Z" />
			<path style={{fill}} d="M14.42,19.2a1.28,1.28,0,0,1-.33.95,1.31,1.31,0,0,1-.95.33H10.57V19.2h2.25c.21,0,.31-.1.31-.3a.36.36,0,0,0-.09-.24.26.26,0,0,0-.22-.1H10.57V17.28h2.25c.21,0,.31-.1.31-.3a.36.36,0,0,0-.09-.24.26.26,0,0,0-.22-.1H10.57V15.36h2.57a1.17,1.17,0,0,1,1.28,1.28v.64a.86.86,0,0,1-.09.39.48.48,0,0,1-.29.26.73.73,0,0,1,.38.63Z" />
		</svg>
	)
}

export default Business;