import React from 'react';

function useOnScreen(ref, rootMargin = '0px') {
	const [isIntersecting, setIntersecting] = React.useState(false);

	React.useEffect(() => {
		const observer = new IntersectionObserver(([entry]) => setIntersecting(entry.isIntersecting), {
			rootMargin
		});
		let tempRef = ref;
		if (tempRef.current) {
			observer.observe(tempRef.current);
		}
		return () => {
			observer.unobserve(tempRef.current);
		};
	}, [ref, rootMargin]);

	return isIntersecting;
}

export default useOnScreen;

