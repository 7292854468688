import React from 'react';
import { TextArea, CharacterCounter } from './style';

export default function TextAreaAtom({
	maxLength = 512,
	placeholder = 'Type here...',
	rows = 6,
	value = '',
	style,
	className,
	showCounter = true,
	...props
}) {
	return (
		<div style={{ ...style, display: 'flex', flexDirection: 'column' }} className={className}>
			<TextArea maxLength={maxLength} placeholder={placeholder} rows={rows} value={value} {...props} />
			{showCounter ? <CharacterCounter>{value.length + '/' + maxLength}</CharacterCounter> : null}
		</div>
	);
}
