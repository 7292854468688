import React from 'react';
import {
	Automation,
	ElectronicsIT,
	Energy,
	IndustrialTechnology,
	Machineries,
	PowerTransmission,
	Apparel,
	BabyWorld,
	BeautyCare,
	FashionAccesories,
	FoodAgriculture,
	Furniture,
	HealthCare,
	HomeConstruction,
	Business
} from 'images/ICONS_INDUSTRY/components/';

const Industries = {
	'0001': Automation,
	'0002': ElectronicsIT,
	'0003': Energy,
	'0004': IndustrialTechnology,
	'0005': Machineries,
	'0006': PowerTransmission,
	'0007': Apparel,
	'0008': BabyWorld,
	'0009': BeautyCare,
	'0010': FashionAccesories,
	'0011': FoodAgriculture,
	'0012': Furniture,
	'0013': HealthCare,
	'0014': HomeConstruction,
	'0015': Business
}
const IndustryIconSelector = ({id, fill}) => { 
	const Component = Industries[id];
	return  <Component fill={fill}/>
}

export default IndustryIconSelector;