import apiClient from 'api-client';
import axios from 'axios';
import { each } from 'lodash';

export async function getUploadImageRequest(URL, params) {
	const uploadImageRequest = await apiClient.post(URL, params);

	return uploadImageRequest.data.data;
}

export async function uploadImageToS3(imageFile, signedPost) {
	const form = new FormData();

	each(signedPost.fields, (value, key) => {
		form.append(key, value);
	});

	form.append('file', imageFile);

	await axios.post(signedPost.url, form, {
		sanitize: false,
		headers: {
			'content-type': 'multipart/form-data',
			'wbn-auth': localStorage.getItem('token')
		}
	});
}

export default async function getPhotoToken(url, imageFile, extras) {
	const data = {
		contentType: imageFile.type,
		fileSize: imageFile.size,
		...extras
	};

	const { signedPost, token } = await getUploadImageRequest(url, data);

	await uploadImageToS3(imageFile, signedPost);

	return token;
}
