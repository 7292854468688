import styled from 'styled-components';
import breakpoints from 'breakpoints';

export const HeadingContainer = styled.div`
	display: flex;
	flex-direction: column;

	${breakpoints('md')`
    flex-direction: row;
  `}
`;

export const ButtonsContainer = styled.div`
	display: flex;
	flex-wrap: wrap;
	margin-bottom: 25px;

	${breakpoints('sm')`
		flex-wrap: nowrap;
		justify-content: center;
	`}

	> a {
		width: calc(50% - 5px);

		:not(:nth-last-child(-n + 2)) {
			margin-bottom: 10px;
		}

		:nth-child(odd) {
			margin-right: 10px;
		}

		${breakpoints('sm')`
			width: 130px;

			:nth-child(odd) {
				margin-right: 0;
			}

			:not(:nth-last-child(-n + 2)) {
				margin-bottom: 0;
			}

			:not(:last-child) {
				margin-right: 5px;
			}
		`}
	}

	button {
		width: 100%;
		padding-left: 0;
		padding-right: 0;
		display: flex;
		align-items: center;
		justify-content: center;
	}
`;

export const TextContainer = styled.div`
	display: flex;
	flex-direction: column;
	margin-bottom: 25px;

	${breakpoints('md', 'lg')`
		flex-direction: row;
		margin-bottom: 0;

    > div:first-child {
      flex: 1;
    }
  `}
`;

export const TextContentContainer = styled.div`
	p:not(:first-child) {
		text-align: justify;
	}

	.cover-profile-container {
		height: 120px;
		/*border-radius: 10px;*/
		overflow: hidden;

		${breakpoints('md')`
			height: 220px;
		`}

		img {
			height: 100%;
			width: 100%;
			object-fit: contain; /* before it was cover*/
		}
	}
`;

export const DescriptionContainer = styled.div`
	flex: 1;
	display: flex;
	flex-direction: column;

	${breakpoints('md', 'lg')`
    width: 100%;

    ${ButtonsContainer} {
      margin-left: auto;
      margin-right: auto;
    }
  `}
`;

export const SummaryCardContainer = styled.div`
	flex: 1;
	max-width: 236px;
	margin-left: auto;
	margin-right: auto;

	${breakpoints('md')`
		margin-left: 30px;
		margin-right: 0;
  `}
`;

export const SocialIconsContainer = styled.div`
	display: flex;
	margin-top: 10px;
	flex-direction: column;
	> div {
		margin-top: 10px;
	}
	.inline-icons-container {
		display: flex;
		> *:not(:first-child) {
			margin-left: 8px;
		}
		> div:nth-child(2) > span:nth-child(3) {
			display: none;
			${breakpoints('lg')`
				display: flex;
			`}
		}
		> div:nth-child(1) > span:nth-child(3) {
			display: none;
			${breakpoints('lg')`
				display: flex;
			`}
		}
	}

	.circle-icons-container {
		display: flex;
		margin-left: auto;

		> *:not(:first-child) {
			margin-left: 15px;
		}
	}
	@media screen and (min-width: 768px) {
		flex-direction: row;
	}
`;

export const CenterBox = styled.div`
	align-items: center;
	border-radius: 5px;
	background-color: var(--wbn-primary-color);
	display: flex;
	justify-content: center;
	color: white;
	margin: 25px 0;
	text-align: center;
	padding: 6px 20px;
`;

export const InfoSectionsContainer = styled.div`
	> div:not(:last-child) {
		margin-bottom: 25px;
	}
`;

export const BusinessVideo = styled.div`
	width: 100%;
	height: 200px;
	margin: 10px 0px;
	> video {
		margin: 0 auto;
		max-width: 700px;
		height: 100%;
		display: block;
	}
	@media screen and (min-width: 768px) {
		height: 300px;
	}
`;
