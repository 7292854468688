import React from 'react';
import { map } from 'lodash';
import { InformationSection } from 'components';
import CertificatesTable from '../certificates-table';
import { Container } from './style';

function joinByKey(data, key) {
	return map(data, key).join(', ');
}

function joinByRangeKeys(obj) {
	return obj ? `${obj.min} - ${obj.max}` : null;
}

function phoneNumber(phone) {
	return phone ? `(${phone.countryCode}) ${phone.businessPhoneNumber}` : null;
}

export default function InformationSections({ id, businessProfile }) {
	return (
		<Container id={id}>
			<InformationSection
				title="General Information"
				leftContent={[
					{ text: 'Business Name', displayValue: businessProfile?.general?.companyName },
					{ text: 'Business Type', displayValue: businessProfile?.general?.businessType?.name },
					{ text: 'Main Industry', displayValue: businessProfile?.general?.industry?.name },
					{
						text: 'Main Categories',
						displayValue: joinByKey(businessProfile?.general?.categories, 'name')
					},
					{ text: 'Country', displayValue: businessProfile?.contactInfo?.country?.countryName },
					{ text: 'State', displayValue: businessProfile?.contactInfo?.state?.stateName }
				]}
				rightContent={[
					{ text: 'Year Established', displayValue: businessProfile?.general?.yearStablished },
					{
						text: 'Total Annual Revenue',
						checkValue: !!businessProfile?.general?.totalAnnualRevenue,
						displayValue: `${joinByRangeKeys(businessProfile?.general?.totalAnnualRevenue)} USD`
					},
					{
						text: 'Total Employees',
						checkValue: !!businessProfile?.general?.totalEmployees,
						displayValue: `Between ${joinByRangeKeys(businessProfile?.general?.totalEmployees)}`
					},
					{
						text: 'Total Production Employees',
						checkValue: !!businessProfile?.general?.totalProductionEmployees,
						displayValue: `Between ${joinByRangeKeys(businessProfile?.general?.totalProductionEmployees)}`
					},
					{
						text: 'Total Production Capacity',
						checkValue: !!businessProfile?.general?.productionCapacity,
						displayValue: `${joinByRangeKeys(businessProfile?.general?.productionCapacity)} ${
							businessProfile?.general?.productionCapacity?.unit
						}/${businessProfile?.general?.productionCapacity?.period}`
					}
				]}
			/>
			<InformationSection
				title="Contact Information"
				leftContent={[
					{ text: 'Legal Company Address', displayValue: businessProfile?.contactInfo?.legalAddressEnglish },
					{ text: 'City', displayValue: businessProfile?.contactInfo?.city },
					{ text: 'Postal ZIP Code', displayValue: businessProfile?.contactInfo?.zipcode },
					{ text: 'Country', displayValue: businessProfile?.contactInfo?.country?.countryName },
					{ text: 'State', displayValue: businessProfile?.contactInfo?.state?.stateName }
				]}
				rightContent={[
					{
						text: 'Shipping Company Address',
						displayValue: businessProfile?.contactInfo?.shippingAddressEnglish
					},
					{ text: 'Company Email', displayValue: businessProfile?.contactInfo?.email },
					{ text: 'Company Website', displayValue: businessProfile?.contactInfo?.website },
					{ text: 'Company Phone', displayValue: phoneNumber(businessProfile?.contactInfo?.phone) },
					{
						text: 'Company Mobile Phone',
						displayValue: phoneNumber(businessProfile?.contactInfo?.mobilePhone)
					}
				]}
			/>
			<InformationSection
				title="Factory"
				leftContent={[
					{
						text: 'Manufacturing Company Address',
						displayValue: businessProfile?.factory?.manufacturingFacilities
					},
					{ text: 'City', displayValue: businessProfile?.factory?.city },
					{ text: 'Postal ZIP Code', displayValue: businessProfile?.factory?.zipcode },
					{ text: 'Country', displayValue: businessProfile?.factory?.country?.countryName }
				]}
				rightContent={[
					{ text: 'State', displayValue: businessProfile?.factory?.state?.stateName },
					{
						text: 'Manufacturing Facilities Area',
						displayValue: businessProfile?.factory?.factorySize ? (
							<span>
								{businessProfile?.factory?.factorySize} m<sup>2</sup>
							</span>
						) : null
					},
					{
						text: 'Number of Production Lines',
						displayValue: businessProfile?.factory?.numberProductionLines
					},
					{
						text: 'Contract Manufacturing Agreement',
						displayValue: joinByKey(businessProfile?.factory?.contractManufacturing, 'code')
					}
				]}
			/>
			<InformationSection
				title="Trade Capabilities"
				headerContent={`Markets: ${joinByKey(businessProfile?.tradeCapabilities?.markets, 'name')}`}
				forceColumnOnMobile
			>
				<InformationSection.LeftContainer>
					<InformationSection.Entry
						fullHeight
						text="Languages Spoken By The Company"
						displayValue={businessProfile?.tradeCapabilities?.spokenLanguages}
					/>
				</InformationSection.LeftContainer>
				<InformationSection.LeftContainer>
					<InformationSection.Entry
						fullHeight
						text="Quantity of Sales Employees"
						displayValue={businessProfile?.tradeCapabilities?.totalEmployeesSales}
					/>
				</InformationSection.LeftContainer>
				<InformationSection.LeftContainer>
					<InformationSection.Entry
						fullHeight
						text="Lead time (in days)"
						displayValue={businessProfile?.tradeCapabilities?.averageLeadTime}
					/>
				</InformationSection.LeftContainer>
			</InformationSection>
			<InformationSection
				title="Business Terms"
				headerContent={`Accepted delivery terms: ${joinByKey(
					businessProfile?.businessTerms?.acceptedDeliveryTerms,
					'name'
				)}`}
				forceColumnOnMobile
			>
				<InformationSection.LeftContainer>
					<InformationSection.Entry
						fullHeight
						text="Accepted Currencies"
						displayValue={joinByKey(businessProfile?.businessTerms?.acceptedPaymentCurrencies, 'name')}
					/>
				</InformationSection.LeftContainer>
				<InformationSection.LeftContainer>
					<InformationSection.Entry
						fullHeight
						text="Packaging types"
						displayValue={joinByKey(businessProfile?.businessTerms?.packagingTypes, 'name')}
					/>
				</InformationSection.LeftContainer>
				<InformationSection.LeftContainer>
					<InformationSection.Entry
						fullHeight
						text="Accepted payment types"
						displayValue={joinByKey(businessProfile?.businessTerms?.acceptedPaymentTypes, 'name')}
					/>
				</InformationSection.LeftContainer>
			</InformationSection>
			<CertificatesTable certificates={businessProfile?.certificates} />
		</Container>
	);
}
