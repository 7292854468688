import styled from 'styled-components';

const CharacterCounter = styled.p`
	color: #7a7a7a;
	text-align: right;
`;

const TextArea = styled.textarea`
	padding: 10px;
	border: 1px solid rgba(34, 36, 38, 0.15);
	border-radius: 7px;
`;

export { TextArea, CharacterCounter };
