import { put, call } from 'redux-saga/effects';
import axios from 'axios';
import { SEARCH_USERS_SUCCESS, SEARCH_USERS_FAILED } from './constants';
import { actionSuccess, actionFailed } from 'shared/actions';

function searchUserApi(params) {
  params.limit = params.size;

	return axios.request({
		url: `${process.env.REACT_APP_API_URL}user/search`,
		method: 'POST',
		data: params
	});
}

export function* searchUser(action) {
	try {
		const {
			data: { data }
		} = yield call(searchUserApi, action.payload);
		const customData = {};
    customData.results = data.profiles;
    customData.total_results = data.totalResults;

		yield put(actionSuccess(SEARCH_USERS_SUCCESS, customData));
	} catch (error) {
		yield put(actionFailed(SEARCH_USERS_FAILED, []));
	}
}
