import React from 'react';
import { map } from 'lodash';
import {useHistory, Link} from 'react-router-dom';
import { ViewMoreText } from 'components';
import { Container, ContentContainer, ProductContainer } from './style';

import productPlaceholder from 'images/placeholders/product.svg';

function generateSeoFriendlyLink(companyName, productName, productID) {
  let prefix = companyName + ' ' + productName + '-';
  return encodeURIComponent(encodeURIComponent(prefix.replace(/ /g, '-')) + productID);
}

function Product({ name, description, mainImage, productID, companyName }) {
	const history = useHistory();
	return (
		<ProductContainer>
			<div style={{ height: 172, width: 172 }}>
        <Link to={`/business-product/${generateSeoFriendlyLink(companyName, name, productID)}`}>
				  <img src={mainImage?.url || productPlaceholder} alt="product" />
        </Link>
			</div>
			<span onClick={()=>history.push(`/business-product/${generateSeoFriendlyLink(companyName, name, productID)}`)}>{name}</span>
			<ViewMoreText maxPreviewChars={200}>{description}</ViewMoreText>
		</ProductContainer>
	);
}

export default function Products({ id, dataSource, companyName }) {
	return (
		<Container id={id}>
			<header>
				<span>Main products of {companyName}</span>
			</header>
			<ContentContainer>
				{map(dataSource, product => (
					<Product key={product.productID} companyName={companyName} {...product} />
				))}
			</ContentContainer>
		</Container>
	);
}
