import styled, { css } from 'styled-components';

const optionHeight = 35;

const truncateStyle = css`
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
`;

export const Container = styled.div`
	background-color: white;
	border: 1px solid rgba(34, 36, 38, 0.15);
	position: relative;
	height: ${optionHeight}px;

	&,
	* {
		cursor: pointer;
	}
`;

export const Button = styled.button`
	align-items: center;
	appearance: none;
	background-color: transparent;
	border: none;
	box-sizing: border-box;
	display: flex;
	font-size: 14px;
	height: 100%;
	outline: none;
	padding: 0 16px;
	text-align: left;
	width: 100%;

	> span {
		${truncateStyle}
	}
`;

export const Arrow = styled.i`
	border-color: black transparent transparent transparent;
	border-style: solid;
	border-width: 4px 4px 0 4px;
	height: 0;
	margin-left: auto;
	width: 0;
`;

export const Options = styled.div`
	appearance: none;
	background-color: white;
	border-radius: 0 0 5px 5px;
	box-shadow: 0 2px 3px 0 rgba(34, 36, 38, 0.15);
	display: none;
	left: 0;
	max-height: ${optionHeight * 4}px;
	overflow-x: hidden;
	overflow-y: auto;
	position: absolute;
	right: 0;
	z-index: 1;

	${props =>
		props.isOpen &&
		css`
			display: block;
		`}
`;

export const Option = styled.div`
	align-items: center;
	align-items: center;
	appearance: none;
	box-sizing: border-box;
	cursor: pointer;
	display: flex;
	font-size: 14px;
	min-height: ${optionHeight}px;
	padding: 10px 16px;
	flex-shrink: 0;

	&:not(:last-child) {
		border-bottom: 1px solid #efefef;
	}

	> span,
	div {
		${truncateStyle}
	}

	${props =>
		props.isHighlighted &&
		css`
			background-color: rgba(0, 0, 0, 0.03);
		`}

	${props =>
		props.isSelected &&
		css`
			font-weight: bold;
		`}
`;
