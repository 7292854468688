import React from 'react';
import Helmet from 'react-helmet';

import { WBNMap } from 'components/molecules';

// import { } from './styles.js';

import g1 from 'images/b2b-tips/tip-2/GRAPHIC.png';
import ideaImg from 'images/b2b-tips/tip-2/1.jpg';
import carControl from 'images/b2b-tips/tip-2/2.jpg';
// import dabieIcon from 'images/b2b-tips/tip-2/WBN-DABIE.svg';

import { TipsFooter } from '../tip0';

import {
  Section1,
  Secion1Title,
  SecctionTitle,
  Section1Description,
  GraphicSection,
  GraphicTitle,
  GraphicDescription,
  SectionContainer,
  SectionBody1,
  SectionBody2,
  SectionBodyImage,
  SectionBodyImageLeft
} from '../style';

const Tip0 = () => {
  window.scrollTo(0, 0);

  return (
    <div>
      <Helmet>
        <title>{`Industrial export marketing, ${process.env.REACT_APP_SEO_FIRST} industrial exporting marketing plan for manufacturing business, b2b industrial export marketing plan worldwide products sell, ${process.env.REACT_APP_SEO_FIRST} industrial export marketing business ${process.env.REACT_APP_SEO_SECOND} exporting supply partnership distributors`}</title>
        <meta name="description" content={`${process.env.REACT_APP_SEO_FIRST} industrial export marketing is an export trade platform for business companies, ${process.env.REACT_APP_SEO_FIRST} industrial manufacturing products export marketing business plan for industries, made in ${process.env.REACT_APP_SEO_SECOND} export platform manufacturing vendors to the export global wholesalers and export distributors, ${process.env.REACT_APP_SEO_FIRST} business trade guide is part of WBN industrial b2b social network, made in ${process.env.REACT_APP_SEO_SECOND} export products manufacturer export business supply, ${process.env.REACT_APP_SEO_FIRST} products for wholesale trade businesses distributors in United States, Asia, Africa, Europe, South America, Latin America and Middle East distribution, WBN the industrial b2b social network for export wholesalers and manufacturing private label industries, made in ${process.env.REACT_APP_SEO_SECOND} products manufacturer supplier for wholesalers and vendors of the market, industrial products for wholesale worldwide distributors, ${process.env.REACT_APP_SEO_FIRST} products manufacturing for private label buyers and new brand for manufacturer, business to business manufacturing supplies and market distributors, ${process.env.REACT_APP_SEO_FIRST} product manufacturing industry looking for worldwide distributors mainly in Germany, Denmark, Austria, Portugal, Spain, France, Norway, Sweden in Europe, China, Japan, Korea, Vietnam in Asia, California, New York, Florida, Chicago Illinois and all the United States of America, South Arabia, Qatar, Oman, UAE, Middle East, Australia, North of Africa, South America and all Latin America`} />
        <meta name="keywords" content={`${process.env.REACT_APP_SEO_SECOND} export marketing, ${process.env.REACT_APP_SEO_FIRST} b2b marketing, ${process.env.REACT_APP_SEO_FIRST} manufacturing marketing, ${process.env.REACT_APP_SEO_FIRST} b2b products, ${process.env.REACT_APP_SEO_FIRST} business marketing, ${process.env.REACT_APP_SEO_FIRST} vendors platform, ${process.env.REACT_APP_SEO_FIRST} social network, ${process.env.REACT_APP_SEO_FIRST} business guide, ${process.env.REACT_APP_SEO_SECOND} manufacturer vendors`} />
      </Helmet>

      <Section1>
        <Secion1Title>WHAT IS INDUSTRIAL EXPORT MARKETING</Secion1Title>
        <Section1Description>WHAT IS INDUSTRIAL EXPORT MARKETING? Marketing derives from the "Market-ing action" which means the penetration or sale of the product directly on market, then the Industrial Marketing for exporting means the sale of industrial products in the world market. The expert in penetration and export defines the best marketing strategy for each industrial product. It's impossible to create and/or define a marketing plan if you do not know the manufacturer's industrial reality in depth, let alone if you do not know the commercial and cultural industrial reality of each market in which you want to sell</Section1Description>
      </Section1>

      <GraphicSection>
        <SecctionTitle>INDUSTRIAL MARKETING: SYNERGY BETWEEN PRODUCT AND MARKET CREATED BY A TECHNICIAN</SecctionTitle>
        <GraphicTitle>Following we will develop a number of ideas and suggestions in order to help you better define your export strategy</GraphicTitle>

        <img src={g1} alt='INTERNATIONAL INDUSTRIAL MARKETING'></img>

        <GraphicDescription>INTERNATIONAL INDUSTRIAL MARKETING The international Industrial Marketing is the synergy between product and market created by a specialized technician who knows well the market and the industrial production reality. In Italy it is called "Internationalization" which includes all the company, product and market actions, in order to create the ideal conditions for exporting. It is absolutely necessary that a product expert (production, sales, logistics, engineering, safety and quality) and foreign markets define the operational program for internationalization REQUEST YOUR MARKETING EXPORT PROGRAM</GraphicDescription>
      </GraphicSection>

      <SectionContainer>
        <SecctionTitle maxWidth='70%'>WHAT SHOULD YOU BE ABLE TO REALIZE AN EXPORT INDUSTRIAL MARKETING TECHNICIAN</SecctionTitle>

        <SectionBody1>
          <div>
            <p>MUST KNOW THE INDUSTRIAL PRODUCTION SYSTEM a good industrial marketing program must include the manufacturer's production capacity and full technical characteristics of the products based on international reference markets.</p>
            <p>MUST BE ABLE TO CREATE A PRODUCT SYSTEM a professional product system it's one of the most important conditions for export is the interaction of products to be marketed with the needs</p>
          </div>

          <SectionBodyImage>
            <img src={ideaImg} alt='WHAT SHOULD YOU BE ABLE TO REALIZE AN EXPORT INDUSTRIAL MARKETING TECHNICIAN'></img>
          </SectionBodyImage>

          <div>
            <p>and demands of the market, moreover the knowledge of international regulations helps to define a realistic plan of industrial marketing.</p>
            <p>MUST KNOW, DIRECTLY, THE INTERNATIONAL MARKET it is essential to know directly the industrial and commercial reality of the United States, China, Asia, Australia, Middle East, Africa, Russia, Europe, South America and Central America.</p>
          </div>
        </SectionBody1>
      </SectionContainer>

      <SectionContainer>
        <SecctionTitle>HOW TO CREATE A PRODUCT SYSTEM SUITABLE</SecctionTitle>

        <SectionBody2>
          <SectionBodyImageLeft>
            <img src={carControl} alt="HOW TO CREATE A PRODUCT SYSTEM SUITABLE"></img>
          </SectionBodyImageLeft>
          <div>
            <p>WHAT IS A COMPANY PRODUCT SYSTEM? An industrial product system are all the conditions that are absolutely necessary to propose your product in any market at a global level. The Product System must consider the main technical characteristics required by international regulations, quality, safety and health certifications of the reference market and all that the end customer requires. In addition, the product must be packaged with an impactful design, labeled according to law, must indicate expiry times, type of use and maintenance (if machinery, accompanied by booklets with instructions for use, maintenance, wiring diagram and safety). It must be securely packed, every single product packaged for shipping, must have a traceability system, referring to its lot of belonging.</p>
            <p>PRICE LIST FOR INTERNATIONALIZATION Pricing should be considered as an important factor to define your "Product System", price list for distributor, retailer and recommended for end users.</p>
          </div>
        </SectionBody2>
      </SectionContainer>

      <TipsFooter />
      <WBNMap />
    </div>
  );
};

export default Tip0;
