import 'wbn_domain_styles';

import React, { Suspense, lazy } from 'react';
import { Provider } from 'react-redux';
import { ToastProvider } from 'react-toast-notifications';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import { SWRConfig } from 'swr';

import { Layout, Header } from './components';
import SetIDComponent from './components/SetIDComponent/SetIDComponent';

import Helmet from 'react-helmet';

import {
	ContactUsForm,
	WhyWBN,
	OurNetwork,
	Home,
	HomeSearch,
	Profile,
	Newsfeed,
	Timeline,
	UserProfileEdit,
	ProfilePublic,
	BusinessMembership,
	MyBusiness,
	Industries,
	BusinessCreate,
	BusinessProfile,
	MissionVision,
	Membership,
	SendRequest,
	BusinessRequests,
} from './pages';

import './shared/functions-globals';

import configureStore from './store/configureStore';
import fetch from './libs/fetch';

// Lazy import
const AboutUs = lazy(() => import('./pages/static/about-us'));
const TermsConditions = lazy(() => import('./pages/static/terms-and-conditions'));
const ContactUs = lazy(() => import('./pages/static/contact-us'));
const BusinessProduct = lazy(() => import('./pages/business-product'));
const PrivacyPolicy = lazy(() => import('./pages/static/privacy-policy'));
const BusinessEditProfile = lazy(()=> import('./pages/business-edit-profile'));

const FreeMarketing = lazy(() => import('./pages/static/free-marketing'));
const AdvertiseWithUs = lazy(() => import('./pages/static/advertise-with-us'));
const MessageCenter = lazy(() => import('./pages/static/message-center'));
const WorkWithWbn = lazy(() => import('./pages/static/work-with-wbn'));

const B2bTip0 = lazy(() => import('./pages/static/b2b-tips/tip0'));
const B2bTip1 = lazy(() => import('./pages/static/b2b-tips/tip1'));
const B2bTip2 = lazy(() => import('./pages/static/b2b-tips/tip2'));
const B2bTip3 = lazy(() => import('./pages/static/b2b-tips/tip3'));
const B2bTip4 = lazy(() => import('./pages/static/b2b-tips/tip4'));
const B2bTip5 = lazy(() => import('./pages/static/b2b-tips/tip5'));
const B2bTip6 = lazy(() => import('./pages/static/b2b-tips/tip6'));
// End lazy import
const store = configureStore();

function Wrapper({ body, navBar, ...props }) {
	return (
		<Layout {...props}>
			{navBar}
			<div className="container-layout__content">
				<div className="container-layout__children">{body}</div>
			</div>
		</Layout>
	);
}

const PageContainerSearch = ({ path, Component, isSearch = true }) => {
	return (
		<Route
			exact
			path={path}
			render={props => (
				<Wrapper
					{...props}
					navBar={<Header isSearch={isSearch} {...props} />}
					body={<Component {...props} />}
				/>
			)}
		/>
	);
};
function App() {
	return (
		<Provider store={store}>
			<SWRConfig value={{ fetcher: fetch, revalidateOnFocus: false }}>
				<ToastProvider>
					<Helmet>
						{process.env.REACT_APP_G_ANALYTICS ? (
							<script
								async
								src={`https://www.googletagmanager.com/gtag/js?id=${process.env.REACT_APP_G_ANALYTICS}`}
							/>
						) : null}
						{process.env.REACT_APP_G_ANALYTICS ? (
							<script>
								{`
										window.dataLayer = window.dataLayer || [];
										function gtag(){dataLayer.push(arguments)}
										gtag('js', new Date());
										gtag('config', '${process.env.REACT_APP_G_ANALYTICS}');
									`}
							</script>
						) : null}
					</Helmet>
					<Suspense fallback={<div>Loading...</div>}>
						<Router>
							<Switch>
								<PageContainerSearch exact path="/" Component={Home} isSearch={false} />
								<PageContainerSearch exact path="/profile/" Component={Profile} />
								<PageContainerSearch exact path="/profile/edit" Component={UserProfileEdit} />
								<PageContainerSearch exact path="/newsfeed" Component={Newsfeed} />
								<PageContainerSearch exact path="/timeline/:timelineID" Component={Timeline} />
                <PageContainerSearch exact path="/profile/:id" Component={ProfilePublic} />
								<PageContainerSearch exact path="/search-result" Component={HomeSearch} />
								<PageContainerSearch exact path="/my-business" Component={MyBusiness} />
								<PageContainerSearch exact path="/business/create" Component={BusinessCreate} />
								<PageContainerSearch exact path="/business/:id" Component={BusinessProfile} />
								<PageContainerSearch exact path="/business-product/:id" Component={BusinessProduct} />
								<Route
									exact
									path="/business/:id/member(s{0,1})"
									render={props => (
										<Wrapper
											{...props}
											navBar={<Header isSearch={true} {...props} />}
											body={<BusinessEditProfile {...props} />}
										/>
									)}
								/>
								<PageContainerSearch exact path="/business/:id/product(s{0,1})" Component={BusinessEditProfile} />
								<PageContainerSearch exact path="/business/:id/send-request" Component={SendRequest} />
								<Route
									exact
									path="/business/:id/requests/:rid?"
									render={props => (
										<Wrapper
											{...props}
											navBar={<Header isSearch={true} {...props} />}
											body={<BusinessRequests {...props} />}
										/>
									)}
								/>
								<Route
									exact
									path="/business/:id/edit/:tab?"
									render={props => (
										<Wrapper
											{...props}
											hasFooter={false}
											navBar={<Header isSearch={true} {...props} />}
											body={<BusinessEditProfile {...props} />}
										/>
									)}
								/>
								<PageContainerSearch exact path="/business/:id/membership" Component={BusinessMembership} />/
								<PageContainerSearch path="/industries" Component={Industries} />
								<PageContainerSearch path="/about-us" Component={AboutUs} />
								<PageContainerSearch path="/mission-vision" Component={MissionVision} />
								<PageContainerSearch path="/terms-and-conditions" Component={TermsConditions} />
								<PageContainerSearch path="/privacy-policy" Component={PrivacyPolicy} />
								<PageContainerSearch path="/our-network" Component={OurNetwork} />
								<PageContainerSearch path="/why-WBN" Component={WhyWBN} />
								<PageContainerSearch exact path="/contact-us" Component={ContactUs} />
								<PageContainerSearch exact path="/contact-us/form" Component={ContactUsForm} />

                <PageContainerSearch path="/membership-standard" Component={props => <Membership membershipType="Standard" />} />
								<PageContainerSearch path="/membership-gold" Component={props => <Membership membershipType="Gold" {...props} />} />
								<PageContainerSearch path="/membership-premium" Component={props => <Membership membershipType="Premium" {...props} />} />
								<PageContainerSearch path="/membership-platinum" Component={props => <Membership membershipType="Platinum" {...props} />} />

                <PageContainerSearch path="/advertise-with-us" Component={AdvertiseWithUs} />
                <PageContainerSearch path="/free-marketing" Component={FreeMarketing} />
                <PageContainerSearch path="/message-center" Component={MessageCenter} />
                <PageContainerSearch path="/work-with-wbn" Component={WorkWithWbn} />

                <PageContainerSearch path="/b2b-tips/export-to-the-world-tip-0" Component={props => <B2bTip0 {...props}/>}/>
                <PageContainerSearch path="/b2b-tips/how-to-create-export-system-tip-1" Component={props => <B2bTip1 {...props}/>}/>
                <PageContainerSearch path="/b2b-tips/industrial-marketing-tip-2" Component={props => <B2bTip2 {...props}/>}/>
                <PageContainerSearch path="/b2b-tips/distribution-network-tip-3" Component={props => <B2bTip3 {...props}/>}/>
                <PageContainerSearch path="/b2b-tips/international-buyers-tip-4" Component={props => <B2bTip4 {...props}/>}/>
                <PageContainerSearch path="/b2b-tips/qualified-exporting-companies-tip-5" Component={props => <B2bTip5 {...props}/>}/>
                <PageContainerSearch path="/b2b-tips/best-products-for-export-tip-6" Component={props => <B2bTip6 {...props}/>}/>

                <Route exact path="/SetID/" render={props => <Wrapper {...props} navBar={<Header />} body={<SetIDComponent />} />} />

								<Redirect to="/" />
							</Switch>
						</Router>
					</Suspense>
				</ToastProvider>
			</SWRConfig>
		</Provider>
	);
}

export default App;
