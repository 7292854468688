import styled from 'styled-components'

export const SocialSummaryStyle = styled.div`
  display: flex;
`
export const SocialIcon = styled.div`
  width: 14px;
  > img{
    width: 100%;
    height: 14px;
  }
`

export const SocialCounter = styled.div`
  font-size: 11px;
  margin-left: 5px;
`
