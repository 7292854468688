import React from 'react';
import { truncate } from 'lodash';
import styled from 'styled-components';

const ViewMore = styled.p`
text-align: justify;
`;

const ViewMoreTrigger = styled.span`
color: cornflowerblue;
cursor: pointer;
`;

export default function ViewMoreText({ children: text, maxPreviewChars }) {
  const [isViewMoreActived, setViewMore] = React.useState(false);
  const truncatedText = truncate(text, { length: maxPreviewChars });

  return (
    <ViewMore>
      {isViewMoreActived ? text : truncatedText}
      {` `}
      {text?.length > truncatedText?.length && (
        <ViewMoreTrigger onClick={() => setViewMore(s => !s)}>
          {isViewMoreActived ? 'View less' : 'View more'}
        </ViewMoreTrigger>
      )}
    </ViewMore>
  );
}
