import React from 'react';
import Helmet from 'react-helmet';

import { WBNMap } from 'components/molecules';
// import { Button } from 'components/atoms';

// import { } from './styles.js';

import g1 from 'images/b2b-tips/tip-1/WBN.png';
import chessImage from 'images/b2b-tips/tip-1/1.jpg';
import img2 from 'images/b2b-tips/tip-1/2.jpg';
import img3 from 'images/b2b-tips/tip-1/3.jpg';
// import dabieIcon from 'images/b2b-tips/tip-1/WBN-DABIE.svg';

import { TipsFooter } from '../tip0';

import {
  Section1,
  Secion1Title,
  SecctionTitle,
  Section1Description,
  GraphicSection,
  GraphicTitle,
  GraphicDescription,
  SectionContainer,
  SectionTwoColumns,
  SectionBody,
  SectionBody1,
  SectionBodyImage,
} from '../style';


const Tip0 = () => {
  window.scrollTo(0, 0);

  return (
    <div>
      <Helmet>
        <title>{`How to start an export business, ${process.env.REACT_APP_SEO_FIRST} products manufacturing for export business supply, how export products manufacturing business synergy, b2b exporting made in ${process.env.REACT_APP_SEO_SECOND} products manufacturer supply, how to start exporting business plan worldwide market`}</title>
        <meta name="description" content={`How to start a business to business export company ${process.env.REACT_APP_SEO_FIRST} export trade platform for business companies, ${process.env.REACT_APP_SEO_FIRST} manufacturing products for export business industry, made in ${process.env.REACT_APP_SEO_SECOND} export platform manufacturing vendors to the export global wholesalers and export distributors, ${process.env.REACT_APP_SEO_FIRST} business trade guide is part of WBN industrial b2b social network, made in ${process.env.REACT_APP_SEO_SECOND} export products manufacturer export business supply, ${process.env.REACT_APP_SEO_FIRST} products for wholesale trade businesses distributors in United States, Asia, Africa, Europe, South America, Latin America and Middle East distribution, WBN the industrial b2b social network for export wholesalers and manufacturing private label industries, made in ${process.env.REACT_APP_SEO_SECOND} products manufacturer supplier for wholesalers and vendors of the market, industrial products for wholesale worldwide distributors, ${process.env.REACT_APP_SEO_FIRST} products manufacturing for private label buyers and new brand for manufacturer, business to business manufacturing supplies and market distributors, ${process.env.REACT_APP_SEO_FIRST} product manufacturing industry looking for worldwide distributors mainly in Germany, Denmark, Austria, Portugal, Spain, France, Norway, Sweden in Europe, China, Japan, Korea, Vietnam in Asia, California, New York, Florida, Chicago Illinois and all the United States of America, South Arabia, Qatar, Oman, UAE, Middle East, Australia, North of Africa, South America and all Latin America`} />
        <meta name="keywords" content={`${process.env.REACT_APP_SEO_SECOND} export business, ${process.env.REACT_APP_SEO_FIRST} b2b business, ${process.env.REACT_APP_SEO_FIRST} manufacturing platform, ${process.env.REACT_APP_SEO_FIRST} b2b products, ${process.env.REACT_APP_SEO_FIRST} business platform, ${process.env.REACT_APP_SEO_FIRST} vendors platform, ${process.env.REACT_APP_SEO_FIRST} social network, ${process.env.REACT_APP_SEO_FIRST} business guide, ${process.env.REACT_APP_SEO_SECOND} manufacturer vendors`} />
      </Helmet>

      <Section1>
        <Secion1Title>HOW TO START AN EXPORT BUSINESS OF INDUSTRIAL PRODUCTS.</Secion1Title>
        <Section1Description>It is not enough to have a quality product or the best product in the world industry. To export it requires a total "easy and direct" synergy between production, product, distribution and reference market. When you want to export you need to consider in addition to the characteristics of the product, to meet the demands and needs of consumers and to create a perfect synergy with the local distributor so that all supply procedures have been pre-set including price lists, policy discounts, packaging, logistics and after-sales service.</Section1Description>
      </Section1>

      <GraphicSection>
        <SecctionTitle>SYNERGY BETWEEN, PRODUCTION, PRODUCT, DISTRIBUTION AND MARKET</SecctionTitle>
        <GraphicTitle>Following we will develop a series of recommendations in order to help you to better define your customized export strategy</GraphicTitle>

        <img src={g1} alt="SYNERGY BETWEEN, PRODUCTION, PRODUCT, DISTRIBUTION AND MARKET"></img>

        <GraphicDescription>EXPORTING IS LIKE A CHESS MATCH from the beginning you have to create a strategic plan to succeed in acquiring new market shares, or at least not to lose any of them. In the chess game the tactic concerns the study of short-term actions, short enough to be calculated in advance by a player, the same in the exporting process, the company will have to have an operational customized plan, for each market sector, shortly, and then in the long term, to anticipate the requests and needs of the most important markets in the world YOU MUST CREATE YOUR CUSTOMIZED EXPORT SYSTEM</GraphicDescription>
      </GraphicSection>

      <SectionContainer>
        <SecctionTitle maxWidth='70%'>EXPORT ABROAD - A GUIDE TO EXPORT ITALIAN PRODUCTS IN THE WORLD</SecctionTitle>

        <SectionBody1>
          <div>
            <p>THE FIRST RULE TO BECOME EXPORTER the owners of the company must want to export. Without direct manager and management staff involvement it will be impossible to create the conditions for export.</p>
            <p>THE COMPANY MUST SEARCH CUSTOMERS DIRECTLY ON THE MARKET It must be imperative for the company to search new markets, using all the new industrial marketing techniques and</p>
          </div>
          <SectionBodyImage>
            <img src={chessImage} alt=""></img>
          </SectionBodyImage>
          <div>
            <p>technology available mainly internet. Now there are very few buyers looking for suppliers visiting directly the Italian industrial parks.</p>
            <p>HOW WERE SEARCHED CUSTOMERS IN THE PAST? when the information on customers and markets was only on some written guide, entrepreneurs could find future partners and distributors only at trade fairs, now outdated because they were expensive and inefficient.</p>
          </div>
        </SectionBody1>
      </SectionContainer>

      <SectionTwoColumns>
        <SectionContainer>
          <SecctionTitle>EXPORT ABROAD - A GUIDE TO EXPORT ITALIAN PRODUCTS</SecctionTitle>

          <SectionBody>
            <img src={img2} alt=""></img>
            <p>EXPORT MANUFACTURING SYSTEM into the modern industry, production involves all the activities necessary (process, personnel and procedures) to achieve company goals. Then, from the warehouse, every machine tool, company cleaning, assembly department, testers, purchasing manager, production planning, insurance of the quality, responsible for safety, workshop leader, technical manager, foreign trade manager, packaging, logistics, accounting, administrator, management .. etc. they are all fundamental for having a Production system suitable for export.</p>
            <p>EXPORT SYSTEM AND PRODUCTIVITY to make a set of activities work in an easy, safe and efficient way it is necessary to create a series of professional procedures (in writing) customized according to the company's reality. These procedures have the task of "simplifying" every single productive activity, so that each component of the system created, knows perfectly their duties and obligations in comparison with the other managers of the company production chain. Therefore, Productivity in the industry is the result of the actions carried out by each single production unit which, working in synergy, together create the IDEAL COMPANY SYNERGY</p>
            <p>THE COMPANY SYSTEM IS RECOGNIZABLE BY THE MARKET When a company production system it's done in a professional way this will be positively perceived by the customers, whoelsalers, competitors, international buyers and/or distributors. This means that the quality and productivity of the company can be easily assessed by experts in the international market.</p>
          </SectionBody>
        </SectionContainer>

        <SectionContainer>
          <SecctionTitle>HOW TO CREATE A PRODUCT SYSTEM SUITABLE</SecctionTitle>
          <SectionBody>
            <img src={img3} alt=""></img>
            <p>WHAT IS A COMPANY PRODUCT SYSTEM A perfect industrial "Products System" are all the conditions strictly necessary to propose your product in any market at a global level. The Product System must consider the main technical characteristics and full specifications required by international regulations, quality, safety and health certifications of the reference market and all that the end customer requires. In addition, the product must be packaged with an impactful design, labeled according to law, must indicate expiry times, type of use and maintenance (if machinery, accompanied by booklets with instructions for use, maintenance, wiring diagram and safety). It must be securely packed, every single product should be packaged for shipping and must have a traceability system, referring to its lot of belonging.</p>
            <p>PRICE LIST should be considered as a very important factor to define your "Product System", you have to define a price list for distributor, for retailer and the one recommended to the end user.</p>
            <p>CREATION OF YOUR PRODUCT SYSTEM it is necessary to carry out a deeped and detailed study of your manufacturing system (including machines, procedures and human resources) also of the markets in which you want to export. Once you have obtained the current status of the product system you must define, in writing, the actions necessary to achieve the goals to have a very "Atractive Product System" for foreign markets</p>
          </SectionBody>
        </SectionContainer>
      </SectionTwoColumns>

      <TipsFooter />
      <WBNMap />
    </div>
  );
};

export default Tip0;
