import React from 'react';
import { Loader } from 'components/atoms';

import styled from 'styled-components';

const ButtonBasic = styled.button`
border-radius: 0.5rem;
border: none;
color: white;
cursor: pointer;
display: block;
font-family: Arial, Helvetica, sans-serif;
font-size: 14px;
font-style: normal;
font-weight: 500;
line-height: 1em;
min-height: 30px;
outline: none;
padding: 0.48571429em 1.0em 0.48571429em;
text-align: center;
text-decoration: none;
:hover {
  box-shadow: 0 0 0 1px transparent inset, 0 0 0 0 rgba(34, 36, 38, 0.15) inset !important;
}
:disabled{
	opacity: 0.5;
}
`;

const Primary = styled(ButtonBasic)`
background-color: var(--wbn-primary-color) !important;
:hover {
  background-color: var(--wbn-primary-color_hover) !important;
}
`;

const Secondary = styled(ButtonBasic)`
background-color: var(--wbn-sidebar-color-line) !important;
:hover {
  background-color: var(--wbn-sidebar-color-line_hover) !important;
}
`;

const Cancel = styled(ButtonBasic)`
background-color: #aaa !important;
`;

const Button = ({ flavor = 'primary', children, isLoading = false, ...props }) => {
  if (flavor === 'primary') {
    return <Primary {...props}>{isLoading ? <Loader /> : children}</Primary>;
  }
  if (flavor === 'secondary') {
    return <Secondary {...props}>{isLoading ? <Loader /> : children}</Secondary>
  }
  if (flavor === 'cancel') {
    return <Cancel {...props}>{isLoading ? <Loader /> : children}</Cancel>
  }
  return (<button {...props}>{isLoading ? <Loader /> : children}</button>);
}

export default Button;
