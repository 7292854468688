import styled from 'styled-components';
import breakpoints from 'breakpoints';

export const Container = styled.div`
	display: flex;
	flex-direction: column;

	> header {
		margin-bottom: 20px;

		> *:first-child {
			width: 180px;
		}

		${breakpoints('md')`
			margin-left: 20px;
		`}
	}
`;

export const Content = styled.div`
	display: flex;

	${props =>
		props.isViewMoreEnabled &&
		`
		flex-direction: column;
	`}

	${props =>
		props.forceColumnOnMobile &&
		breakpoints('xs', 'md')`
		flex-direction: column;
	`}

	> * {
		flex: 1;

		${props =>
			props.forceColumnOnMobile &&
			breakpoints('xs', 'md')`
			:not(:last-child) {
				border-bottom: 1px solid white;
			}
		`}
	}
`;

export const LeftContainer = styled.div`
	display: flex;
	flex-direction: column;
`;

export const RightContainer = styled.div`
	display: flex;
	flex-direction: column;
	display: none;
	border-top: 1px solid white;

	${props =>
		props.isViewMoreEnabled &&
		`
		display: flex;
	`}

	${breakpoints('md')`
		display: flex;
		border-top: none;
	`}
`;

export const EntryContainer = styled.div`
	display: flex;
	height: 40px;
	font-size: 12px;

	:not(:last-child) {
		border-bottom: 1px solid white;
	}

	> * {
		display: flex;
		flex: 1;
		align-items: center;
		padding: 5px 15px;
	}

	> label {
		background-color: var(--wbn-primary-color_background);
	}

	> div {
		background-color: var(--wbn-primary-color_navbar);
		overflow: auto;

		> span {
			display: flex;
			height: 100%;
			align-items: center;

			${props =>
				props.shouldNotCentered &&
				`
			align-items: flex-start;
		`}
		}
	}
`;

export const ViewMoreContainer = styled.div`
	display: flex;
	justify-content: flex-end;
	color: cornflowerblue;
	margin-top: 5px;
	cursor: pointer;

	${breakpoints('md')`
		display: none;
	`}
`;

export const FooterContainer = styled.div`
	display: flex;
	padding: 15px;
	background-color: var(--wbn-primary-color_navbar);
	border-top: 1px solid white;
`;

export const HeaderContainer = styled.div`
	display: flex;
	padding: 15px;
	background-color: var(--wbn-primary-color_navbar);
	border-bottom: 1px solid white;
	min-height: 80px;
	font-size: 12px;
`;
