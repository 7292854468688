import { put, call } from 'redux-saga/effects';
import { actionFailed } from 'shared/actions';
import axios from 'axios';
import {
	// PUT_PROFILE_SUCCESS,
	PUT_PROFILE_FAILED
} from './constants';

function putProfileApi(data) {
	return axios.request({
		url: `${process.env.REACT_APP_API_URL}user/profile`,
		method: 'PUT',
		headers: {
			'Content-Type': 'application/json',
			'wbn-auth': localStorage.getItem('token')
		},
		data
	});
}

export function* putProfileSaga(action) {
	try {
		const response = yield call(putProfileApi, action.payload);
		console.log('putProfileSaga', response);
		// yield put(actionSuccess(PUT_PROFILE_SUCCESS, customData));
	} catch (error) {
		yield put(actionFailed(PUT_PROFILE_FAILED, []));
	}
}
