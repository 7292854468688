import React from 'react';

const HealthCare = ({ fill = "white" }) => {
	return (
		<svg id="Capa_1" data-name="Capa 1"
			xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
			<title>ICON HEALTH CARE</title>
			<path style={{ fill: "none", strokeWidth: "0.5px", stroke: fill, strokeMiterlimit: 10, }} d="M23.28,6.89A5.05,5.05,0,0,0,21.45,4a5.49,5.49,0,0,0-2.56-1.1,6.31,6.31,0,0,0-3.31.19,9.54,9.54,0,0,0-2.5,1.48c-.39.31-.75.64-1.14,1a2.73,2.73,0,0,1-.25-.23A13,13,0,0,0,9.46,3.67a5.83,5.83,0,0,0-3.74-.83,6.7,6.7,0,0,0-2.51.71A5,5,0,0,0,1,5.89,6.19,6.19,0,0,0,.46,7.77a6.71,6.71,0,0,0,0,2.05A9.06,9.06,0,0,0,3,14.28c.82.86,1.7,1.65,2.56,2.47l5.81,5.61a.8.8,0,0,0,1.16,0l2.2-2.13,4-3.87a37.29,37.29,0,0,0,2.73-2.75A9.44,9.44,0,0,0,23.11,11,6.63,6.63,0,0,0,23.28,6.89Z" />
			<path style={{fill }} d="M11.69,20.65c0,.26.31,1.64.31,1.88,0-.25.22-1.63.22-1.88Z" />
			<path style={{fill }} d="M11,7.78A1.67,1.67,0,0,1,9.73,6.2a1.12,1.12,0,0,0-1.2-1.15H4.2c0,.08,0,.14.07.2a1.13,1.13,0,0,0,1.06.64H7.17c.21,0,.33.1.33.27s-.13.28-.33.28H5.6a1.09,1.09,0,0,0,1,.83c.56,0,1.12,0,1.68,0,.22,0,.35.12.34.29s-.13.27-.34.27H7a1.11,1.11,0,0,0,1.08.83h3c0-.25,0-.49,0-.72S11.09,7.8,11,7.78Z" />
			<path style={{fill }} d="M13.06,7.78A1.67,1.67,0,0,0,14.32,6.2a1.12,1.12,0,0,1,1.2-1.15h4.33l-.07.2a1.13,1.13,0,0,1-1.06.64H16.88c-.21,0-.33.1-.33.27s.12.28.33.28h1.56a1.06,1.06,0,0,1-1,.83c-.56,0-1.12,0-1.68,0-.22,0-.35.12-.34.29s.12.27.34.27h1.27A1.1,1.1,0,0,1,16,8.67h-3c0-.25,0-.49,0-.72S13,7.8,13.06,7.78Z" />
			<path style={{stroke: fill, strokeMiterlimit:10, fill, strokeWidth:"0.39px"}} d="M13.49,18h-.7v.62a1.26,1.26,0,0,1-.5,1l-.45.38a.48.48,0,0,0-.09.14.75.75,0,0,0,.16,0,1.74,1.74,0,0,0,.9-.29,1.81,1.81,0,0,0,.78-1.81S13.52,18,13.49,18Z" />
			<path style={{stroke: fill, strokeMiterlimit:10, fill, strokeWidth:"0.39px"}} d="M15.21,14.9h-.88v0a1.11,1.11,0,0,1-1.08,1.61c-.46,0-.92,0-1.37,0A1.81,1.81,0,0,0,11.16,20l.2-.4-.13-.17A1.19,1.19,0,0,1,11,18.1a1.17,1.17,0,0,1,1.17-.7h1.11a1.94,1.94,0,0,0,1.52-.7A1.92,1.92,0,0,0,15.21,14.9Z" />
			<path style={{stroke: fill, strokeMiterlimit:10, fill, strokeWidth:"0.39px"}} d="M15.7,10c-.35-.12-1.57-.35-1.8-.09a.46.46,0,0,0,.14.74,2.71,2.71,0,0,0,1,.08A1.39,1.39,0,0,1,15,13.5H10.56A2,2,0,0,0,8.69,16a1.89,1.89,0,0,0,1,1.21.11.11,0,0,0,.12,0l.43-.53a.1.1,0,0,0,0-.15h0a1.12,1.12,0,0,1-.7-1.34,1.1,1.1,0,0,1,1.11-.81H15A2.22,2.22,0,0,0,15.7,10Z" />
			<path style={{stroke: fill, strokeMiterlimit:10, fill, strokeWidth:"0.39px"}} d="M8.77,13.7a.12.12,0,0,0-.06-.2A1.4,1.4,0,0,1,7.8,13a1.35,1.35,0,0,1-.17-1.45,1.33,1.33,0,0,1,1.23-.8c.32,0,.64.1.8-.22a.41.41,0,0,0-.07-.37A.7.7,0,0,0,9,9.87a2.33,2.33,0,0,0-1.65.66,2.23,2.23,0,0,0,.88,3.69.16.16,0,0,0,.23-.08,3.68,3.68,0,0,1,.34-.44Z" />
			<path style={{stroke: fill, strokeMiterlimit: 10, fill, strokeWidth: "0.26px"}} d="M12.22,18.13h-.53v.74h.53Z" />
			<path style={{stroke: fill, strokeMiterlimit: 10, fill, strokeWidth: "0.26px"}} d="M12.22,14.9h-.53V16h.53Z" />
			<path style={{stroke: fill, strokeMiterlimit: 10, fill, strokeWidth: "0.26px"}} d="M11.68,6.39c0,2.26,0,3.85,0,6.11v.21h.55v-.22c0-2.26,0-3.85,0-6.11A.56.56,0,0,1,12.38,6a.55.55,0,0,0-.05-.77.57.57,0,0,0-.76,0,.54.54,0,0,0,0,.75A.62.62,0,0,1,11.68,6.39Z" />
		</svg>
	)
}

export default HealthCare;
