import styled from 'styled-components';

const ReplySectionWrapper = styled.div`
	
`;
ReplySectionWrapper.User = styled.div`
	
`;
ReplySectionWrapper.Replies = styled.div``;

const Replies = styled.div``;
const UserComment = styled.div`
	display: flex;
	justify-content: space-around;
	align-items: center;
`;
UserComment.Picture = styled.div`
	img {
		width: 35px;
		height: 35px;
		border-radius: 50%;
		border: 1px solid #e3e3e3;
		object-fit: contain;
	}
`
UserComment.TextArea = styled.div`
	width: 75%;
	margin: 0px 8px;
	textarea {
		outline: none;
		background-color: #e3e3e3;
		width: 100%;
		font-size: 12px;
	}
`;
UserComment.IconSend = styled.div`
	display: flex;
	border-radius: 50%;
	border: 1px solid #e3e3e3;
	width: 35px;
	height: 35px;
	padding: 2px;
	justify-content: center;
	align-items: center;
	> img {
		width: 26px;
		height: 26px;
	}
`;
export { ReplySectionWrapper, Replies, UserComment };
