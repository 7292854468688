import React from 'react';

const ElectronicsIT = ({ fill = "white" }) => {
	return (
		<svg id="Capa_1" data-name="Capa 1"
			xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
			<title>ICON ELECTRONIC IT</title>
			<circle style={{fill: "none"}} cx="17.95" cy="21.78" r="0.52" />
			<circle style={{fill: "none"}} cx="7.71" cy="21.78" r="0.52" />
			<path style={{fill: "none"}} d="M11.12,23.15h0a.13.13,0,0,1-.12-.09l-.9-2.15-.44,1a.13.13,0,0,1-.13.09H7.85a.14.14,0,0,1,0-.28H9.43l.53-1.2a.17.17,0,0,1,.13-.08.14.14,0,0,1,.13.09l.9,2.15.94-2.15a.14.14,0,0,1,.13-.09h0a.13.13,0,0,1,.13.09l.9,2.11.94-2.12a.17.17,0,0,1,.13-.08.14.14,0,0,1,.13.09l.91,2.13.44-.91a.13.13,0,0,1,.13-.08h1.91a.14.14,0,1,1,0,.28H16L15.45,23a.16.16,0,0,1-.13.08.14.14,0,0,1-.13-.09l-.9-2.12L13.35,23a.16.16,0,0,1-.13.08h0a.13.13,0,0,1-.13-.09l-.9-2.1-.95,2.16A.13.13,0,0,1,11.12,23.15Z" />
			<path style={{fill: "none"}} d="M17.41,6.66H8A1.29,1.29,0,0,0,6.75,8v9.37A1.29,1.29,0,0,0,8,18.61h9.37a1.29,1.29,0,0,0,1.29-1.29V8A1.29,1.29,0,0,0,17.41,6.66Zm-7.2,6.8a.86.86,0,1,1,0-1.71.85.85,0,0,1,.85.85A.86.86,0,0,1,10.21,13.46Zm5.72-1.59a1.06,1.06,0,0,1-.11.45.49.49,0,0,1-.33.29.84.84,0,0,1,.44.73v.74a1.34,1.34,0,0,1-1.48,1.48H11.5V14.08h2.59a.32.32,0,0,0,.36-.35.45.45,0,0,0-.1-.27.34.34,0,0,0-.26-.12H11.5V11.87h2.59a.32.32,0,0,0,.36-.35.45.45,0,0,0-.1-.28.34.34,0,0,0-.26-.12H11.5V9.65h2.95a1.47,1.47,0,0,1,1.09.39,1.45,1.45,0,0,1,.39,1.09Z" />
			<path style={{fill: "none"}} d="M10.21,11.75a.86.86,0,1,0,.85.85A.85.85,0,0,0,10.21,11.75Z" />
			<path style={{fill: "none"}} d="M14.45,9.65H11.5v1.47h2.59a.34.34,0,0,1,.26.12.45.45,0,0,1,.1.28.32.32,0,0,1-.36.35H11.5v1.47h2.59a.34.34,0,0,1,.26.12.45.45,0,0,1,.1.27.32.32,0,0,1-.36.35H11.5v1.48h2.95a1.34,1.34,0,0,0,1.48-1.48v-.74a.84.84,0,0,0-.44-.73.49.49,0,0,0,.33-.29,1.06,1.06,0,0,0,.11-.45v-.74A1.45,1.45,0,0,0,15.54,10,1.47,1.47,0,0,0,14.45,9.65Z" />
			<path style={{fill}} d="M17.12,5.84H8.4A2.31,2.31,0,0,0,6.11,8.16V17A2.31,2.31,0,0,0,8.4,19.36h8.72A2.31,2.31,0,0,0,19.41,17V8.16A2.31,2.31,0,0,0,17.12,5.84Zm1.21,11.32a1.24,1.24,0,0,1-1.21,1.26H8.4a1.24,1.24,0,0,1-1.21-1.26V8.07A1.24,1.24,0,0,1,8.4,6.81h8.72a1.24,1.24,0,0,1,1.21,1.26Z" />
			<path style={{fill}} d="M15.82,8.36H9.55a1,1,0,0,0-1,1V16a1,1,0,0,0,1,1h6.27a1,1,0,0,0,1-1V9.34A1,1,0,0,0,15.82,8.36Zm-5.61,5.1a.86.86,0,1,1,0-1.71.85.85,0,0,1,.85.85A.86.86,0,0,1,10.21,13.46Zm5.72-1.59a1.06,1.06,0,0,1-.11.45.49.49,0,0,1-.33.29.84.84,0,0,1,.44.73v.74a1.34,1.34,0,0,1-1.48,1.48H11.5V14.08h2.59a.32.32,0,0,0,.36-.35.45.45,0,0,0-.1-.27.34.34,0,0,0-.26-.12H11.5V11.87h2.59a.32.32,0,0,0,.36-.35.45.45,0,0,0-.1-.28.34.34,0,0,0-.26-.12H11.5V9.65h2.95a1.47,1.47,0,0,1,1.09.39,1.45,1.45,0,0,1,.39,1.09Z" />
			<rect style={{fill}} x="21.62" y="15.22" width="1.15" height="3.61" rx="0.46" ry="0.46" transform="translate(39.22 -5.17) rotate(90)" />
			<rect style={{fill}} x="21.62" y="10.24" width="1.15" height="3.61" rx="0.23" ry="0.23" transform="translate(34.24 -10.15) rotate(90)" />
			<path style={{fill}} d="M23.49,8.69H20.72a.42.42,0,0,0-.42.42v1.17a.42.42,0,0,0,.42.42h2.77a.42.42,0,0,0,.42-.42V9.11A.42.42,0,0,0,23.49,8.69Zm-2.2,1.56a.51.51,0,1,1,.51-.51A.51.51,0,0,1,21.29,10.25Zm1.61,0a.51.51,0,0,1,0-1,.51.51,0,1,1,0,1Z" />
			<path style={{fill}} d="M23.49,13.67H20.72a.42.42,0,0,0-.42.42v1.18a.42.42,0,0,0,.42.42h2.77a.42.42,0,0,0,.42-.42V14.09A.42.42,0,0,0,23.49,13.67Zm-2.2,1.56a.5.5,0,0,1-.5-.5.51.51,0,1,1,1,0A.5.5,0,0,1,21.29,15.23Zm1.61,0a.5.5,0,0,1-.5-.5.5.5,0,0,1,.5-.51.51.51,0,0,1,.51.51A.5.5,0,0,1,22.9,15.23Z" />
			<rect style={{fill}} x="21.68" y="5.47" width="0.85" height="3.61" rx="0.17" ry="0.17" transform="translate(14.82 29.38) rotate(-90)" />
			<path style={{fill}} d="M4.87,7.29H1.63a.23.23,0,0,0-.23.23v.54a.23.23,0,0,0,.23.23H4.87a.23.23,0,0,0,.23-.23V7.52A.23.23,0,0,0,4.87,7.29Zm-2.32.85a.35.35,0,0,1-.35-.35.35.35,0,0,1,.35-.35.35.35,0,0,1,.35.35A.34.34,0,0,1,2.55,8.14ZM4,8.14a.34.34,0,0,1-.35-.35A.35.35,0,0,1,4,7.44a.35.35,0,0,1,.35.35A.35.35,0,0,1,4,8.14Z" />
			<path style={{fill}} d="M4.78,17.59H1.54a.23.23,0,0,0-.23.23v.55a.23.23,0,0,0,.23.23H4.78A.24.24,0,0,0,5,18.37v-.55A.23.23,0,0,0,4.78,17.59Zm-2.32.86a.36.36,0,0,1,0-.71.36.36,0,0,1,0,.71Zm1.44,0a.36.36,0,0,1,0-.71.36.36,0,0,1,0,.71Z" />
			<rect style={{fill}} x="2.36" y="7.49" width="1.23" height="4.24" rx="0.17" ry="0.17" transform="translate(-6.63 12.58) rotate(-90)" />
			<path style={{fill}} d="M5,13.55l-1.7-2.19a.18.18,0,0,0-.24,0L1.37,13.58a.16.16,0,0,0,0,.24l1.7,2.24a.16.16,0,0,0,.24,0L5,13.79A.18.18,0,0,0,5,13.55Zm-1.84.61a.47.47,0,1,1,.47-.47A.47.47,0,0,1,3.2,14.16Z" />
			<path style={{fill}} d="M10.17,12.83l.09-.08.1-.08.09-.08a.41.41,0,0,0,.07-.11.21.21,0,0,0,0-.12.25.25,0,0,0-.09-.2.33.33,0,0,0-.24-.07.35.35,0,0,0-.23.07.37.37,0,0,0-.09.22h.19a.13.13,0,0,1,0-.1.1.1,0,0,1,.09,0,.12.12,0,0,1,.13.13.19.19,0,0,1,0,.11.94.94,0,0,1-.14.13,1.49,1.49,0,0,0-.18.17.91.91,0,0,0-.11.16v.13h.68v-.16h-.45Z" />
			<path style={{fill}} d="M13.56,3.65a1,1,0,1,1,1,1A1,1,0,0,1,13.56,3.65Zm.55,0a.49.49,0,1,0,.48-.48A.49.49,0,0,0,14.11,3.65Z" />
			<path style={{fill}} d="M12.08,1.37a1,1,0,1,1,1,1A1,1,0,0,1,12.08,1.37Zm.55,0a.49.49,0,1,0,.48-.48A.49.49,0,0,0,12.63,1.37Z" />
			<rect style={{fill}} x="18" y="1.37" width="1.15" height="3.68" rx="0.16" ry="0.16" transform="translate(37.16 6.43) rotate(180)" />
			<rect style={{fill}} x="16.32" y="1.37" width="1.15" height="3.68" rx="0.16" ry="0.16" transform="translate(33.79 6.43) rotate(180)" />
			<rect style={{fill}} x="7.89" y="1.37" width="1.15" height="3.68" rx="0.16" ry="0.16" transform="translate(16.93 6.43) rotate(180)" />
			<rect style={{fill}} x="6.21" y="1.37" width="1.15" height="3.68" rx="0.16" ry="0.16" transform="translate(13.57 6.43) rotate(180)" />
			<circle style={{fill}} cx="18.23" cy="21.7" r="0.52" />
			<circle style={{fill}} cx="7.99" cy="21.7" r="0.52" />
			<line  style={{fill: "none", stroke: fill, strokeMiterlimit:10, "strokeWidth": "0.2px" }} x1="3.2" y1="15.91" x2="3.2" y2="17.69" />
			<polyline style={{fill: "none", stroke: fill, strokeMiterlimit:10, "strokeWidth": "0.2px" }} points="0.95 9.45 0.26 9.45 0.26 13.69 1.45 13.69" />
			<polyline style={{fill: "none", stroke: fill, strokeMiterlimit:10,"strokeWidth": "0.2px"}} points="10.46 4.68 10.46 1.22 12.34 1.22" />
			<polyline style={{fill: "none",  stroke: fill, strokeMiterlimit:10, "strokeWidth": "0.4px" }} points="13.56 3.65 11.72 3.65 11.72 4.68" />
			<path style={{fill: "none", "stroke": fill,  "strokeMiterlimit":10, "strokeWidth": "0.2px"}} d="M11.4,23.07h0a.13.13,0,0,1-.13-.09l-.9-2.15-.44,1a.14.14,0,0,1-.13.09H8.13A.14.14,0,0,1,8,21.75a.14.14,0,0,1,.14-.14H9.71l.53-1.2a.16.16,0,0,1,.13-.08.13.13,0,0,1,.13.09l.9,2.15.94-2.16a.16.16,0,0,1,.13-.08h0a.13.13,0,0,1,.13.08l.9,2.12.95-2.12a.13.13,0,0,1,.13-.08.13.13,0,0,1,.12.09l.91,2.13.44-.91a.13.13,0,0,1,.13-.08h1.91a.14.14,0,0,1,0,.28H16.27L15.73,23a.16.16,0,0,1-.13.08.14.14,0,0,1-.13-.09l-.9-2.12-.94,2.11a.17.17,0,0,1-.13.09h0a.14.14,0,0,1-.13-.09l-.9-2.1L11.53,23A.16.16,0,0,1,11.4,23.07Z" />
		</svg>
	)
}

export default ElectronicsIT;

