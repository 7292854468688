import { put, call } from 'redux-saga/effects';
import { map } from 'lodash';
import { actionSuccess, actionFailed } from 'shared/actions';
import axios from 'axios';
import { GET_LIST_BUSINESS_SUCCESS, GET_LIST_BUSINESS_FAILED } from './constants';

function listBusinessApi() {
	return axios.request({
		url: `${process.env.REACT_APP_API_URL}ref-data/business-type`,
		method: 'GET'
	});
}

export function* listBusinessSaga() {
	try {
		const {
			data: { data }
		} = yield call(listBusinessApi);

		const customData = map(data, ({ code, businessTypeName }) => {
			return {
				key: code,
				text: businessTypeName,
				value: code
			};
		});

		yield put(actionSuccess(GET_LIST_BUSINESS_SUCCESS, customData));
	} catch (error) {
		yield put(actionFailed(GET_LIST_BUSINESS_FAILED, []));
	}
}
