import {
	GET_LAST_REGISTERED_WATCHER,
	GET_LAST_REGISTERED_SUCCESS,
	GET_LAST_REGISTERED_FAILED
} from './constants';

const initialState = {
	isLoading: true,
	data: []
};

export default (state = initialState, action) => {
	switch (action.type) {
		case GET_LAST_REGISTERED_WATCHER:
			return {
				isLoading: true,
				data: []
			};
		case GET_LAST_REGISTERED_SUCCESS:
			return {
				isLoading: false,
				data: action.payload
			};
		case GET_LAST_REGISTERED_FAILED:
			return {
				isLoading: false,
				data: action.payload
			};
		default:
			return state;
	}
};
