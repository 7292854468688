import React from 'react';
import Helmet from 'react-helmet';

import { WBNMap } from 'components/molecules';
// import { Button } from 'components/atoms';

// import { } from './styles.js';

import g1 from 'images/b2b-tips/tip-5/WBN_TIP.png';
import qualifiedInsutrialImage from 'images/b2b-tips/tip-5/1.jpg';
import coffeeImage from 'images/b2b-tips/tip-5/2.jpg';
// import dabieIcon from 'images/b2b-tips/tip-5/WBN-DABIE.svg';

import {
  Section1,
  Secion1Title,
  SecctionTitle,
  Section1Description,
  GraphicSection,
  GraphicTitle,
  GraphicDescription,
  SectionContainer,
  SectionBody1,
  SectionBody,
  SectionBodyImage,
  SectionBodyImageLeft,
} from '../style';

import { TipsFooter } from '../tip0';


const Tip0 = () => {
  window.scrollTo(0, 0);

  return (
    <div>
      <Helmet>
        <title>{`Qualified industrial exporter, b2b company exporter to ${process.env.REACT_APP_SEO_FIRST} market, qualified industrial manufacturing products export ${process.env.REACT_APP_SEO_SECOND}, industrial b2b exporter distribution products vendors, qualified industrial b2b exporter supply b2b player manufacturer supply`}</title>
        <meta name="description" content={`${process.env.REACT_APP_SEO_FIRST} qualified industrial exporter, who is a qualified industry exporter in the distribution network, industrial exporter network is an export trade platform for business buyers from companies, ${process.env.REACT_APP_SEO_FIRST} industrial exporter of manufacturing network, how to create your products export marketing business buyers for industries, made in ${process.env.REACT_APP_SEO_SECOND} export platform manufacturing vendors to the export global wholesalers and export distributors, ${process.env.REACT_APP_SEO_FIRST} business trade guide is part of WBN industrial b2b social network, made in ${process.env.REACT_APP_SEO_SECOND} export products manufacturer export business supply, ${process.env.REACT_APP_SEO_FIRST} products for wholesale trade businesses distributors in United States, Asia, Africa, Europe, South America, Latin America and Middle East distribution, WBN the industrial b2b social network for export wholesalers and manufacturing private label industries, made in ${process.env.REACT_APP_SEO_SECOND} products manufacturer supplier for wholesalers and vendors of the market, industrial products for wholesale worldwide distributors, ${process.env.REACT_APP_SEO_FIRST} products manufacturing for private label buyers and new brand for manufacturer, business to business manufacturing supplies and market distributors, ${process.env.REACT_APP_SEO_FIRST} product manufacturing industry looking for worldwide distributors mainly in Germany, Denmark, Austria, Portugal, Spain, France, Norway, Sweden in Europe, China, Japan, Korea, Vietnam in Asia, California, New York, Florida, Chicago Illinois and all the United States of America, South Arabia, Qatar, Oman, UAE, Middle East, Australia, North of Africa, South America and all Latin America`} />
        <meta name="keywords" content={`${process.env.REACT_APP_SEO_SECOND} exporter, ${process.env.REACT_APP_SEO_FIRST} b2b exporter, ${process.env.REACT_APP_SEO_FIRST} manufacturing exporter, ${process.env.REACT_APP_SEO_FIRST} business exporter, ${process.env.REACT_APP_SEO_FIRST} exporter platform, ${process.env.REACT_APP_SEO_FIRST} social network, ${process.env.REACT_APP_SEO_FIRST} business exporter, ${process.env.REACT_APP_SEO_SECOND} manufacturer exporter`} />
      </Helmet>

      <Section1>
        <Secion1Title>WHO IS A QUALIFIED INDUSTRIAL EXPORTER</Secion1Title>
        <Section1Description>WHO IS A QUALIFIED INDUSTRIAL EXPORTER? a qualified exporter is a company with a system that allows to produce according to the technical specifications of the global market respecting the standards of health, quality and safety of the product. A qualified industrial exporter implements an "operational plan for international B2B marketing" in synergy with management goals. The exporter has qualified personnel for export that interacts with buyers respecting the principles of diplomacy and morality. The exporter programs each purchase orders onsidering delivery time, material purchases and own production capacity.</Section1Description>
      </Section1>

      <GraphicSection>
        <SecctionTitle>QUALIFIED INDUSTRIAL EXPORTER: READY FOR GLOBAL B2B</SecctionTitle>
        <GraphicTitle>Following we will define some tips to understand how to be ready to become a qualified exporter</GraphicTitle>
        <img src={g1} alt="QUALIFIED INDUSTRIAL EXPORTER: READY FOR GLOBAL B2B"></img>
        <GraphicDescription>HOW TO BECOME A QUALIFIED EXPORTER to access to the global market and become a qualified exporter you must be recognized by your own products (defined according to the market needs including certifications), a very good knowledge of the market (including language and local culture), a good B2B reputation including bank references, a perfect logistics management (including excelent packaging), available to offer private label manufacturing services & pricing, discounts and full sales conditions according to the partner business in each region ASK FOR INDUSTRIAL TIPS TO DEFINE YOUR EXPORT PROGRAM</GraphicDescription>
      </GraphicSection>

      <SectionContainer>
        <SecctionTitle maxWidth='70%'>GLOBAL OPEN MARKET FOR INDUSTRIAL BUSINESS TO BUSINESS - ONLY FOR QUALIFIED MARKETS</SecctionTitle>

        <SectionBody1>
          <div>
            <p>BIG OR SMALL COMPANY TO EXPORT? Actually it’s most easy than ever for small and medium companies, regardless of size, to export products and services into the global market. The world’s potential consumers are just awaiting for qualified offers it doesn't matter distance and countries, just qualified products from qualified exporters.</p>
            <p>INTERNET AND EXPORT MARKETS internet offers a great way to improve export options, furthermore internet has made exporting more viable smal and medium businesses worldwide.</p>
          </div>
          <SectionBodyImage width="200px" height="202px">
            <img src={qualifiedInsutrialImage} alt=""></img>
          </SectionBodyImage>
          <div>
            <p>Last years  the value of good services exports was growing very fast by trillions for some regions. Thousands of new exporters can attest, diversifying customer base through exporting can help to change the domestic and global economies.</p>
            <p>HOW TO REACH MORE SALES CHANNELS? the global online sales system is ideal for small and medium B2B companies, the main idea it's to sell into multiple global markets, having fast return on investment improving profit</p>
          </div>
        </SectionBody1>
      </SectionContainer>

      <SectionContainer>
        <SecctionTitle>WHAT SHOULD YOU BE ABLE TO REALIZE AN EXPORT INDUSTRIAL MARKETING TECHNICIAN</SecctionTitle>

        <SectionBody>
          <div style={{display: 'flex'}}>
            <SectionBodyImageLeft width="200px" height="202px">
              <img src={coffeeImage} alt=""></img>
            </SectionBodyImageLeft>
            <div>
              <p>WORLD EXPORT ECONOMIC PROSPECT According to the World Bank report the global economic prospects, trade in goods and services is likely to more than triple by 2030. With this significant projected growth in global trade, fueled in large part by newly affluent consumers in China, India, Indonesia, Brazil, Russia and other developing economies, the challenge for businesses of all sizes in the world is how to dip into this incredible revenue torrent... The Qualified Exporter will be a plus into this new economy trend.</p>
              <p>HOW TO GROW OUR EXPORT? Exporting can be very easy, opens your horizons to what’s going on in the worldwide market and business economy. You should develop new relationships and be very open mind to open new markets step by step in each market. Follow market trend and try to improve your products according to the market requirements.</p>
              <p>INCREASE YOUR INTELLECTUAL PROPERTY try to obtain product patents and license for each new market, furthermore it helps a lot to have product certification for each country adding a deep smart management and intercultural expertise to your negociations.</p>
            </div>
          </div>
          <p>USE PRODUCTION CAPABILITIES FULLY DEFENDING YOUR DOMESTIC MARKET Exporting is profitable supporting local manufacturing capabilities helping domestic market to increase jobs and services from vendors and material suppliers, furthermore it helps businesses to learn how to compete more successfully abroad increasing market knowledge and bringing new technology. Keep on mind that the volume of trade it's growing exponentially and barriers to trade falling, competition is intensifying also in our own backyard while we simultaneously open markets for our products and services in other markets.</p>
        </SectionBody>
      </SectionContainer>

      <TipsFooter />
      <WBNMap />
    </div>
  );
};

export default Tip0;
