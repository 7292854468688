import { put, call } from 'redux-saga/effects';
import { actionSuccess, actionFailed } from 'shared/actions';
import axios from 'axios';
import { POST_IMAGE_S3_SUCCESS, POST_IMAGE_S3_FAILED } from './constants';

function postImageS3Api({ data, url }) {
	return axios.request({
		method: 'POST',
		headers: {
			'Content-Type': 'multipart/form-data',
			'wbn-auth': localStorage.getItem('token')
		},
		url: url,
		data
	});
}

function validateImageS3Api({ token }) {
	return axios.request({
		method: 'POST',
		url: `${process.env.REACT_APP_API_URL}user/profile-photo/upload-validate`,
		headers: {
			'wbn-auth': localStorage.getItem('token')
		},
		data: {
			token: token
		}
	});
}

function imageUploadRequest({ pictureFile }) {
	return axios.request({
		method: 'POST',
		url: `${process.env.REACT_APP_API_URL}user/profile-photo/upload-request`,
		headers: {
			'Content-Type': 'application/json',
			'wbn-auth': localStorage.getItem('token')
		},
		data: {
			contentType: pictureFile.type,
			fileSize: pictureFile.size
		}
	});
}

export function* postImageS3Saga(action) {
	try {
		const {
			data: { data }
		} = yield call(imageUploadRequest, action.payload);

		const formData = new FormData();
		for (let f in data.signedPost.fields) {
			formData.set(f, data.signedPost.fields[f]);
		}
		formData.set('Content-Type', action.payload.pictureFile.type);
		formData.append('file', action.payload.pictureFile);

		yield call(postImageS3Api, { url: data.signedPost.url, data: formData });
		yield call(validateImageS3Api, { token: data.token });

		yield put(actionSuccess(POST_IMAGE_S3_SUCCESS, {}));
	} catch (error) {
		yield put(actionFailed(POST_IMAGE_S3_FAILED, {}));
		console.log('postImageS3Saga error', error);
	}
}
