import styled from 'styled-components';

export const Container = styled.div`
display: flex;
flex-direction: column;
@media screen and (min-width: 768px) {
  align-items: center;
  flex-direction: row;
}
`;

export const Icons = styled.div`
@media screen and (min-width: 768px) {
  margin-right: 20px;
}
display: flex;
> span {
  background-color: lightgray;
  border-radius: 50%;
  cursor: pointer;
  height: 25px;
  width: 25px;
}
> span:not(:last-child) {
  margin-right: 10px;
}
`;

export const Links = styled.div`
margin-top: 10px;
@media screen and (min-width: 768px) {
  margin-top: 0;
}
> span:not(:last-child) {
  margin-right: 25px;
}
`;