import { SEARCH_USERS_WATCHER, SEARCH_USERS_SUCCESS, SEARCH_USERS_FAILED } from './constants';

const initialState = {
	isLoading: true,
  data: {
    results: [],
    total_results: 0
  }
};

export default (state = initialState, action) => {
	switch (action.type) {
		case SEARCH_USERS_WATCHER:
			return {
				isLoading: true,
				data: []
			};
		case SEARCH_USERS_SUCCESS:
			return {
				isLoading: false,
				data: action.payload
			};
		case SEARCH_USERS_FAILED:
			return {
				isLoading: false,
				data: initialState.data
			};
		default:
			return state;
	}
};
