
import axios from 'axios';
import apiClient, { updateApiClientToken } from 'api-client';

const FIVE_SECONDS = 5000;

export function redirectToLogin(path) {
  let continueUrl = encodeURIComponent(path ? path : window.location.href);
  let redirectURL = `${process.env.REACT_APP_AUTH_URL}ServiceLogin?continue=${continueUrl}`;
  window.open(redirectURL, '_self');
}

export function hasValidSession() {
  return hasTokenValid();
}

export function saveSession(session) {
  localStorage.setItem('token', session.token);
  localStorage.setItem('tokenExpire', session.tokenExpire * 1000);
  localStorage.setItem('refreshToken', session.refreshToken);
  localStorage.setItem('refreshTokenExpire', session.refreshTokenExpire * 1000);
}

export function clearSessionStorage() {
  localStorage.removeItem('token');
  localStorage.removeItem('tokenExpire');
  localStorage.removeItem('refreshToken');
  localStorage.removeItem('refreshTokenExpire');
  localStorage.removeItem('profile');
}

export function hasTokenValid() {
  let token = localStorage.getItem('token');
  let tokenExpire = localStorage.getItem('tokenExpire');
  return (token !== null) && (tokenExpire !== null) && parseInt(tokenExpire) > Date.now();
}

export function hasRefreshTokenValid() {
  let refreshToken = localStorage.getItem('refreshToken');
  let refreshTokenExpire = localStorage.getItem('refreshTokenExpire');
  return (refreshToken !== null) && (refreshTokenExpire !== null) && parseInt(refreshTokenExpire) > Date.now();
}

export async function refreshToken() {
  if (!hasRefreshTokenValid()) {
    return false;
  }

  let refreshToken = localStorage.getItem('refreshToken');

  const response = await axios({
    method: 'POST',
    url: `${process.env.REACT_APP_AUTH_URL}refresh-token`,
    headers: {
      'wbn-auth': refreshToken
    }
  });

  if (!response.data.success) {
    return clearSessionStorage();
  }

  const tokensData = response.data.data;
  saveSession(tokensData);
  updateApiClientToken(tokensData.token);

  await updateProfile();
  return true;
}

export async function updateProfile() {
  if (!hasTokenValid()) {
    return null;
  }

  let response = await apiClient.get(`${process.env.REACT_APP_API_URL}user/profile`);
  let profile = response.data.data;
  localStorage.setItem('userID', profile.userID);
  localStorage.setItem('profile', JSON.stringify(profile));
  localStorage.setItem('profileUpdateDate', Date.now());
}

export function getProfile() {
  if (!hasTokenValid()) {
    return null;
  }

  return JSON.parse(localStorage.getItem('profile'));
}

export async function getProfileAsyn() {
  if (!hasTokenValid()) {
    if (!hasRefreshTokenValid()) {
      return null;
    }
    await refreshToken();
  }

  await updateProfile();
  return getProfile();
}

let timeoutId = null;

function removeTimeout() {
  if (timeoutId) {
    clearTimeout(timeoutId);
  }
  timeoutId = null;
}

async function loadTimeout() {
  if (hasRefreshTokenValid()) {
    if (!hasTokenValid()) {
      await refreshToken();
      removeTimeout();
    } else {
      let tokenExpire = parseInt(localStorage.getItem('tokenExpire'));
      timeoutId = setTimeout(async () => { 
        await refreshToken();
        removeTimeout();
        loadTimeout();
      }, Math.max(0, tokenExpire - Date.now() - FIVE_SECONDS));
    }
  }
}

loadTimeout();
window.ononline = () => {
  if (timeoutId) {
    clearTimeout(timeoutId);
  }
  loadTimeout();
}

export default {
  redirectToLogin,
  hasValidSession,
  saveSession,
  clearSessionStorage,
  hasTokenValid,
  hasRefreshTokenValid,
  refreshToken,
  getProfile,
  updateProfile,
  getProfileAsyn,
};
