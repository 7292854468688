import React from 'react';

const Energy = ({ fill = "white" }) => {
	return (
<svg id="Capa_1" data-name="Capa 1" 
	xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
	<title>ICONS</title>
	<path style={{fill}} d="M10.54,7.89,8.82.26a.26.26,0,0,0-.51,0L7.82,5.14A.83.83,0,0,0,8,5.72L9.3,7.46l0,.07.16.62A2.68,2.68,0,0,1,10.54,7.89Z"/>
	<path style={{fill}} d="M12.41,9.83l8-1a.26.26,0,0,0,0-.51L15.46,7.4a.9.9,0,0,0-.62.11L13,8.65l-.08,0-.59.08A3.64,3.64,0,0,1,12.41,9.83Z"/>
	<path style={{fill}} d="M8.46,10.51l-6.41,4.7a.25.25,0,0,0,.24.44L7,14.07a.78.78,0,0,0,.48-.39l1.07-1.89a.34.34,0,0,1,.06-.06l.51-.4A2.52,2.52,0,0,1,8.46,10.51Z"/>
	<path style={{fill}} d="M9.89,12.08l-.91,9a.2.2,0,0,0,.21.23H11.5a.21.21,0,0,0,.21-.23l-.66-9.19a2,2,0,0,1-.91,0A.22.22,0,0,0,9.89,12.08Z"/>
	<path style={{fill}} d="M15.07,13.51l-1.44-3.35c-.05-.12-.28-.05-.28.08l0,2.34a.29.29,0,0,0,.13.25l.84.64,0,0,.12.27A2.09,2.09,0,0,1,15.07,13.51Z"/>
	<path style={{fill}} d="M16.2,14.91l3.85.27a.14.14,0,0,0,.06-.26l-2.23-1a.39.39,0,0,0-.3,0l-1,.4h0l-.31,0A1.53,1.53,0,0,1,16.2,14.91Z"/>
	<path style={{fill}} d="M14.15,15l-2.48,2.62c-.09.1.06.27.18.21l2.12-1a.33.33,0,0,0,.18-.22l.25-1,0,0,.2-.22A2,2,0,0,1,14.15,15Z"/>
	<path style={{fill}} d="M15,15.74l-.5,5.33a.19.19,0,0,0,.18.21h1.11a.19.19,0,0,0,.19-.2l-.36-5.34A1.12,1.12,0,0,1,15,15.74Z"/>
	<rect style={{fill}} x="5.73" y="21.95" width="13.17" height="2.05" rx="0.72" ry="0.72"/>
	<path style={{fill}} d="M10.46,8.89a1.12,1.12,0,0,0-1,1.23,1.14,1.14,0,0,0,1.27,1,1.12,1.12,0,0,0,1-1.23A1.13,1.13,0,0,0,10.46,8.89ZM10,10.15a.18.18,0,1,1,.18-.18A.18.18,0,0,1,10,10.15Zm1.2-.33a.15.15,0,0,1,0,.09.12.12,0,0,1-.07.07.16.16,0,0,1,.09.15v.15a.28.28,0,0,1-.31.31h-.62v-.31h.55s.07,0,.07-.07a.07.07,0,0,0,0-.06l0,0h-.55V9.82h.55a.06.06,0,0,0,.07-.07s0-.05,0-.06a.05.05,0,0,0,0,0h-.55V9.35h.62a.32.32,0,0,1,.23.08.3.3,0,0,1,.08.23Z"/>
	<path style={{fill}} d="M10,10h0l0,0,0,0,0,0v0s0,0,0,0H10s0,0,0,0h0v0h0s0,0,0,0a0,0,0,0,1,0,0l0,0,0,0,0,0v0h.15v0H10Z"/>
	<ellipse style={{fill}} cx="15.28" cy="14.52" rx="0.59" ry="0.57"/>
	<path style={{fill: "none"}} d="M11.19,9.82V9.66a.3.3,0,0,0-.08-.23.32.32,0,0,0-.23-.08h-.62v.31h.55a.05.05,0,0,1,0,0s0,0,0,.06a.06.06,0,0,1-.07.07h-.55v.31h.55l0,0a.07.07,0,0,1,0,.06s0,.07-.07.07h-.55v.31h.62a.28.28,0,0,0,.31-.31v-.15A.16.16,0,0,0,11.1,10a.12.12,0,0,0,.07-.07A.15.15,0,0,0,11.19,9.82Z"/>
	<path style={{fill: "none"}} d="M11.19,9.82V9.66a.3.3,0,0,0-.08-.23.32.32,0,0,0-.23-.08h-.62v.31h.55a.05.05,0,0,1,0,0s0,0,0,.06a.06.06,0,0,1-.07.07h-.55v.31h.55l0,0a.07.07,0,0,1,0,.06s0,.07-.07.07h-.55v.31h.62a.28.28,0,0,0,.31-.31v-.15A.16.16,0,0,0,11.1,10a.12.12,0,0,0,.07-.07A.15.15,0,0,0,11.19,9.82Z"/>
</svg>
	)
}

export default Energy;


