import styled from 'styled-components';
import breakpoints from 'breakpoints';

const titleFontSize = 14;
const contentFontSize = titleFontSize - 1;

export const LayoutContainer = styled.div`
	display: flex;
	flex-direction: column;
	min-height: inherit;
`;

export const LayoutHeaderContainer = styled.div`
	margin-top: 20px;
	margin-bottom: 30px;

	${breakpoints('sm')`
		margin-top: 0;
	`}

	> header {
		text-align: center;
	}
`;

export const LayoutContentContainer = styled.div`
	display: flex;
	min-height: 500px;
	height: calc(100vh - 280px);
	border-radius: 10px;
	overflow: hidden;

	/* mobile only */
	${breakpoints('xs', 'sm')`
		> div:first-child {
			display: flex;
		}

		> div:last-child {
			display: none;
		}

		${props =>
			props.focusOnChat &&
			`
			> div:last-child {
				display: flex;
			}

			> div:first-child {
				display: none;
			}
		`}	
	`}
`;

export const MessageThreadsContainer = styled.div`
	width: 100%;
	background: var(--wbn-primary-color);
	display: flex;
	flex-direction: column;

	> div:first-child {
		flex-shrink: 0;
		background-color: var(--wbn-sidebar-color-line);
		input {
			color: white;
		}
		i {
			border-color: white transparent transparent transparent;
		}
		input + span {
			color: #e8e8e8;
		}
		div[role='listbox'] {
			background-color: var(--wbn-sidebar-color-line);
			color: white;
		}
	}

	${breakpoints('lg')`
		width: 30%;
	`}

	${breakpoints('sm', 'lg')`
		width: 50%;
	`}
`;

export const ChatContainer = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	background: var(--wbn-primary-color_navbar);

	${breakpoints('lg')`
		width: 70%;
	`}

	${breakpoints('sm', 'lg')`
		width: 50%;
	`}
`;

export const MessageThreadContainer = styled.div`
	align-items: center;
	background: var(--wbn-primary-color);
	color: white;
	cursor: pointer;
	display: flex;
	padding: 10px 20px;

	${props =>
		props.selected &&
		`
		background: var(--wbn-primary-color_hover);
	`};

	:hover {
		background: var(--wbn-primary-color_hover);
	}

	:not(:last-child) {
		border-bottom: 1px solid white;
	}

	> div:last-child {
		display: flex;
		flex-direction: column;
		flex: 1;
		height: 100%;
		margin-left: 10px;
		min-width: 0;

		> span:first-child {
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
			font-size: ${titleFontSize}px;
		}

		> span:not(:first-child) {
			font-size: ${contentFontSize}px;
		}
	}
`;

export const InboxHeaderContainer = styled.div`
	border-bottom: 1px solid white;
	padding: 0 20px 5px 20px;
	display: flex;
	flex-direction: column;

	${breakpoints('sm')`
		#back {
			display: none;
		}
	`}

	* {
		font-size: ${contentFontSize}px;
		color: #61636d;
	}

	i {
		transition: color 0.2s;

		:hover {
			color: #36383e;
		}
	}
`;

export const InboxContentContainer = styled.div`
	display: flex;
	flex-direction: column;
	overflow-y: auto;
	padding: 20px;
	flex: 1;
`;

export const InboxFooterContainer = styled.div`
	display: flex;
	padding: 20px;
	background: var(--wbn-primary-color_background);
	align-items: center;
	margin-bottom: 20px;

	${breakpoints('sm')`
		margin-right: 20px;
		border-radius: 0 10px 10px 0;
	`}

	.input {
		flex: 1;
		margin-right: 10px;
	}

	.icon {
		color: var(--wbn-sidebar-color-line);
	}
`;

export const MessageContainer = styled.div`
	display: flex;

	:not(:last-child) {
		margin-bottom: 10px;
	}

	> div:last-child {
		max-width: 70%;
		border-radius: 10px;
		min-height: 50px;
		padding: 10px;
		word-break: break-all;

		${breakpoints('lg')`
			max-width: 40%;
		`}
	}

	${props =>
		props.position === 'left' &&
		`
		justify-content: flex-start;

		> div:last-child {
			background: white;
		}
	`}

	${props =>
		props.position === 'right' &&
		`
		justify-content: flex-end;

		> div:last-child {
			background: var(--wbn-primary-color);
			color: white;
		}
	`}
`;
