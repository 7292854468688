import styled from 'styled-components';

export const DomainLogo = styled.img`
  height: 37px;
  width: 134px;

  @media screen and (min-width: 992px) {
    max-height: 58px;
    height: 58px;
    width: 235px;
  }
`;

export const ContainerHeader = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  padding: 20px 25px 10px 25px;
  background-color: var(--wbn-primary-color_navbar);
  height: auto;
  @media screen and (min-width: 768px) {
    height: 93px;
    flex-wrap: nowrap;
    padding: 25px 30px;
  }
  @media screen and (min-width: 992px) {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-height: 110px;
  }
`;

export const ContainerLeft = styled.div`
  display: flex;
  align-items: center;

  img {
    display: block;
  }
`;
