import React from 'react';
import Helmet from 'react-helmet';

import { WBNMap } from 'components/molecules';
// import { Button } from 'components/atoms';

import { SeccionTwoColumns } from './styles.js';

import g1 from 'images/b2b-tips/tip-3/WBN_1.png';
import mapImage from 'images/b2b-tips/tip-3/MAP.png';
import territorialImage from 'images/b2b-tips/tip-3/TERRITORIALS.jpg';
// import dabieIcon from 'images/b2b-tips/tip-3/WBN-DABIE.svg';

import {
  Section1,
  Secion1Title,
  SecctionTitle,
  Section1Description,
  GraphicSection,
  GraphicTitle,
  GraphicDescription,
  SectionContainer,
  SectionBody,
  SectionBody2,
  SectionBodyImageRight,
} from '../style';

import { TipsFooter } from '../tip0';


const Tip0 = () => {
  window.scrollTo(0, 0);

  return (
    <div>
      <Helmet>
        <title>{`How to create a distribution network for manufacturing products, how to find distributors worldwide system, industrial marketing plan for ${process.env.REACT_APP_SEO_FIRST} business suppliers, b2b wholesale distribution network products system, how create distributor network system`}</title>
        <meta name="description" content={`${process.env.REACT_APP_SEO_FIRST} how to create a distribution network, industrial export network is an export trade platform for business companies, ${process.env.REACT_APP_SEO_FIRST} industrial manufacturing network, how to create your products export marketing business plan for industries, made in ${process.env.REACT_APP_SEO_SECOND} export platform manufacturing vendors to the export global wholesalers and export distributors, ${process.env.REACT_APP_SEO_FIRST} business trade guide is part of WBN industrial b2b social network, made in ${process.env.REACT_APP_SEO_SECOND} export products manufacturer export business supply, ${process.env.REACT_APP_SEO_FIRST} products for wholesale trade businesses distributors in United States, Asia, Africa, Europe, South America, Latin America and Middle East distribution, WBN the industrial b2b social network for export wholesalers and manufacturing private label industries, made in ${process.env.REACT_APP_SEO_SECOND} products manufacturer supplier for wholesalers and vendors of the market, industrial products for wholesale worldwide distributors, ${process.env.REACT_APP_SEO_FIRST} products manufacturing for private label buyers and new brand for manufacturer, business to business manufacturing supplies and market distributors, ${process.env.REACT_APP_SEO_FIRST} product manufacturing industry looking for worldwide distributors mainly in Germany, Denmark, Austria, Portugal, Spain, France, Norway, Sweden in Europe, China, Japan, Korea, Vietnam in Asia, California, New York, Florida, Chicago Illinois and all the United States of America, South Arabia, Qatar, Oman, UAE, Middle East, Australia, North of Africa, South America and all Latin America`} />
        <meta name="keywords" content={`distribution network, b2b export network, worldwide distribution network, industrial b2b, ${process.env.REACT_APP_SEO_FIRST} export products, b2b export platform, made ${process.env.REACT_APP_SEO_SECOND} platform, export business synergy, ${process.env.REACT_APP_SEO_FIRST} b2b products, export manufacturing products`} />
      </Helmet>

      <Section1>
        <Secion1Title>HOW TO CREATE A PRODUCT DISTRIBUTION NETWORK ABROAD</Secion1Title>
        <Section1Description>The creation of its own network of distributors in the world takes place following the implementation of the global industrial marketing operational plan, which must be defined considering the main business objectives, the characteristics of the product to be exported and the synergy with the market. We need to know how to choose the most suitable countries for their products, they influence the ability of the export manager, his knowledge of languages (mainly English and Spanish) and the export procedures of his foreign office</Section1Description>
      </Section1>

      <GraphicSection>
        <SecctionTitle>NETWORK OF DISTRIBUTORS: IMPLEMENTATION OF THE EXPORT INDUSTRIAL MARKETING PLAN</SecctionTitle>
        <GraphicTitle>Following we will develop a series of concepts and suggestions in order to help you better define your business strategy</GraphicTitle>
        <img src={g1} alt="NETWORK OF DISTRIBUTORS: IMPLEMENTATION OF THE EXPORT INDUSTRIAL MARKETING PLAN"></img>
        <GraphicDescription>INTERNATIONAL EXHIBITIONS INSIDE YOUR EXPORT BUSINESS PLAN? the most important considerations, for the internationalization of your products, are the investments to be made and the cost-benefit ratio or the operations. If you decide to participate in a Fair, you must be sure that the nation and fair choices are the right ones with participation of many industrial buyers and distributors of your industrial categories. You should evaluate total exhibition costs including fees, cost of stand, accessories, logistics, catalogues and specification sheets, food and accommodation of the personnel at the fair.</GraphicDescription>
      </GraphicSection>

      <SectionContainer>
        <SecctionTitle maxWidth='70%'>EXAMPLE OF AN INDUSTRIAL GLOBAL DISTRIBUTION NEWTORK</SecctionTitle>
        <img src={mapImage} alt="EXAMPLE OF AN INDUSTRIAL GLOBAL DISTRIBUTION NEWTORK"></img>
        <GraphicDescription>BENEFIT/COST RATIO OF INTERNATIONAL EXHIBITIONS After fair normally we come back to our office with "Many Contacts" for follow up and try, from home, to conclude a future distribution relationship, in this case the fair is not valid and the investment has been wasted as the "contacts "end in themselves are not enough, those that count are those that become your distributors. WHO MUST PARTICIPATE IN A FAIR AND WHEN? A company decides to exhibit at the fair if it is economically strong to maintain and/or consolidate the presence of its brand and the relationships with local distributors already acquired. For medium and small companies, the costs of international exhibitions are prohibitive and a positive feedback is not always possible. Once acquired will be your distributor to participate in the fair.. THEREFORE IT IS FUNDAMENTAL TO HAVE A LOCAL DISTRIBUTOR.</GraphicDescription>
      </SectionContainer>

      <SectionContainer>
        <SecctionTitle>PARTICIPATE THE WHOLE YEAR AT THE MOST IMPORTANT FAIR: CREATE A TECHNICAL WEB SITE AND BE PART OF AN EXPORT PLATFORM!</SecctionTitle>

        <SectionBody2>
          <div>
            <p>LAUNCH YOUR PRODUCTS AND START EXPORTING GLOBAL Thanks to the "International B2B Industrial Marketing" the search a new customers, distributors and wholesalers in the world no longer requires expensive and demanding trips abroad for export managers; even a lot less than fairs that now are prohibiteve and, many times, without an economic return. Even intermediaries are not the solution you need to start talking directly with final B2B distributors.</p>
            <p>EXPORT DIRECTLY Until few years ago, exporting products and services to foreign countries requires long periods of time, heavy and demanding costs for small and medium-sized companies. Today, thanks to our network dedicated to export "Business to Business", export is within the reach of all companies.</p>
          </div>

          <SectionBodyImageRight>
            {/* <img></img> */}
          </SectionBodyImageRight>
        </SectionBody2>

      </SectionContainer>

      <SectionContainer>
        <SecctionTitle>WEB PLATFORM FOR FOREIGN TRADE = INTERNET NETWORK + TECHNICAL EXPORT SUPPORT</SecctionTitle>

        <SeccionTwoColumns>
          <div>
            <img style={{ maxWidth: '608px' }} src={territorialImage} alt="WEB PLATFORM FOR FOREIGN TRADE = INTERNET NETWORK + TECHNICAL EXPORT SUPPORT"></img>
          </div>

          <div>
            <p>WHAT IS A WEB PLATFORM FOR GLOBAL EXPORT? is one of the main tools for worldwide exporting, so has to be selected according to your goals:</p>
            <ul style={{fontSize: '12px'}}>
              <li>- It's an international showcase for your products.</li>
              <li>- It's a constant presence of your factory in the world.</li>
              <li>- It's a world's Fair 24 hours/day 365 days/year.</li>
              <li>- It's the market tool that uses your future distributor.</li>
              <li>- It's made according to international market needs.</li>
              <li>- It's defined in the ​​most important market languages.</li>
              <li>- It's your open door to export worldwide.</li>
              <li>- It's the right tool to increase your sales abroad.</li>
            </ul>
          </div>
        </SeccionTwoColumns>

        <p>NETWORK ITALIAN BUSINESS GUIDE FOR WESTERN INDUSTRIES Created in 2000, the "Italian Business Guide" Network is the only modern instrument of new technology, which allows Italian industries to be immediately visible and operative in the most important circuits of the Business to Business (B2B) world. Your company and your products will be "professionally launched" in "all" the portals of the Network that counts millions of industrial visits</p>
      </SectionContainer>

      <SectionContainer>
        <SecctionTitle>PARTICIPATE THE WHOLE YEAR AT THE MOST IMPORTANT FAIR: CREATE A TECHNICAL WEBSITE AND BE PART OF AN EXPORT PLATFORM!</SecctionTitle>

        <SectionBody>
          <p>EXPORT OFFICE INSIDE OUR COMPANY It is essential that within the company organization chart to have at least one person responsible for managing foreign clients and market development. The export manager must be a commercial technician who knows perfectly (spoken and written) at least English and Spanish, optionally is required additional language as Chinese, Arabic or Russian. It is advisable that the export manager is a permanent corporate employee because he will have information about the markets and about the customers who are treasured for every small and medium industry.</p>
          <p>CORPORATE PERSONNEL TRAINING It is necessary to define an annual staff training program at all levels. The internal Company System, in modern industry, is the set of activities necessary to achieve the company objectives at all levels, then the warehouse worker, each machine tool operator, company cleaning team, the assembly department, control and testers group, purchasing manager, production programmer, planing officer, quality assurance, security manager, workshop manager, technical manager, foreign trade manager, packaging staff, logistics department, accounting officer, administrator, management .. etc. they are all fundamental for having a productive system suitable for exporting worldwide</p>
        </SectionBody>
      </SectionContainer>

      <TipsFooter />
      <WBNMap />
    </div>
  );
};

export default Tip0;
