import React from 'react';

import Standard from 'images/membership/WBN_STANDARD.js';
import Gold from 'images/membership/WBN_GOLD.svg';
import Premium from 'images/membership/WBN_PREMIUM.svg';
import Platinum from 'images/membership/WBN_PLATINUM.svg';

const images = {
	standard: Standard,
	gold: Gold,
	premium: Premium,
	platinum: Platinum
};

const MembershipBadge = ({ membershipType = 'standard' }) => {
	if (membershipType === 'standard') {
		return <Standard fill="var(--wbn-primary-color)" />;
	}
	return (
		<img
			src={images[membershipType]}
			alt={`WBN WBNB2B B2B Membership ${membershipType} Badge - Manufacturers Suppliers Vendors Sellers `}
		/>
	);
};

export default MembershipBadge;
