import { put, call } from 'redux-saga/effects';
import { actionSuccess, actionFailed } from 'shared/actions';
import axios from 'axios';
import { POST_LOGOUT_SUCCESS, POST_LOGOUT_FAILED } from './constants';

function postLogoutApi() {
	return axios.request({
		url: `${process.env.REACT_APP_AUTH_URL}logout`,
		method: 'POST',
		headers: {
			'wbn-auth': localStorage.getItem('token')
		}
	});
}

export function* postLogoutSaga() {
	try {
		/*const response = yield call(postLogoutApi);*/
		yield call(postLogoutApi);
		localStorage.clear();
		window.location.replace('/');
		yield put(actionSuccess(POST_LOGOUT_SUCCESS, {}));
	} catch (error) {
		yield put(actionFailed(POST_LOGOUT_FAILED, {}));
	}
}
