import React, { useState, useEffect } from 'react';
import useSWR from 'swr';
import styled from 'styled-components';
import userProfilePlaceholder from 'images/icons/user_profile_placeholder.png';
import ReplySection from 'components/molecules/reply-section';
import { SocialSummaryReactions } from 'components';
import { reactToComment, checkReaction, deleteReactionFromComment } from 'utils/social-api';
const PostCommentStyle = styled.div`
	display: flex;
	margin: 10px 0px;
`;
PostCommentStyle.UserPicture = styled.div`
	> img {
		width: 35px;
		height: 35px;
		object-fit: contain;
		border-radius: 50%;
		border: 1px solid #e3e3e3;
	}
`;
PostCommentStyle.Data = styled.div`
	width: 100%;
	margin: 0px 5px;
`;
PostCommentStyle.Author = styled.div`
	font-size: 13px;
`;
PostCommentStyle.CommentBody = styled.div`
	font-size: 12px;
`;
PostCommentStyle.Footer = styled.div`
	display: flex;
	flex-direction: column;
`;
PostCommentStyle.Footer.Actions = styled.div`
	display: flex;
	font-size: 11px;
	> div {
		margin-right: 5px;
		text-decoration: underline;
		cursor: pointer;
		color: #333;
	}
`;

const ShowRepliesStyle = styled.div`
	font-size: 11px;
	text-decoration: underline;
	cursor: pointer;
`;

const PostComment = ({ user, body, hasReply, reactions }) => {
	const [reaction, setReaction] = useState({ reacted: false, reaction: null });
	const [showReplies, setShowReplies] = useState(false);
	const handleOnClickReact = async (resourceID, reaction) => {
		if (reaction.reacted) {
			let pastReaction = reaction.reaction;
			setReaction({ reacted: false, reaction: null });
			let response = await deleteReactionFromComment({ resourceID });
			if (response.success) {
			} else {
				setReaction({ reacted: true, reaction: pastReaction });
			}
		} else {
			setReaction({ reacted: true, reaction: 'like' });
			let response = await reactToComment({ resourceID, reactionType: 'like' });
			if (response.success) {
			} else {
				setReaction({ reacted: false, reaction: null });
			}
		}
	};
	useEffect(() => {
		const fetch = async () => {
			let response = await checkReaction({ resourceID: body.commentID });
			if (response && response.success) {
				if (response.data) {
					setReaction({ reacted: true, reaction: response.data.reactionType });
				}
			} else {
				console.log('Error', response);
			}
		};
		if (body.commentID) fetch();
	}, [body.commentID]);
	return (
		<PostCommentStyle>
			<PostCommentStyle.UserPicture>
				<img
					src={user.profilePhoto?.url || userProfilePlaceholder}
					alt={`User who comment post WBN ${user.username}`}
				/>
			</PostCommentStyle.UserPicture>
			<PostCommentStyle.Data>
				<PostCommentStyle.Author>{user.firstName + ' ' + user.lastName}</PostCommentStyle.Author>
				<PostCommentStyle.CommentBody>{body.content}</PostCommentStyle.CommentBody>
				<PostCommentStyle.Footer>
					<PostCommentStyle.Footer.Actions>
						<SocialSummaryReactions reactions={reactions} />
						<div
							onClick={() => {
								handleOnClickReact(body.commentID, reaction);
							}}
						>
							{reaction.reacted ? 'Liked' : 'Like'}
						</div>
						{hasReply ? <div>Reply</div> : null}
					</PostCommentStyle.Footer.Actions>
					{hasReply ? (
						<>
							{showReplies ? (
								<div>
									<ShowRepliesStyle onClick={() => setShowReplies(false)}>Hide replies</ShowRepliesStyle>
									<ReplySection resourceID={body.commentID} user={user} resourceType="comment" />
								</div>
							) : (
								<ShowRepliesStyle
									onClick={() => {
										setShowReplies(true);
									}}
								>
									Show replies
								</ShowRepliesStyle>
							)}
						</>
					) : null}
				</PostCommentStyle.Footer>
			</PostCommentStyle.Data>
		</PostCommentStyle>
	);
};
const PostCommentComponent = ({ user, body, hasReply = true }) => {
	const { data, error } = useSWR(`social/reaction/summary/${body.commentID}`);
	let reactions;
	if (error) {
		reactions = { reactionCount: 0 };
	}
	if (!data) {
		reactions = { reactionCount: 0 };
	} else {
		reactions = data.data?.data;
	}
	return <PostComment user={user} body={body} hasReply={hasReply} reactions={reactions} />;
};
export default PostCommentComponent;
