import { put, call } from 'redux-saga/effects';
import { actionSuccess, actionFailed } from 'shared/actions';
import { GET_POST_SUCCESS, GET_POST_FAILED } from './constants';

function getPostApi() {
	return 1;
}

export function* getPostSaga(action) {
	try {
		const response = yield call(getPostApi, action.payload);
		console.log('response', response);
		yield put(actionSuccess(GET_POST_SUCCESS, response));
	} catch (error) {
		yield put(actionFailed(GET_POST_FAILED));
	}
}
