import { GET_GENDER_WATCHER, GET_GENDER_SUCCESS, GET_GENDER_FAILED } from './constants';

const initialState = {
	isLoadingGender: true,
	data: []
};

export default (state = initialState, action) => {
	switch (action.type) {
		case GET_GENDER_WATCHER:
			return {
				isLoadingGender: true,
				data: []
			};
		case GET_GENDER_SUCCESS:
			return {
				isLoadingGender: false,
				data: action.payload
			};

		case GET_GENDER_FAILED:
			return {
				isLoadingGender: false,
				data: []
			};

		default:
			return state;
	}
};
