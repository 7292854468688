import styled from 'styled-components';
import breakpoints from 'breakpoints';

export const Container = styled.div`
	display: flex;
	flex-direction: column;

	> div:first-child {
		display: flex;
		flex-direction: column;
		align-items: center;
		margin-bottom: 20px;

		p {
			width: 90%;
			margin-bottom: 20px;
			text-align: center;
		}
	}
`;

export const CardContainer = styled.div`
	border: 1px solid white;
	width: 100%;
	padding: 30px;
	margin-bottom: 30px;
	background: var(--wbn-primary-color_navbar);
	border-radius: 10px;
`;

export const FormContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;

	> button {
		height: 35px;
		color: #fff !important;
	}

	> button:last-child {
		background-color: var(--wbn-sidebar-color-line) !important;
	}
`;

export const SenderContainer = styled.div`
	width: 100% !important;

	${breakpoints('sm')`
		width: 43.75% !important;
	`}
`;
