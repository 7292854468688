import React from 'react';
import { SocialMenuActions, CommentSection, SocialSummaryReactions } from 'components';
import { PostStyled, Author } from './style';
import { Dropdown } from 'semantic-ui-react';
import { format } from 'timeago.js';
import userProfilePlaceholder from 'images/icons/user_profile_placeholder.png';
import businessProfilePlaceholder from 'images/placeholders/business.svg';
import useSWR from 'swr';
import { deletePost } from 'utils/social-api/';

const renderName = author => {
	if (author.businessID) {
		return author.general.companyName;
	} else {
		return author.firstName + ' ' + author.lastName;
	}
};
const renderPhoto = author => {
	if (author.businessID) {
		return author.general.profilePhoto?.url || businessProfilePlaceholder;
	} else {
		return author.profilePhoto?.url || userProfilePlaceholder;
	}
};

const Post = ({ header, body, reactions, user }) => {
	return (
		<PostStyled>
			<PostStyled.Header>
				<Author>
					<Author.Photo>
						<img src={renderPhoto(header.author)} alt="post" />
					</Author.Photo>
					<Author.Data>
						<Author.Data.Name>{renderName(header.author)}</Author.Data.Name>
						<Author.Data.Time>
							<span title={new Date(header.metadata.datetime).toString()}>
								{format(header.metadata.datetime)}
							</span>
						</Author.Data.Time>
					</Author.Data>
				</Author>
				<div>
					<Dropdown icon={{ name: 'setting', size: 'large', color: 'grey' }} direction="left">
						<Dropdown.Menu>
							<Dropdown.Item
								text="Remove post"
								onClick={async () => {
									try {
										await deletePost({ postID: header.metadata.resourceID });
										window.location.reload()
									}catch(err){
										alert("Ocurrió un error al borrar el post")
									}
								}}
							/>
						</Dropdown.Menu>
					</Dropdown>
				</div>
			</PostStyled.Header>
			<PostStyled.Body>
				{body.text ? <PostStyled.Body.Text>{body.text}</PostStyled.Body.Text> : null}
				{body.picture ? (
					<PostStyled.Body.Picture>
						<img
							src="https://encrypted-tbn0.gstatic.com/images?q=tbn%3AANd9GcQYy5j5g5gU9l6P4X1tNLQ03Bg3V2wKbL6hMVcp-2rzGybFye_J"
							alt="Business Post WBN"
						/>
					</PostStyled.Body.Picture>
				) : null}
			</PostStyled.Body>
			<PostStyled.Footer>
				<PostStyled.Footer.Summary>
					<SocialSummaryReactions reactions={reactions} />
				</PostStyled.Footer.Summary>

				<PostStyled.Footer.Actions>
					<SocialMenuActions resourceID={header.metadata.resourceID} />
				</PostStyled.Footer.Actions>
			</PostStyled.Footer>
			<CommentSection resourceID={header.metadata.resourceID} user={user} />
		</PostStyled>
	);
};

const PostComponent = ({ header, body, user }) => {
	const { data, error } = useSWR(`social/reaction/summary/${header.metadata.resourceID}`);
	if (error) return <div>Error loading data</div>;
	if (!data) return <></>;

	return <Post header={header} body={body} user={user} reactions={data.data?.data} />;
};

export default PostComponent;
