import styled from 'styled-components';

const MembershipWrapper = styled.div`
	display: flex;
	flex-direction: column;
	@media screen and (min-width: 768px) {
		flex-direction: row;
	}
	> div {
		flex-grow: 1;
		flex-basis: 0;
		> * {
			margin: 10px auto;
		}
		border-radius: 10px;
		background-color: var(--wbn-primary-color_navbar);
		margin: 10px 10px;
		padding: 20px;
	}
`;
const ImagePackageContainer = styled.div`
	width: 196px;
	height: 196px;
	@media screen and (min-width: 768px) {
		width: 240px;
		height: 240px;
	}
`;
const ImagePackageContainerMini = styled.div`
	width: 72px;
	height: 72px;
	@media screen and (min-width: 768px) {
		width: 96px;
		height: 96px;
	}
`;
const UpgradeContainer = styled.div`
	display: flex;
	flex-direction: column;
`;

const Payment = styled.div`
	background-color: var(--wbn-primary-color_navbar);
	border-radius: 10px;
	padding: 15px;
`
export {
	MembershipWrapper,
	ImagePackageContainer,
	ImagePackageContainerMini,
	UpgradeContainer,
	Payment
}