import { put, call } from 'redux-saga/effects';
import { map } from 'lodash';
import axios from 'axios';
import { GET_LAST_REGISTERED_SUCCESS, GET_LAST_REGISTERED_FAILED } from './constants';
import { actionSuccess, actionFailed } from 'shared/actions';

function listLastRegisterApi() {
	return axios.request({
		url: `${process.env.REACT_APP_API_URL}search/business/last-registered`,
		method: 'GET'
	});
}

export function* listLastRegisterSaga(action) {
	try {
		const {
			data: { data }
		} = yield call(listLastRegisterApi);

		const customData = map(data, ({ _id, general, shortDescription, contactInfo, businessID }) => {
			return {
				key: _id,
				general,
				value: _id,
				shortDescription,
				contactInfo,
				businessID
			};
		});

		yield put(actionSuccess(GET_LAST_REGISTERED_SUCCESS, customData));
	} catch (error) {
		yield put(actionFailed(GET_LAST_REGISTERED_FAILED, []));
	}
}
