import { GET_COUNTRIES_WATCHER, GET_COUNTRIES_SUCCESS, GET_COUNTRIES_FAILED } from './constants';

const initialState = {
	isLoadingCountries: true,
	countries: []
};

export default (state = initialState, action) => {
	switch (action.type) {
		case GET_COUNTRIES_WATCHER:
			return {
				isLoadingCountries: true,
				countries: []
			};
		case GET_COUNTRIES_SUCCESS:
			return {
				isLoadingCountries: false,
				countries: action.payload
			};

		case GET_COUNTRIES_FAILED:
			return {
				isLoadingCountries: false,
				countries: []
			};

		default:
			return state;
	}
};
