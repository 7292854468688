import React, { useState } from 'react';
import { CardElement, injectStripe } from 'react-stripe-elements';
import { Input } from 'semantic-ui-react';
import apiClient from 'api-client';
import Button from 'components/atoms/button';
import usePreDefinedToasts from 'hooks/use-pre-defined-toasts';

import styled from 'styled-components';

const CardForm = styled.div`
	> div {
		border: 1px solid #e6e6e6;
		padding: 10px;
		border-radius: 3px;
		background-color: white;
	}
`;
const CheckoutForm = props => {
	const { showSuccessToast, showFailureToast } = usePreDefinedToasts();
	const [fullName, setFullName] = useState('');
	const [address, setAddress] = useState('');
	const [email, setEmail] = useState(props.userProfile.email);
	const [isProcessingPayment, setIsProcessingPayment] = useState(false);
	async function submit(ev) {
		setIsProcessingPayment(true);
		const cardElement = props.elements.getElement('card');

		let { paymentMethod, error } = await props.stripe.createPaymentMethod({
			type: 'card',
			card: cardElement,
			billing_details: {
				email: email,
				address: { line1: address },
				name: fullName
			}
		});
		console.log('Payment Method', paymentMethod, error);
		if (error) {
			return alert('You have error in your card information: ' + error.message);
		}

		try {
			let response = await apiClient.post(`${process.env.REACT_APP_API_URL}subscription/subscribe`, {
				paymentMethod: paymentMethod.id,
				businessID: props.businessId,
				planId: props.planID,
				email: email
			});

			console.log(response);
			showSuccessToast('Successful checkout!');
			window.location.reload();
		} catch (error) {
			console.log(error);
			showFailureToast(
				'We are not able to process your payment at this time, please try again in a few minutes or contact support'
			);
		}
		setIsProcessingPayment(false);
	}

	return (
		<div
			className="checkout"
			style={{
				border: '1px solid white',
				backgroundColor: '#fafafa',
				borderRadius: '10px',
				padding: '20px',
				margin: '0 auto'
			}}
		>
			<h5>Type your credit/debit card information bellow to proceed</h5>
			<div>{`You choose membership ${props.membershipType.toUpperCase()}`}</div>
			<div style={{ padding: '10px' }}>
				<Input
					type="text"
					placeholder="Name on card"
					value={fullName}
					onChange={e => setFullName(e.target.value)}
					style={{ width: '100%' }}
				/>
			</div>
			<CardForm style={{ padding: '10px', marginTop: '10px' }}>
				<CardElement />
			</CardForm>
			<div style={{ padding: '10px', marginTop: '10px' }}>
				<Input
					type="text"
					placeholder="Address"
					value={address}
					onChange={e => setAddress(e.target.value)}
					style={{ width: '100%' }}
				/>
			</div>
			<div style={{ padding: '10px', marginTop: '10px' }}>
				<Input
					type="text"
					placeholder="email"
					value={email}
					onChange={e => setEmail(e.target.value)}
					style={{ width: '100%' }}
				/>
			</div>
			<Button
				isLoading={isProcessingPayment}
				disabled={isProcessingPayment}
				style={{ margin: '0 auto', padding: '12px 30px' }}
				onClick={submit}
			>
				Purchase
			</Button>
		</div>
	);
};

export default injectStripe(CheckoutForm);
