import React from 'react';
import { map } from 'lodash';
import { Form, Button } from 'semantic-ui-react';
import { Field as FinalFormField } from 'react-final-form';
import { TextArea } from 'components';
import { FormContainer, CardContainer, SenderContainer } from './style';
import styles from './styles.module.css';

const MemorizedFinalFormField = React.memo(FinalFormField);

const subjectOptions = [
	'We are looking for a Qualified Manufacturer Industry',
	'We are looking for a Private Label Products Manufacturer',
	'We would like to become your Exclusive Distributor in my country',
	'We are looking to become your Worldwide Distributor',
	'We would like to receive full price list of your products',
	'We would like to receive your Discount Policy',
	'Other'
];

export default function SendRequestForm({
	handleSubmit,
	submitting,
	businessProfile,
	businesses,
	userProfile,
	form
}) {
	const senderOptions = React.useMemo(() => {
		const fullName = `${userProfile.firstName} ${userProfile.lastName}`;

		const meOption = {
			key: userProfile.userID,
			text: fullName,
			value: userProfile.userID
		};

		const myBusinessesOptions = map(businesses, item => ({
			key: item.businessID,
			text: item.business.general.companyName,
			value: item.businessID
		}));

		return [meOption, ...myBusinessesOptions];
		// eslint-disable-next-line
	}, []);

	const renderSenderDropdown = React.useCallback(
		({ input }) => {
			return (
				<Form.Dropdown
					{...input}
					fluid
					search
					selection
					options={senderOptions}
					onChange={(_, { value }) => input.onChange(value)}
					className={styles.noPadding}
					style={{ width: '100%', minWidth: 'auto' }}
					width={5}
				/>
			);
		},
		// eslint-disable-next-line
		[]
	);

	return (
		<FormContainer>
			<CardContainer>
				<Form>
					<SenderContainer>
						<Form.Group inline unstackable widths="equal">
							<Form.Field width={2} label="To:" />
							<Form.Field width={5} className={styles.noPadding}>
								{businessProfile.general.companyName}
							</Form.Field>
						</Form.Group>

						<Form.Group inline unstackable widths="equal">
							<Form.Field width={2} label="Send as:" />
							<MemorizedFinalFormField name="sender" render={renderSenderDropdown} />
						</Form.Group>
					</SenderContainer>

					<Form.Group grouped style={{ marginLeft: '-0.5em' }}>
						<Form.Field width={3} label="Subject" />

						<FinalFormField name="subjectOption">
							{({ input }) => (
								<>
									<Form.Select
										{...input}
										style={{ width: '100%' }}
										width={7}
										options={map(subjectOptions, value => ({ text: value, value }))}
										onChange={(_, { value }) => {
											form.change('subject', undefined);
											input.onChange(value);
										}}
									/>
									{input.value === 'Other' && (
										<FinalFormField name="subject">
											{({ input }) => <Form.Input {...input} width={7} placeholder="Type your subject" />}
										</FinalFormField>
									)}
								</>
							)}
						</FinalFormField>
					</Form.Group>

					<Form.Group grouped>
						<Form.Field width={3} label="Message" style={{ marginLeft: '-0.5em' }} />

						<FinalFormField name="message">
							{({ input }) => <Form.Field {...input} control={TextArea} style={{ width: '100%' }} rows={7} />}
						</FinalFormField>
					</Form.Group>
				</Form>
			</CardContainer>
			<Button
				type="submit"
				loading={submitting}
				onClick={async e => {
					try {
						await handleSubmit(e);
						form.reset();
					} catch (err) {
						console.error('[Submission Error]', err);
					}
				}}
			>
				Upload Inquiry
			</Button>
		</FormContainer>
	);
}
