import React from 'react';
import WBNMapSource from 'components/atoms/wbn-map-image';
import Button from 'components/atoms/button';
import WBNNetwork from 'components/molecules/wbn-network';
import MembershipPlans from 'components/molecules/membership-plans';
import Helmet from 'react-helmet';
const OurNetwork = () => {
	return (
		<div>
			<Helmet>
				<title>{`${process.env.REACT_APP_SEO_SECOND} free B2B export network ${process.env.REACT_APP_SEO_SECOND} world manufacturing distributors, ${process.env.REACT_APP_SEO_FIRST} industrial social network technology to help small companies to export worldwide, WBN manufacturing network supplies, world industry matching investors ${process.env.REACT_APP_SEO_FIRST}, WBN manufacturing platform`}</title>
				<meta name="description" content={`${process.env.REACT_APP_SEO_FIRST} free export B2B tool for export and industry exporter in the distribution network, industrial free exporter business tool is an export trade platform for business buyers from companies, ${process.env.REACT_APP_SEO_FIRST} industrial exporter business tool of manufacturing network, how to create your products export marketing business buyers for industries, made in ${process.env.REACT_APP_SEO_SECOND} export platform manufacturing vendors to the export global wholesalers and export distributors, ${process.env.REACT_APP_SEO_FIRST} business trade guide is part of WBN industrial b2b social network, made in ${process.env.REACT_APP_SEO_SECOND} export products manufacturer export business supply, ${process.env.REACT_APP_SEO_FIRST} products for wholesale trade businesses distributors in United States, Asia, Africa, Europe, South America, Latin America and Middle East distribution, WBN the industrial b2b social network for export wholesalers and manufacturing private label industries, made in ${process.env.REACT_APP_SEO_SECOND} products manufacturer supplier for wholesalers and vendors of the market, industrial products for wholesale worldwide distributors, ${process.env.REACT_APP_SEO_FIRST} products manufacturing for private label buyers and new brand for manufacturer, business to business manufacturing supplies and market distributors, ${process.env.REACT_APP_SEO_FIRST} product manufacturing industry looking for worldwide distributors mainly in Germany, Denmark, Austria, Portugal, Spain, France, Norway, Sweden in Europe, China, Japan, Korea, Vietnam in Asia, California, New York, Florida, Chicago Illinois and all the United States of America, South Arabia, Qatar, Oman, UAE, Middle East, Australia, North of Africa, South America and all Latin America`}/>
				<meta name="keywords" content={`${process.env.REACT_APP_SEO_SECOND} export tool, ${process.env.REACT_APP_SEO_FIRST} companies tool, ${process.env.REACT_APP_SEO_FIRST} manufacturing export, ${process.env.REACT_APP_SEO_FIRST} business exporter, ${process.env.REACT_APP_SEO_FIRST} exporter platform, ${process.env.REACT_APP_SEO_FIRST} social network, ${process.env.REACT_APP_SEO_FIRST} business exporter, ${process.env.REACT_APP_SEO_SECOND} manufacturer exporter`}/>
			</Helmet>
			<div>
				<p style={{ textAlign: 'center', padding: '5px 10px' }}>WBN OUR NETWORK</p>
				<p style={{ textAlign: 'center', padding: '5px 30px' }}>
					We have defined a professional B2B Network considering the market needs to help 
					companies, FOR FREE, to connect with B2B manufacturing, distribution industries, 
					making Import and Export trade available for everyone in few clicks.
				</p>
			</div>
			<Button style={{ margin: '30px auto' }}>WBN THE INDUSTRIAL B2B SOCIAL NETWORK</Button>
			<WBNNetwork />
			<MembershipPlans />
			<WBNMapSource/>
		</div>
	);
};

export default OurNetwork;
