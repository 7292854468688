import React from 'react';


const Furniture = ({fill = "white"}) => {
	return (
<svg id="Capa_1" data-name="Capa 1" 
	xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
	<title>FURNITURE</title>
	<rect style={{fill}} x="2.7" y="11.57" width="3.9" height="0.8"/>
	<path style={{fill}} d="M9.62,16.66v-.23a1.09,1.09,0,0,1,1.09-1.08H20.8a1.09,1.09,0,0,1,1.1,1.08v.23m-.72,0v-.23a.37.37,0,0,0-.38-.36H10.71a.37.37,0,0,0-.37.36v.23m0,0H21.18"/>
	<path style={{fill}} d="M16.89,15.6H14.42V13.31h2.47ZM15,15.35h1.39v-2H15Z"/>
	<rect style={{fill}} x="1.32" width="22.34" height="0.99"/>
	<path style={{fill}} d="M19,5.47H11.67a.19.19,0,0,0-.2.18v5.14a.19.19,0,0,0,.2.18H19a.19.19,0,0,0,.2-.18V5.65A.19.19,0,0,0,19,5.47ZM13.87,8.61a.38.38,0,1,1,.38-.38A.38.38,0,0,1,13.87,8.61Zm2.56-.71a.46.46,0,0,1-.05.2.25.25,0,0,1-.15.14.36.36,0,0,1,.2.32v.33a.68.68,0,0,1-.17.49.65.65,0,0,1-.49.17H14.45V8.89h1.16a.13.13,0,0,0,.15-.15.2.2,0,0,0,0-.13.16.16,0,0,0-.11,0H14.45V7.9h1.16c.1,0,.15-.05.15-.16a.19.19,0,0,0,0-.12.16.16,0,0,0-.11,0H14.45V6.91h1.32a.59.59,0,0,1,.66.66Z"/>
	<rect style={{fill}} x="10.33" y="20.89" width="2.71" height="0.96" rx="0.45" ry="0.45"/>
	<rect style={{fill}} x="2.24" y="0.99" width="0.91" height="16.41"/>
	<rect style={{fill}} x="6.15" y="0.99" width="0.91" height="16.41"/>
	<rect style={{fill}} x="2.7" y="5.69" width="3.9" height="0.8"/>
	<path style={{fill}} d="M22.16,3.44c0-.18-.09-.3-.27-.32l-.26,0H9.09c-.29,0-.39.13-.39.46v9.33c0,.34.11.47.4.47H21.73c.33,0,.43-.12.43-.5V3.59A.75.75,0,0,0,22.16,3.44ZM9.56,12.62V3.83H21.15v8.79Z"/>
	<path style={{fill}} d="M13.85,8.33l0,0,0,0,0,0,0,0a.13.13,0,0,0,0-.06A.1.1,0,0,0,14,8a.15.15,0,0,0-.1,0,.18.18,0,0,0-.11,0,.15.15,0,0,0,0,.1h.08l0,0,.05,0s0,0,0,.06a0,0,0,0,1,0,0,.17.17,0,0,1-.06.06l-.08.08,0,.07v.06H14V8.37h-.2Z"/>
	<path style={{fill: "none", stroke: fill, strokeMiterlimit :10, strokeWidth: "0.7px"}} d="M22.17,23.66H1.87V19.08h20.3Z"/>
	<path style={{fill: "none"}} d="M16.43,8.89a.68.68,0,0,1-.17.49.65.65,0,0,1-.49.17H14.45V8.89h1.16a.13.13,0,0,0,.15-.15.2.2,0,0,0,0-.13.16.16,0,0,0-.11,0H14.45V7.9h1.16c.1,0,.15-.05.15-.16a.19.19,0,0,0,0-.12.16.16,0,0,0-.11,0H14.45V6.91h1.32a.59.59,0,0,1,.66.66V7.9a.46.46,0,0,1-.05.2.25.25,0,0,1-.15.14.36.36,0,0,1,.2.32Z"/>
	<path style={{fill: "none"}} d="M16.43,8.89a.68.68,0,0,1-.17.49.65.65,0,0,1-.49.17H14.45V8.89h1.16a.13.13,0,0,0,.15-.15.2.2,0,0,0,0-.13.16.16,0,0,0-.11,0H14.45V7.9h1.16c.1,0,.15-.05.15-.16a.19.19,0,0,0,0-.12.16.16,0,0,0-.11,0H14.45V6.91h1.32a.59.59,0,0,1,.66.66V7.9a.46.46,0,0,1-.05.2.25.25,0,0,1-.15.14.36.36,0,0,1,.2.32Z"/>
</svg>
	)
}

export default Furniture;
