import { GET_LIST_BUSINESS_WATCHER, GET_LIST_BUSINESS_SUCCESS, GET_LIST_BUSINESS_FAILED } from './constants';

const initialState = {
	isLoadingListBusiness: true,
	data: []
};

export default (state = initialState, action) => {
	switch (action.type) {
		case GET_LIST_BUSINESS_WATCHER:
			return {
				isLoadingListBusiness: true,
				data: []
			};
		case GET_LIST_BUSINESS_SUCCESS:
			return {
				isLoadingListBusiness: false,
				data: action.payload
			};

		case GET_LIST_BUSINESS_FAILED:
			return {
				isLoadingListBusiness: false,
				data: []
			};

		default:
			return state;
	}
};
