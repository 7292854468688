import { put, call } from 'redux-saga/effects';
import { map } from 'lodash';
import axios from 'axios';
import { GET_INDUSTRIES_SUCCESS, GET_INDUSTRIES_FAILED } from './constants';
import { actionSuccess, actionFailed } from 'shared/actions';

function listIndustriesApi() {
	return axios.request({
		url: `${process.env.REACT_APP_API_URL}catsys/industry`,
		method: 'GET'
	});
}

export function* listIndustriesSaga() {
	try {
		const {
			data: { data }
		} = yield call(listIndustriesApi);
		const customData = map(data, ({ id, name }) => {
			return {
				key: id,
				text: name,
				value: id
			};
		});

		yield put(actionSuccess(GET_INDUSTRIES_SUCCESS, customData));
	} catch (error) {
		yield put(actionFailed(GET_INDUSTRIES_FAILED, []));
	}
}
