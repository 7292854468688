export const actionWatcher = (type, payload) => {
	return {
		type,
		payload
	};
};

export const actionSuccess = (type, payload) => {
	return {
		type,
		payload
	};
};

export const actionFailed = (type, payload) => {
	return {
		type,
		payload
	};
};
