import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Input, Icon } from 'semantic-ui-react';

import { GET_COUNTRIES_WATCHER } from 'store/countries/constants';
import { actionWatcher } from 'shared/actions';

import { Select, Autocomplete } from 'components';
import businessOptions from './businessOptions';

import {
  BodySelect,
  Body,
  Section,
} from './styles';

function CountryItem({ text, icon }) {
  return (
    <div>
      <i style={{ marginRight: 10 }}>{icon}</i>
      {text}
    </div>
  );
}

const getQuerySearchParams = searchUrl => {
  const business = searchUrl.get('business') || 'MA';
  const countriesParams = searchUrl.get('countries') || 'global';
  const worldSearch = searchUrl.get('search') || '';

  return { business: business, countries: countriesParams, stateWord: worldSearch };
};

const SearchSection = ({ getCountries, countries, history }) => {
  const urlParams = new URLSearchParams(history.location.search);
  const params = getQuerySearchParams(urlParams);
  const [selectBusiness, setSelectBusiness] = useState(params.business);
  const [selectCuntries, setSelectCuntries] = useState(params.countries);
  const [searchWord, setSearchWord] = useState(params.stateWord);

  const handleInput = e => {
    setSearchWord(e.target.value);
  };
  function handleSubmitSearch() {
    history.push(
      `/search-result?business=${selectBusiness}&countries=${selectCuntries}&search=${encodeURIComponent(
        searchWord
      )}`
    );
  }

  function handlerSelectBusinessOptions(data) {
    setSelectBusiness(data.value);
  }

  function handlerSearch(e) {
    setSearchWord(e.target.value);
    let { keyCode } = e;
    if (keyCode === 13) {
      handleSubmitSearch();
    }
  }

  function handlerSelectCountries(data) {
    setSelectCuntries(data.value);
  }

  useEffect(() => {
    getCountries();

    return history.listen((location, action) => {
      const params = getQuerySearchParams(new URLSearchParams(location.search));
      setSelectBusiness(params.business);
      setSelectCuntries(params.countries);
      setSearchWord(params.stateWord);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Section>
      <Body>
        <BodySelect>
          <Select
            options={businessOptions}
            defaultValue={selectBusiness}
            onChange={handlerSelectBusinessOptions}
          />
          <Autocomplete
            defaultValue={selectCuntries}
            options={[{ key: 'global', text: 'Global', value: 'global' }, ...countries]}
            onChange={handlerSelectCountries}
            render={option => (option.icon ? <CountryItem {...option} /> : option.text)}
          />
        </BodySelect>
        <Input
          type="search"
          placeholder="Search companies and products"
          icon={
            <Icon
              onClick={handleSubmitSearch}
              name="search"
              style={{cursor: 'pointer', pointerEvents: 'all'}} />
          }
          onKeyUp={handlerSearch}
          onChange={handleInput}
          value={searchWord}
        />
      </Body>
    </Section>
  );
};

const mapStateToProps = ({ countries }) => {
  return {
    countries: countries.countries
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getCountries: () => dispatch(actionWatcher(GET_COUNTRIES_WATCHER))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SearchSection);
