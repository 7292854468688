import React, { useState } from 'react';
import { TextArea, PostComment } from 'components';
import { ReplySectionWrapper, UserComment } from './style';
import { postCommentOnEntity } from 'utils/social-api';
import useSWR, { useSWRPages } from 'swr';
import userProfilePlaceholder from 'images/icons/user_profile_placeholder.png';
import sendIcon from 'images/icons/send_2.png';
const createPostComment = async ({
	resourceID,
	commentContent,
	setCommentContent,
	revalidateComments,
	resourceType = 'comment'
}) => {
	let content = commentContent.content;
	try {
		setCommentContent({ disabled: true, content: '' });
		let response = await postCommentOnEntity({ content, resourceType, resourceID });
		console.log('CREATING COMMENT REPLY RESPONSE', response, revalidateComments);
		if (response && response.success) {
			setCommentContent({ disabled: false, content: '' });
			revalidateComments();
		} else {
			setCommentContent({ disabled: false, content: content });
		}
	} catch (error) {
		console.error('Error sending comment', error);
		setCommentContent({ disabled: false, content: content });
	}
};
const size = 5;
const ReplySection = ({ user, resourceID }) => {
	const [commentContent, setCommentContent] = useState({ disabled: false, content: '' });
	const [thereAreMoreComments, setThereAreMoreComments] = useState(true);
	const { pages, isLoadingMore, pageSWRs, loadMore } = useSWRPages(
		'reply-comments',
		({ offset, withSWR }) => {
			const url = 'social/comments/' + resourceID + `?skip=${offset || 0}&size=${size}`;
			// eslint-disable-next-line react-hooks/rules-of-hooks
			const { data: response } = withSWR(useSWR(url));

			if (!response) return null;

			const { data } = response.data;
			if (data.comments.length === 0) {
				setThereAreMoreComments(false);
			}else{
				setThereAreMoreComments(true);
			}
			return data.comments.map((comment, key) => {
				return <PostComment key={key} user={comment.whoComment} body={comment} hasReply={false} />;
			});
		},
		(data, pageSWRs) => {
			return (pageSWRs + 1) * size;
		},
		[]
	);
	const revalidateComments = ()=>{
		pageSWRs.forEach(e=>{
			e.revalidate()
		})
	}
	return (
		<ReplySectionWrapper>
			<ReplySectionWrapper.User>
				{user ? (
					<UserComment>
						<UserComment.Picture style={{ display: 'flex' }}>
							<img src={user.profilePhoto?.url || userProfilePlaceholder} alt="post" />
						</UserComment.Picture>
						<UserComment.TextArea>
							<TextArea
								rows={1}
								placeholder="Post your comment"
								showCounter={false}
								value={commentContent.content}
								disabled={commentContent.disabled}
								onChange={e => {
									setCommentContent({ disabled: false, content: e.target.value });
								}}
								onKeyPress={e => {
									if (e.key === 'Enter' && !e.shiftKey) {
										e.preventDefault();
										createPostComment({
											resourceID,
											commentContent,
											setCommentContent,
											revalidateComments: revalidateComments
										});
									}
								}}
							/>
						</UserComment.TextArea>
						<UserComment.IconSend
							onClick={() => {
								if (!commentContent.disabled && commentContent.content) {
									createPostComment({
										resourceID,
										commentContent,
										setCommentContent,
										revalidateComments: revalidateComments
									});
								}
							}}
						>
							<img src={sendIcon} alt="WBN Icon create comment - The industrial B2B social network" />
						</UserComment.IconSend>
					</UserComment>
				) : (
					<div>If you want to comment you must be loged in.</div>
				)}
			</ReplySectionWrapper.User>
			<ReplySectionWrapper.Replies>
				<div>{pages}</div>
				<div
					style={{ fontSize: '12px', cursor: 'pointer', color: 'blue' }}
					onClick={() => {
						if (!isLoadingMore) {
							loadMore();
						}
					}}
				>
					{isLoadingMore ? 'Loading' : thereAreMoreComments ? 'Load more replies' : null}
				</div>
			</ReplySectionWrapper.Replies>
		</ReplySectionWrapper>
	);
};
const ReplySectionComponent = ({ resourceID, user, resourceType = 'post' }) => {
	return <ReplySection user={user} resourceID={resourceID} resourceType={resourceType} />;
};

export default ReplySectionComponent;
