import React from 'react';
import { Link } from 'react-router-dom';
import { map } from 'lodash';
import logoWbn from 'domain_brand';
import { Icon } from 'semantic-ui-react';
import { connect } from 'react-redux';
import { actionWatcher } from 'shared/actions';
import { POST_LOGOUT_WATCHER } from 'store/logout/constants';

import {
  Logo,
  Mask,
  MenuMobileContainer,
  MenuContent,
  MenuHeader,
  MenuItem,
} from './styles';

const accountList = [
  { name: 'My account', icon: 'user', path: '/profile' },
  { name: 'Messages', icon: 'chat', path: '/my-messages' },
  { name: 'My business', icon: 'suitcase', path: '/my-business' }
];

const mainMenuList = [
  { name: 'Home', icon: 'home', path: '/' },
  { name: 'Articles', icon: 'file alternate outline', path: '/b2b-tips/export-to-the-world-tip-0' },
  { name: 'Industries', icon: 'industry', path: '/industries' }
];

const MenuMobile = ({ setShowMenu, postLogout }) => {
  let profile = localStorage.getItem('profile');
  if (profile) {
    profile = JSON.parse(profile);

    accountList[1].path = `/business/${profile.userID}/requests`
  }

  return (
    <MenuMobileContainer>
      <MenuContent>
        <MenuHeader>
          <div>
            <Link to="/" onClick={() => setShowMenu(false)}>
              <Logo src={logoWbn} alt="WBNB2B LOGO Industrial B2B2 Manufacturers" />
            </Link>
          </div>
          <Icon
            name="close"
            style={{ color: '#FFF', fontSize: '18px' }}
            onClick={() => setShowMenu(false)}
          />
        </MenuHeader>
        <div>
          <MenuItem.Title>ACCOUNT</MenuItem.Title>
          <MenuItem.List>
            {map(accountList, ({ name, icon, path }) => {
              return (
                <li key={name}>
                  <Link to={path} onClick={() => setShowMenu(false)}>
                    <Icon name={icon} />
                    {name}
                  </Link>
                </li>
              );
            })}
          </MenuItem.List>
        </div>
        <div>
          <MenuItem.Title>MAIN MENU</MenuItem.Title>
          <MenuItem.List>
            {map(mainMenuList, ({ name, icon, path }) => {
              return (
                <li key={name}>
                  <Link to={path} onClick={() => setShowMenu(false)}>
                    <Icon name={icon} />
                    {name}
                  </Link>
                </li>
              );
            })}
          </MenuItem.List>
        </div>
        <div>
          <MenuItem.Title>SUPPORT</MenuItem.Title>
          <MenuItem.List>
            <li>
              <Link to="/contact-us/form" onClick={e => setShowMenu(false)}>
                <Icon name="phone" />
                Contact us
							</Link>
            </li>
          </MenuItem.List>
        </div>
        <div>
          <MenuItem.Title />
          <MenuItem.List>
            <li>
              <Link to="/" onClick={e => {
                e.preventDefault();
                setShowMenu(false);
                postLogout();
              }}>
                <Icon name="log out" />
                Logout
							</Link>
            </li>
          </MenuItem.List>
        </div>
      </MenuContent>
      <Mask onClick={() => setShowMenu(false)} />
    </MenuMobileContainer>
  );
};


const mapStateToProps = ({ logout }) => {
  return {
    logout: logout.data
  };
};

const mapDispatchToProps = dispatch => {
  return {
    postLogout: () => dispatch(actionWatcher(POST_LOGOUT_WATCHER))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MenuMobile);
