import React from 'react';
import { SocialAction } from 'components';
import styled from 'styled-components';

const SocialMenuActionStyle = styled.div`
	display: flex;
	justify-content: space-around;
`;

const SocialMenuAction = ({resourceID}) => {
	return (
		<SocialMenuActionStyle>
			<SocialAction resourceID= {resourceID} actionType="LIKE" />
			<SocialAction actionType="COMMENT" />
			<SocialAction actionType="SHARE" />
		</SocialMenuActionStyle>
	);
};

export default SocialMenuAction;
