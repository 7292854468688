import React from 'react';
import Helmet from 'react-helmet';

import { WBNMap } from 'components/molecules';
// import { Button } from 'components/atoms';

// import { } from './styles.js';

import g1 from 'images/b2b-tips/tip-4/WBN_TIP.png';
import industrialBuyerImage from 'images/b2b-tips/tip-4/1.jpg';
import coffeeImage from 'images/b2b-tips/tip-4/2.jpg';
// import dabieIcon from 'images/b2b-tips/tip-4/WBN-DABIE.svg';

import {
  Section1,
  Secion1Title,
  SecctionTitle,
  Section1Description,
  GraphicSection,
  GraphicTitle,
  GraphicDescription,
  SectionContainer,
  SectionBody1,
  SectionBodyImageLeft,
  SectionBodyImage,
  SectionBody2,
} from '../style';

import { TipsFooter } from '../tip0';


const Tip0 = () => {
  window.scrollTo(0, 0);

  return (
    <div>
      <Helmet>
        <title>{`Who is the industrial buyer, professional b2b industrial buyer in charge to purchase ${process.env.REACT_APP_SEO_FIRST} products, international b2b industrial buyers supply, export b2b products buyers suppliers, ${process.env.REACT_APP_SEO_SECOND} b2b buyer manufacturing organization, vendors evaluation USA`}</title>
        <meta name="description" content={`${process.env.REACT_APP_SEO_FIRST} industrial buyer, who is buyer in the distribution network, industrial export network is an export trade platform for business buyers from companies, ${process.env.REACT_APP_SEO_FIRST} industrial buyers of manufacturing network, how to create your products export marketing business buyers for industries, made in ${process.env.REACT_APP_SEO_SECOND} export platform manufacturing vendors to the export global wholesalers and export distributors, ${process.env.REACT_APP_SEO_FIRST} business trade guide is part of WBN industrial b2b social network, made in ${process.env.REACT_APP_SEO_SECOND} export products manufacturer export business supply, ${process.env.REACT_APP_SEO_FIRST} products for wholesale trade businesses distributors in United States, Asia, Africa, Europe, South America, Latin America and Middle East distribution, WBN the industrial b2b social network for export wholesalers and manufacturing private label industries, made in ${process.env.REACT_APP_SEO_SECOND} products manufacturer supplier for wholesalers and vendors of the market, industrial products for wholesale worldwide distributors, ${process.env.REACT_APP_SEO_FIRST} products manufacturing for private label buyers and new brand for manufacturer, business to business manufacturing supplies and market distributors, ${process.env.REACT_APP_SEO_FIRST} product manufacturing industry looking for worldwide distributors mainly in Germany, Denmark, Austria, Portugal, Spain, France, Norway, Sweden in Europe, China, Japan, Korea, Vietnam in Asia, California, New York, Florida, Chicago Illinois and all the United States of America, South Arabia, Qatar, Oman, UAE, Middle East, Australia, North of Africa, South America and all Latin America`} />
        <meta name="keywords" content={`${process.env.REACT_APP_SEO_SECOND} export buyers, ${process.env.REACT_APP_SEO_FIRST} b2b buyers, ${process.env.REACT_APP_SEO_FIRST} manufacturing buyers, ${process.env.REACT_APP_SEO_FIRST} buyers products, ${process.env.REACT_APP_SEO_FIRST} business buyers, ${process.env.REACT_APP_SEO_FIRST} buyers platform, ${process.env.REACT_APP_SEO_FIRST} social network, ${process.env.REACT_APP_SEO_FIRST} business guide, ${process.env.REACT_APP_SEO_SECOND} manufacturer buyers`} />
      </Helmet>

      <Section1>
        <Secion1Title>WHO IS AN INDUSTRIAL BUYER</Secion1Title>
        <Section1Description>The buyer is the person in charge to make company purchase decisions regarding each material, component, product and services required by his organization. The industrial buyer selects the best supplier for each product need it according to several information received by technitians and industrial requirements used to evaluate the best supplier or vendor. The international buyer considers, technical specifications, quality, pricing, packaging, delivery time, additional terms and condistions and his own customized evaluation procedure to define the best supplier for each company requirement</Section1Description>
      </Section1>

      <GraphicSection>
        <SecctionTitle>INDUSTRIAL BUYER: MAKES PURCHASE DECISIONS</SecctionTitle>

        <GraphicTitle>Following we will define tips and suggestions to understand how to prepare the best offer strategy to complete information required by buyers</GraphicTitle>

        <img src={g1} alt="INDUSTRIAL BUYER: MAKES PURCHASE DECISIONS"></img>

        <GraphicDescription>B2B BUYING PROCESS: INTERNATIONAL INDUSTRIAL BUYERS Currently, to have a great customer services, each company needs to know every topic that affects the quality of their industrial system, one of the most important is certainly purchasing division. The internal procedures for the purchase of materials and services are key part of the production system, therefore each purchase must be programmed on time to satisfy the internal requests of the processes, not only manufacturing even those of the sales, administrative, logisticis and all other departments ASK FOR INDUSTRIAL TIPS TO DEFINE YOUR EXPORT PROGRAM</GraphicDescription>
      </GraphicSection>

      <SectionContainer>
        <SecctionTitle maxWidth='70%'>HOW INTERNATIONAL B2B BUYERS ARE SOURCING NEW INDUSTRIAL SUPPLIERS?</SecctionTitle>

        <SectionBody1>
          <div>
            <p>VISITING INTERNATIONAL SHOWS There are some buyers that, still, visit fairs to meet new suppliers, however each time are less buyers insisting in this method because it is almost impossible to reach and select the best industrial sources during three days of show... many of them back to their office only with a lot of business cards.</p>
            <p>USING SEARCHING ENGINE MOTOR It's almost daily Buyers routine to visit searching motors as google, yahoo and others to find new manufacturers and sources of what are looking for. Normally they</p>
          </div>
          <SectionBodyImage width='200px' height='200px'>
            <img src={industrialBuyerImage} alt=""></img>
          </SectionBodyImage>
          <div>
            <p>just check and visit a group of Top websites listed in the first web page results, furthermore their first filter the fast info obtained in your web site.</p>
            <p>WORLDWIDE SEARCH More buyers head online to conduct their vendors it doesn't matter how far is the vendor, the most important it's to guarantee technical specifications, quality and delivery time, for sure also best price. Modern supplier sales process should match with buyers procedures highlighting an strong online presence.</p>
          </div>
        </SectionBody1>
      </SectionContainer>

      <SectionContainer>
        <SecctionTitle>WHAT ARE THE BIGGEST FACTOR OF IMPORTANCE TO INDUSTRIAL B2B BUYERS</SecctionTitle>

        <SectionBody2>
          <SectionBodyImageLeft width='200px' height='202px'>
            <img src={coffeeImage} alt=""></img>
          </SectionBodyImageLeft>
          <div>
            <p>DELIVERY TIME For industrial buyers the most important factor it's, for sure, delivery performance, because to run and maintain an industrial manufacturing process they need to keep a minimal quantity of products available in stock to avoid any kind of emergency. To buy bulk or big quantities should be defined a Monthly or Annual Buying Program. PRICE When you're talking about industrial buying process the price and discount policy should fit the right delivery time to have the best purchasing program. ANSWER TIME & CUSTOMER SERVICES It's one of the key topics for buyers, they need to be assisted during the whole time, also after sales. Any answer should be done before 24 hours to maintain a great customer services to guarantee future deals. PAYMENT CONDITIONS When you have industrial quantities you should be prepared to offer several payment proposals to the buyer, from letter of credit, FOB, CIF and others FULL TECHNICAL SPECIFICATIONS If you don't have full technical specifications of your products or services you can't send an offer because will be refused immediately. CONTRACT AND GENERAL TERMS AND CONDITIONS As normal procedure each supplier should be prepared (before send an offer) with a General Contract including Sales terms & conditions, with full agreements with buyer regarding the technical specification, quantities, delivery time, packaging, price, discount policy, payment conditions, shipment procedure, logistics, customer services, claims procedure solutions, legal terms and expiration date. The contract should be defined for both parties according to the type of business and international law applied in case of claims</p>
          </div>
        </SectionBody2>
      </SectionContainer>

      <TipsFooter />
      <WBNMap />
    </div>
  );
};

export default Tip0;
