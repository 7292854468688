import React from 'react';
import { Dimmer, Loader, Segment } from 'semantic-ui-react';
import './styles.less';

const OwnLoader = ({text = 'Loading'}) => {
	return (
		<div className="container-own-loader">
			<Segment>
				<Dimmer active inverted>
					<Loader inverted>{text}</Loader>
				</Dimmer>
			</Segment>
		</div>
	);
};

export default OwnLoader;
