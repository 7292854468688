import React from 'react';
import Newsfeed from './index';
import { useParams } from 'react-router-dom';
const TimeLine = () => {
	const { timelineID } = useParams();
	// console.log("TIMELINE", timelineID);
	return <Newsfeed urlFetching={`timeline/load/${timelineID}`} type='timeline' />;
};

export default TimeLine;
