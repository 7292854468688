import {
	GET_BUSINESS_PROFILE_WATCHER,
	GET_BUSINESS_PROFILE_SUCCESS,
	GET_BUSINESS_PROFILE_FAILED
} from './constants';

const initialState = {
	isLoading: true,
	data: {}
};

export default (state = initialState, action) => {
	switch (action.type) {
		case GET_BUSINESS_PROFILE_WATCHER:
			return {
				isLoading: true,
				data: {}
			};

		case GET_BUSINESS_PROFILE_SUCCESS:
			return {
				isLoading: false,
				data: action.payload
			};

		case GET_BUSINESS_PROFILE_FAILED:
			return {
				isLoading: false,
				data: {}
			};

		default:
			return state;
	}
};
