import React from 'react';
import { Industry } from 'components';
import './styles.css';
import Button from '../../components/atoms/button';
import { Helmet } from 'react-helmet';
const Industries = ({ history }) => {
	window.scrollTo(0, 0);
	return (
		<div>
			<Helmet>
				<title>
					{process.env.REACT_APP_SEO_FIRST} industries, main {process.env.REACT_APP_SEO_FIRST}
					manufacturing industries, made in {process.env.REACT_APP_SEO_SECOND} manufacturer products
					industry, {process.env.REACT_APP_SEO_FIRST} industrial wholesale distributors, made in
					{process.env.REACT_APP_SEO_SECOND} private label products vendors,
					{process.env.REACT_APP_SEO_FIRST} manufacturing industries supply, B2B USA manufacturer
				</title>
				<meta
					name="description"
					content={`${process.env.REACT_APP_SEO_FIRST} manufacturing industries, ${process.env.REACT_APP_SEO_FIRST} B2B industrial manufacturing supplies, made in ${process.env.REACT_APP_SEO_SECOND} manufacturing vendors for B2B global distributors, WBN your best source of manufacturer suppliers and vendors, ${process.env.REACT_APP_SEO_FIRST} business guide is part of WBN industrial b2b social network, made in ${process.env.REACT_APP_SEO_SECOND} export products manufacturing business supply, ${process.env.REACT_APP_SEO_FIRST} products for wholesale business distributors in United States, Asia, Africa, Europe, South America, Latin America and Middle East distribution, WBN the industrial b2b social network for wholesalers and manufacturing private label industries, made in ${process.env.REACT_APP_SEO_SECOND} products manufacturer supplier for wholesalers and vendors of the market, industrial products for wholesale worldwide distributors, ${process.env.REACT_APP_SEO_FIRST} products manufacturing for private label buyers and new brand for manufacturer, business to business manufacturing supplies and market distribution, ${process.env.REACT_APP_SEO_FIRST} product manufacturing industry looking for worldwide distributors mainly in Germany, Denmark, Austria, Portugal, Spain, France, Norway, Sweden in Europe, China, Japan, Korea, Vietnam in Asia, California, New York, Florida, Chicago Illinois and all the United States of America, South Arabia, Qatar, Oman, UAE, Middle East, Australia, North of Africa, South America and all Latin America`}
				/>
				<meta
					name="keywords"
					content={`${process.env.REACT_APP_SEO_FIRST} industries, ${process.env.REACT_APP_SEO_FIRST} distribution, ${process.env.REACT_APP_SEO_FIRST} manufacturer industry, ${process.env.REACT_APP_SEO_FIRST} industrial buyers, ${process.env.REACT_APP_SEO_FIRST} industry source, ${process.env.REACT_APP_SEO_FIRST} b2b vendors, industrial social network, ${process.env.REACT_APP_SEO_FIRST} business guide, ${process.env.REACT_APP_SEO_FIRST} manufacturing supply`}
				/>
			</Helmet>
			<p style={{ textAlign: 'center', padding: '5px 10px' }}>
				WBN defines all the industrial manufacturing categories to help find and manage your Business to
				Business. The industrial categories and listings shown are based on world market requirements in order
				to support users better. We encourage you to send us your comments and new categories to improve it.
			</p>
			<div>
				<Button style={{ margin: '20px auto' }}>INDUSTRIAL CATEGORIES SUGGESTED BY WBN</Button>
			</div>
			<Industry history={history} />
		</div>
	);
};

export default Industries;
