import { put, call } from 'redux-saga/effects';
import { map } from 'lodash';
import { actionSuccess, actionFailed } from 'shared/actions';
import axios from 'axios';
import { GET_COUNTRY_STATE_SUCCESS, GET_COUNTRY_STATE_FAILED } from './constants';

function getCountryStateApi(idState) {
	return axios.request({
		url: `${process.env.REACT_APP_API_URL}ref-data/country/${idState}/state`,
		method: 'GET'
	});
}

export function* countryStateSaga(action) {
	try {
		const {
			data: { data }
		} = yield call(getCountryStateApi, action.payload);
		const customData = map(data, ({ stateName, stateID, countryCode }) => {
			return {
				key: stateID,
				text: stateName,
				value: stateID,
				flag: countryCode.toLowerCase()
			};
		});

		yield put(actionSuccess(GET_COUNTRY_STATE_SUCCESS, customData));
	} catch (error) {
		yield put(actionFailed(GET_COUNTRY_STATE_FAILED, {}));
	}
}
