import { takeLatest } from 'redux-saga/effects';

import { GET_POST_WATCHER } from './post/constants';
import { GET_COUNTRIES_WATCHER } from './countries/constants';
import { GET_COUNTRY_STATE_WATCHER } from './country-state/constants';
import { GET_GENDER_WATCHER } from './gender/constants';
import { GET_LANGUAGES_WATCHER } from './languages/constants';
import { PUT_PROFILE_WATCHER } from './put-profile/constants';
import { GET_JOB_POSITION_WATCHER } from './job-position/constants';
import { GET_LIST_BUSINESS_WATCHER } from './list-business/constants';
import { POST_LOGOUT_WATCHER } from './logout/constants';
import { POST_IMAGE_S3_WATCHER } from './upload-to-s3-bucket/constants';
import { CREATE_BUSINESS_WATCHER } from './create-business/constants';
import { GET_INDUSTRIES_WATCHER } from './list-industries/constants';
import { GET_INDUSTRY_CATEGORIES_WATCHER } from './list-industry-categories/constants';
import { GET_LIST_MY_BUSINESS_WATCHER } from './list-my-business/constants';
import { GET_INDUSTRY_FULL_TREE_WATCHER } from './list-industry-full-tree/constants';
import { GET_LAST_REGISTERED_WATCHER } from './last-registered/constants';
import { GET_BUSINESS_PROFILE_WATCHER } from './business-profile/constants';
import { SEARCH_BUSINESS_WATCHER } from './search-business/constants';
import { SEARCH_PRODUCTS_WATCHER } from './search-products/constants';
import { SEARCH_USERS_WATCHER } from './search-users/constants';

import { getPostSaga } from './post/sagas';
import { getCountriesSaga } from './countries/sagas';
import { countryStateSaga } from './country-state/sagas';
import { genderSaga } from './gender/sagas';
import { languagesSaga } from './languages/sagas';
import { putProfileSaga } from './put-profile/sagas';
import { jobPositionSaga } from './job-position/sagas';
import { listBusinessSaga } from './list-business/sagas';
import { postLogoutSaga } from './logout/sagas';
import { postImageS3Saga } from './upload-to-s3-bucket/sagas';
import { createBusinessSaga } from './create-business/sagas';
import { listIndustriesSaga } from './list-industries/sagas';
import { listIndustryCategoriesSaga } from './list-industry-categories/sagas';
import { listMyBusinessSaga } from './list-my-business/sagas';
import { listIndustryFullTreeSaga } from './list-industry-full-tree/sagas';
import { listLastRegisterSaga } from './last-registered/sagas';
import { searchBusiness } from './search-business/sagas';
import { searchProduct } from './search-products/sagas';
import { searchUser } from './search-users/sagas';
import { getBusinessProfileSaga } from './business-profile/sagas';

function* rootSaga() {
  yield takeLatest(GET_POST_WATCHER, getPostSaga);
  yield takeLatest(GET_COUNTRIES_WATCHER, getCountriesSaga);
  yield takeLatest(GET_COUNTRY_STATE_WATCHER, countryStateSaga);
  yield takeLatest(GET_GENDER_WATCHER, genderSaga);
  yield takeLatest(GET_LANGUAGES_WATCHER, languagesSaga);
  yield takeLatest(PUT_PROFILE_WATCHER, putProfileSaga);
  yield takeLatest(GET_JOB_POSITION_WATCHER, jobPositionSaga);
  yield takeLatest(GET_LIST_BUSINESS_WATCHER, listBusinessSaga);
  yield takeLatest(POST_LOGOUT_WATCHER, postLogoutSaga);
  yield takeLatest(POST_IMAGE_S3_WATCHER, postImageS3Saga);
  yield takeLatest(CREATE_BUSINESS_WATCHER, createBusinessSaga);
  yield takeLatest(GET_INDUSTRIES_WATCHER, listIndustriesSaga);
  yield takeLatest(GET_INDUSTRY_CATEGORIES_WATCHER, listIndustryCategoriesSaga);
  yield takeLatest(GET_LIST_MY_BUSINESS_WATCHER, listMyBusinessSaga);
  yield takeLatest(GET_INDUSTRY_FULL_TREE_WATCHER, listIndustryFullTreeSaga);
  yield takeLatest(GET_LAST_REGISTERED_WATCHER, listLastRegisterSaga);
  yield takeLatest(GET_BUSINESS_PROFILE_WATCHER, getBusinessProfileSaga);
  yield takeLatest(SEARCH_BUSINESS_WATCHER, searchBusiness);
  yield takeLatest(SEARCH_PRODUCTS_WATCHER, searchProduct);
  yield takeLatest(SEARCH_USERS_WATCHER, searchUser);
}

export default rootSaga;
