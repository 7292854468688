import { SEARCH_BUSINESS_WATCHER, SEARCH_BUSINESS_SUCCESS, SEARCH_BUSINESS_FAILED } from './constants';

const initialState = {
	isLoading: true,
	data: []
};

export default (state = initialState, action) => {
	switch (action.type) {
		case SEARCH_BUSINESS_WATCHER:
			return {
				isLoading: true,
				data: []
			};
		case SEARCH_BUSINESS_SUCCESS:
			return {
				isLoading: false,
				data: action.payload
			};
		case SEARCH_BUSINESS_FAILED:
			return {
				isLoading: false,
				data: action.payload
			};
		default:
			return state;
	}
};
