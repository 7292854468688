import React from 'react';
import Downshift from 'downshift';
import { find } from 'lodash';
import { Options, Option, Button, Arrow, Container } from './style';

export default function Select({ options, prompt, onChange, defaultValue, style, id }) {
	return (
		<Downshift
			itemToString={item => (item ? item.text : '')}
			onChange={onChange}
			id={id}
			initialSelectedItem={find(options, { value: defaultValue })}
		>
			{({
				getMenuProps,
				getRootProps,
				getItemProps,
				getToggleButtonProps,
				selectedItem,
				highlightedIndex,
				isOpen
			}) => {
				return (
					<Container {...getRootProps()} 	style={style}>
						<Button {...getToggleButtonProps()}>
							<span>{(selectedItem && selectedItem.text) || prompt}</span>
							<Arrow aria-hidden />
						</Button>
						<Options {...getMenuProps()} isOpen={isOpen}>
							{options.map((option, index) => (
								<Option
									{...getItemProps({ index, key: option.value, item: option })}
									isHighlighted={highlightedIndex === index}
									isSelected={selectedItem === option}
								>
									<span>{option.text}</span>
								</Option>
							))}
						</Options>
					</Container>
				);
			}}
		</Downshift>
	);
}

Select.Options = Options;
Select.Option = Option;
Select.Container = Container;
Select.Arrow = Arrow;
