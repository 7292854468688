import { combineReducers } from 'redux';
import post from './post';
import countries from './countries';
import countryState from './country-state';
import gender from './gender';
import languages from './languages';
import jobPosition from './job-position';
import listBusiness from './list-business';
import logout from './logout';
import postUploadToS3Bucket from './upload-to-s3-bucket';
import listIndustries from './list-industries';
import listIndustryCategories from './list-industry-categories';
import createBusiness from './create-business';
import listMyBusiness from './list-my-business';
import listIndustriesFullTree from './list-industry-full-tree';
import listLastRegister from './last-registered';
import businessProfile from './business-profile';
import searchBusiness from './search-business';
import searchProduct from './search-products';
import searchUsers from './search-users';

export default combineReducers({
	post,
	countries,
	countryState,
	gender,
	languages,
	jobPosition,
	listBusiness,
	logout,
	postUploadToS3Bucket,
	listIndustries,
	listIndustryCategories,
	createBusiness,
	listMyBusiness,
	listIndustriesFullTree,
	listLastRegister,
	businessProfile,
	searchBusiness,
  searchProduct,
  searchUsers,
});
