import React from 'react';

const HealthCare = ({ fill = "white" }) => {
	return (
		<svg id="Capa_1" data-name="Capa 1"
			xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
			<title>ICON HEALTH CARE</title>
			<path style={{fill}} d="M15.88,13.63h3.9a.38.38,0,0,1,.38.38v3.64a.38.38,0,0,1-.38.38l-4.85,0S17.27,16,15.88,13.63Z" />
			<path style={{fill}} d="M12.67,3.84a11.88,11.88,0,0,1,2,1.87,3.32,3.32,0,0,1,1.88-1.57,14.89,14.89,0,0,1-2.72-2A3.07,3.07,0,0,1,12.67,3.84ZM14,1.4Z" />
			<path style={{fill}} d="M9,23.85H1.6c-.36,0-.5-.15-.35-.42a6.07,6.07,0,0,1,.66-1c.09-.11.34-.14.52-.19a2.31,2.31,0,0,1,.37,0,.85.85,0,0,0,.78-.57,1.9,1.9,0,0,0,.11-.33c0-.08,0-.16.05-.24A.23.23,0,0,1,4.11,21c1.15.9,4.17.4,5.57.16l2.37-.45c.29-.05.39-.06.5.25a2.58,2.58,0,0,0,.61.94,1,1,0,0,0,.95.49.66.66,0,0,1,.66.36,7.28,7.28,0,0,0,.44.69c.17.27.08.41-.29.41Z" />
			<path style={{fill}} d="M15.21,14.35a3.08,3.08,0,0,0-4.19-2c-.48.21-2.49,1.2-2.93,1.48-.81.5-1.63,1-2.4,1.53a2.9,2.9,0,0,0-1.17,1.61,2.12,2.12,0,0,0,1,2.46,3.86,3.86,0,0,0,1.64.41c.39-.07.78-.1,1.16-.2.75-.18,1.49-.38,2.22-.59.54-.16,2.66-1,3.16-1.25A3.09,3.09,0,0,0,15.21,14.35Zm-2.4,3.11a2.35,2.35,0,0,1-1,.21A2.4,2.4,0,0,1,11,17.5a2.37,2.37,0,0,1-1.33-1.27,2.4,2.4,0,1,1,3.17,1.23Z" />
			<path style={{fill}} d="M17.35,4.82a2.17,2.17,0,0,1,2.14,2.09.53.53,0,1,1-1.06,0,1.1,1.1,0,0,0-2.19-.09,1.12,1.12,0,0,0,1.07,1.23.51.51,0,0,1,.54.43.54.54,0,0,1-.56.63,2.19,2.19,0,0,1-2.07-1.75A2.16,2.16,0,0,1,17.35,4.82Z" />
			<path style={{fill}} d="M11.2.19A1.68,1.68,0,0,0,9.56,1.48h0S9.45,2,8.18,3.06A8.89,8.89,0,0,1,5.39,4.81H5.32A2.12,2.12,0,1,0,7.84,7.27h0a3.38,3.38,0,0,1,1-1.5A7.31,7.31,0,0,1,11.61,3.5h0A1.68,1.68,0,0,0,11.2.19Zm-5,7.89a1.15,1.15,0,0,1-.48.1,1.2,1.2,0,1,1,.48-.1Zm5.88-5.85a1,1,0,0,1-.52.55,1,1,0,0,1-.4.08,1,1,0,0,1-.93-1.35A1,1,0,0,1,10.8,1a1,1,0,0,1,1.3.51A1,1,0,0,1,12.12,2.23Z" />
			<path style={{fill}} d="M8.73,7.45A2.77,2.77,0,0,1,6.86,9.83,17.07,17.07,0,0,1,9,12.45a3.59,3.59,0,0,1,3.85-1.16C11.39,10,10.22,8.92,8.73,7.45Z" />
			<path style={{fill}} d="M11.17,15.29v0l0,0h0v-.05a.08.08,0,0,0,0,0h-.1a.08.08,0,0,0,0,0h0s0,0,0,0h0s0,0,0,0v0l0,0,0,0,0,0v0h.15v0h-.1S11.16,15.29,11.17,15.29Z" />
			<path style={{fill}} d="M12.9,14.8a1.16,1.16,0,0,0-2.12.93,1.18,1.18,0,0,0,.64.62,1.19,1.19,0,0,0,.89,0A1.17,1.17,0,0,0,12.9,14.8Zm-1.73.62a.18.18,0,0,1-.18-.18.19.19,0,1,1,.18.18Zm1.24-.34a.3.3,0,0,1,0,.1.13.13,0,0,1-.08.07.16.16,0,0,1,.1.15v.16a.32.32,0,0,1-.09.24.32.32,0,0,1-.23.08h-.64v-.32H12c.05,0,.08,0,.08-.07a.08.08,0,0,0,0-.06l-.06,0h-.56v-.32H12c.05,0,.08,0,.08-.07a.14.14,0,0,0,0-.06l-.06,0h-.56V14.6h.64a.32.32,0,0,1,.24.08.34.34,0,0,1,.08.23Z" />
			<path style={{fill:"none"}} d="M14,14.3a2.4,2.4,0,0,0-4.4,1.93A2.37,2.37,0,0,0,11,17.5a2.4,2.4,0,0,0,.87.17,2.35,2.35,0,0,0,1-.21A2.4,2.4,0,0,0,14,14.3Zm-2.87,1.12a.18.18,0,0,1-.18-.18.19.19,0,1,1,.18.18Zm1.24-.34a.3.3,0,0,1,0,.1.13.13,0,0,1-.08.07.16.16,0,0,1,.1.15v.16a.32.32,0,0,1-.09.24.32.32,0,0,1-.23.08h-.64v-.32H12c.05,0,.08,0,.08-.07a.08.08,0,0,0,0-.06l-.06,0h-.56v-.32H12c.05,0,.08,0,.08-.07a.14.14,0,0,0,0-.06l-.06,0h-.56V14.6h.64a.32.32,0,0,1,.24.08.34.34,0,0,1,.08.23Z" />
			<path style={{fill:"none"}} d="M11.17,15.05a.19.19,0,0,0-.18.19.18.18,0,0,0,.18.18.19.19,0,1,0,0-.37Z" />
			<path style={{fill:"none"}} d="M12.09,14.61h-.64v.32H12l.06,0a.14.14,0,0,1,0,.06c0,.05,0,.07-.08.07h-.56v.32H12l.06,0a.08.08,0,0,1,0,.06s0,.07-.08.07h-.56v.32h.64a.32.32,0,0,0,.23-.08.32.32,0,0,0,.09-.24V15.4a.16.16,0,0,0-.1-.15.13.13,0,0,0,.08-.07.3.3,0,0,0,0-.1v-.16a.34.34,0,0,0-.08-.23A.32.32,0,0,0,12.09,14.61Z" />
			<path style={{fill:"none"}} d="M14,14.3a2.4,2.4,0,0,0-4.4,1.93A2.37,2.37,0,0,0,11,17.5a2.4,2.4,0,0,0,.87.17,2.35,2.35,0,0,0,1-.21A2.4,2.4,0,0,0,14,14.3Zm-2.87,1.12a.18.18,0,0,1-.18-.18.19.19,0,1,1,.18.18Zm1.24-.34a.3.3,0,0,1,0,.1.13.13,0,0,1-.08.07.16.16,0,0,1,.1.15v.16a.32.32,0,0,1-.09.24.32.32,0,0,1-.23.08h-.64v-.32H12c.05,0,.08,0,.08-.07a.08.08,0,0,0,0-.06l-.06,0h-.56v-.32H12c.05,0,.08,0,.08-.07a.14.14,0,0,0,0-.06l-.06,0h-.56V14.6h.64a.32.32,0,0,1,.24.08.34.34,0,0,1,.08.23Z" />
			<path style={{fill:"none"}} d="M11.17,15.05a.19.19,0,0,0-.18.19.18.18,0,0,0,.18.18.19.19,0,1,0,0-.37Z" />
			<path style={{fill:"none"}} d="M12.09,14.61h-.64v.32H12l.06,0a.14.14,0,0,1,0,.06c0,.05,0,.07-.08.07h-.56v.32H12l.06,0a.08.08,0,0,1,0,.06s0,.07-.08.07h-.56v.32h.64a.32.32,0,0,0,.23-.08.32.32,0,0,0,.09-.24V15.4a.16.16,0,0,0-.1-.15.13.13,0,0,0,.08-.07.3.3,0,0,0,0-.1v-.16a.34.34,0,0,0-.08-.23A.32.32,0,0,0,12.09,14.61Z" />
			<path style={{fill:"none"}} d="M5.76,8.18a1.2,1.2,0,1,1,.48-.1A1.15,1.15,0,0,1,5.76,8.18Z" />
			<path style={{fill:"none"}} d="M5.76,8.18a1.2,1.2,0,1,1,.48-.1A1.15,1.15,0,0,1,5.76,8.18Z" />
			<path style={{fill:"none"}} d="M11.2,2.86a1,1,0,0,1-.93-1.35A1,1,0,0,1,10.8,1a1,1,0,0,1,1.3.51,1,1,0,0,1,0,.76,1,1,0,0,1-.52.55A1,1,0,0,1,11.2,2.86Z" />
			<path style={{fill:"none"}} d="M11.2,2.86a1,1,0,0,1-.93-1.35A1,1,0,0,1,10.8,1a1,1,0,0,1,1.3.51,1,1,0,0,1,0,.76,1,1,0,0,1-.52.55A1,1,0,0,1,11.2,2.86Z" />
			<path style={{fill:"none"}} d="M12.41,15.56a.32.32,0,0,1-.09.24.32.32,0,0,1-.23.08h-.64v-.32H12c.05,0,.08,0,.08-.07a.08.08,0,0,0,0-.06l-.06,0h-.56v-.32H12c.05,0,.08,0,.08-.07a.14.14,0,0,0,0-.06l-.06,0h-.56V14.6h.64a.32.32,0,0,1,.24.08.34.34,0,0,1,.08.23v.16a.17.17,0,0,1,0,.1.1.1,0,0,1-.07.07.16.16,0,0,1,.1.15Z" />
			<path style={{fill:"none"}} d="M12.41,15.56a.32.32,0,0,1-.09.24.32.32,0,0,1-.23.08h-.64v-.32H12c.05,0,.08,0,.08-.07a.08.08,0,0,0,0-.06l-.06,0h-.56v-.32H12c.05,0,.08,0,.08-.07a.14.14,0,0,0,0-.06l-.06,0h-.56V14.6h.64a.32.32,0,0,1,.24.08.34.34,0,0,1,.08.23v.16a.17.17,0,0,1,0,.1.1.1,0,0,1-.07.07.16.16,0,0,1,.1.15Z" />
			<path style={{fill:"none", stroke:"fill","strokeMiterlimit":10, "strokeWidth":"0.6px"}} d="M13.26,11.76h8a.85.85,0,0,1,.83.86v6.31a.85.85,0,0,1-.83.86H10.62" />
		</svg>
	)
}

export default HealthCare;