import styled from 'styled-components';

const BadgeSectionDesktop = styled.div`
	display: none;
	@media (min-width: 992px) {
		display: flex;
		width: 100%;
		justify-content: space-around;
	}
`;
const BadgeWrapper = styled.div`
	width: 50px;
	height: 50px;
`;
const ButtonSection = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: center;
	button {
		margin: 5px 5px;
	}
	@media (min-width: 992px) {
		flex-direction: column;
		button {
			margin: 5px 0px;
		}
	}
`;

export { BadgeSectionDesktop, BadgeWrapper, ButtonSection };
