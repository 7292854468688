import React from 'react';

const Text = () => {
	return (
		<div style={{ fontSize: '12px' }}>
			<ol>
				<li>
					Collection of Information
					<ol type="a">
						<li>Information you provide to us</li>
						<li>Information that we collect automatically</li>
						<li>Information we receive from third parties</li>
					</ol>
				</li>
				<li>Use of Personal Information</li>
				<li>Disclosure or sharing of personal information</li>
				<li>Retention</li>
				<li>Cookies</li>
				<li>Changes of this Privacy Policy</li>
				<li>
					Visitors from European Union
					<ol type="a">
						<li>Legal basis for data processing</li>
						<li>International transfers of personal information</li>
						<li>Your rights</li>
					</ol>
				</li>
				<li>How to contact us</li>
				<li>Language</li>
			</ol>
			<p>WBN PRIVACY POLICY</p>
			<p>
				Our Privacy Policy document applies to the collection, use and disclosure of information in connection
				with the services offered by WBN Network. At WBN we help all the companies to connect with B2B
				manufacturers, distributors and buyers to start and/or to improve their own business confidence,
				relationship and profit at very low cost, making Import and Export trade available for everyone in few
				clicks
			</p>

			<p>
				Your access and/or use of the WBN Websites, applications, technologies, software and Services through
				the following Network of websites:{' '}
				<a href="https://www.italianbusinessguide.com" target="_blank" rel="noopener noreferrer">
					www.italianbusinessguide.com
				</a>
				,{' '}
				<a href="https://www.dubaimanufacturing.com" target="_blank" rel="noopener noreferrer">
					www.dubaimanufacturing.com
				</a>
				,{' '}
				<a href="https://www.chinamanufacturingguide.com" target="_blank" rel="noopener noreferrer">
					www.chinamanufacturingguide.com
				</a>
				,{' '}
				<a href="https://www.japanbusinessguide.com" target="_blank" rel="noopener noreferrer">
					www.japanbusinessguide.com
				</a>
				,{' '}
				<a href="https://www.russiabusinessguide.com" target="_blank" rel="noopener noreferrer">
					www.russiabusinessguide.com
				</a>
				,{' '}
				<a href="https://www.mexicobusinessguide.com" target="_blank" rel="noopener noreferrer">
					www.mexicobusinessguide.com
				</a>
				,{' '}
				<a href="https://www.indonesiamanufacturing.com" target="_blank" rel="noopener noreferrer">
					www.indonesiamanufacturing.com
				</a>
				,{' '}
				<a href="https://www.indiamanufacturingguide.com" target="_blank" rel="noopener noreferrer">
					www.indiamanufacturingguide.com
				</a>
				,{' '}
				<a href="https://www.canadamanufacturingguide.com" target="_blank" rel="noopener noreferrer">
					www.canadamanufacturingguide.com
				</a>
				,{' '}
				<a href="https://www.brazilbusinessguide.com" target="_blank" rel="noopener noreferrer">
					www.brazilbusinessguide.com
				</a>
				,{' '}
				<a href="https://www.australianbusinessguide.com" target="_blank" rel="noopener noreferrer">
					www.australianbusinessguide.com
				</a>
				,{' '}
				<a href="https://www.africamanufacturingguide.com" target="_blank" rel="noopener noreferrer">
					www.africamanufacturingguide.com
				</a>
				,{' '}
				<a href="https://www.egyptmanufacturing.com" target="_blank" rel="noopener noreferrer">
					www.egyptmanufacturing.com
				</a>
				,{' '}
				<a href="https://www.saudiarabiabusinessguide.com" target="_blank" rel="noopener noreferrer">
					www.saudiarabiabusinessguide.com
				</a>
				,{' '}
				<a href="https://www.francebusinessguide.com" target="_blank" rel="noopener noreferrer">
					www.francebusinessguide.com
				</a>
				,{' '}
				<a href="https://www.unitedkingdommanufacturing.com" target="_blank" rel="noopener noreferrer">
					www.unitedkingdommanufacturing.com
				</a>
				,{' '}
				<a href="https://www.usamanufacturingguide.com" target="_blank" rel="noopener noreferrer">
					www.usamanufacturingguide.com
				</a>
				,{' '}
				<a href="https://www.texasmanufacturingguide.com" target="_blank" rel="noopener noreferrer">
					www.texasmanufacturingguide.com
				</a>
				,{' '}
				<a href="https://www.californiamanufacturingguide.com" target="_blank" rel="noopener noreferrer">
					www.californiamanufacturingguide.com
				</a>
				,{' '}
				<a href="https://www.miamimanufacturingguide.com" target="_blank" rel="noopener noreferrer">
					www.miamimanufacturingguide.com
				</a>
				,{' '}
				<a href="https://www.atlantamanufacturingguide.com" target="_blank" rel="noopener noreferrer">
					www.atlantamanufacturingguide.com
				</a>
				,{' '}
				<a href="https://www.newyorkmanufacturingguide.com" target="_blank" rel="noopener noreferrer">
					www.newyorkmanufacturingguide.com
				</a>
				,{' '}
				<a href="https://www.peruvianbusinessguide.com" target="_blank" rel="noopener noreferrer">
					www.peruvianbusinessguide.com
				</a>
				,{' '}
				<a href="https://www.chilebusinessguide.com" target="_blank" rel="noopener noreferrer">
					www.chilebusinessguide.com
				</a>
				,{' '}
				<a href="https://www.germanybusinessguide.com" target="_blank" rel="noopener noreferrer">
					www.germanybusinessguide.com
				</a>{' '}
				is subject to the terms and conditions and WBN Privacy Policy (See 3.3) contained in this document.
				Furthermore, the company listing policy, promotion services, advertisement services and other rules
				and policies regarding above websites will be published from time to time. This document and such
				other rules and policies of the Websites are collectively referred to below as the “Terms and
				Conditions”. By accessing and use of the Websites and Services, you agree to accept and be bound by
				the Terms. Please do not access or use the Services or the websites if you do not accept full of the
				Terms and Conditions.
			</p>
			<p>At WBN Inc. we recognize the importance of privacy and confidentiality of personal information. </p>
			<p>WBN Inc (WBN) is a B2B Network of websites (Network) which connects and facilitates connection between businesses (Business). The WBN Network is for business to business connections and therefore is only made available for use by businesses and not for individual or personal use. </p>
			<p>This Privacy Policy sets out the ways in which WBN collect, use and disclose information in connection with its operation of the Network including personal information about Network visitors and representatives and employees of Businesses (You). This Privacy Policy also applies to use of our Network via a mobile device, either through mobile applications or mobile-optimized websites.</p>
			<p>If you have any questions or concerns about the use of your personal information, then please contact us using the contact details provided at the below section "How to contact us". All capitalized terms not defined in this document shall have the meanings ascribed to them in WBN Terms and Conditions.</p>

			<p>1. COLLECTION OF INFORMATION</p>
			<p>The personal information that we may collect about you broadly falls into the following categories:</p>
			<p>Information you provide to us</p>
			<p>If you are a representative or employee of any User or Business or a sole User or Business:</p>
			<p>You will be asked to provide certain contact information that is necessary for the registering for a Network account on behalf of a User or Business, including name, address, phone number, email address, job title and department; you will be asked to provide certain identity information, tax registration and/or related information about your business, such as your company name, business type and industry, corporate registration details and information about your business license; </p>
			<p>If you are a User, we will also ask you to provide details or preferences about the goods and products that you may be interested in buying via the Network and any details or preferences relevant to your interest;</p>
			<p>If you are successfully verified by WBN, in addition to the information mentioned above, we may also collect your additional information about your company as taxes id and turnover;</p>
			<p>In connection with the facilitation of payments over the Network, you will be asked to provide certain payment information. This might include billing address, credit/debit card numbers, expiration dates and security code and tracking information from cheques or money orders to facilitate the purchase price of the services due to the Network listing packages;</p>
			<p>We will process any information provided to us in connection with disputes or to support complaints in connection with the Network. Please note, Users and/or Business may submit complaints regarding the services on the Network and under such circumstances, we will process data provided to us by Users and/or Business that is relevant to the disputes or complaints submitted. This may include services on the Network to the extent relevant to the disputes or complaints that are being reviewed by the Network and to the extent that the individual(s) has provided their explicit consent to their data being shared with us for the purposes of handling the complaints and/or settling the disputes.</p>
			<p>The information you submit to the Network for publication may be made publicly available on the Network and therefore accessible by any internet user. You should exercise caution when deciding what information, you submit to us for publication.</p>
			<p>Information that we collect automatically</p>
			<p>If you contact us (such as via our customer services department message service, telephone or email), we may record a copy of your correspondence and may ask for additional information to verify your identity.</p>
			<p>We will collect contact details and other information of users from publicly available sources for the purposes of verifying the users and performing due diligence on the users.</p>
			<p>We will collect details of user activities, transactions and interactions on the Network including information relating to the types and specifications of the products required and services purchased, pricing and delivery information, dispute and complaint records, communications between users and any information disclosed in any discussion forum.</p>
			<p>From time to time, we may also collect information about the existing and prospective users, during or in connection with trade shows, industry events and/or other functions. Including representative contact names, addresses, phone numbers, fax numbers and email addresses.</p>
			<p>If you visit WBN Network, we will automatically collect certain information from your device. In some countries, including countries in the European Economic Area, this information may be considered as personal information under applicable data protection laws. Specifically, this information may include IP addresses, device type, unique device identification numbers, browser type, broad geographic location (e.g. country or city-level location), browsing patterns and details of how you have interacted with our Network and the products and services available on it. In addition, we gather statistical information about the Network and visitors to the Network including, browser software, operating system, software and hardware attributes, pages viewed, number of sessions and unique visitors. Collecting this information enables us to better understand the visitors who come to our Network, where they come from, and what content is of interest to them.  We use this information for our internal analytics purposes and to improve the quality and relevance of our Network. Some of this information may be collected using cookies and similar tracking technology, as explained further under the section “Cookies” below.</p>
			<p>Information that we receive from third parties</p>
			<p>We may receive personal information about you from social media platforms if you choose to register for a Network account via a social media account. Exactly what information we receive will depend on your privacy settings with the applicable Network, but it would typically include basic public profile information such as: </p>
			<p>Your user name or nickname, your profile picture, Country, Company name, Contact details</p>
			<p>We may receive personal information about you from third parties that are engaged by us to assist with providing verification services, this may include your name, email address, company details and contact information. </p>
			<p>If you choose to participate in reviewing or rating Users and Business on our Network, we may receive personal information about you from third parties that work with us to conduct reviews into our services and/or rate us.</p>

			<p>2. USE OF PERSONAL INFORMATION</p>
			<p>We collect and use your personal information for the following purposes:</p>
			<p>Verifying your identity and conducting security, KYC (know-your-customer) and anti-money laundering diligence and background checks in order to set up user accounts;</p>
			<p>Verifying your eligibility to register as a User in accordance with our Terms and Conditions of use here;</p>
			<p>Setting up / administering user accounts, including providing log-in IDs and developing a Network profile; </p>
			<p>Providing users with customer service support; responding to requests, feedback, and managing claims and/or disputes;</p>
			<p>Facilitating communication between Users and Business via the Network;</p>
			<p>Providing services via the Network; </p>
			<p>Personalizing our marketing and communications with you based on your browsing records;</p>
			<p>Performing research or statistical analysis in order to improve the content and layout of the Network, and to improve the product offerings and services on the Network. </p>
			<p>Identifying, developing and marketing and advertising products and services that we believe you will value, including across browsers and devices, in accordance with applicable laws. Cookies or other similar technologies may be used to provide you with advertising based upon your browsing activities and interests (see section "Cookies" below). Where we are required by applicable law, we will seek your consent prior to sending you communications for marketing purposes; </p>
			<p>If you are a Business, we will share details of the last viewed products on the Network to enable other Business to contact you with details of products and/or services that might interest you.</p>
			<p>We may also use your personal information for other purposes that are not incompatible with the purposes we have disclosed to you (such as archiving purposes in the public interest, scientific or historical research purposes, or statistical purposes) if and where this is permitted by applicable data protection laws.</p>

			<p>3. DISCLOSURE OR SHARING OF PERSONAL INFORMATION</p>
			<p>We may disclose (or provide access to) personal information to the following categories of recipients:</p>
			<p>Other Network Users where necessary in connection with the facilitation of purchase orders and communications made between you and the other users.</p>
			<p>Third party business partners, service providers and/or affiliates of WBN engaged by us or working with us to assist us to provide services to you or who otherwise process personal information for purposes described in this Privacy Policy or notified to you when we collect your personal information. Categories of these partners or service providers include:</p>
			<ul>
				<li>Our business partners (for example, in order for them to provide you with discounts or offers that may interest you);</li>
				<li>Marketing and advertising platforms, such as Google, Twitter, Facebook and Instagram, and providers of analytics services relating to users’ behavior, in order to tailor the content you see when visiting our Network. These platforms may combine information they collect on our Network with data on their platforms and data they collect from other websites or through other sources in order to conduct targeted advertising. The processing activities of these third party marketing platforms are governed by their own privacy policies, not this Privacy Policy;</li>
				<li>Payment service providers who collect and process personal information to assist with settling the payments for transactions on our services. The activities of payment service providers may be governed by their own privacy policies, not this Privacy Policy; </li>
				<li>Credit risk assessment providers to conduct risk assessment on Business</li>
				<li>Cloud computing service providers to provide cloud storage services;</li>
				<li>Customer service providers to provide after-sale services;</li>
				<li>Risk control service providers to assess the security of users’ accounts and transaction risks;</li>
			</ul>
			<p>To our professional advisers, law enforcement agencies, insurers, government and regulatory and other organizations where we believe it is necessary to comply with applicable laws or to exercise, establish or defend our legal rights or protect your vital interests or those of any other person, we may also disclose and transfer your personal information or as otherwise required or permitted by applicable laws.</p>
			<p>To any other person with your consent to the disclosure.</p>
			<p>We may provide aggregated or anonymized data to third parties, but when we do so, the information we share is in a de-identified format that does not personally identify you. </p>

			<p>4. RETENTION</p>
			<p>We retain your personal information as long as we have an ongoing legitimate business need to do so for example to provide services or products to you, or as required or permitted by applicable laws, such as tax and accounting laws.</p>
			<p>When we have no ongoing legitimate business need to process your personal information, we will either delete or anonymize it or, if this is not possible (for example, because your personal information has been stored in backup archives), then we will securely store your personal information and isolate it from any further processing until deletion is possible.</p>

			<p>5. COOKIES</p>
			<p>In this topic we explain how we use cookies technologies and similar (we'll refer "cookies") to recognize you when you visit any of our 25 export platforms. It explains what these technologies are and why we use them, as well as your rights to control them. </p>
			<p>A cookie is a small amount of data sent to your browser and stored on your computer's or mobile device’s hard drive. If you do not de-activate or erase the cookie, each time you use the same browser or mobile device to access any of our 25 Platforms, our web servers will be notified of your visit to the Network and in turn we may have knowledge of your visit and the pattern of your usage. </p>
			<p>The WBN cookies are called "first party cookies". Cookies set up by other parties are called "third party cookies".  Third party cookies enable third party features or functionality to be provided on or through the website (e.g. like advertising, interactive content and analytics). The parties that set these third party cookies can recognize your device both when it visits the website in question and also when it visits certain other websites.</p>
			<p>Cookies can also be categorized as session cookies (which are erased once you close the browser or shut down the device) and persistent cookies (which remain even after closing the browser or shutting down the device/application and which are activated each time you visit any Platform). The session cookies use encrypted data to authenticate you. The persistent cookies do not store account numbers or passwords. We use both session cookies and persistent cookies. </p>
			<p>Why we use cookies?</p>
			<p>We use first and third party cookies for several reasons. Some cookies are required for technical and/or security reasons in order for our Network to operate, and we refer to these as "essential" or "strictly necessary" cookies. First party cookies are mainly served to enable basic functionality on our Network, including web analytics cookies that help us understand how users use our web pages and how we can improve their use. </p>
			<p>Furthermore, we also use social media cookies. You may register in any of our website membership and "share" website content through social media such as Facebook and Twitter. Sometimes we embed videos from websites like YouTube. These websites have their own privacy policies which govern their use of information, which you can find on the respective websites.</p>
			<p>We also use cookies to enable us to track and target the interests of Network visitors to enhance the experience on our Network by researching visiting patterns and conduct interest-based advertising; assisting our partners to track user visits to the websites. We use first and third party cookies for these purposes.</p>

			<p>Cookies we use</p>
			<p>The specific categories of first and third party cookies served through our Platform and the purposes they perform are described following (please note that the specific cookies served may vary):</p>
			<p>How can I control cookies?</p>
			<p>You can determine if and how a cookie will be accepted by configuring the privacy setting of the browser you are using to access the Platform or the "privacy setting" of your device. As the means by which you can refuse cookies through your web browser controls vary from browser-to-browser, you should visit your browser's help menu for more information. If you adjust the privacy setting in the browser, your device will continue collecting data unless you adjust the privacy setting of the device, and vice versa.</p>
			<p>You have the right to decide whether to accept or reject cookies. If you choose to set your web browser controls to reject cookies, you may still use our website though your access to some functionality and areas of our website may be restricted.</p>
			<p>Google Analytics (More information) Our Network uses Google Analytics, an internet analytics service provided by Google, Inc. ("Google"). Google's cookies allow us analyze use of any of our websites by telling us which pages our users are viewing, which ones are most popular, what time of day our Network are visited, if users have previously visited our Network, from which website users are redirected to our Network were and the like. The data generated by the cookie about your use of the Network will be transmitted to Google and stored by Google on servers in the United States.</p>
			<p>WBN Network uses an anonymization tool of IP addresses so that the IP addresses are truncated within the territory of the Member States of the European Union and the other members of the agreement on the European Economic Area. The non-truncated IP address are only exceptionally transmitted to Google’s servers in the US and then truncated there.</p>
			<p>Google uses the data on behalf of WBN Inc. to evaluate your use of the Platform, to compile reports on website activity for the website operators and for other website activity and internet usage services. Google may disclose this information to third parties under the terms of the law or for further processing by third parties on behalf of Google. For more information about Google Analytics cookies, visit: </p>
			<ul type="circle">
				<li>The Google Help pages and Privacy Policy: Google Privacy Policy: https://policies.google.com/privacy; and</li>
				<li>The Google Analytics Help Center: https://developers.google.com/analytics/devguides/collection/analyticsjs/cookie-usage</li>
			</ul>
			<p>The IP address that your browser will pass on to Google Analytics will not be associated with any other data stored by Google. Through your browser settings, you can block cookies; however, this can lead to problems with the use of some functionalities of the website. Through the add-on "Google Analytics Opt-out" you can through your current web browser opt-out to the use of Google Analytics from then on: http://tools.google.com/dlpage/gaoptout?hl=en. More information can be found here: https://support.google.com/analytics/answer/6004245</p>

			<p>6. CHANGES TO THIS PRIVACY POLICY</p>
			<p>We may update this Privacy Policy from time to time in response to changing legal, technical or business developments. When we update our Privacy Policy, we will take appropriate measures to inform you, consistent with the significance of the changes we make. We will obtain your consent to any material Privacy Policy changes if and where this is required by applicable data protection laws.</p>
 			<p>Any changes to this Privacy Policy will be communicated by us posting an amended Privacy Policy on the Network. Once posted on the Network, the new Privacy Policy will be effective immediately. You can see when this Privacy Notice was last updated by checking the “last updated” date displayed at the top of this Privacy Notice. </p>
			
			<p>7. VISITORS FROM THE EUROPEAN UNION</p>
			<p>This section of the WBN Privacy Policy applies only if you use any of our websites or services covered by this Privacy Policy from a country that is a Member State of the European Union and in the event of and from the date of the UK's departure from the European Union, the United Kingdom (the "EEA"), and supplements the information in this Privacy Policy. </p>
			<p>a. If you are in the EEA the data controller of your personal information is Worldwide Business Network Inc. in Miami, Florida US. For contact details, please see section 8. "HOW TO CONTACT US" below. </p>
			<p>Legal basis for data processing</p>
			<p>We process personal information for the purposes set out in this Privacy Policy, as described above. Our legal basis for processing personal information will depend on the personal information concerned and the specific context in which we collect it. However, we will normally collect and process personal information about you only where it is:</p>
			
			<p>necessary for providing the services pursuant to the agreement between you and WBN Inc, Miami FL (for example, to provide you with the services you request and to identify and authenticate you so you may use WBN Network);</p>
			<p>necessary to comply with legal requirements (for example, to comply with applicable accounting rules and to make mandatory disclosures to law enforcement);</p>
			<p>necessary for our legitimate interests and not overridden by your rights; and/or</p>
			<p>where it is based on your consent.</p>
			
			<p>If we collect and use your personal information in reliance on our legitimate interests (or those of any third party), this interest will normally be to operate our Network and services, manage our relationship with you and communicate with you as necessary to provide our services to you and for our legitimate commercial interest, for instance, when responding to your queries, improving our Network and our services, undertaking marketing, or for the purposes of ensuring the security of our Network and services and detecting or preventing illegal activities such as fraud. We may have other legitimate interests and if appropriate we will make clear to you at the relevant time what those legitimate interests are.</p>

			<p>If we ask you to provide personal information to comply with a legal requirement or to enter into a contract with you, we will make this clear at the relevant time. We will advise you whether the provision of your personal information is mandatory or not (as well as of the possible consequences if you do not provide your personal information). In some instances, you may be required to provide us with personal information for processing as described above, in order for us to be able to provide you all of our services, and for you to use all the features of our Network.</p>

			<p>If you have questions about or need further information concerning the legal basis on which we collect and use your personal information, please contact us using the contact details provided under section 8. "HOW TO CONTACT US"  below.</p>

			<p>b. International transfers of personal information</p>

			<p>In connection with our provision of the Platform and its connected services, we may transfer your personal information to countries outside of the EEA, including to countries that may not provide the same level of data protection as your home country such as the United States. We take appropriate steps to ensure that recipients of your personal information are bound to duties of confidentiality and we implement appropriate measures to ensure your personal information will remain protected in accordance with this Privacy Policy, such as standard contractual clauses. A copy of those clauses can be requested from services@wbnb2b.com </p>

			<p>c. Your rights</p>
			<p>If you are a resident of the EEA, you also have the following data protection rights, which you can exercise at any time by contacting us using the contact details provided under section 8. "HOW TO CONTACT US" below:</p>

			<p>The right to access, correct, update or request deletion of your personal information. </p>
			<p>The right to object to processing of your personal information when it is based on our legitimate interests, and separately the right to object to direct marketing.</p>
			<p>The right to ask us, in some situations, to restrict processing of your personal information or request portability of your personal information.</p>
			
			<p>The right to opt-out of marketing communications we send you at any time. You can exercise this right by clicking on the “unsubscribe” or “opt-out” link in the marketing e-mails we send you.  To opt-out of other forms of marketing (such as postal marketing or telemarketing), then please contact us using the contact details provided under section 8. "HOW TO CONTACT US"  below.</p>

			<p>If we have collected and process your personal information with your consent, then you have the right to withdraw your consent at any time. Withdrawing your consent will not affect the lawfulness of any processing we conducted prior to your withdrawal, nor will it affect processing of your personal information conducted in reliance on lawful processing grounds other than consent.</p>
			
			<p>The right to complain to a data protection authority about our collection and use of your personal information. For more information, please contact your local data protection authority.</p>
			<p>We respond to all requests we receive from individuals wishing to exercise their data protection rights in accordance with applicable data protection laws.</p>
			<p>If you are aware of changes or inaccuracies in your information, you should inform us of such changes so that our records may be updated or corrected.</p>

			<p>8. HOW TO CONTACT US</p>
			<p>f you have any requests, questions or concerns about our use of your personal information and this Privacy Policy, please contact us at services@wbnb2b.com</p>

			<p>9. LANGUAGE</p>
			<p>If there is any conflict between the English version and another language version of this Privacy Policy, the English version shall prevail</p>
		</div>
	);
};

export default Text;
