import React from 'react';
import { Loader } from 'components';
import { Container } from './style';

export default function Layout({ isLoading, children }) {
	return (
		<Container>
			<div>
				<header>Send your inquiry</header>
			</div>
			{isLoading ? <Loader /> : children}
		</Container>
	);
}
