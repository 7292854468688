import React from 'react';


const Apparel = ({ fill = "white" }) => {
	return (
		<svg id="Capa_1" data-name="Capa 1"
			xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
			<title>ICON APPAREL</title>
			<path style={{fill}} d="M11.91,8.59H12A5.12,5.12,0,0,0,16.6,5.5l-.44-.2c-.21-.09-.4-.15-.58,0-.43-.26-.84-.53-1.26-.77a13,13,0,0,0-2-1.15.13.13,0,0,1-.07-.1,3.74,3.74,0,0,1,0-.58c0-.06.07-.13.13-.17s.25-.14.35-.23A1.09,1.09,0,0,0,12.94,1,1.1,1.1,0,0,0,11.69.52a1.07,1.07,0,0,0-.75,1c0,.14.05.26.22.28s.22-.08.26-.21,0-.13.05-.19A.54.54,0,0,1,12,1a.56.56,0,0,1,.56.38.58.58,0,0,1-.41.75c-.34.1-.39.17-.39.53a5.66,5.66,0,0,1,0,.58.31.31,0,0,1-.13.2c-.29.17-.59.31-.88.49L8.44,5.34c-.16-.18-.33-.15-.53-.06l-.4.18A4.72,4.72,0,0,0,11.91,8.59Zm2.84-1.77A4.36,4.36,0,0,1,12.07,8,4,4,0,0,1,9.26,6.82m0,0,1.52-.69a5.15,5.15,0,0,1,.82-.32A2,2,0,0,1,12.94,6l1.67.77.14.07M9.07,6.36a.2.2,0,0,1-.31-.09l-.24-.38L10.7,4.51l.13-.07a2.23,2.23,0,0,1,2.36,0c.71.44,1.42.89,2.13,1.32.14.09.17.17.09.32-.18.32-.25.36-.58.21-.58-.25-1.15-.5-1.72-.77a2.52,2.52,0,0,0-2.33.05c-.56.28-1.14.52-1.71.79" />
			<path style={{fill}} d="M13.55,22.57c-1.57-.05-2.89,0-4.06,0-2.22,0-3,0-4-.37,0,.54.47,1.28,1,1.28,4.14,0,6.33.07,10.47.07a1.54,1.54,0,0,0,1.56-1.42,11,11,0,0,1-4,.48Z" />
			<path style={{fill}} d="M.72,11c-.13.14-.19.27-.07.44l2,3a.27.27,0,0,0,.38.11l.05,0A20.41,20.41,0,0,0,.79,10.92Z" />
			<path style={{fill}} d="M4,7.27c-.56.61-1,1.38-1.59,2L1.27,10.42A21.45,21.45,0,0,1,3.68,14.2l.23-.1a16.21,16.21,0,0,0,1.57-.72A30.75,30.75,0,0,0,4,7.27Z" />
			<path style={{fill}} d="M21,14.49a.26.26,0,0,0,.37-.11l2-3a.31.31,0,0,0-.06-.44l-.06-.06a23.44,23.44,0,0,0-2.34,3.52Z" />
			<path style={{fill}} d="M21.62,9.25c-.56-.6-1-1.37-1.59-2a39.55,39.55,0,0,0-1.25,6.28,12.48,12.48,0,0,0,1.32.55l.17.07a23.29,23.29,0,0,1,2.49-3.73Z" />
			<path style={{fill}} d="M19.27,6.93a3.88,3.88,0,0,0-.76-.58l-1.3-.58A5.87,5.87,0,0,1,12,9.27h-.09a5.44,5.44,0,0,1-5-3.54l-1.21.53a4.38,4.38,0,0,0-.95.53c3.26,9.28,1,12.72.81,14.74.83.44,1.54.44,4,.42,1.17,0,2.5,0,4.07,0,3.16.09,4.11-.19,5-.49-.16-1.86-.84-3.67-.86-5.9A29.77,29.77,0,0,1,19.27,6.93ZM9.21,15.59a.73.73,0,1,1,0-1.46.73.73,0,0,1,0,1.46Zm4.88-1.36a.85.85,0,0,1-.09.38.44.44,0,0,1-.28.26.69.69,0,0,1,.37.62v.63a1.15,1.15,0,0,1-1.26,1.26H10.31V16.12h2.21a.27.27,0,0,0,.3-.3.31.31,0,0,0-.08-.23.27.27,0,0,0-.22-.1H10.31V14.23h2.21a.27.27,0,0,0,.3-.3.31.31,0,0,0-.08-.23.27.27,0,0,0-.22-.1H10.31V12.34h2.52a1.15,1.15,0,0,1,1.26,1.26Z" />
			<path style={{fill}} d="M9.18,15.05,9.25,15l.09-.07.08-.08a.22.22,0,0,0,.05-.08.2.2,0,0,0,0-.11.24.24,0,0,0-.07-.17.33.33,0,0,0-.21-.06.32.32,0,0,0-.2.06.33.33,0,0,0-.08.18H9.1a.19.19,0,0,1,0-.09.11.11,0,0,1,.08,0,.1.1,0,0,1,.11.11.16.16,0,0,1,0,.09l-.13.12A.67.67,0,0,0,9,15a.42.42,0,0,0-.09.14v.11h.58v-.14H9.13A.15.15,0,0,1,9.18,15.05Z" />
			<path style={{fill: "none"}} d="M14.09,16.12a1.15,1.15,0,0,1-1.26,1.26H10.31V16.12h2.21a.27.27,0,0,0,.3-.3.31.31,0,0,0-.08-.23.27.27,0,0,0-.22-.1H10.31V14.23h2.21a.27.27,0,0,0,.3-.3.31.31,0,0,0-.08-.23.27.27,0,0,0-.22-.1H10.31V12.34h2.52a1.15,1.15,0,0,1,1.26,1.26v.63a.85.85,0,0,1-.09.38.44.44,0,0,1-.28.26.69.69,0,0,1,.37.62Z" />
			<path style={{fill, stroke: fill, strokeMiterlimit:10, strokeWidth: "0.2px"}} d="M11.13,1.79c.16,0,.22-.08.26-.21s0-.13,0-.19A.54.54,0,0,1,12,1a.56.56,0,0,1,.56.38.57.57,0,0,1-.41.75c-.34.1-.39.17-.39.53v.94h.53s0-.67,0-.86.08-.13.14-.17.24-.14.34-.23A1.08,1.08,0,0,0,11.66.52a1.07,1.07,0,0,0-.75,1C10.92,1.65,11,1.77,11.13,1.79Z" />
		</svg>)
}

export default Apparel;