import React from 'react';
import {
	DabieSection,
	DabieContainer,
	DabieImageWrap,
	MessageDabie,
	InformationSection,
	MoreInformationSection
} from './style';
import WBNIconLi from 'components/atoms/styled/wbn-icon-li';
import ListOfBenefits from 'components/atoms/styled/list-benefits';
import { Button, WBNMapSource } from 'components';
import Helmet from 'react-helmet';
const ContacUs = ({ history }) => {
	return (
		<div>
			<Helmet>
				<title>{`${process.env.REACT_APP_SEO_FIRST} manufacturing business, B2B manufacturing suppliers to B2B global distributors, made in ${process.env.REACT_APP_SEO_SECOND} products manufacturing business vendors, ${process.env.REACT_APP_SEO_FIRST} wholesale products production supply, ${process.env.REACT_APP_SEO_SECOND} manufacturer vendor B2B distributors supplies, ${process.env.REACT_APP_SEO_FIRST} sales`}</title>
				<meta name="description" content={`${process.env.REACT_APP_SEO_FIRST} Manufacturing business, ${process.env.REACT_APP_SEO_FIRST} B2B manufacturing products industry, made in ${process.env.REACT_APP_SEO_SECOND} manufacturers vendors for global wholesalers and distributors, ${process.env.REACT_APP_SEO_FIRST} business guide is part of WBN industrial b2b social network, made in ${process.env.REACT_APP_SEO_SECOND} export products manufacturer business supply, ${process.env.REACT_APP_SEO_FIRST} products for wholesale businesses distributors in United States, Asia, Africa, Europe, South America, Latin America and Middle East distribution, WBN the industrial b2b social network for wholesalers and manufacturing private label industries, made in ${process.env.REACT_APP_SEO_SECOND} products manufacturer supplier for wholesalers and vendors of the market, industrial products for wholesale worldwide distributors, ${process.env.REACT_APP_SEO_FIRST} products manufacturing for private label buyers and new brand for manufacturer, business to business manufacturing supplies and market distributors, ${process.env.REACT_APP_SEO_FIRST} product manufacturing industry looking for worldwide distributors mainly in Germany, Denmark, Austria, Portugal, Spain, France, Norway, Sweden in Europe, China, Japan, Korea, Vietnam in Asia, California, New York, Florida, Chicago Illinois and all the United States of America, South Arabia, Qatar, Oman, UAE, Middle East, Australia, North of Africa, South America and all Latin America`}/>
				<meta name="keywords" content={`${process.env.REACT_APP_SEO_FIRST} b2b manufacturers, ${process.env.REACT_APP_SEO_FIRST} b2b distribution, ${process.env.REACT_APP_SEO_FIRST} production, ${process.env.REACT_APP_SEO_FIRST} b2b buyers, ${process.env.REACT_APP_SEO_FIRST} business, ${process.env.REACT_APP_SEO_FIRST} vendors, ${process.env.REACT_APP_SEO_FIRST} social network, ${process.env.REACT_APP_SEO_FIRST} business guide, ${process.env.REACT_APP_SEO_SECOND} manufacturing suppliers`}/>
			</Helmet>
			<div>
				<p style={{ textAlign: 'center', padding: '5px 30px' }}>WBN Contact Us - Customer Services</p>
				<p style={{ textAlign: 'center', padding: '0px 10px' }}>
					We're here to help, please note that at WBN we really care about you and your business to business.
				</p>
				<p style={{ textAlign: 'center', padding: '0px 10px' }}>
					We offer several options for customer support, just let us know how can we help you today...
				</p>
				<p style={{ textAlign: 'center', padding: '0px 10px' }}>
					Maybe you would like to know how to create your distribution network, how you can find the right
					manufacturer need it for your company, how to buy from a distributor, how to produce in private
					label basics, how it works WBN Network, if everything it&#39;s free,... or any question you may
					have, please be free to contact us.
				</p>
			</div>
			<DabieSection>
				<DabieContainer>
					<DabieImageWrap>
						<img
							src={`/img/domain/${process.env.REACT_APP_DOMAIN_NAME}/DABIE/DABIE_6_${process.env.REACT_APP_DOMAIN_NAME}.svg`}
							alt="DABIE WBNB2B Contact US - Industrial B2B SOCIAL NETWORK"
						/>
					</DabieImageWrap>
					<div>
						<h4>TALK TO US</h4>
					</div>
					<MessageDabie>
						<p>If you want to talk to a person we're here waiting for your phone call </p>
					</MessageDabie>
					<div>
						<p>USA +1 (305) 8335700</p>
						<p>EUROPE +39.333.63713644</p>
					</div>
				</DabieContainer>
				<DabieContainer>
					<DabieImageWrap>
						<img
							src={`/img/domain/${process.env.REACT_APP_DOMAIN_NAME}/DABIE/DABIE_7_${process.env.REACT_APP_DOMAIN_NAME}.svg`}
							alt="DABIE Message WBNB2B Contact US - Industrial B2B SOCIAL NETWORK"
						/>
					</DabieImageWrap>
					<div>
						<h4>SEND YOUR MESSAGE</h4>
					</div>
					<MessageDabie>
						<p>
							If you want to send a <br /> Whatsapp go ahead
						</p>
					</MessageDabie>
					<div>
						<p>USA +1 (305) 8335700</p>
						<p>EUROPE +39.333.63713644</p>
					</div>
				</DabieContainer>
			</DabieSection>
			<div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
				<InformationSection>
					<p>
						For information and customer services Whatsapp <br />
						USA +1 (305) 8335700
					</p>
				</InformationSection>
				<MoreInformationSection>
					<ListOfBenefits>
						<li>
							<WBNIconLi /> If you want to send a Text Message just go ahead
						</li>
						<li>
							<WBNIconLi /> If you want to send a Whatsapp go ahead
						</li>
						<li>
							<WBNIconLi /> If you want to have a video call with WBN, go ahead
						</li>
						<li>
							<WBNIconLi /> We're here... We're not machines...
							<br />
							We will take care of your request
						</li>
					</ListOfBenefits>
				</MoreInformationSection>
				<div style={{ margin: '30px auto' }}>
					<Button
						flavor="secondary"
						style={{ width: '120px' }}
						onClick={() => history.push('/contact-us/form')}
					>
						Need Help
					</Button>
				</div>
				<WBNMapSource />
			</div>
		</div>
	);
};

export default ContacUs;
