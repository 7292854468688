import { GET_JOB_POSITION_WATCHER, GET_JOB_POSITION_SUCCESS, GET_JOB_POSITION_FAILED } from './constants';

const initialState = {
	isLoadingJobPosition: true,
	data: []
};

export default (state = initialState, action) => {
	switch (action.type) {
		case GET_JOB_POSITION_WATCHER:
			return {
				isLoadingJobPosition: true,
				data: []
			};
		case GET_JOB_POSITION_SUCCESS:
			return {
				isLoadingJobPosition: false,
				data: action.payload
			};

		case GET_JOB_POSITION_FAILED:
			return {
				isLoadingJobPosition: false,
				data: []
			};

		default:
			return state;
	}
};
