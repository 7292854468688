import styled, { css } from 'styled-components';
import breakpoints from 'breakpoints';

const sharedStyle = css`
	> * {
		align-items: center;
		display: flex;
		display: flex;
		flex: 1;
		font-size: 12px;
		height: 40px;
		padding: 5px 15px;
	}

	> *:first-child {
		flex: 4;
	}
`;

export const TableHeader = styled.div`
	display: flex;
	background-color: var(--wbn-primary-color_background);

	${sharedStyle}

	> span:not(:first-child) {
		display: none;

		${breakpoints('md')`
			display: flex;
			border-left: 1px solid white;
		`}
	}
`;

export const CertificateContainer = styled.div`
	display: flex;
	flex-direction: column;
	background-color: var(--wbn-primary-color_navbar);
	padding: 5px 0;

	:not(:last-child) {
		border-bottom: 1px solid white;
	}

	label {
		margin-right: 5px;
	}

	${sharedStyle}

	${breakpoints('md')`
		align-items: center;
		height: 68px;
		flex-direction: row;

		label {
			display: none;
		}
	`}
`;
