import React from 'react';
import styled, { keyframes } from 'styled-components';

const rotate = keyframes`
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
`;

const LoaderStyled = styled.div`
	animation: ${rotate} 1s linear infinite;
	display: inline-block;
	width: 20px;
	height: 20px;
	:after {
		content: ' ';
		display: block;
		width: 16px;
		height: 16px;
		margin: 2px;
		border-radius: 50%;
		border: 3px solid #fff;
		border-color: #fff transparent #fff transparent;
		animation: lds-dual-ring 1.2s linear infinite;
	}
`;

const Loader = () => {
	return <LoaderStyled></LoaderStyled>;
};

export default Loader;
