import styled from 'styled-components';

const ContactUsContainer = styled.div`
	width: 100%;
`;

const FormContainer = styled.div`
	width: 100%;
`;
const FormRow = styled.div`
	display: flex;
	flex-direction: column;
	@media screen and (min-width: 768px) {
		flex-direction: row;
	}
`;
FormRow.Field = styled.div`
	flex-basis: 50%;
	display: flex;
	align-items: center;
	width: 100%;
	flex-direction: column;
	input,
	div[role='combobox'] {
		width: 190px;
		height: 32px;
		margin: 5px 5px;
		border: 1px solid var(--wbn-sidebar-color-line);
		border-radius: 20px;
		font-size: 12px;
		> button {
			font-size: 12px;
		}
	}
	input::placeholder {
		font-size: 12px;
	}
	div[role='listbox'] > div {
		font-size: 12px;
	}
	> label {
		margin-left: 5px;
		font-size: 12px;
		width: 135px;
	}
	@media screen and (min-width: 600px) {
		flex-direction: row;
	}
`;
const WrapperTexArea = styled.div`
	max-width: 500px;
	width: 100%;
	textarea {
		width: 100%;
	}
`;
const FormButtons = styled.div`
	display: flex;
	margin: 20px auto;
	max-width: 300px;
	> button {
		margin: 0px 10px;
	}
`;
export { ContactUsContainer, FormContainer, FormRow, WrapperTexArea, FormButtons };
