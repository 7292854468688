import React from 'react';
import { Link } from 'react-router-dom';

import Button from 'components/atoms/button';
import WBNIconLi from 'components/atoms/styled/wbn-icon-li';
import { PlansContainer, PlanDetail, ListBenefits } from './style';
import MembershipBadge from 'components/atoms/membership-badge';
import Standard from 'images/membership/WBN_STANDARD.svg';
import Gold from 'images/membership/WBN_GOLD.svg';
import Premium from 'images/membership/WBN_PREMIUM.svg';
import Platinum from 'images/membership/WBN_PLATINUM.svg';

const packageList = [
	{
		id: 'standard',
		img: Standard,
		price: 'FREE LISTING',
		benefits: ['Up to 07 products showcase', 'All features defined', 'All features defined']
	},

	{
		id: 'gold',
		img: Gold,
		price: 'US$ 1850',
		period: 'Annual fee',
		benefits: ['Up to 20 products showcase', 'Business Verification', 'Qty 3 Advertising boost/month']
	},
	{
		id: 'premium',
		img: Premium,
		price: 'US$ 2650',
		period: 'Annual fee',
		benefits: [
			'Up to 50 products showcase',
			'Business Verification',
			'Qty 10 Advertising boost/month',
			'Qty 06 hours Professional support'
		]
	},
	{
		id: 'platinum',
		img: Platinum,
		price: 'US$ 3650',
		period: 'Annual fee',
		benefits: [
			'Up to 100 products showcase',
			'Business Verification',
			'Qty 20 Advertising boost/month',
			'Qty 12 hours Professional support'
		]
	}
];

const MembershipPlans = () => {
	return (
		<PlansContainer>
			<div>
				<Button style={{ margin: '30px auto' }}>WBN LISTING PACKAGES PRICES</Button>
				<p style={{ margin: '30px auto' }}>
					We're here for your success... WBN Inc has defined the best industrial B2B export tool including a
					professional services possible to guide you through the global market, from the initial product and
					company setup to the implementation of your export marketing program. WBN helps you to chart your
					business steps towards great world-class Industrial B2B program, providing best customized solution
					according to your goals. Select your package and enjoy your business with us
				</p>
			</div>
			<div>
				{packageList.map((plan, key) => {
					return (
						<PlanDetail key={key}>
							<div style={{ textAlign: 'center' }}>
								<Link to={`/membership-${plan.id}`}>
									<MembershipBadge membershipType={plan.id}/>
								</Link>
								<p>{plan.price}</p>
								<p style={{ height: '32px' }}>{plan.period}</p>
							</div>
							<ListBenefits>
								{plan.benefits.map((benefit, key) => (
									<li key={key}>
										<WBNIconLi /> {benefit}
									</li>
								))}
							</ListBenefits>
						</PlanDetail>
					);
				})}
			</div>
		</PlansContainer>
	);
};

export default MembershipPlans;
