import React from 'react';
import { map } from 'lodash';
import placeholderBusiness from 'images/placeholders/business.svg';
import { Link } from 'react-router-dom';
import './styles.css';
import { Section } from 'components/atoms';
import {
	LastestRegisteredBusinesses,
	LastBusinessCard,
	BodyBusiness,
	PhotoWrapper,
	DescriptionBusiness
} from './style.js';


function buildBusinessLink(name, id) {
  return encodeURIComponent(encodeURIComponent(name.replace(/ /g, '-')) + '-' + id);
}

const LastestBusinesses = ({ title, typeOfBusiness }) => {
	return (
		<LastestRegisteredBusinesses>
			<div className="container-card-comments__header">
				<p>{title}</p>
			</div>
			<div>
				{map(typeOfBusiness, ({ contactInfo, general, key, businessID }) => {
					return (
						<LastBusinessCard key={key}>
							<div style={{ margin: '5px 0px' }}>
								<span style={{ fontWeight: '600' }}>
									<Link to={`/business/${buildBusinessLink(general.companyName, businessID)}/`}>
                    {general.companyName}
                  </Link>
								</span>
							</div>
							<BodyBusiness>
								<PhotoWrapper>
                  <Link to={`/business/${buildBusinessLink(general.companyName, businessID)}/`}>
                    <img
                      src={general?.profilePhoto?.url || placeholderBusiness}
                      alt={`${general.companyName} WBNB2B`}/>
                  </Link>
								</PhotoWrapper>
								<DescriptionBusiness>
									<div style={{ textOverflow: 'ellipsis', height: '100px', overflow: 'hidden' }}>
										<p>{general.shortDescription}</p>
									</div>
									<Section fontSize="12px" style={{ height: '18px', margin: '10px 0px' }}>
										<p>
											{(contactInfo.country?.countryName).slice(0, 24) +
												((contactInfo.country?.countryName).length > 16 ? '...' : '')}{' '}
											- {general.businessType.name}
										</p>
									</Section>
								</DescriptionBusiness>
							</BodyBusiness>
						</LastBusinessCard>
					);
				})}
			</div>
		</LastestRegisteredBusinesses>
	);
};

export default LastestBusinesses;
