import styled from 'styled-components';


export const ContainerWrapper = styled.div`
  margin: 0 auto;
  @media screen and (min-width: 375px) {
		width: 310px;
	}
	@media screen and (min-width: 768px) {
		width: 708px;
	}
	@media screen and (min-width: 992px) {
		width: 748px;
	}
	@media screen and (min-width: 1200px) {
		width: 956px;
	}
`;

export const Profile = styled.div`
  max-width: 315px;

  @media screen and (min-width: 768px) {
		width: 705px;
    max-width: 705px;
  }

  @media screen and (min-width: 992px) {
		width: 745px;
		max-width: 745px;
  }
  
  @media screen and (min-width: 1200px) {
		width: 800px;
    max-width: 800px;
  }
`;

Profile.CoverPhoto = styled.div`
  width: 100%;
  height: 90px;
  background-color: black;
  > img {
    height: 100%;
    width: 100%;
    object-fit: contain;
  }

  @media screen and (min-width: 768px) {
    width: 100%;
    height: 200px;
    background-color: black;
  }
`;


Profile.HeadInfo = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 16px 0px 15px;
`;

Profile.HeadInfo.Photo = styled.div`
  flex: 1;
  width: 140px;
  height: 140px;
  padding-right: 15px;
  & > img {
    background-color: white;
    border-radius: 50%;
    width: 140px;
    height: 140px;
    object-fit: contain;
  }

  @media screen and (min-width: 768px) {
    flex: 20%;
  }
`;

Profile.HeadInfo.Section = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  flex:1;

  @media screen and (min-width: 768px) {
    flex: 80%;
    flex-direction: row;
  }
`;

Profile.HeadInfo.SubSection = styled.div`
  @media screen and (min-width: 768px) {
    flex: 35%;
  }
`;


Profile.DetailInfo = styled.div`
  margin-top: 10px;

  & p {
    text-align: justify;
  }

  @media screen and (min-width: 1200px) {
    width: 100%;
    height: 250px;
    padding: 18px 25px;
    background-color: var(--wbn-primary-color_navbar);
    border-radius: 20px;
  }
`;

Profile.Invite = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 20px;

  @media screen and (max-width: 768px) {
    display: none;
  }
`;

Profile.Invite.SubComponentLeft = styled.div`
  & img {
    width: 474px;
    height: 515px;
  }
`;

Profile.Invite.SubComponentRigth = styled.div`
  & > img{
    width: 181px;
    height: 285px;
  }

  @media screen and (max-width: 1200px) {
    display: none;  
  }
`;

Profile.Business = styled.div`
margin-top: 30px;
`;

export const Title = styled.div`
margin-bottom: 15px;
background-color: var(--wbn-primary-color);
font-size: 14px;
color: white;
display: flex;
align-items: center;
justify-content: center;
text-align: center;

> span {
padding: 5px 10px;
border-radius: 5px;
}
`;