import React from 'react';
import { Button } from 'components';
import { map } from 'lodash';
import {
	Container,
	Content,
	LeftContainer,
	RightContainer,
	EntryContainer,
	ViewMoreContainer,
	FooterContainer,
	HeaderContainer
} from './style';

function Entry({ text, displayValue, checkValue, fullHeight }) {
	let value = checkValue && displayValue;

	if (checkValue === undefined) {
		value = displayValue;
	}

	return (
		<EntryContainer shouldNotCentered={value?.length > 50} style={fullHeight ? { height: '100%' } : null}>
			<label>{text}</label>
			<div>
				<span>{value}</span>
			</div>
		</EntryContainer>
	);
}

export default function InfomationSection({
	title,
	leftContent,
	rightContent,
	footerContent,
	headerContent,
	forceColumnOnMobile,
	children,
	style
}) {
	const [isViewMoreEnabled, setViewMore] = React.useState(false);

	function renderEntry({ text, ...rest }) {
		return <Entry text={text} key={text} {...rest} />;
	}

	return (
		<Container>
			<header>
				<Button>{title}</Button>
			</header>
			<div style={{ borderRadius: 10, overflow: 'hidden', ...style }}>
				{!!headerContent && <HeaderContainer>{headerContent}</HeaderContainer>}
				<Content isViewMoreEnabled={isViewMoreEnabled} forceColumnOnMobile={forceColumnOnMobile}>
					{children}
					{!!leftContent && <LeftContainer>{map(leftContent, renderEntry)}</LeftContainer>}
					{!!rightContent && (
						<RightContainer isViewMoreEnabled={isViewMoreEnabled}>
							{map(rightContent, renderEntry)}
						</RightContainer>
					)}
				</Content>
				{!!footerContent && <FooterContainer>{footerContent}</FooterContainer>}
				{!!rightContent && (
					<ViewMoreContainer onClick={() => setViewMore(v => !v)}>
						{isViewMoreEnabled ? 'View less' : 'View more'}
					</ViewMoreContainer>
				)}
			</div>
		</Container>
	);
}

InfomationSection.LeftContainer = LeftContainer;
InfomationSection.Entry = Entry;
