import React from 'react';
import { Link } from 'react-router-dom';
import { Dropdown } from 'semantic-ui-react';
import { Button } from 'components/atoms';
import { connect } from 'react-redux';
import styled from 'styled-components';
import Media from 'react-media';
import auth from 'auth';

import {
  iconMessage,
  iconNotification,
  iconContacts,
  iconHome,
  iconMyBusiness,
  iconProfile
} from './icons_header';

import {
  Actions,
  ActionOption,
  ActionOptionContainer,
  ContainerUser
} from './styles';

import { POST_LOGOUT_WATCHER } from 'store/logout/constants';
import { actionWatcher } from 'shared/actions';


const StyledDropdown = styled(Dropdown)`
@media screen and (min-width: 992px) {
  display: flex !important;
  justify-content: center;
  width: auto;
}
`;

const StyledDropdownHeader = styled(Dropdown.Header)`
background-color: #e6e6e6;
margin: 0 !important;
padding-top: 8px !important;
padding-bottom: 8px !important;
`;

const LoginRegisterBasic = styled(Button)`
color: #fff !important;
font-weight: 100 !important;
transition: all ease .2s;
padding: 0 !important;
:hover {
  animation: all ease .2s;
  transition: all ease .2s;
}
`;

const LoginButton = styled(LoginRegisterBasic)`
width: 102px;
@media screen and (min-width: 992px) {
  width: 125px;
  height: 32px;
}
@media screen and (min-width: 1200px) {
  width: 125px;
  height: 32px;
}
`;

const RegisterButton = styled(LoginRegisterBasic)`
width: 102px;
margin-left: 4px;
margin-right: 0;
@media screen and (min-width: 992px) {
  width: 125px;
  height: 32px;
}
@media screen and (min-width: 1200px) {
  width: 125px;
  height: 32px;
}
`;

function loginClick() {
  window.open(`${process.env.REACT_APP_AUTH_URL}serviceLogin?continue=${encodeURIComponent(window.location)}`, '_self');
}

function registerClick() {
  window.open(`${process.env.REACT_APP_ACCOUNT_URL}register?continue=${encodeURIComponent(window.location)}`, '_self');
}

const MenuDekstop = ({ isLogged, postLogout }) => {
  const [profile, setProfile] = React.useState({ userID: 'me' });

  React.useEffect(() => {
    if (!isLogged) return;

    const loadProfile = async () => {
      let profile = await auth.getProfileAsyn();
      if (profile) setProfile(profile);
    };
    loadProfile();
  }, [isLogged]);

  return (
    <Actions>
      {isLogged ? (
        <>
          <Media query="(min-width: 768px)" render={() => <>
            <ActionOption to="/">
              <ActionOptionContainer>
                <img src={iconHome} alt="home" />
                <span>Home</span>
              </ActionOptionContainer>
            </ActionOption>
            <ActionOption to={`/my-business`}>
              <ActionOptionContainer>
                <img src={iconMyBusiness} alt="My Business" />
                <span>My Business</span>
              </ActionOptionContainer>
            </ActionOption>
            <ActionOption to="/newsfeed">
              <ActionOptionContainer>
                <img src={iconContacts} alt="B2B" />
                <span>My B2B</span>
              </ActionOptionContainer>
            </ActionOption>
          </>} />

          <ActionOption to={`/business/${profile?.userID}/requests`}>
            <ActionOptionContainer>
              <img src={iconMessage} alt="Messaging" />
              <span>Messages</span>
            </ActionOptionContainer>
          </ActionOption>
          <div className={ActionOption.className}>
            <ActionOptionContainer>
              <img src={iconNotification} alt="Alerts" />
              <span>Notifications</span>
            </ActionOptionContainer>
          </div>
          <ContainerUser>
            <img src={iconProfile} alt="" />
            <StyledDropdown icon="caret down" pointing className="icon right">
              <Dropdown.Menu>
                <StyledDropdownHeader content="Account" />
                <Dropdown.Item as={Link} to="/profile" text="My profile" />
                {/* <Dropdown.Item text="Settings" /> */}
                {/* <Dropdown.Item text="Language" /> */}
                <StyledDropdownHeader content="" />
                <Dropdown.Item text="Favorite Business" />
                <StyledDropdownHeader content="" />
                <Dropdown.Item as={Link} to="/contact-us/form" text="Need help?" />
                {/* <Dropdown.Item as={Link} to="/contact-us/form" text="Report something" /> */}
                <StyledDropdownHeader content="" />
                <Dropdown.Item text="Logout" onClick={() => postLogout()} />
              </Dropdown.Menu>
            </StyledDropdown>
          </ContainerUser>
        </>
      ) : (
          <>
            <LoginButton
              style={{ "marginLeft": "5px" }}
              flavor='secondary'
              onClick={loginClick}>
              Login
					</LoginButton>
            <Media query="(min-width: 992px)" render={() => <RegisterButton
              flavor='secondary'
              onClick={registerClick}>
              Register
					</RegisterButton>} />
          </>
        )}
    </Actions>
  );
};

const mapStateToProps = ({ logout }) => {
  return {
    logout: logout.data
  };
};

const mapDispatchToProps = dispatch => {
  return {
    postLogout: () => dispatch(actionWatcher(POST_LOGOUT_WATCHER))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MenuDekstop);
