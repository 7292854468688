import styled from 'styled-components';
import ListBenefits from 'components/atoms/styled/list-benefits';
const PlanDetail = styled.div`
	display: flex;
	flex-direction: column;
	border: 1px solid var(--wbn-sidebar-color-line);
	border-radius: 25px;
	padding: 10px 10px;
	width: 210px;
	margin-right: 10px;
	margin-bottom: 10px;
	height: 365px; /* 342px */
	background-color: var(--wbn-primary-color_navbar);
	img, svg {
		width: 145px;
		height: 145px;
	}
	> div > p:nth-child(2) {
		font-size: 16px;
		color: #777;
		margin-bottom: 0px;
	}
`;

const PlansContainer = styled.div`
	display: flex;
	flex-direction: column;
	> div:nth-child(2) {
		display: flex;
		justify-content: space-around;
		flex-wrap: wrap;
	}
`;


export { PlansContainer, PlanDetail, ListBenefits };
