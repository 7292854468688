import { put, call } from 'redux-saga/effects';
import axios from 'axios';
import { GET_BUSINESS_PROFILE_SUCCESS, GET_BUSINESS_PROFILE_FAILED } from './constants';
import { actionSuccess, actionFailed } from 'shared/actions';

function getBusinessProfileApi(id) {
	return axios.request({
		url: `${process.env.REACT_APP_API_URL}business/profile/${id}`,
		method: 'GET'
	});
}

export function* getBusinessProfileSaga(action) {
	try {
		const {
			data: { data }
		} = yield call(getBusinessProfileApi, action.payload);

		yield put(actionSuccess(GET_BUSINESS_PROFILE_SUCCESS, data));
	} catch (error) {
		yield put(actionFailed(GET_BUSINESS_PROFILE_FAILED, {}));
	}
}
