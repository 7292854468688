import React, { useState } from 'react';
import { InputText, Select, TextArea, Button } from 'components';
import { ContactUsContainer, FormContainer, FormRow, FormButtons, WrapperTexArea } from './style';
import apiClient from 'api-client';
import useSWR from 'swr';
import usePreDefinedToasts from 'hooks/use-pre-defined-toasts';
const parseOptions = (array, ...keys) => {
	if (!Array.isArray(array)) array = [];
	return [{ key: 'None', text: 'Select', value: null }].concat(array.map(item => ({
		key: item[keys[0]],
		text: item[keys[1]],
		value: item[keys[2]]
	})));
};

const onlyFromUSA = JSON.parse(
	'[{"text":"Select one state", "value":"None"},{"text":"AL","value":"AL"},{"text":"AK","value":"AK"},{"text":"AZ","value":"AZ"},{"text":"AR","value":"AR"},{"text":"CA","value":"CA"},{"text":"CO","value":"CO"},{"text":"CT","value":"CT"},{"text":"DE","value":"DE"},{"text":"DC","value":"DC"},{"text":"FL","value":"FL"},{"text":"GA","value":"GA"},{"text":"HI","value":"HI"},{"text":"ID","value":"ID"},{"text":"IL","value":"IL"},{"text":"IN","value":"IN"},{"text":"IA","value":"IA"},{"text":"KS","value":"KS"},{"text":"LA","value":"LA"},{"text":"ME","value":"ME"},{"text":"MD","value":"MD"},{"text":"MA","value":"MA"},{"text":"MI","value":"MI"},{"text":"MN","value":"MN"},{"text":"MS","value":"MS"},{"text":"MO","value":"MO"},{"text":"MT","value":"MT"},{"text":"NE","value":"NE"},{"text":"NV","value":"NV"},{"text":"NH","value":"NH"},{"text":"NJ","value":"NJ"},{"text":"NM","value":"NM"},{"text":"NY","value":"NY"},{"text":"NC","value":"NC"},{"text":"ND","value":"ND"},{"text":"OH","value":"OH"},{"text":"OK","value":"OK"},{"text":"OR","value":"OR"},{"text":"PA","value":"PA"},{"text":"RI","value":"RI"},{"text":"SC","value":"SC"},{"text":"SD","value":"SD"},{"text":"TN","value":"TN"},{"text":"TX","value":"TX"},{"text":"VT","value":"VT"},{"text":"VA","value":"VA"},{"text":"WA","value":"WA"},{"text":"WV","value":"WV"},{"text":"WI","value":"WI"},{"text":"WY","value":"WY"}]'
);
const ContactUsForm = ({ businessType, countries, jobPosition, industries }) => {
	const { showSuccessToast, showFailureToast } = usePreDefinedToasts();
	const [values, setValues] = useState({});
	const [loading, setLoading] = useState(false);
	const industryOptions = parseOptions(industries, 'id', 'name', 'id');
	const handleInputText = event => {
		let name = event.target.name;
		let value = event.target.value;
		setValues(prev => {
			return {
				...prev,
				[name]: value
			};
		});
	};
	const handleSelect = (name, value) => {
		handleInputText({ target: { name: name, value: value } });
	};
	const handleSubmit = async () => {
		setLoading(true);
		try{
			await apiClient.post('/needhelp/send', values)
			showSuccessToast('Request sent successfully, you will be contact soon!')
		}catch(error){
			showFailureToast('There was an error processing your request, please try again in a few hours!')
		}
		setLoading(false);
	};
	return (
		<ContactUsContainer>
			<div>
				<p style={{ textAlign: 'center', padding: '5px 30px' }}>NEED BUSINESS HELP?</p>
				<p style={{ textAlign: 'center', padding: '0px 10px' }}>
					Want to Buy products from Qualified Manufacturer in Italy
				</p>
				<p style={{ textAlign: 'center', padding: '0px 10px', margin: '10px 0px' }}>
					SEND US YOUR ENQUIRE TO SUPPORT YOUR BUSINESS - NO FEE APPLIED
				</p>
			</div>
			<FormContainer>
				<div style={{margin: '10px 0px'}}>DEFINE TYPE OF YOUR ENQUIRY</div>
				<FormRow>
					<FormRow.Field>
						<label>First Name</label>
						<InputText name="firstName" value={values.firstName} onChange={handleInputText} />
					</FormRow.Field>
					<FormRow.Field>
						<label>Last Name</label>
						<InputText name="lastName" value={values.lastName} onChange={handleInputText} />
					</FormRow.Field>
				</FormRow>
				<FormRow>
					<FormRow.Field>
						<label>Company Name</label>
						<InputText
							placeholder="Legal name of your business"
							name="businessName"
							value={values.businessName}
							onChange={handleInputText}
						/>
					</FormRow.Field>
					<FormRow.Field>
						<label>Type of Business</label>
						<Select
							defaultValue={values.businessType}
							onChange={value => {
								handleInputText({ target: { name: 'businessType', value: value.value } });
							}}
							options={parseOptions(businessType, 'code', 'businessTypeName', 'code')}
						/>
					</FormRow.Field>
				</FormRow>
				<FormRow>
					<FormRow.Field>
						<label>Web site</label>
						<InputText
							placeholder="https://..."
							name="website"
							value={values.website}
							onChange={handleInputText}
						/>
					</FormRow.Field>
					<FormRow.Field>
						<label>Position in the company</label>
						<Select
							defaultValue={values.jobPosition || null}
							onChange={value => {
								handleSelect('jobPosition', value.value);
							}}
							options={parseOptions(jobPosition, 'jobPositionID', 'name', 'jobPositionID')}
						/>
					</FormRow.Field>
				</FormRow>
				<FormRow>
					<FormRow.Field>
						<label>Address</label>
						<InputText name="address" value={values.address} onChange={handleInputText} />
					</FormRow.Field>
					<FormRow.Field>
						<label>Postal / Zip Code</label>
						<InputText name="zipcode" value={values.zipcode} onChange={handleInputText} />
					</FormRow.Field>
				</FormRow>
				<FormRow>
					<FormRow.Field>
						<label>City</label>
						<InputText name="city" value={values.city} onChange={handleInputText} />
					</FormRow.Field>
					<FormRow.Field>
						<label>Only from USA</label>
						<Select
							defaultValue={values.usaState || 'None'}
							onChange={value => {
								handleSelect('usaState', value.value);
							}}
							options={onlyFromUSA}
						/>
					</FormRow.Field>
				</FormRow>
				<FormRow>
					<FormRow.Field>
						<label>Country</label>
						<Select
							defaultValue={values.country || null}
							onChange={value => {
								handleSelect('country', value.value);
							}}
							options={parseOptions(countries, 'code', 'countryName', 'code')}
						/>
					</FormRow.Field>
				</FormRow>
				<FormRow>
					<FormRow.Field>
						<label>Company Telephone*</label>
						<InputText name="companyPhone" value={values.companyPhone} onChange={handleInputText} />
					</FormRow.Field>
					<FormRow.Field>
						<label>Fax</label>
						<InputText name="companyFax" value={values.companyFax} onChange={handleInputText} />
					</FormRow.Field>
				</FormRow>
				<FormRow>
					<FormRow.Field>
						<label>Total Employees</label>
						<InputText name="totalEmployees" value={values.totalEmployees} onChange={handleInputText} />
					</FormRow.Field>
					<FormRow.Field>
						<label>Sales Employees</label>
						<InputText name="salesEmployees" value={values.salesEmployees} onChange={handleInputText} />
					</FormRow.Field>
				</FormRow>
				<FormRow>
					<FormRow.Field>
						<label>Mobile Phone</label>
						<InputText name="mobilePhone" value={values.mobilePhone} onChange={handleInputText} />
					</FormRow.Field>
					<FormRow.Field>
						<label>Email*</label>
						<InputText name="email" value={values.email} onChange={handleInputText} />
					</FormRow.Field>
				</FormRow>
				<div style={{margin: '10px 0px'}}>DEFINE PRODUCTS YOU NEED TO BUY</div>
				<FormRow>
					<FormRow.Field>
						<label>Looking for product 1</label>
						<Select
							defaultValue={values.buyProduct1 || null}
							onChange={value => {
								handleSelect('buyProduct1', value.value);
							}}
							options={industryOptions}
						/>
					</FormRow.Field>
					<FormRow.Field>
						<label>Looking for product 2</label>
						<Select
							defaultValue={values.buyProduct2 || null}
							onChange={value => {
								handleSelect('buyProduct2', value.value);
							}}
							options={industryOptions}
						/>
					</FormRow.Field>
				</FormRow>
				<FormRow>
					<FormRow.Field>
						<label>Looking for product 3</label>
						<Select
							defaultValue={values.buyProduct3 || null}
							onChange={value => {
								handleSelect('buyProduct3', value.value);
							}}
							options={industryOptions}
						/>
					</FormRow.Field>
					<FormRow.Field>
						<label>Looking for product 4</label>
						<Select
							defaultValue={values.buyProduct4 || null}
							onChange={value => {
								handleSelect('buyProduct4', value.value);
							}}
							options={industryOptions}
						/>
					</FormRow.Field>
				</FormRow>
				<div style={{margin: '10px 0px'}}>SEND US MORE INFORMATION ABOUT PRODUCT/SERVICES YOU NEED IT: </div>
				<WrapperTexArea>
					<TextArea
						placeholder="Type here..."
						rows={4}
						name="moreInfoBuy"
						maxLength={512}
						value={values.moreInfoBuy}
						onChange={handleInputText}
					/>
				</WrapperTexArea>

				<div>DEFINE PRODUCTS YOU WANT TO SELL</div>
				<FormRow>
					<FormRow.Field>
						<label>Product/Service 1</label>
						<Select
							options={industryOptions}
							defaultValue={values.sellProduct1 || null}
							onChange={value => {
								handleSelect('sellProduct1', value.value);
							}}
						/>
					</FormRow.Field>
					<FormRow.Field>
						<label>Product/Service 2</label>
						<Select
							defaultValue={values.sellProduct2 || null}
							onChange={value => {
								handleSelect('sellProduct2', value.value);
							}}
							options={industryOptions}
						/>
					</FormRow.Field>
				</FormRow>
				<FormRow>
					<FormRow.Field>
						<label>Product/Service 3</label>
						<Select
							defaultValue={values.sellProduct3 || null}
							onChange={value => {
								handleSelect('sellProduct3', value.value);
							}}
							options={industryOptions}
						/>
					</FormRow.Field>
					<FormRow.Field>
						<label>Product/Service 4</label>
						<Select
							defaultValue={values.sellProduct4 || null}
							onChange={value => {
								handleSelect('sellProduct4', value.value);
							}}
							options={industryOptions}
						/>
					</FormRow.Field>
				</FormRow>
				<div style={{margin: '10px 0px'}}>SEND US MORE INFORMATION ABOUT PRODUCT/SERVICES TO SELL: </div>
				<WrapperTexArea>
					<TextArea
						placeholder="Type here..."
						rows={4}
						name="moreInfoSell"
						maxLength={512}
						value={values.moreInfoSell}
						onChange={handleInputText}
					/>
				</WrapperTexArea>
				<div>
					<p>Please: send us complete information about your request to support you in the right way</p>
				</div>
				<FormButtons>
					<Button flavor="cancel">Reset Form</Button>
					<Button flavor="secondary" isLoading={loading} disabled={loading} onClick={handleSubmit}>
						Submit to WBN
					</Button>
				</FormButtons>
			</FormContainer>
		</ContactUsContainer>
	);
};
const ContactUsFormWrapper = () => {
	const countries = useSWR('/ref-data/country');
	const businessType = useSWR('/ref-data/business-type');
	const jobPosition = useSWR('/ref-data/job-position');
	const industries = useSWR('/catsys/industry');
	return (
		<ContactUsForm
			jobPosition={jobPosition.data?.data.data}
			businessType={businessType.data?.data.data}
			countries={countries.data?.data.data}
			industries={industries.data?.data.data}
		/>
	);
};
export default ContactUsFormWrapper;
