import React, { useEffect } from 'react';
import { noop } from 'lodash';
import { Form as FinalForm } from 'react-final-form';
import { everyIsComplete, someHasError } from 'utils/requests';
import useSWR from 'swr';
import apiClient from 'api-client';
import { getApiPayload, checkFormValues } from './form-methods';
import SendRequestForm from './form';
import auth from 'auth';
import Layout from './layout';
import usePreDefinedToast from 'hooks/use-pre-defined-toasts';

const MemorizedFinalForm = React.memo(FinalForm);

function SendRequestPage({ businessProfile, businesses, userProfile }) {
	const { showSuccessToast, showFailureToast } = usePreDefinedToast();

	const onSubmit = React.useCallback(async formValues => {
		try {
			let validForm = checkFormValues(formValues, userProfile);
			if (validForm) {
				const data = getApiPayload(formValues, { userProfile });
				await apiClient.post('business/request', data);
				showSuccessToast('Inquiry sent successfully');
			} else {
				showFailureToast('You have to fill all the form fields');	
			}
		} catch (error) {
			showFailureToast();
			throw error;
		}
		// eslint-disable-next-line
	}, []);

	const initialValues = React.useMemo(() => {
		return {
			receiver: businessProfile.businessID
		};
		// eslint-disable-next-line
	}, []);

	return (
		<MemorizedFinalForm
			onSubmit={onSubmit}
			validate={noop}
			initialValues={initialValues}
			component={SendRequestForm}
			businessProfile={businessProfile}
			businesses={businesses}
			userProfile={userProfile}
		/>
	);
}

export default function SendRequestContainer({ match }) {
	useEffect(() => {
		if (!auth.hasValidSession()) {
			auth.redirectToLogin();
		}
	}, []);

	const businessId = match.params.id;

	const profile = useSWR(`business/profile/${businessId}`);
	const businesses = useSWR('business/profile/my-list');
	const userProfile = useSWR('user/profile');

	const responses = [profile, businesses, userProfile];

	if (someHasError(responses)) {
		return 'An unexpected error has occurred, please try again.';
	}

	if (!everyIsComplete(responses)) {
		return <Layout isLoading />;
	}

	return (
		<Layout>
			<SendRequestPage
				businessProfile={profile.data.data.data}
				businesses={businesses.data.data.data}
				userProfile={userProfile.data.data.data}
			/>
		</Layout>
	);
}
