import { put, call } from 'redux-saga/effects';
import { map } from 'lodash';
import { actionSuccess, actionFailed } from 'shared/actions';
import axios from 'axios';
import { GET_JOB_POSITION_SUCCESS, GET_JOB_POSITION_FAILED } from './constants';

function jobPositionApi() {
	return axios.request({
		url: `${process.env.REACT_APP_API_URL}ref-data/job-position`,
		method: 'GET'
	});
}

export function* jobPositionSaga() {
	try {
		const {
			data: { data }
		} = yield call(jobPositionApi);

		const customData = map(data, ({ jobPositionID, name }) => {
			return {
				key: jobPositionID,
				text: name,
				value: jobPositionID
			};
		});

		yield put(actionSuccess(GET_JOB_POSITION_SUCCESS, customData));
	} catch (error) {
		yield put(actionFailed(GET_JOB_POSITION_FAILED, []));
	}
}
