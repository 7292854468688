import React, { useEffect } from 'react';
import styled from 'styled-components';
import { Button } from 'components/atoms';
import breakpoints from 'breakpoints';
import { useHistory } from 'react-router-dom';
import './styles.css';

const HeroSectionStyle = styled.div`
	display: none;
	align-items: center;
	${breakpoints('lg')`
		display: flex;
	`}
	margin-bottom: 25px;
`;

HeroSectionStyle.Text = styled.div`
	display: flex;
	width: 28%;
	padding: 20px;
	margin: 20px 0px;
	text-align: center;
	flex-direction: column;
	> button {
		margin: 0 auto;
	}
`;

HeroSectionStyle.Multimedia = styled.div`
	width: 44%;
	video{
		width: 100%;
	}
`;

function getVideoLink() {
  if (process.env.REACT_APP_DOMAIN_NAME === "Italy") {
    return "https://static-content.wbnb2b.com/wbn-oficial-presentation-industrial-b2b-it.m4v";
  }
  if (["Mexico", "Chile", "Peru"].includes(process.env.REACT_APP_DOMAIN_NAME)) {
    return "https://static-content.wbnb2b.com/wbn-oficial-presentation-industrial-b2b-es.m4v";
  }
  if ("China" === process.env.REACT_APP_DOMAIN_NAME) {
    return "https://static-content.wbnb2b.com/wbn-oficial-presentation-industrial-b2b-ch.m4v";
  }

  return "https://static-content.wbnb2b.com/wbn-oficial-presentation-industrial-b2b-en.m4v";
}

const HeroSection = () => {
  const history = useHistory();
  return (
    <>
      <HeroSectionStyle>
        <HeroSectionStyle.Text>
          <p>
            <strong>Worldwide Business Network</strong>. At WBN we know that, for new generations, the B2B
						experience go through social networks, the future distributors and reps are already looking for
						new ways to do, mainly, global business. We are ready to support thousands of startups to engage
						with manufacturers to start selling products wholesale or online…
					</p>
        </HeroSectionStyle.Text>
        <HeroSectionStyle.Multimedia>
          <video
            poster="/wbnb2b_presentation_worldwide_logo.jpg"
            controls
            src={getVideoLink()}
          ></video>
        </HeroSectionStyle.Multimedia>
        <HeroSectionStyle.Text>
          <p>
            WBN, as IT industrial company, has designed a smart way to support millennial distributors, buyers
						and wholesalers to find the Right Partner using our Industrial B2B Social Network.
					</p>
          <Button flavor="secondary" onClick={() => {
            history.push('/contact-us/form')
          }}>Need Help?</Button>
        </HeroSectionStyle.Text>
      </HeroSectionStyle>
      <div className="container-hero-section__footer">
        <div className="container-hero-section__footer--text">
          <p>
            <strong>Worldwide Business Network</strong>. At WBN we know that, for new generations, the B2B
						experience go through social networks, the future distributors and reps are already looking for
						new ways to do, mainly, global business. We are ready to support thousands of startups to engage
						with manufacturers to start selling products wholesale or online… WBN, as IT industrial company,
						has designed a smart way to support millennial distributors, buyers and wholesalers to find the
						Right Partner using our Industrial B2B Social Network.
					</p>
          <Button onClick={() => {
            history.push('/contact-us/form')
          }}>Need Help?</Button>
        </div>
        <div className="container-hero-section__footer--media">
          <video
            poster="/wbnb2b_presentation_worldwide_logo.jpg"
            controls
            src={getVideoLink()}
          ></video>
        </div>
      </div>
    </>
  );
};
const HeroSectionComponent = () => {
  useEffect(() => {
    let video = document.body.querySelector('video');
    video.addEventListener('ended', function () {
      video.load();
    });
  });

  return (
    <div className="container-hero-section">
      <HeroSection />
    </div>
  );
};

export default HeroSectionComponent;
