import styled from 'styled-components';

export const World = styled.div`
max-width: 850px;
margin: 0 auto;
`;

export const WorldHeader = styled.div`
margin-top: 24px;
margin-bottom: 16px;
text-align: center;
> p {
  font-size: 12px;
  line-height: 16px;
}
@media screen and (min-width: 768px) {
  line-height: 24px;
}
@media screen and (min-width: 1200px) {
  line-height: 20px;
}
`;

export const WorldHeaderTitle = styled.h3`
margin: 30px auto;
background-color: var(--wbn-primary-color);
width: auto;
min-width: auto;
height: 30px;
display: flex;
align-items: center;
border-radius: 4px;
color: #fff;
font-size: 12px;
font-weight: bold;
text-align: center;
justify-content: center;
@media screen and (min-width: 768px) {
  width: 430px;
  min-width: 420px;
}
@media screen and (min-width: 1200px) {
  margin: 35px auto;
}
`;
