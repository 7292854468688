import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import { ContentCardComments, ContentCardCommentsProducts, ContentCardCommentsUsers } from 'components';
import WBNMap from 'components/molecules/wbn-map';
import { actionWatcher } from 'shared/actions';
import { map } from 'lodash';
import './styles.css';
import { Pagination } from 'semantic-ui-react';
import { SEARCH_BUSINESS_WATCHER } from '../../store/search-business/constants';
import { SEARCH_PRODUCTS_WATCHER } from '../../store/search-products/constants';
import { SEARCH_USERS_WATCHER } from '../../store/search-users/constants';

import { OwnLoader } from '../../components';

const enterpriseValues = {
  PR: 'Products', MA: 'Manufacturers', DI: 'Distributors',
  WS: 'Wholesalers', IM: 'Importers', EX: 'Exporters',
  RE: 'Retailers', IN: 'Investors', CO: 'Consultants',
  GO: 'Government', UR: 'Users',
};

function handlePersonalityMessage(enterprise) {
  return enterpriseValues[enterprise] || '';
}

const HomeSearch = ({
  getsearchBusiness,
  getsearchProducts,
  getsearchUsers,
  searchBusiness,
  searchProduct,
  searchUsers,
  location,
  history
}) => {
  window.scrollTo(0, 0);
  const [pageActive, setPageActive] = useState(1);
  const urlParams = new URLSearchParams(location.search);
  const business = urlParams.get('business');
  const countries = urlParams.get('countries') || 'global';
  const search = urlParams.get('search') || '';

  useEffect(() => {
    if (business === 'PR') {
      const getProduct = async () => {
        const queryGetAllProduct = {
          textQuery: search,
          skip: 0,
          size: 5
        };

        const getAllProducts = await getsearchProducts(queryGetAllProduct);
        return getAllProducts;
      };

      getProduct();
    } else if (business === "UR") {
      getsearchUsers({ textQuery: search, skip: 0, size: 5, country: countries !== 'global' ? countries : undefined });
    } else {
      const getBusiness = async () => {
        const queryGetAllBusiness =
          countries !== 'global'
            ? {
              textQuery: search,
              businessType: business,
              country: countries,
              skip: 0,
              size: 5
            }
            : {
              textQuery: search,
              businessType: business,
              skip: 0,
              size: 5
            };

        const getAllBusiness = await getsearchBusiness(queryGetAllBusiness);
        return getAllBusiness;
      };

      getBusiness();
    }
  }, [getsearchBusiness, getsearchProducts, getsearchUsers, location, business, countries, search]);

  async function getBusinessPage(e, { activePage }) {
    setPageActive(activePage);

    let skip = (activePage - 1) * 5;
    if (business === 'PR') {
      const getAllProducts = await getsearchProducts({
        textQuery: search,
        skip: skip,
        size: 5
      });
      return getAllProducts;
    } else if (business === "UR") {
      return await getsearchUsers({
        textQuery: search,
        skip: skip,
        size: 5,
        country: countries !== 'global' ? countries : undefined
      });
    } else {
      let searchPayload = {
        textQuery: search,
        businessType: business,
        skip: (activePage - 1) * 5,
        size: 5
      };

      if (countries !== 'global') {
        searchPayload.country = countries;
      }
      const getAllBusiness = await getsearchBusiness(searchPayload);
      return getAllBusiness;
    }
  }

  function handlerRenderCardComment(item, id) {
    if (business === 'PR') {
      return (<ContentCardCommentsProducts key={id} product={item} />);
    }
    if (business === "UR") {
      return (<ContentCardCommentsUsers key={id} user={item} history={history} />);
    }
    return (<ContentCardComments business={item} key={id} history={history} />);
  }

  function handlerRenderDataCard() {
    if (business === "PR") {
      return searchProduct.data.results;
    }
    if (business === "UR") {
      return searchUsers.data.results;
    }
    return searchBusiness.data.results;
  }

  function totalPagesSearchResult() {
    if (business === "PR") {
      return searchProduct.data.total_results;
    }
    if (business === "UR") {
      return searchUsers.data.total_results;
    }
    return searchBusiness.data.total_results;
  }

  return (
    <div className="container-home ">
      <Helmet>
        <title>
          {process.env.REACT_APP_SEO_FIRST} manufacturing companies search,{' '}
          {process.env.REACT_APP_SEO_FIRST} manufacturer suppliers sourcing, made in{' '}
          {process.env.REACT_APP_SEO_SECOND} products distributors search, wholesale manufacturing
					distribution source, {process.env.REACT_APP_SEO_FIRST} private label products manufacturer
					supply WBN global buyers sourcing vendors
				</title>
        <meta
          name="description"
          content={`WBN global manufacturing search, ${process.env.REACT_APP_SEO_FIRST} B2B manufacturing companies sourcing, made in ${process.env.REACT_APP_SEO_SECOND} manufacturer vendors search for global wholesale B2B distributors, WBN offers search of ${process.env.REACT_APP_SEO_FIRST} business guide is part of WBN industrial b2b social network, made in ${process.env.REACT_APP_SEO_SECOND} export products manufacturing business supply, ${process.env.REACT_APP_SEO_FIRST} products for wholesale business distributors in United States, Asia, Africa, Europe, South America, Latin America and Middle East distribution, WBN the industrial b2b social network for wholesalers and manufacturing private label industries, made in ${process.env.REACT_APP_SEO_SECOND} products manufacturer supplier for wholesalers and vendors of the market, industrial products for wholesale worldwide distributors, ${process.env.REACT_APP_SEO_FIRST} products manufacturing for private label buyers and new brand for manufacturer, business to business manufacturing supplies and market distributors, ${process.env.REACT_APP_SEO_FIRST} product manufacturing industry looking for worldwide distributors mainly in Germany, Denmark, Austria, Portugal, Spain, France, Norway, Sweden in Europe, China, Japan, Korea, Vietnam in Asia, California, New York, Florida, Chicago Illinos and all the United States of America, South Arabia, Qatar, Oman, UAE, Middle East, Australia, North of Africa, South America and all Latin America`}
        />
        <meta
          name="keywords"
          content={`${process.env.REACT_APP_SEO_FIRST} manufacturing search, ${process.env.REACT_APP_SEO_FIRST} distributors search, ${process.env.REACT_APP_SEO_FIRST} manufacturer search, ${process.env.REACT_APP_SEO_FIRST} buyer search, ${process.env.REACT_APP_SEO_FIRST} business sourcing, ${process.env.REACT_APP_SEO_FIRST} vendors search , ${process.env.REACT_APP_SEO_FIRST} social network, ${process.env.REACT_APP_SEO_FIRST} business guide, ${process.env.REACT_APP_SEO_FIRST} manufacturing suppliers`}
        />
      </Helmet>
      <p>
        {totalPagesSearchResult()} Results for{' '}
        {handlePersonalityMessage(business) + ' - ' + search}
      </p>
      {(searchBusiness.isLoading && searchProduct.isLoading && searchUsers.isLoading) ? (<OwnLoader />) : (
        <>
          {map(handlerRenderDataCard(), (item, index) => handlerRenderCardComment(item, index))}
          <div className="container-home-pagination">
            <p className="container-home-pagination-title">WBN THE INDUSTRIAL B2B SOCIAL NETWORK</p>
            <Pagination
              boundaryRange={0}
              defaultActivePage={pageActive}
              ellipsisItem={null}
              firstItem={null}
              lastItem={null}
              siblingRange={1}
              totalPages={Math.ceil(totalPagesSearchResult() / 5)}
              onPageChange={getBusinessPage}
            />
          </div>
        </>
      )}
      <WBNMap />
    </div>
  );
};

const mapStateToProps = ({ searchBusiness, searchProduct, searchUsers }) => {
  return {
    searchBusiness,
    searchProduct,
    searchUsers,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getsearchBusiness: params => dispatch(actionWatcher(SEARCH_BUSINESS_WATCHER, params)),
    getsearchProducts: params => dispatch(actionWatcher(SEARCH_PRODUCTS_WATCHER, params)),
    getsearchUsers: params => dispatch(actionWatcher(SEARCH_USERS_WATCHER, params)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(HomeSearch);
