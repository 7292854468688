
import styled from 'styled-components';

export const SeccionTwoColumns = styled.div`
  display: flex;
  > :first-child {
    margin-right: 15px;
  }
  > :last-child {
    margin-left: 15px;
  }
`;
