import React from 'react';

const HomeConstruction = ({ fill = "white" }) => {
	return (
		<svg id="Capa_1" data-name="Capa 1"
			xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
			<title>ICONS</title>
			<path style={{ fill }} d="M23.2,11.33l-11.33-8a.89.89,0,0,0-1.06,0l-10,7.59a.33.33,0,0,1-.53-.26V9.77A.35.35,0,0,1,.41,9.5L9.8,2.38a2.55,2.55,0,0,1,3,0L23.58,10a.34.34,0,0,1,.14.26v.85A.33.33,0,0,1,23.2,11.33Z" />
			<polygon style={{ fill }} points="20.1 3.85 16.99 3.85 16.99 6.57 20.1 8.34 20.1 3.85" />
			<path style={{ fill: "none", stroke: fill, strokeMiterlimit: 10 }} d="M10.06,6.06m8.4,4v2a2.56,2.56,0,0,1-.32,1.23,1.53,1.53,0,0,1-1,.81,2.22,2.22,0,0,1,1.35,2v2a3.79,3.79,0,0,1-1.17,3,5,5,0,0,1-3.37,1H4.85v-4h8c.73,0,1.09-.31,1.09-.94a1.05,1.05,0,0,0-.3-.75,1,1,0,0,0-.79-.32h-8v-4h8c.73,0,1.09-.31,1.09-.95a1.08,1.08,0,0,0-.3-.75,1.07,1.07,0,0,0-.79-.32h-8l5.21-4a3.31,3.31,0,0,1,1.36-.69,3.7,3.7,0,0,1,1.46.69Z" />
		</svg>
	)
}

export default HomeConstruction;

