import { put, call } from 'redux-saga/effects';
import { map } from 'lodash';
import axios from 'axios';
import { SEARCH_BUSINESS_SUCCESS, SEARCH_BUSINESS_FAILED } from './constants';
import { actionSuccess, actionFailed } from 'shared/actions';

function searchBusinessApi(params) {
	return axios.request({
		url: `${process.env.REACT_APP_API_URL}search/business`,
		method: 'POST',
		data: params
	});
}

export function* searchBusiness(action) {
	try {
		const {
			data: { data }
		} = yield call(searchBusinessApi, action.payload);
		const customData = {};
		customData.results = map(
			data.results,
			({ businessProfile: { general, contactInfo, businessID, subscription, verified }, reactions }) => {
				return {
					general,
					contactInfo,
					businessID,
					subscription,
					verified,
					reactions
				};
			}
		);
		customData.total_results = data.total_results;
		yield put(actionSuccess(SEARCH_BUSINESS_SUCCESS, customData));
	} catch (error) {
		yield put(actionFailed(SEARCH_BUSINESS_FAILED, []));
	}
}
