import React from 'react';
import img_empty from 'images/img_profile_empty.svg';
import coverImgEmpty from 'images/placeholders/cover-business-profile_empty.svg';
// import create_business from '../../images/create_your_business_profile.svg';
import { ContentCardComments } from 'components';
// import Button from 'components/atoms/button';

import { Loader } from 'components';
import useSWR from 'swr';
import { every } from 'lodash';

import {
  ContainerWrapper,
  Profile,
  Title,
} from './style';
// import { Link } from 'react-router-dom';

import NewsFeed from 'pages/newsfeed';


const ProfilePublic = props => {
  let userID = props.match.params.id;
  let profileRequest = useSWR('user/public-profile/' + userID);
  let businessRequest = useSWR('business/profile/list-business?userID=' + userID);

  if (!every([profileRequest, businessRequest], 'data.data.success')) return <Loader text="Loading user profile..." />;

  let info = profileRequest.data.data.profile;
  let business = businessRequest.data.data.data;
  const history = props.history;

  return (
    <ContainerWrapper>
      <Profile>
        <Profile.CoverPhoto>
          <img src={info.coverPhoto?.url || coverImgEmpty} alt="cover"></img>
        </Profile.CoverPhoto>
        <Profile.HeadInfo>
          <Profile.HeadInfo.Photo>
            <img src={info.profilePhoto?.url || img_empty} alt="profile"></img>
          </Profile.HeadInfo.Photo>

          <Profile.HeadInfo.Section>
            <Profile.HeadInfo.SubSection>
              <p>{info.firstName} {info.lastName}</p>
              <p>{info.profession || ''}</p>
              <p>{(info.country && info.country.countryName) || ''}/{(info.state && info.state.stateName) || ''}</p>
            </Profile.HeadInfo.SubSection>
            <Profile.HeadInfo.SubSection>
              <p>{info.businessType ? `Type of Business: ${info.businessType.name}` : null}</p>
              {/* <p>{info.birthDate ? `Date of Birth: ${info.birthDate}` : null}</p> */}
            </Profile.HeadInfo.SubSection>
          </Profile.HeadInfo.Section>
          <Profile.DetailInfo>
            <p>About me:</p>
            <p>{info.briefPresentation}</p>
            {info.companyName ? <p>Work Company Name: {info.companyName}</p> : ''}
            {info.businessType ? <p>Business Type: {info.businessType.name}</p> : ''}
            {info.industry ? <p>Type of Industry: {info.industry.name}</p> : ''}
            {info.jobPosition ? <p>Job Position: {info.jobPosition.name}</p> : ''}
            {info.preferredLanguage ? <p>Preferred language: {info.preferredLanguage.name}</p> : ''}
          </Profile.DetailInfo>
          {business.length && <Profile.Business id="business-list">
            <Title>
              <span>Business List</span>
            </Title>
            {business.map((item, key) => {
              return <ContentCardComments business={item.business} key={key} history={history} />;
            })}
          </Profile.Business>}
          <div>
            <Title>Timeline</Title>
            <NewsFeed type="timeline" urlFetching={`timeline/load/${userID}`}></NewsFeed>
          </div>
        </Profile.HeadInfo>
      </Profile>
    </ContainerWrapper>
  );
};

export default ProfilePublic;
