import React from 'react';
import { Table } from 'semantic-ui-react';

export default function TableEmptyContent({ colSpan, contentText = 'No content.' }) {
	return (
		<Table.Footer>
			<Table.Row>
				<Table.HeaderCell colSpan={colSpan} textAlign="center">
					{contentText}
				</Table.HeaderCell>
			</Table.Row>
		</Table.Footer>
	);
}
