import React from 'react';

const BabyWorld = ({ fill = "white" }) => {
	return (
		<svg id="Capa_1" data-name="Capa 1"
			xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
			<title>ICON BABY WORLD</title>
			<path style={{fill}} d="M13.08,2l-3,.54L13.2,8.16l6.17-5C17.52,1.41,16.84,1.33,13.08,2Z" />
			<path style={{fill}} d="M20.88,10.78a5,5,0,0,0,.57-1.42,5,5,0,0,0-.67-2.95l-5.3,4.48Z" />
			<path style={{fill}} d="M10,12c-.1-.07-.21-.12-.31-.18a1.61,1.61,0,0,1-.5-.47,1.74,1.74,0,0,0-2.92.07.86.86,0,0,1-.29.31l-.68.4C6.91,12.1,8.47,12.05,10,12Z" />
			<path style={{fill}} d="M10.58,6.18A.35.35,0,0,1,10.3,6,2.79,2.79,0,0,0,7.65,4.24,2.79,2.79,0,0,0,5,5.94a.38.38,0,0,1-.3.23,1,1,0,0,0-.8,1,1,1,0,0,0,.83.95A.44.44,0,0,1,5,8.33,2.94,2.94,0,0,0,7.67,10a2.9,2.9,0,0,0,2.61-1.69.37.37,0,0,1,.3-.24,1,1,0,0,0,0-1.93ZM7.38,7.33A.3.3,0,0,1,7,7.27a.49.49,0,0,0-.41-.19.45.45,0,0,0-.33.18A.3.3,0,0,1,6,7.42a.32.32,0,0,1-.14,0A.31.31,0,0,1,5.68,7a1.06,1.06,0,0,1,.8-.49,1.1,1.1,0,0,1,1,.42A.3.3,0,0,1,7.38,7.33Zm2.19,0a.26.26,0,0,1-.17,0,.3.3,0,0,1-.25-.14.64.64,0,0,0-.39-.22.42.42,0,0,0-.35.16A.3.3,0,0,1,8,7.3a.29.29,0,0,1-.09-.41,1.05,1.05,0,0,1,.84-.43,1.21,1.21,0,0,1,.92.5A.31.31,0,0,1,9.57,7.38Z" />
			<path style={{fill}} d="M7.79,3.68a.51.51,0,0,1-.49,0,.43.43,0,0,1-.18-.42,1.16,1.16,0,0,1,.35-.61c.13-.12.26-.24.34-.33s.09-.08.1-.13,0-.15-.12-.24A.59.59,0,0,1,8.27,2a.45.45,0,0,1,.22.39A1,1,0,0,1,8.17,3c-.15.14-.29.25-.39.35a.73.73,0,0,0-.13.1S7.68,3.6,7.79,3.68Z" />
			<path style={{fill}} d="M18.75,13.92c-.1-.84-1.52-.71-1.52-.71H12.56l-4.24,4s6.39.1,8.36,0,2.06-.18,2.08-.85A15.08,15.08,0,0,0,18.75,13.92ZM15.5,15.5a.39.39,0,1,1,.38-.38A.38.38,0,0,1,15.5,15.5Zm2.56-.71a.49.49,0,0,1,0,.2.23.23,0,0,1-.15.13.38.38,0,0,1,.2.33v.33a.59.59,0,0,1-.66.66H16.08v-.66h1.16a.15.15,0,0,0,.16-.16.17.17,0,0,0,0-.12.13.13,0,0,0-.11-.05H16.08v-.66h1.16a.15.15,0,0,0,.16-.16.17.17,0,0,0,0-.12.14.14,0,0,0-.11-.06H16.08v-.66H17.4a.66.66,0,0,1,.49.18.68.68,0,0,1,.17.49Z" />
			<path style={{fill}} d="M3.28,23.37a2.66,2.66,0,1,1,2.65-2.65A2.66,2.66,0,0,1,3.28,23.37Zm0-4.07A1.42,1.42,0,1,0,4.7,20.72,1.41,1.41,0,0,0,3.28,19.3Z" />
			<path style={{fill}} d="M14.64,23.37a2.66,2.66,0,1,1,2.66-2.65A2.66,2.66,0,0,1,14.64,23.37Zm0-4.07a1.42,1.42,0,1,0,1.42,1.42A1.42,1.42,0,0,0,14.64,19.3Z" />
			<path style={{fill}} d="M8,13.2s-2.43-.45-3.9.67c-1.09,1-2.24,2.28-2.24,2.28s-1.47,1.09-.24,1.09H3.28Z" />
			<path style={{fill}} d="M23.19,2.16a.78.78,0,0,0-1.09-.1l-18,15.19A5.23,5.23,0,0,1,5.69,18L23.1,3.24A.75.75,0,0,0,23.19,2.16Z" />
			<path style={{fill}} d="M15.48,15.22l0,0,.05,0,0,0,0,0a.07.07,0,0,0,0-.05.1.1,0,0,0,0-.09.15.15,0,0,0-.1,0,.18.18,0,0,0-.11,0,.13.13,0,0,0,0,.09h.09s0,0,0,0l0,0s.05,0,.05.06a.09.09,0,0,1,0,.05l-.07.06-.08.07-.05.08v.06h.31v-.07h-.2A.05.05,0,0,1,15.48,15.22Z" />
			<path style={{fill: "none"}} d="M18.06,15.78a.59.59,0,0,1-.66.66H16.08v-.66h1.16a.15.15,0,0,0,.16-.16.17.17,0,0,0,0-.12.13.13,0,0,0-.11-.05H16.08v-.66h1.16a.15.15,0,0,0,.16-.16.17.17,0,0,0,0-.12.14.14,0,0,0-.11-.06H16.08v-.66H17.4a.66.66,0,0,1,.49.18.68.68,0,0,1,.17.49v.33a.49.49,0,0,1,0,.2.23.23,0,0,1-.15.13.38.38,0,0,1,.2.33Z" />
			<path style={{fill: "none"}} d="M18.06,15.78a.59.59,0,0,1-.66.66H16.08v-.66h1.16a.15.15,0,0,0,.16-.16.17.17,0,0,0,0-.12.13.13,0,0,0-.11-.05H16.08v-.66h1.16a.15.15,0,0,0,.16-.16.17.17,0,0,0,0-.12.14.14,0,0,0-.11-.06H16.08v-.66H17.4a.66.66,0,0,1,.49.18.68.68,0,0,1,.17.49v.33a.49.49,0,0,1,0,.2.23.23,0,0,1-.15.13.38.38,0,0,1,.2.33Z" />
		</svg>
	)
}

export default BabyWorld;
