
import styled from 'styled-components';


export const MenuMobileContainer = styled.div`
  width: 100%;
  min-height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1;
  display: flex;
  @media (min-width: 992px) {
    display: none;
  }
`;

export const Logo = styled.img`
  margin: 0 auto;
  width: 80px;
  height: 80px;
`;

export const Mask = styled.div`
  opacity: .6;
  background-color: #000;
  width: 100%;
`;

export const MenuContent = styled.div`
  background-color: var(--wbn-primary-color);
  width: 300px;
  min-width: 300px;
`;

export const MenuHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 16px 0;
  padding: 0 16px;
  button {
    background-color: #2296f3 !important;
    color: #FFF !important;
  }
`;

export const MenuItem = styled.div`
`;

MenuItem.Title = styled.div`
  background-color: var(--wbn-primary-color_hover);
  color: #FFF;
  font-weight: 600;
  padding: 6px 16px;
  height: 32px;
`;

MenuItem.List = styled.ul`
  list-style: none;
  padding-left: 0;
  margin: 10px 0;
  a {
    color: #FFF;
    padding: 4px 24px;
    display: flex;
  }
  a i {
    margin-right: 10px;
  }
`;