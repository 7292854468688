import React from 'react';
import styled, { css } from 'styled-components';

const IconStyle = styled.div`
	align-items: center;
	cursor: pointer;
	display: flex;
	flex-direction: column;
	> img {
		height: 30px;
		width: 30px;
		${props =>
			props.size &&
			css`
				height: ${props.size};
				width: ${props.size};
			`};
		${props =>
			props.isCircle &&
			css`
				border-radius: 50%;
				background-color: var(--wbn-sidebar-color-line);
			`}
		
	}
	> span {
		font-size: 12px;
	}
`;

export default function CircleIcon({ text, src, alt, isCircle = true, onClick, activeSrc, isActive = false }) {
	return (
		<IconStyle isCircle={isCircle}>
			<img src={isActive?activeSrc:src} alt={alt} onClick={onClick}/>
			<span>{text}</span>
		</IconStyle>
	);
}
