function getSubject(formValues) {
	if (formValues.subjectOption === 'Other') {
		return formValues.subject;
	}

	return formValues.subjectOption;
}

export function getApiPayload(formValues, { userProfile }) {
	const data = {
		to: formValues.receiver,
		from: formValues.sender,
		subject: getSubject(formValues),
		message: formValues.message
	};

	if (data.from === userProfile.userID) {
		delete data.from;
	}

	return data;
}

export function checkFormValues(formValues) {
	return ![formValues.receiver, formValues.sender, getSubject(formValues), formValues.message].some(e => e == null);
}
