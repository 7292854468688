import styled from 'styled-components';

export default styled.ul`
	li {
		font-size: 12px;
		margin-bottom: 5px;
		:before {
			background-color: white;
		}
		> img {
			width: 14px;
			height: 14px;
		}
	}
`;
