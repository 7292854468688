import styled from 'styled-components';

const input = styled.input`
	width: 238px;
	border-radius: 20px;
	border: 1px solid var(--wbn-sidebar-color-line);
	padding: 5px 15px;
	height: 32px;

	:invalid{
		background-color: #ffdddd;
	}
`

export default input;