import {
	iconAutomation,
	iconElectronicsIt,
	iconEnergy,
	iconIndustrialTechnology,
	iconMachineries,
	iconPowerTransmition,
	iconApparel,
	iconBabyWorld,
	iconBeautyCare,
	iconFashionAccessories,
	iconFoodAgriculture,
	iconFurniture,
	iconHealthCare,
	iconHomeConstruction,
	iconBusinessServices
} from 'shared/icons';

const data = [
	{
		_id: '0001',
		name: 'Automation',
		image: iconAutomation
	},
	{
		_id: '0002',
		name: 'Electronics & IT',
		image: iconElectronicsIt
	},
	{
		_id: '0003',
		name: 'Energy',
		image: iconEnergy
	},
	{
		_id: '0004',
		name: 'Industrial Technology',
		image: iconIndustrialTechnology
	},
	{
		_id: '0005',
		name: 'Machineries',
		image: iconMachineries
	},
	{
		_id: '0006',
		name: 'Power Transmission',
		image: iconPowerTransmition
	},
	{
		_id: '0007',
		name: 'Apparel',
		image: iconApparel
	},
	{
		_id: '0008',
		name: 'Baby World',
		image: iconBabyWorld
	},
	{
		_id: '0009',
		name: 'Beauty Care',
		image: iconBeautyCare
	},
	{
		_id: '0010',
		name: 'Fashion Accessories',
		image: iconFashionAccessories
	},
	{
		_id: '0011',
		name: 'Food & Agriculture',
		image: iconFoodAgriculture
	},
	{
		_id: '0012',
		name: 'Furniture',
		image: iconFurniture
	},
	{
		_id: '0013',
		name: 'Health Care',
		image: iconHealthCare
	},
	{
		_id: '0014',
		name: 'Home & Construction',
		image: iconHomeConstruction
	},
	{
		_id: '0015',
		name: 'Business Services',
		image: iconBusinessServices
	}
];

export default data;
