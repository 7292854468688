import { put, call } from 'redux-saga/effects';
import { map } from 'lodash';
import axios from 'axios';
import { GET_INDUSTRY_CATEGORIES_SUCCESS, GET_INDUSTRY_CATEGORIES_FAILED } from './constants';
import { actionSuccess, actionFailed } from 'shared/actions';

function listIndustryCategoriesApi(idIndustry) {
	return axios.request({
		url: `${process.env.REACT_APP_API_URL}catsys/industry/${idIndustry}/category`,
		method: 'GET'
	});
}

export function* listIndustryCategoriesSaga(action) {
	try {
		const {
			data: { data }
		} = yield call(listIndustryCategoriesApi, action.payload);

		const customData = map(data, ({ id, name }) => {
			return {
				key: id,
				text: name,
				value: id
			};
		});

		yield put(actionSuccess(GET_INDUSTRY_CATEGORIES_SUCCESS, customData));
	} catch (error) {
		yield put(actionFailed(GET_INDUSTRY_CATEGORIES_FAILED, []));
	}
}
