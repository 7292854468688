import { GET_POST_WATCHER, GET_POST_SUCCESS, GET_POST_FAILED } from './constants';

const initialState = {
	result: 10
};

export default (state = initialState, action) => {
	switch (action.type) {
		case GET_POST_WATCHER:
			return state;
		case GET_POST_SUCCESS:
			return {
				...state,
				result: action.payload + state.result
			};
		case GET_POST_FAILED:
			return {
				...state,
				result: state.result
			};

		default:
			return state;
	}
};
