import React from 'react';
import './styles.css';
import RepresentativePicture from '../domain-components/representative_territorial_picture';
import {Button} from 'components/atoms';
const HomeBody = () => {
	return (
		<div className="container-home-body">
			<div className="container-home-body__header">
				<div className="container-home-body__header--empty">
					<RepresentativePicture picture="MAIN" />
				</div>
				<div className="container-home-body__header--text">
					<h4>WBN, Your Gateway to the Worldwide Manufacturing Suppliers</h4>
					<p>
						WBN, as industrial B2B Network, guarantees a perfect B2B connection between distributors, vendors,
						suppliers, manufacturers, private label business, buyers and full players of the products import
						and export global industry.
					</p>
					<Button onClick={()=>window.open(`${process.env.REACT_APP_ACCOUNT_URL}register?continue=${window.location}`, '_self')} flavor="secondary" style={{padding: "15px"}}>Register Now and Go Global</Button>
				</div>
				<div className="container-home-body__header--media">
					<RepresentativePicture picture="SECONDARY_1" />
					<RepresentativePicture picture="SECONDARY_2" />
				</div>
			</div>
		</div>
	);
};

export default HomeBody;
