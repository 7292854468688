import { GET_INDUSTRIES_WATCHER, GET_INDUSTRIES_SUCCESS, GET_INDUSTRIES_FAILED } from './constants';

const initialState = {
	isLoadingIndustries: true,
	data: []
};

export default (state = initialState, action) => {
	switch (action.type) {
		case GET_INDUSTRIES_WATCHER:
			return {
				isLoadingIndustries: true,
				data: []
			};
		case GET_INDUSTRIES_SUCCESS:
			return {
				isLoadingIndustries: false,
				data: action.payload
			};

		case GET_INDUSTRIES_FAILED:
			return {
				isLoadingIndustries: false,
				data: []
			};

		default:
			return state;
	}
};
