import styled from 'styled-components';

const LastestRegisteredBusinesses = styled.div`
	max-width: 375px;
	margin: 5px;
`;

const LastBusinessCard = styled.div`
	border-radius: 10px;
	background-color: var(--wbn-primary-color_navbar);
	max-width: 375px;
	padding: 10px 20px;
	margin: 15px 0px;
`;
const BodyBusiness = styled.div`
	display: flex;
	width: 100%;
`;

const PhotoWrapper = styled.div`
	width: 80px;
	height: 80px;
	img {
		display: block;
		width: 100%;
		height: 100%;
		background-color: white;
		object-fit: contain;
	}
`;
const DescriptionBusiness = styled.div`
	padding-left: 10px;
	display: flex;
	width: 235px;
	margin-bottom: 10px;
	flex-direction: column;
	justify-content: space-between;
	@media screen and (min-width: 768px){
		width: 255px;
	}
`

export { LastestRegisteredBusinesses, LastBusinessCard, BodyBusiness, PhotoWrapper, DescriptionBusiness };
