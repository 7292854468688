
import styled from 'styled-components';


export const Section1Body = styled.div`
  padding: 0 30px 30px 0;
  margin-left: 0px;
  display: flex;

  > :first-child {
    flex-grow: 1;
    padding-top: 30px;
  }

  > :last-child {
    flex-grow: 1;
    margin: 0 72px 0 72px;
  }
`;

export const ReviewBody = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
`;

export const ReviewImage = styled.div`
  margin: 0 auto;
  width: ${props => props.width || '147px'};
  max-width: ${props => props.width || '147px'};
  height: ${props => props.height || '147px'};
  max-height: ${props => props.height || '147px'};
`;

export const ReviewItem = styled.div`
  text-align: center;
  // flex-grow: 1;
  margin: 10px;
  // flex-basis: 0;
`;


export const TwoImages = styled.div`
  margin-top: 30px;
  display: flex;

  > :first-child {
    flex-grow: 1;
    flex-basis: 0;
    margin-left: 60px;
  }

  > :last-child {
    padding-top: 30px;
    flex-grow: 1;
    flex-basis: 0;
    margin-right: 30px;
  }
`;

export const InfoPhone = styled.div`
  width: 600px;
  margin: 20px auto;
  border-top-style: solid;
  border-bottom-style: solid;
  border-top-width: 2px;
  border-bottom-width: 2px;
  border-top-color: var(--wbn-primary-color);
  border-bottom-color: var(--wbn-primary-color);

  > p {
    text-align: center;
  }
`;