import React, { useEffect } from 'react';
import Button from '../../../components/atoms/button';

import Plans from 'components/molecules/membership-plans';
import MembershipBadge from 'components/atoms/membership-badge';

import WBNPlus from '../../../images/WBN_plus.svg';

import WBNIconLi from 'components/atoms/styled/wbn-icon-li';
import {
	TextHeader,
	PackageDetailContainer,
	PackageStandardDetailContainer,
	ImagePackageContainer,
	ListBenefits,
	PackageAditionalContainer,
	PackageStandardAditionalContainer
} from './style';

import { textDABIE, benefitsByMembership, textHeader } from './text';
import Helmet from 'react-helmet';

const textSelector = membershipType => {
	const x = {
		standard: {
			title: `WBNB2B Membership Standard ${process.env.REACT_APP_SEO_SECOND} B2B suppliers, B2B manufacturer vendors to B2B global distribution supply, ${process.env.REACT_APP_SEO_FIRST} products manufacturing business suppliers, ${process.env.REACT_APP_SEO_FIRST} wholesale production supply, ${process.env.REACT_APP_SEO_SECOND} products manufacturer vendors B2B distributors supplies, ${process.env.REACT_APP_SEO_FIRST} sales suppliers`,
			description: `${process.env.REACT_APP_SEO_FIRST} business suppliers, ${process.env.REACT_APP_SEO_FIRST} manufacturing business products industry, made in ${process.env.REACT_APP_SEO_SECOND} manufacturing vendors to the export global wholesalers and export distributors, ${process.env.REACT_APP_SEO_FIRST} business guide is part of WBN industrial b2b social network, made in ${process.env.REACT_APP_SEO_SECOND} export products manufacturer export business supply, ${process.env.REACT_APP_SEO_FIRST} products for wholesale businesses distributors in United States, Asia, Africa, Europe, South America, Latin America and Middle East distribution, WBN the industrial b2b social network for export wholesalers and manufacturing private label industries, made in ${process.env.REACT_APP_SEO_SECOND} products manufacturer supplier for wholesalers and vendors of the market, industrial products for wholesale worldwide distributors, ${process.env.REACT_APP_SEO_FIRST} products manufacturing for private label buyers and new brand for manufacturer, business to business manufacturing supplies and market distributors, ${process.env.REACT_APP_SEO_FIRST} product manufacturing industry looking for worldwide distributors mainly in Germany, Denmark, Austria, Portugal, Spain, France, Norway, Sweden in Europe, China, Japan, Korea, Vietnam in Asia, California, New York, Florida, Chicago Illinois and all the United States of America, South Arabia, Qatar, Oman, UAE, Middle East, Australia, North of Africa, South America and all Latin America`,
			keywords: `${process.env.REACT_APP_SEO_SECOND} business suppliers, ${process.env.REACT_APP_SEO_FIRST} b2b export, ${process.env.REACT_APP_SEO_FIRST} export tool, ${process.env.REACT_APP_SEO_FIRST} business tool, ${process.env.REACT_APP_SEO_FIRST} export business, ${process.env.REACT_APP_SEO_FIRST} export vendors, ${process.env.REACT_APP_SEO_FIRST} social network, ${process.env.REACT_APP_SEO_FIRST} business guide, ${process.env.REACT_APP_SEO_SECOND} manufacturing suppliers`
		},
		gold: {
			title: `WBNB2B Membership Gold ${process.env.REACT_APP_SEO_FIRST} suppliers, ${process.env.REACT_APP_SEO_FIRST} manufacturer export suppliers to B2B distributors, ${process.env.REACT_APP_SEO_FIRST} manufacturing business suppliers, ${process.env.REACT_APP_SEO_SECOND} wholesale export supply, ${process.env.REACT_APP_SEO_SECOND} export products vendors B2B distributors supply, ${process.env.REACT_APP_SEO_FIRST} sales export suppliers USA business trade`,
			description: `${process.env.REACT_APP_SEO_FIRST} manufacturer export suppliers, ${process.env.REACT_APP_SEO_FIRST} manufacturing business distributors industry, made in ${process.env.REACT_APP_SEO_SECOND} manufacturer export vendors to the export global wholesalers and export distributors, ${process.env.REACT_APP_SEO_FIRST} business guide is part of WBN industrial b2b social network, made in ${process.env.REACT_APP_SEO_SECOND} export products manufacturer export business supply, ${process.env.REACT_APP_SEO_FIRST} products for wholesale businesses distributors in United States, Asia, Africa, Europe, South America, Latin America and Middle East distribution, WBN the industrial b2b social network for export wholesalers and manufacturing private label industries, made in ${process.env.REACT_APP_SEO_SECOND} products manufacturer supplier for wholesalers and vendors of the market, industrial products for wholesale worldwide distributors, ${process.env.REACT_APP_SEO_FIRST} products manufacturing for private label buyers and new brand for manufacturer, business to business manufacturing supplies and market distributors, ${process.env.REACT_APP_SEO_FIRST} product manufacturing industry looking for worldwide distributors mainly in Germany, Denmark, Austria, Portugal, Spain, France, Norway, Sweden in Europe, China, Japan, Korea, Vietnam in Asia, California, New York, Florida, Chicago Illinois and all the United States of America, South Arabia, Qatar, Oman, UAE, Middle East, Australia, North of Africa, South America and all Latin America`,
			keywords: `${process.env.REACT_APP_SEO_SECOND} export suppliers, ${process.env.REACT_APP_SEO_FIRST} b2b export, ${process.env.REACT_APP_SEO_FIRST} export vendors, ${process.env.REACT_APP_SEO_FIRST} export business, ${process.env.REACT_APP_SEO_FIRST} business supply, ${process.env.REACT_APP_SEO_FIRST} vendors buyers, ${process.env.REACT_APP_SEO_FIRST} social network, ${process.env.REACT_APP_SEO_FIRST} business guide, ${process.env.REACT_APP_SEO_SECOND} manufacturing buyers`
		},
		premium: {
			title: `WBNB2B Membership Premium ${process.env.REACT_APP_SEO_FIRST} export, ${process.env.REACT_APP_SEO_FIRST} B2B export manufacturers and distributors, ${process.env.REACT_APP_SEO_FIRST} export manufacturing business buyers, Italy wholesalers export supply, ${process.env.REACT_APP_SEO_FIRST} export B2B products vendors for wholesale distributors buyers, ${process.env.REACT_APP_SEO_FIRST} sales export supplier US trade`,
			description: `${process.env.REACT_APP_SEO_FIRST} export business suppliers, ${process.env.REACT_APP_SEO_FIRST} manufacturers business to the distributors industry, made in ${process.env.REACT_APP_SEO_SECOND} manufacturers export vendors to the export global wholesalers and export distributors, ${process.env.REACT_APP_SEO_FIRST} business guide is part of WBN industrial b2b social network, made in ${process.env.REACT_APP_SEO_SECOND} export products manufacturer export business supply, ${process.env.REACT_APP_SEO_FIRST} products for wholesale businesses distributors in United States, Asia, Africa, Europe, South America, Latin America and Middle East distribution, WBN the industrial b2b social network for export wholesalers and manufacturing private label industries, made in ${process.env.REACT_APP_SEO_SECOND} products manufacturer supplier for wholesalers and vendors of the market, industrial products for wholesale worldwide distributors, ${process.env.REACT_APP_SEO_FIRST} products manufacturing for private label buyers and new brand for manufacturer, business to business manufacturing supplies and market distributors, ${process.env.REACT_APP_SEO_FIRST} product manufacturing industry looking for worldwide distributors mainly in Germany, Denmark, Austria, Portugal, Spain, France, Norway, Sweden in Europe, China, Japan, Korea, Vietnam in Asia, California, New York, Florida, Chicago Illinois and all the United States of America, South Arabia, Qatar, Oman, UAE, Middle East, Australia, North of Africa, South America and all Latin America`,
			keywords: `${process.env.REACT_APP_SEO_SECOND} manufacturing export, ${process.env.REACT_APP_SEO_FIRST} b2b manufacturing, ${process.env.REACT_APP_SEO_FIRST} manufacturing vendors, ${process.env.REACT_APP_SEO_FIRST} export business, ${process.env.REACT_APP_SEO_FIRST} business buyers, ${process.env.REACT_APP_SEO_FIRST} vendors buyers, ${process.env.REACT_APP_SEO_FIRST} social network, ${process.env.REACT_APP_SEO_FIRST} business guide, ${process.env.REACT_APP_SEO_SECOND} manufacturing buyers`
		},
		platinum: {
			title: `WBNB2B Membership Platinum ${process.env.REACT_APP_SEO_FIRST} B2B trade, ${process.env.REACT_APP_SEO_FIRST} export trade manufacturers suppliers, made in ${process.env.REACT_APP_SEO_SECOND} export distributors suppliers, ${process.env.REACT_APP_SEO_FIRST} manufacturing buyers trade for export global, ${process.env.REACT_APP_SEO_SECOND} B2B manufacturing vendors export trade manufacturing offers sales distribution supply`,
			description: `${process.env.REACT_APP_SEO_FIRST} B2B export trade business vendors, ${process.env.REACT_APP_SEO_FIRST} export trade manufacturers business to the distributors industry, made in ${process.env.REACT_APP_SEO_SECOND} manufacturers export trade vendors to the export global wholesalers and export distributors, ${process.env.REACT_APP_SEO_FIRST} business guide is part of WBN industrial export trade b2b social network, made in ${process.env.REACT_APP_SEO_SECOND} export products manufacturer export business supply, ${process.env.REACT_APP_SEO_FIRST} products for wholesale businesses distributors in United States, Asia, Africa, Europe, South America, Latin America and Middle East distribution, WBN the industrial b2b social network for export wholesalers and manufacturing private label industries, made in ${process.env.REACT_APP_SEO_SECOND} products manufacturer supplier for wholesalers and vendors of the market, industrial products for wholesale worldwide distributors, ${process.env.REACT_APP_SEO_FIRST} products manufacturing for private label buyers and new brand for manufacturer, business to business manufacturing supplies and market distributors, ${process.env.REACT_APP_SEO_FIRST} product manufacturing industry looking for worldwide distributors mainly in Germany, Denmark, Austria, Portugal, Spain, France, Norway, Sweden in Europe, China, Japan, Korea, Vietnam in Asia, California, New York, Florida, Chicago Illinois and all the United States of America, South Arabia, Qatar, Oman, UAE, Middle East, Australia, North of Africa, South America and all Latin America`,
			keywords: `${process.env.REACT_APP_SEO_SECOND} export trade, ${process.env.REACT_APP_SEO_FIRST} manufacturing trade, ${process.env.REACT_APP_SEO_FIRST} manufacturing buyers, ${process.env.REACT_APP_SEO_FIRST} b2b export, ${process.env.REACT_APP_SEO_FIRST} business supplies, ${process.env.REACT_APP_SEO_FIRST} vendors b2b, ${process.env.REACT_APP_SEO_FIRST} social network, ${process.env.REACT_APP_SEO_FIRST} business guide, ${process.env.REACT_APP_SEO_SECOND} manufacturer buyers`
		}
	};
	return x[membershipType];
};
const Membership = ({ membershipType = 'Standard', ...props }) => {
	const handleClickBuy = () => {
		props.history.push({
			pathname: 'my-business',
			search: '?membership=choose-business'
		});
	};
	useEffect(() => {
		document.getElementById('membership-root').scrollIntoView();
	}, []);
	return (
		<div id={'membership-root'}>
			<Helmet>
				<title>{textSelector(membershipType.toLowerCase()).title}</title>
				<meta name="description" content={textSelector(membershipType.toLowerCase()).description} />
				<meta name="keywords" content={textSelector(membershipType.toLowerCase()).keywords} />
			</Helmet>
			<div>
				<TextHeader>
					{membershipType} B2B Services (WBN {membershipType} Package)
				</TextHeader>
				<TextHeader>{textHeader[membershipType]}</TextHeader>
				<Button style={{ margin: '30px auto' }}>
					{membershipType} User - {membershipType} Package
				</Button>
			</div>
			{membershipType !== 'Standard' ? (
				<>
					<PackageDetailContainer>
						<ImagePackageContainer>
							<MembershipBadge membershipType={membershipType.toLowerCase()} />
						</ImagePackageContainer>
						<div>
							<ListBenefits>
								{benefitsByMembership[membershipType].map((benefit, key) => {
									return (
										<li key={key}>
											<WBNIconLi /> {benefit}
										</li>
									);
								})}
							</ListBenefits>
							<div>
								<Button flavor='secondary' onClick={handleClickBuy} style={{ margin: '0 auto' }}>
									Buy now!
								</Button>
							</div>
						</div>
					</PackageDetailContainer>
					<div style={{ textAlign: 'center', margin: '30px 0px' }}>
						<img style={{ width: '30px', height: '30px' }} src={WBNPlus} alt="Plus sign WBN B2B" />
					</div>
					<PackageAditionalContainer>
						<div>
							<ListBenefits>
								{benefitsByMembership['Standard'].map((benefit, key) => {
									return (
										<li key={key}>
											<WBNIconLi /> {benefit}
										</li>
									);
								})}
							</ListBenefits>
						</div>
						<div>
							<div>
								<img
									style={{ width: '180px', height: '195px' }}
									src={`/img/domain/${process.env.REACT_APP_DOMAIN_NAME}/DABIE/DABIE_4_${process.env.REACT_APP_DOMAIN_NAME}.svg`}
									alt="DABIE WBNB2B IS OUR PET - HE HELPS YOU TO INCREASE YOUR B2B RELATIONSHIPS"
								/>
							</div>
							<div
								style={{
									borderRadius: '15px',
									backgroundColor: 'var(--wbn-primary-color)',
									color: 'white',
									padding: '15px',
									textAlign: 'center',
									marginTop: '10px'
								}}
							>
								<p>{textDABIE[membershipType]}</p>
							</div>
						</div>
					</PackageAditionalContainer>
				</>
			) : (
				<>
					<PackageStandardDetailContainer>
						<ImagePackageContainer>
							<MembershipBadge membershipType="standard" />
						</ImagePackageContainer>
						<div style={{ width: '100%', maxWidth: '512px', padding: '5px 16px' }}>
							<ListBenefits>
								{benefitsByMembership['Standard'].map((benefit, key) => {
									return (
										<li key={key}>
											<WBNIconLi /> {benefit}
										</li>
									);
								})}
							</ListBenefits>
						</div>
					</PackageStandardDetailContainer>
					<PackageStandardAditionalContainer>
						<div
							style={{
								borderRadius: '15px',
								backgroundColor: 'var(--wbn-primary-color)',
								color: 'white',
								padding: '15px',
								textAlign: 'center'
							}}
						>
							<p>{textDABIE[membershipType]}</p>
						</div>
						<div>
							<img
								style={{ width: '171px', height: '288px' }}
								src={`/img/domain/${process.env.REACT_APP_DOMAIN_NAME}/DABIE/DABIE_5_${process.env.REACT_APP_DOMAIN_NAME}.svg`}
								alt="img 1"
							/>
						</div>
					</PackageStandardAditionalContainer>
				</>
			)}
			<Plans />
		</div>
	);
};

export default Membership;
