import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import './styles.less';

import { GET_INDUSTRY_FULL_TREE_WATCHER } from 'store/list-industry-full-tree/constants';
import { actionWatcher } from 'shared/actions';

import Button from '../../components/atoms/button';
import IndustryIconSelector from './industryIcon';
import industryDescription from './industryDescription';

const CategoryContainer = ({ category }) => {
	return (
		<div className="category-card">
			<div className="category-card__title">
				<div>
					<Link
						to={`/search-result?business=MA&countries=global&search=${encodeURIComponent(category.name)}`}
					>
						<strong style={{ color: 'var(--wbn-primary-color)', fontSize: '12px' }}>{category.name}</strong>
					</Link>
				</div>
			</div>
			<div className="category-card__subcategories">
				{category.subCategories.map((item, key) => {
					return (
						<Link
							key={key}
							to={`/search-result?business=MA&countries=global&search=${encodeURIComponent(category.name)}`}
						>
							<div>{item.name}</div>
						</Link>
					);
				})}
			</div>
		</div>
	);
};
const IndustryContainer = ({ industry, history }) => {
	return (
		<div className="industry-card" key={industry.id}>
			<div className="industry-card__description">
				<div className="industry-card__description-icon">
					<IndustryIconSelector id={industry.key} fill="var(--wbn-primary-color)" />
				</div>
				<div style={{ margin: '5px 0' }}>
					<Button
						onClick={() =>
							history.push(
								`/search-result?business=MA&countries=global&search=${encodeURIComponent(industry.text)}`
							)
						}
						style={{ margin: '0 auto', width: '100%', padding: '5px' }}
					>
						{industry.text}
					</Button>
				</div>
				<div className="industry-card__description-text">
					<div style={{ fontSize: '10px' }}>{industryDescription(industry.key)}</div>
				</div>
			</div>
			<div className="industry-card__categories">
				{industry.categories.map((category, categoryKey) => {
					return <CategoryContainer category={category} key={categoryKey} />;
				})}
			</div>
		</div>
	);
};
const IndustryList = ({ getIndustriesFullTree, industriesCategories, history }) => {
	return (
		<div className="industries-container">
			{industriesCategories.map((industry, key) => {
				return <IndustryContainer industry={industry} key={key} history={history} />;
			})}
		</div>
	);
};

const mapStateToProps = ({ listIndustriesFullTree }) => {
	return {
		industriesCategories: listIndustriesFullTree.data
	};
};

const mapDispatchToProps = dispatch => {
	return {
		getIndustriesFullTree: () => dispatch(actionWatcher(GET_INDUSTRY_FULL_TREE_WATCHER))
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(IndustryList);
