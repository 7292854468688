import { createStore, applyMiddleware, compose } from 'redux';
import createSagaMiddleware from 'redux-saga';
import rootSaga from './rootSagas';
import rootReducer from './rootReducers';

const configureStore = () => {
	const sagaMiddleware = createSagaMiddleware();
	let store;

	if (window.navigator.userAgent.includes('Chrome')) {
		store = createStore(
			rootReducer,
			compose(
				applyMiddleware(sagaMiddleware)
				// window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
			)
		);
	} else {
		store = createStore(rootReducer, compose(applyMiddleware(sagaMiddleware)));
	}

	sagaMiddleware.run(rootSaga);

	return store;
};

export default configureStore;
