import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
align-items: center;
display: flex;
`;

const Shape = styled.img`
cursor: pointer;
height: 20px;
margin-right: 7px;
width: 20px;
`;

export default function InlineIcon({ number, text, src, alt, onClick }) {
  return (
    <Container>
      <Shape onClick={onClick} src={src} alt={alt} />
      {number || number === 0 ? <span>{number}&nbsp;</span> : <span></span>} <span>{text}</span>
    </Container>
  );
}
