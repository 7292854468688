import styled from 'styled-components';
import ListBenefits from 'components/atoms/styled/list-benefits';

const TextHeader = styled.p`
	text-align: center; 
	padding: 0px 10px;
`;
const PackageDetailContainer = styled.div`
	display: flex;
	flex-direction: column;
	border-radius: 30px;
	border: 1px solid var(--wbn-sidebar-color-line);
	justify-content: space-around;
	align-items: center;
	margin: 0 auto;
	background-color: var(--wbn-primary-color_navbar);
	> div:nth-child(2){
		width: 265px;
		@media screen and (min-width: 768px) {
			width: 300px;
		}
	}
	width: 313px;
	height: 520px;
	@media screen and (min-width: 768px) {
		flex-direction: row;
		width: 707px;
		height: 335px;
	}
	@media screen and (min-width: 992px) {
		width: 745px;
	}
	@media screen and (min-width: 1200px) {
		width: 800px;
	}
`;

const PackageStandardDetailContainer = styled.div`
	display: flex;
	flex-direction: column;
	border-radius: 30px;
	border: 1px solid var(--wbn-sidebar-color-line);
	justify-content: space-around;
	align-items: center;
	margin: 0 auto;
	padding-top: 15px;
	padding-bottom: 15px;
	background-color: var(--wbn-primary-color_navbar);
	> div:nth-child(2){
		width: 220px;
		@media screen and (min-width: 768px) {
			width: 300px;
		}
	}
	width: 313px;
	min-height: 520px;
	@media screen and (min-width: 768px) {
		width: 682px;
		height: 710px;
	}
	@media screen and (min-width: 992px) {
		width: 745px;
	}
`;

const ImagePackageContainer = styled.div`
	> img, svg{
		width: 196px;
		height: 196px;
		@media screen and (min-width: 768px) {
			width: 240px;
			height: 240px;
		}
	}
`
const PackageAditionalContainer = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: space-around;
	> div:nth-child(1){
		width: 100%;
	}
	> div:nth-child(2){
		width: 233px;
		margin: 0 auto;
	}
	margin: 0 auto;
	width: 313px;
	@media screen and (min-width: 768px) {
		flex-direction: row;
		width: 707px;
		> div:nth-child(1){
		width: 450px;
	}
	}
	@media screen and (min-width: 992px) {
		width: 745px;
	}
	@media screen and (min-width: 1200px) {
		width: 800px;
	}
`
const PackageStandardAditionalContainer = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	> div:nth-child(1){
		width: 233px;
		height: 128px;
	}
	> div:nth-child(2){
		width: 171px;
		margin-left: 0px;
	}
	margin: 30px auto;
	width: 233px;
	@media screen and (min-width: 768px) {
		flex-direction: row;
		align-items: center;
		width: 707px;
		> div:nth-child(2){
			margin-left: 30px;
		}
	}
	@media screen and (min-width: 992px) {
		width: 745px;
	}
	@media screen and (min-width: 1200px) {
		width: 800px;
	}
`

export {
	TextHeader,
	PackageDetailContainer,
	PackageStandardDetailContainer,
	ImagePackageContainer,
	ListBenefits,
	PackageAditionalContainer,
	PackageStandardAditionalContainer,
}