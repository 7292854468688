import React, { useEffect, useState } from 'react';
import likeIcon from 'images/icons/likes.svg';
import commentIcon from 'images/icons/comment.svg';
import shareIcon from 'images/icons/share.svg';
import likedIcon from 'images/icons/liked.svg';
import { SocialActionStyle } from './style';
import { reactToPost, checkReaction, deleteReactionFromPost } from 'utils/social-api';
import usePreDefinedToasts from 'hooks/use-pre-defined-toasts';

const actionTypeText = {
	LIKE: 'Like',
	COMMENT: 'Comment',
	SHARE: 'Share'
};
const actionTypeIcon = {
	LIKE: likeIcon,
	COMMENT: commentIcon,
	SHARE: shareIcon
};
const reactedIcon = {
	like: likedIcon
};
const handleOnClickReact = async (resourceID, reaction, setReaction) => {
	if (reaction.reacted) {
		let pastReaction = reaction.reaction;
		setReaction({ reacted: false, reaction: null });
		let response = await deleteReactionFromPost({ resourceID });
		if (response.success) {
		} else {
			setReaction({ reacted: true, reaction: pastReaction });
		}
	} else {
		setReaction({ reacted: true, reaction: 'like' });
		let response = await reactToPost({ resourceID, reactionType: 'like' });
		if (response.success) {
		} else {
			setReaction({ reacted: false, reaction: null });
		}
	}
};

const SocialAction = ({ actionType, resourceID }) => {
  const { showSuccessToast } = usePreDefinedToasts();

  const handleClickShare = async e => {
    let input = document.createElement('input');
    input.setAttribute('value', window.location.href);
    input.setAttribute('type', 'text');
    document.body.appendChild(input);
    input.select();
    document.execCommand('Copy');
    document.body.removeChild(input);
    showSuccessToast('Product Link copied!');
  };

	const [reaction, setReaction] = useState({ reacted: false, reaction: null });
	useEffect(() => {
		const fetch = async () => {
			let response = await checkReaction({ resourceID });
			if (response && response.success) {
				if (response.data) {
					setReaction({ reacted: true, reaction: response.data.reactionType });
				}
			} else {
				console.log('Error', response);
			}
		};
		if (resourceID) fetch();
	}, [resourceID]);
	return (
		<SocialActionStyle
			onClick={() => {
				if (actionType === 'LIKE') {
					handleOnClickReact(resourceID, reaction, setReaction);
        } else if (actionType === "SHARE") {
          handleClickShare();
        } else if (actionType === "COMMENT") {
          window.location.hash = ''; // First Clear
          window.location.hash = '#comments-section';
        }
			}}
		>
			<SocialActionStyle.Icon>
				<img src={reaction.reacted ? reactedIcon[reaction.reaction] : actionTypeIcon[actionType]} alt='Social Action WBN'/>
			</SocialActionStyle.Icon>
			<div>{actionTypeText[actionType]}</div>
		</SocialActionStyle>
	);
};
export default SocialAction;
