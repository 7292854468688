import React from 'react';
import styled from 'styled-components';
import WHY_WBN_CONNECTED from 'images/why-wbn/WHY_WBN_CONNECTED.png';
import TECHNOLOGY from 'images/why-wbn/TECHNOLOGY.png';
import WHY_WBN_B2B from 'images/why-wbn/WHY_WBN_B2B.png';
import WHY_WBN_SOLUTIONS from 'images/why-wbn/WHY_WBN_SOLUTIONS.png';
import MARKET_PLACE from 'images/why-wbn/MARKET_PLACE.png';
import { WBNMap } from 'components/molecules';
import Helmet from 'react-helmet';

const MessageFrame = styled.div`
	display: flex;
	justify-content: space-around;
	align-items: center;
	margin: 20px 5px;
	flex-direction: column;
	@media screen and (min-width: 768px) {
		flex-direction: row;
		height: 300px;
	}
`;
const ImageContainer = styled.div`
	margin-bottom: 35px;
	> img {
		width: 100%;
		height: 100%;
	}
`;
const DataContainer = styled.div`
	margin-bottom: 35px;
	width: 315px;
	@media screen and (min-width: 768px) {
		width: 335px;
		height: 197px;
	}
	@media screen and (min-width: 1200px) {
		width: 452px;
		height: 265px;
	}
`;
const TextContainer = styled.div`
	padding: 30px 60px;
	background-color: var(--wbn-primary-color_navbar);
	border-radius: 15px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 100%;
	height: 100%;
	> p {
		text-align: center;
	}
`;

const messages = [
	{
		title: 'WBN TO BE CONNECTED',
		message:
			'The world industrial business choose us because we provide the best and easy way to connect with the industrial manufacturing companies, distributors, buyers, and export business players of the global market.',
		image: WHY_WBN_CONNECTED,
		alt: ''
	},
	{
		title: 'WBN TO BE CONNECTED',
		message:
			'The world industrial business choose us because we provide the best and easy way to connect with the industrial manufacturing companies, distributors, buyers, and export business players of the global market',
		image: TECHNOLOGY,
		alt: ''
	},
	{
		title: 'WBN TO BE CONNECTED',
		message:
			'The world industrial business choose us because we provide the best and easy way to connect with the industrial manufacturing companies, distributors, buyers, and export business players of the global market',
		image: WHY_WBN_SOLUTIONS,
		alt: ''
	},
	{
		title: 'WBN TO BE CONNECTED',
		message:
			'The world industrial business choose us because we provide the best and easy way to connect with the industrial manufacturing companies, distributors, buyers, and export business players of the global market',
		image: WHY_WBN_B2B,
		alt: ''
	},
	{
		title: 'WBN TO BE CONNECTED',
		message:
			'The world industrial business choose us because we provide the best and easy way to connect with the industrial manufacturing companies, distributors, buyers, and export business players of the global market',
		image: MARKET_PLACE,
		alt: ''
	}
];
const toggleData = (index, frame) => {
	const x = (
		<DataContainer>
			<TextContainer>
				<p>{frame.title}</p>
				<p>{frame.message}</p>
			</TextContainer>
		</DataContainer>
	);
	const y = (
		<DataContainer>
			<ImageContainer>
				<img src={frame.image} alt={frame.alt} />
			</ImageContainer>
		</DataContainer>
	);
	if (index % 2) {
		return (
			<>
				{x}
				{y}
			</>
		);
	} else {
		return (
			<>
				{y}
				{x}
			</>
		);
	}
};
const WhyWBN = () => {
	return (
		<div style={{ display: 'flex', flexDirection: 'column' }}>
			<Helmet>
				<title>{`WBN an American export network, ${process.env.REACT_APP_SEO_FIRST} export trade platform for manufacturing vendors, made in ${process.env.REACT_APP_SEO_SECOND} export platform for distribution supplies, ${process.env.REACT_APP_SEO_FIRST} manufacturing platform buyers trade for export global, ${process.env.REACT_APP_SEO_SECOND} B2B manufacturing platform suppliers USA`}</title>
				<meta name="description" content={`WBN an American B2B trade platform for global business, ${process.env.REACT_APP_SEO_FIRST} export trade manufacturers platform business advertise to the distributors industry, made in ${process.env.REACT_APP_SEO_SECOND} manufacturers export trade platform vendors to the export global wholesalers and export distribution, ${process.env.REACT_APP_SEO_FIRST} business guide is part of WBN industrial export trade b2b social network, made in ${process.env.REACT_APP_SEO_SECOND} export products manufacturer export business supply, ${process.env.REACT_APP_SEO_FIRST} products for wholesale businesses distribution in United States, Asia, Africa, Europe, South America, Latin America and Middle East distribution, WBN the industrial b2b social network for export wholesalers and manufacturing private label industries, made in ${process.env.REACT_APP_SEO_SECOND} products manufacturer supplier for wholesalers and vendors of the market, industrial products for wholesale worldwide distributors, ${process.env.REACT_APP_SEO_FIRST} products manufacturing for private label buyers and new brand for manufacturer, business to business manufacturing supplies and market distributors, ${process.env.REACT_APP_SEO_FIRST} product manufacturing industry looking for worldwide distributors mainly in Germany, Denmark, Austria, Portugal, Spain, France, Norway, Sweden in Europe, China, Japan, Korea, Vietnam in Asia, California, New York, Florida, Chicago Illinois and all the United States of America, South Arabia, Qatar, Oman, UAE, Middle East, Australia, North of Africa, South America and all Latin America`}/>
				<meta name="keywords" content={`${process.env.REACT_APP_SEO_SECOND} export platform, ${process.env.REACT_APP_SEO_FIRST} manufacturing platform, ${process.env.REACT_APP_SEO_FIRST} manufacturing buyers, ${process.env.REACT_APP_SEO_FIRST} b2b platform, ${process.env.REACT_APP_SEO_FIRST} business supplies, ${process.env.REACT_APP_SEO_FIRST} vendors b2b, ${process.env.REACT_APP_SEO_FIRST} social network, ${process.env.REACT_APP_SEO_FIRST} business guide, ${process.env.REACT_APP_SEO_SECOND} manufacturer vendors`}/>
			</Helmet>
			<div>
				<p style={{ textAlign: 'center', padding: '5px 10px' }}>WHY WBN</p>
				<p style={{ textAlign: 'center', padding: '5px 30px' }}>
					WHY TO USE WBN B2B MARKETPLACE? There are several reasons to use WBN Industrial Network, our
					platform was designed and created to offer a real opportunity to be connected, to use the last
					technology, having a direct B2B approach and customized business solution for every company in the
					world...
				</p>
			</div>
			{messages.map((frame, index) => {
				return <MessageFrame key={index}>{toggleData(index, frame)}</MessageFrame>;
			})}
			<WBNMap />
		</div>
	);
};

export default WhyWBN;
