import React from 'react';
import Helmet from 'react-helmet';

import { WBNMap } from 'components/molecules';
// import { Button } from 'components/atoms';

import { LastSectionDescription } from './styles.js';

import g1 from 'images/b2b-tips/tip-6/WBN_TIP_H.png';
import threadImage from 'images/b2b-tips/tip-6/2.jpg';
import worldImage from 'images/b2b-tips/tip-6/1.png';

import {
  Section1,
  Secion1Title,
  SecctionTitle,
  Section1Description,
  GraphicSection,
  GraphicTitle,
  GraphicDescription,
  SectionContainer,
  SectionBodyImageLeft,
  SectionBody2,
  SectionBody1,
  SectionBodyImage,
} from '../style';

import { TipsFooter } from '../tip0';


const Tip0 = () => {
  window.scrollTo(0, 0);

  return (
    <div>
      <Helmet>
        <title>{`Products you can export, best ${process.env.REACT_APP_SEO_FIRST} products you can b2b export to global market, most popular products to export to industrial b2b market, tips to export best products for industrial sectors, products ${process.env.REACT_APP_SEO_FIRST}exporting to global b2b market export US`}</title>
        <meta name="description" content={`${process.env.REACT_APP_SEO_FIRST} products you can export who is a qualified industry exporter in the distribution network, industrial exporter network is an export trade platform for business buyers from companies, ${process.env.REACT_APP_SEO_FIRST} industrial exporter of manufacturing network, how to create your products export marketing business buyers for industries, made in ${process.env.REACT_APP_SEO_SECOND} export platform manufacturing vendors to the export global wholesalers and export distributors, ${process.env.REACT_APP_SEO_FIRST} business trade guide is part of WBN industrial b2b social network, made in ${process.env.REACT_APP_SEO_SECOND} export products manufacturer export business supply, ${process.env.REACT_APP_SEO_FIRST} products for wholesale trade businesses distributors in United States, Asia, Africa, Europe, South America, Latin America and Middle East distribution, WBN the industrial b2b social network for export wholesalers and manufacturing private label industries, made in ${process.env.REACT_APP_SEO_SECOND} products manufacturer supplier for wholesalers and vendors of the market, industrial products for wholesale worldwide distributors, ${process.env.REACT_APP_SEO_FIRST} products manufacturing for private label buyers and new brand for manufacturer, business to business manufacturing supplies and market distributors, ${process.env.REACT_APP_SEO_FIRST} product manufacturing industry looking for worldwide distributors mainly in Germany, Denmark, Austria, Portugal, Spain, France, Norway, Sweden in Europe, China, Japan, Korea, Vietnam in Asia, California, New York, Florida, Chicago Illinois and all the United States of America, South Arabia, Qatar, Oman, UAE, Middle East, Australia, North of Africa, South America and all Latin America`} />
        <meta name="keywords" content={`${process.env.REACT_APP_SEO_SECOND} export products, ${process.env.REACT_APP_SEO_FIRST} b2b products, ${process.env.REACT_APP_SEO_FIRST} manufacturing exporter, ${process.env.REACT_APP_SEO_FIRST} business exporter, ${process.env.REACT_APP_SEO_FIRST} exporter platform, ${process.env.REACT_APP_SEO_FIRST} social network, ${process.env.REACT_APP_SEO_FIRST} business exporter, ${process.env.REACT_APP_SEO_SECOND} manufacturer exporter`} />
      </Helmet>

      <Section1>
        <Secion1Title>PRODUCTS YOU CAN EXPORT TO THE WORLD MARKET</Secion1Title>
        <Section1Description>PRODUCTS YOU CAN EXPORT TO THE WORLD MARKET As industrial fundamental principle: all products have been created according to the market demand, then everyone can be exported around the world. But there are well-defined parameters that restrict the export such as: the size of production, units used, product certifications, regulations of foreign countries, information on labels, the ingredients used for production, not least "the price and terms of sale". We must consider a professional market research according to your market target then you can decide the best marketing strategy to reach your goals.</Section1Description>
      </Section1>

      <GraphicSection>
        <SecctionTitle>WHAT ARE THE BEST PRODUCTS TO EXPORT WORLDWIDE?</SecctionTitle>
        <GraphicTitle>Following we will define some tips to understand what kind of products can be exported global</GraphicTitle>
        <img src={g1} alt="WHAT ARE THE BEST PRODUCTS TO EXPORT WORLDWIDE?"></img>
        <GraphicDescription>WHAT ARE THE BEST PRODUCTS TO EXPORT GLOBAL? the very best product to export it's what you have available in your city and/or country that match market requirements, can be from raw materials to customized or final product, in private label or produced by third party. There are several conditions to accomplish according to the kind of product, from raw materials, to final manufacturerd products, as the right manufacturing facilities, private label system, packaging setup, logistics, sales conditions, distribution contracts, price list and discount policy ASK FOR INDUSTRIAL TIPS TO DEFINE YOUR EXPORT PROGRAM</GraphicDescription>
      </GraphicSection>

      <SectionContainer>
        <SecctionTitle maxWidth='70%'>WHAT ARE THE BEST PRODUCTS TO EXPORT INTO THE GLOBAL MARKET?</SecctionTitle>
        <SectionBody2>
          <SectionBodyImageLeft width="200px" height="202px">
            <img src={threadImage} alt=""></img>
          </SectionBodyImageLeft>
          <div>
            <p>INDUSTRIAL PRODUCTS FOR EXPORT? it means industrial production with industrial quantities to have the very best price possible to export into the distribution market, normally you need to create your own distribution chain to have industrial quantities to maintain your facilities, personnel and costs. You should done with your own Brand.</p>
            <p>NICHE PRODUCTS FOR EXPORT? ideal for small manufacturing companies that can produce small quantity of products for a niche markets, from food to technology sector mainly prototypes and customized spare parts that can not be done by the middle or big industry. It's very necessary to use high quality raw materials and to maintain a group of high end qualified workers to guarantee maximum quality to the market.</p>
            <p>PRIVATE LABEL PRODUTCS? It's production services to third party who has invest in his own brand, normally the customer defines manufacturing drawing and technical specs for industrial manufacturing process</p>
          </div>
        </SectionBody2>
      </SectionContainer>

      <SectionContainer>
        <SecctionTitle>WHAT SHOULD YOU BE ABLE TO REALIZE AN EXPORT INDUSTRIAL MARKETING TECHNICIAN</SecctionTitle>
        <SectionBody1>
          <div>
            <ul>
              <li>1 Electrical machinery, equipment</li>
              <li>2 Mineral fuels including oil</li>
              <li>3 Machinery including computers</li>
              <li>4 Vehicles</li>
              <li>5 Plastics, plastic articles</li>
              <li>6 Gems, precious metals</li>
              <li>7 Optical, technical, medical apparatus</li>
              <li>8 Pharmaceuticals</li>
              <li>9 Organic chemicals</li>
              <li>10 Iron, steel</li>
              <li>11 Aircraft, spacecraft</li>
            </ul>
          </div>
          <SectionBodyImage width="306px" height="297px">
            <img src={worldImage} alt=""></img>
          </SectionBodyImage>
          <div>
            <ul>
              <li>12 Articles of iron or steel</li>
              <li>13 Furniture, bedding, lighting , signs, prefab buildings</li>
              <li>14 Knit or crochet clothing, accessories</li>
              <li>15 Clothing, accessories (not knit or crochet)</li>
              <li>16 Ores, slag, ash</li>
              <li>17 Other chemical goods</li>
              <li>18 Aluminum</li>
              <li>19 Rubber, rubber articles</li>
              <li>20 Paper, paper items</li>
              <li>21 Cooper</li>
            </ul>
          </div>
        </SectionBody1>
        <LastSectionDescription>...THE 50 MOST EXPORTED PRODUCTS IN THE WORLD 22 Footwear, 23 Wood, 24 Perfumes, cosmetics, 25 Inorganic chemicals, 26 Meat, 27 Fish, 28 Beverages, spirits, vinegar, 29 Fruits, nuts 30 Toys, games, 31 Ships, boats, 32 Cereals, 33 Oil seeds, 34 Animal/vegetable fats, oils, waxes, 35 Dairy, eggs, honey, 36 Tanning, dyes, paints, varnishes, ink, 37 Leather/animal gut articles, 38 Food industry waste, animal fodder, 39 Glass, 40 Miscellaneous food preparations, 41 Cereal/milk preparations, 42 Other base metal goods, 43 Base metal tools, cutlery, 44 Vegetables, 45 Miscellaneous textiles, worn clothing , 46 Vegetable/fruit/nut preparations, 47 Soaps, washing preparations, lubricants, waxes, 48 Fertilizers, 49 Cotton, 50 Ceramic products</LastSectionDescription>
      </SectionContainer>

      <TipsFooter />
      <WBNMap />
    </div>
  );
};

export default Tip0;
