import React from 'react';
import SidebarLeft from '../sidebar-left';
import FooterNew from '../footer-new';

import './styles.less';

const Layout = ({ children, hasFooter = true }) => {
	return (
		<div className="container-layout">
			<SidebarLeft />
			<div className="container-layout-main">
				{children}
				{hasFooter && <FooterNew />}
			</div>
		</div>
	);
};

export default Layout;
