import React from 'react';
import './styles.css';

const HelpSection = () => {
	return (
		<div className="container-help-section">
			<div className="container-help-section__content">
				WBN (Worldwide Business Network) is a professional "Industrial B2B Social Network",
				created to help, democratically, the world distributors, buyers, suppliers and manufacturers to
				establish and develop business relationship in the most important markets... the best B2B Import &
				Export experience FOR FREE
			</div>
		</div>
	);
};

export default HelpSection;
