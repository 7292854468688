import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { map } from 'lodash';
import { CardMyBusiness } from 'components';
import './styles.less';
import { Loader } from 'semantic-ui-react';
import auth from 'auth';
import qs from 'qs';

import { GET_LIST_MY_BUSINESS_WATCHER } from 'store/list-my-business/constants';
import { actionWatcher } from 'shared/actions';

const MyBusiness = ({ myBusiness, loadingMyBusiness, getListMyBusiness, ...props }) => {
	const query = qs.parse(props.location.search, { ignoreQueryPrefix: true });
	useEffect(() => {
		if (!auth.hasValidSession()) {
			auth.redirectToLogin();
		}
	}, []);
	useEffect(() => {
		getListMyBusiness();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<div className="container-my-business">
			{query.membership === 'choose-business' && myBusiness.length ? (
				<div style={{ margin: '20px auto', textAlign: 'center' }}>
					<p>In order to get your membership you have to choose which business you want to upgrade</p>
					<p>Go to 'Manage' and then 'Membership' tab</p>
				</div>
			) : null}
			<Link className="container-my-business__create-business" to="business/create">
				{myBusiness.length ? 'Create another Business profile' : 'Create your Business profile'}
			</Link>
			{myBusiness.length ? (
				<div className="container-my-business__list">
					{map(myBusiness, (item, index) => {
						if (!item.business) return null;
						return <CardMyBusiness key={index} {...item} />;
					})}
				</div>
			) : (
				<div className="container-my-business__no-business">
					{loadingMyBusiness ? <Loader active inline /> : "You don't have any business registered yet"}
				</div>
			)}
		</div>
	);
};

const mapStateToProps = ({ listMyBusiness }) => {
	return {
		myBusiness: listMyBusiness.data,
		loadingMyBusiness: listMyBusiness.loading
	};
};

const mapDispatchToProps = dispatch => {
	return {
		getListMyBusiness: () => dispatch(actionWatcher(GET_LIST_MY_BUSINESS_WATCHER))
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(MyBusiness);
