import axios from 'axios';
import { omitBy, isNull, isNaN } from 'lodash';

// TODO: https://www.techynovice.com/setting-up-JWT-token-refresh-mechanism-with-axios/

let axiosInstance;

export function updateApiClientToken(token) {
  if (axiosInstance) {
    axiosInstance.defaults.headers['wbn-auth'] = token;
  }
}

function clearSessionStorage() {
	localStorage.removeItem('token');
	localStorage.removeItem('tokenExpire');
	localStorage.removeItem('refreshToken');
	localStorage.removeItem('refreshTokenExpire');
	localStorage.removeItem('userID');
}
function isTrash(value) {
	return isNaN(value) || isNull(value);
}

function validateStatus(status) {
	return status >= 200 && status < 300;
}

function getHeaders() {
	const headers = {};

	if (localStorage.getItem('token')) {
		headers['wbn-auth'] = localStorage.getItem('token');
	}

	return headers;
}

function isTokenExpiredError(errorResponse) {
	console.log('ERROR RESPONSE IS TOKEN EXPIRED?', errorResponse);
	if (errorResponse.status === 401 && errorResponse.data.name === 'JsonWebTokenError') {
		clearSessionStorage();
		window.location.reload(); //Temp solution for useSWR
		return false;
	}
	return (
		errorResponse.status === 401 &&
		['TokenNotFound', 'TokenExpiredError', 'SessionError'].some(e => e === errorResponse.data.name)
	);
}

function onAccessTokenFetched(access_token) {
	subscribers.forEach(callback => callback(access_token));
	subscribers = [];
}

function addSubscriber(callback) {
	subscribers.push(callback);
}

let isAlreadyFetchingAccessToken = false;

let subscribers = [];

async function resetTokenAndReattemptRequest(error) {
	console.log('Reset token process');
	try {
		const { response: errorResponse } = error;
		const resetToken = localStorage.getItem('refreshToken');
		if (!resetToken) {
			return Promise.reject(error);
		}

		const retryOriginalRequest = new Promise(resolve => {
			addSubscriber(access_token => {
				errorResponse.config.headers['wbn-auth'] = access_token;
				resolve(axios(errorResponse.config));
			});
		});
		if (!isAlreadyFetchingAccessToken) {
			console.log('Trying to refresh token');
			isAlreadyFetchingAccessToken = true;
			const response = await axios({
				method: 'POST',
				url: `${process.env.REACT_APP_AUTH_URL}refresh-token`,
				headers: {
					'wbn-auth': resetToken
				},
				validateStatus: function(status) {
					return status < 500;
				}
			});

			if (!response.data || !response.data.success) {
				clearSessionStorage();
				window.location.reload();
				return Promise.reject(errorResponse);
			}
			const { token, tokenExpire, refreshToken, refreshTokenExpire } = response.data.data;
			localStorage.setItem('token', token);
			localStorage.setItem('tokenExpire', tokenExpire*1000);
			localStorage.setItem('refreshToken', refreshToken);
			localStorage.setItem('refreshTokenExpire', refreshTokenExpire*1000);
			isAlreadyFetchingAccessToken = false;
			onAccessTokenFetched(token);
		}
		return retryOriginalRequest;
	} catch (err) {
		clearSessionStorage();
		console.error('SECOND CATCH ERROR', err);
		return Promise.reject(err);
	}
}

function createInstance() {
	const instance = axios.create({
		validateStatus,
		baseURL: process.env.REACT_APP_API_URL,
		headers: getHeaders()
	});

	instance.interceptors.request.use(
		config => {
			config.data = omitBy(config.data, isTrash);
			return config;
		},
		error => Promise.reject(error)
	);

	instance.interceptors.response.use(
		response => {
			if (validateStatus(response.status) && response?.data?.success === false) {
				const message = `[Application Error]: There is a response, status=${response.status} but failure data.`;
				console.error(message, response);
				throw response;
			}
			return response;
		},
		error => Promise.reject(error)
	);

	instance.interceptors.response.use(
		response => response,
		error => {
			const errorResponse = error.response;
			if (errorResponse && isTokenExpiredError(errorResponse)) {
				return resetTokenAndReattemptRequest(error);
			}
			return Promise.reject(errorResponse);
		}
	);

	return instance;
}

function getInstance() {
	if (!axiosInstance) {
		axiosInstance = createInstance();
	}

	return axiosInstance;
}

export default getInstance();
