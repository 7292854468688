import styled from 'styled-components';

const DabieSection = styled.section`
	display: flex;
	flex-direction: column;
	align-items: center;
	margin: 30px auto;
	max-width: 648px;
	@media screen and (min-width: 768px) {
		flex-direction: row;
		justify-content: space-between;
	}
`;

const DabieContainer = styled.div`
	padding: 20px;
	display: flex;
	box-shadow: 5px 5px 15px grey;
	flex-direction: column;
	align-items: center;
	border-radius: 20px;
	border: 1px solid var(--wbn-sidebar-color-line);
	background-color: var(--wbn-primary-color_navbar);
	height: 386px;
	width: 290px;
	> div > p,
	h4 {
		text-align: center;
	}
	margin-top: 20px;
	@media screen and (min-width: 768px) {
		margin-top: 0px;
	}
`;

const DabieImageWrap = styled.div`
	width: 185px;
	height: 205px;
	margin: 10px 0px;
	> img {
		width: 100%;
		height: 100%;
	}
`;

const MessageDabie = styled.div`
	padding: 10px 10px;
`;

const InformationSection = styled.div`
	border-radius: 20px;
	background-color: var(--wbn-primary-color);
	width: 312px;
	height: 65px;
	color: white;
	padding: 10px;
	margin: 10px 0px;
	text-align: center;
	> p {
		font-size: 12px;
	}
	@media screen and (min-width: 768px){
		width: 648px;
		> p {
			font-size: 16px;
		}
	}
`;
const MoreInformationSection = styled.div`
	border-radius: 20px;
	background-color: var(--wbn-primary-color_navbar);
	width: 320px;
	height: 125px;
	text-align: center;
	padding: 8px 10px;
	margin: 10px 0px;
	@media screen and (min-width: 768px){
		width: 648px;
	}
`;

export {
	DabieSection,
	DabieContainer,
	DabieImageWrap,
	MessageDabie,
	InformationSection,
	MoreInformationSection
};
