import { GET_COUNTRY_STATE_WATCHER, GET_COUNTRY_STATE_SUCCESS, GET_COUNTRY_STATE_FAILED } from './constants';

const initialState = {
	isLoadingCountryState: true,
	data: []
};

export default (state = initialState, action) => {
	switch (action.type) {
		case GET_COUNTRY_STATE_WATCHER:
			return {
				isLoadingCountryState: true,
				data: []
			};
		case GET_COUNTRY_STATE_SUCCESS:
			return {
				isLoadingCountryState: false,
				data: action.payload
			};

		case GET_COUNTRY_STATE_FAILED:
			return {
				isLoadingCountryState: false,
				data: []
			};

		default:
			return state;
	}
};
