import styled from 'styled-components';
const SocialActionStyle = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  flex-grow: 1;
	flex-basis: 0;
  :hover{
    background-color: rgba(0, 0, 0, 0.1);
  }
`;
SocialActionStyle.Icon = styled.div`
  padding: 5px;
	> img {
		width: 20px;
		height: 20px;
  }
`;

export {SocialActionStyle}