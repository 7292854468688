import React from 'react';
import image_business from 'images/image_business.png';
import { Icon } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import './styles.less';

const CardMyBusiness = ({ business: { businessID, general, contactInfo, subscription } }) => {
	return (
		<div className="container-card-my-business">
			<img src={general.profilePhoto?.url || image_business} alt="business" />
			<div>
				<div className="container-card-my-business__title">{general.companyName}</div>
				<div className="container-card-my-business__info">
					<div>{contactInfo.country.countryName + ' ' + contactInfo.country.emoji}</div>
					<div className="container-card-my-business__info-types">
						<span>{general.verified?'Verified':'Not Verified'}</span>
						<span>{subscription?.toUpperCase() || 'STANDARD'}</span>
					</div>
				</div>
				<div className="container-card-my-business__description">{general.shortDescription}</div>
				<div className="container-card-my-business__footer">
					<div></div>
					<div className="container-card-my-business__footer-actions">
						<Link to={`/business/${businessID}/?editMode=1`}>
							Manage Business <Icon name="setting" />
						</Link>
						<Link to={`/business/${businessID}/`}>
							View as a buyer <Icon name="eye" />
						</Link>
					</div>
				</div>
			</div>
		</div>
	);
};

export default CardMyBusiness;
