import styled from 'styled-components';
const PostStyled = styled.div`
	border-radius: 15px 15px 15px 15px;
	background-color: white;
	max-width: 500px;
	margin: 10px 0px;
	box-shadow: 1px 1px 2px grey;
`;
PostStyled.Header = styled.div`
	display: flex;
	border-radius: 15px 15px 0px 0px;
	height: 75px;
	padding: 8px 15px;
	justify-content: space-between;
	align-items: center;
`;
PostStyled.Body = styled.div`
	display: flex;
	max-width: 500px;
`;
PostStyled.Body.Text = styled.div`
	padding: 5px 15px;
`
PostStyled.Body.Picture = styled.div`
	background-color: #eee;
	img {
		width: 100%;
		height: 100%;
		object-fit: contain;
	}
`
PostStyled.Footer = styled.div`
	display: flex;
	flex-direction: column;
	height: 75px;
	border-radius: 0px; /* Style footer with no border*/
	align-items: center;
	border-bottom-style: solid;
	border-bottom-color: #e3e3e3;
	border-bottom-width: 1px;
`;
PostStyled.Footer.Actions = styled.div`
	width: 100%;
	padding: 5px 10px;
	border-top-color: #e3e3e3;
`;
PostStyled.Footer.Summary = styled.div`
	width: 100%;
	padding: 5px 10px;
`;
const Author = styled.div`
	height: 50px;
	display: flex;
	align-items: center;
`;
Author.Photo = styled.div`
	width: 35px;
	height: 35px;
	> img {
		width: 100%;
		height: 100%;
		border-radius: 50%;
		border: 1px solid #e3e3e3;
		object-fit: contain;
	}
`;
Author.Data = styled.div`
	padding: 5px 10px;
`;
Author.Data.Name = styled.div``
Author.Data.Time = styled.div`
	font-size: 12px;
`
export { PostStyled, Author };
