import React, { useState } from 'react';
import useSWR from 'swr';
import { Elements, StripeProvider } from 'react-stripe-elements';
import CheckoutForm from './CheckoutForm';
import MembershipBadge from 'components/atoms/membership-badge';
import { Loader } from 'components';
import Helmet from 'react-helmet';
import Button from 'components/atoms/button';

import {
	MembershipWrapper,
	ImagePackageContainer,
	ImagePackageContainerMini,
	UpgradeContainer,
	Payment
} from './style';

const UpgradeMembership = ({ membershipType, setCheckoutFormStatus, plans }) => {
	const membershipsArray = ['standard', 'gold', 'premium', 'platinum'];

	const Wrapper = ({ membershipTypeValue }) => {
		let plan = plans.find(p => p.subscription === membershipTypeValue);
		return (
			<div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', margin: "10px 0px" }}>
				<div style={{ margin: '10px 20px 10px 0px' }}>
					<ImagePackageContainerMini>
						<MembershipBadge membershipType={membershipTypeValue} />
					</ImagePackageContainerMini>
				</div>
				<div>
					<p style={{fontSize: "16px"}}>USD {(plan.price/100).toFixed(2)} / Year</p>
					<Button
						onClick={() => {
							setCheckoutFormStatus({ show: true, membershipType: membershipTypeValue, planID: plan.planId });
						}}
						style={{
							padding: '10px 30px',
							margin: '0 auto'
						}}
					>
						Upgrade
					</Button>
				</div>
			</div>
		);
	};
	let index = membershipsArray.indexOf(membershipType);
	return (
		<UpgradeContainer>
			{index < 1 ? <Wrapper membershipTypeValue={membershipsArray[1]} /> : null}
			{index < 2 ? <Wrapper membershipTypeValue={membershipsArray[2]} /> : null}
			{index < 3 ? <Wrapper membershipTypeValue={membershipsArray[3]} /> : null}
		</UpgradeContainer>
	);
};

const BusinessMembership = ({ businessProfile, history, setupStripe, userProfile }) => {
	const membershipType = businessProfile.subscription || 'standard';
	const verifiedBusiness = businessProfile.verified;
	const businessID = businessProfile.businessID;
	const [checkoutFormStatus, setCheckoutFormStatus] = useState({ show: false, membershipType: '' });
	return (
		<>
			<Helmet>
				<script src="https://js.stripe.com/v3/" async></script>
			</Helmet>
			<div style={{ textAlign: 'center', margin: '20px auto' }}>
				<h3>Your business membership</h3>
			</div>
			<MembershipWrapper>
				<div>
					<h4 style={{ textAlign: 'center' }}>Business Membership</h4>
					<p style={{ textAlign: 'center' }}>Your business currently have a {membershipType} membership</p>
					<ImagePackageContainer>
						<MembershipBadge membershipType={membershipType} />
					</ImagePackageContainer>
					<p style={{ textAlign: 'center' }}>
						{!verifiedBusiness
							? `You don't have a verified business. This is important if you want to be more reliable to other business`
							: `You have a verified Business`}
					</p>
				</div>
				<div>
					{membershipType !== 'platinum' ? (
						<>
							<h5 style={{ textAlign: 'center' }}>WBN Packages</h5>
							<p>
								You can adquire the following packages and power up your business, increase your B2B
								realtionships and sell to the world.
							</p>
							<UpgradeMembership
								membershipType={membershipType}
								setCheckoutFormStatus={setCheckoutFormStatus}
								plans={setupStripe.plans}
							/>
						</>
					) : (
						<>
							<p>You already have the best membership in our network!</p>
						</>
					)}
				</div>
			</MembershipWrapper>
			{checkoutFormStatus.show ? (
				<div>
					<Payment>
						<StripeProvider apiKey={setupStripe.publicKey}>
							<div style={{ maxWidth: '480px', margin: '0 auto' }}>
								<h3 style={{ textAlign: 'center' }}>You are close to upgrade your business</h3>
								<Elements>
									<CheckoutForm
										membershipType={checkoutFormStatus.membershipType}
										businessId={businessID}
										planID={checkoutFormStatus.planID}
										userProfile={userProfile}
									/>
								</Elements>
							</div>
						</StripeProvider>
					</Payment>
				</div>
			) : null}
		</>
	);
};

export default function BusinessMembershipPage(props) {
	const businessId = props.match.params.id;

	const { data: response } = useSWR(`${process.env.REACT_APP_API_URL}business/profile/${businessId}`);
	const { data: setupStripe } = useSWR(`${process.env.REACT_APP_API_URL}subscription/setup`);
	const { data: userProfile} = useSWR(`user/profile`);
	const profile = response ? response.data : null;

	if (profile && !profile.success) {
		return 'An unexpected error has occurred, please try again.';
	}

	if (!profile || !setupStripe || !userProfile) {
		return <Loader />;
	}
	console.log('SETUP STRIPE', setupStripe);
	return (
		<BusinessMembership
			businessProfile={profile.data}
			history={props.history}
			setupStripe={setupStripe.data?.data}
			userProfile={userProfile.data?.data}
		/>
	);
}
