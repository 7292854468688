import styled from 'styled-components';

export const CreatePostStyle = styled.div`
	background-color: var(--wbn-primary-color_navbar);
	max-width: 500px;
	height: 167px;
	border-radius: 15px;
	> div {
		padding: 10px;
	}
`;
CreatePostStyle.Header = styled.div`
	border-radius: 15px 15px 0px 0px;
	background-color: var(--wbn-primary-color);
	height: 35px;
	display: flex;
	justify-content: space-between;
	color: white;
`;
CreatePostStyle.Body = styled.div`
	height: 82px;
	display: flex;
	justify-content: space-between;
	align-items: center;
`;

CreatePostStyle.Body.Photo = styled.div`
	width: 45px;
	height: 45px;
	margin-right: 5px;
	> img {
		border-radius: 50%;
		width: 100%;
		height: 100%;
		object-fit: contain;
		border: 1px solid #e3e3e3;
	}
`;
CreatePostStyle.Body.Content = styled.div`
	width: 400px;
	textarea{
		width: 100%;
	}
`;
CreatePostStyle.Footer = styled.div`
	border-top-style: solid;
	border-top-color: #a3a3a3;
	height: 50px;
	display: flex;
	justify-content: space-between;
	align-items: center;
`;