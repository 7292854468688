import React from 'react';
import BusinessPlaceholder from 'images/placeholders/business.svg';
import { Icon } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import { Button, MembershipBadge } from 'components/atoms';
import verifiedSvg from 'images/subscriptions-badges/verified.svg';
import { BadgeSectionDesktop, BadgeWrapper, ButtonSection } from './style';

function buildBusinessLink(name, id) {
  return encodeURIComponent(encodeURIComponent((name + ' ' + id).replace(/ /g, '-')));
}

function ContentCardComponent({ business, history }) {
  let { contactInfo, general, businessID, subscription, verified, reactions } = business;

	return (
		<div className="container-home__card-comments container-home__card-comments-search">
			<div className="container-card-comments-search">
				<div className="container-card-comments-search-img">
          <Link to={`/business/${buildBusinessLink(general.companyName, businessID)}/`}>
					  <img src={general.profilePhoto?.url || BusinessPlaceholder} alt={`WBNB2B ${general.companyName} logo`} />
          </Link>
					<p style={{ textAlign: 'center' }}>{general.industry?.name}</p>
				</div>
				<div className="container-card-comments-search-header">
					<div className="container-card-comments-search-header-title">
						<h2>
							<Link to={`/business/${buildBusinessLink(general.companyName, businessID)}/`}>{general.companyName}</Link>
						</h2>
					</div>
					<div className="container-card-comments-search-header-tab">
						<p>
							{general.yearStablished || '----'} | {contactInfo.country.countryName} |{' '}
							{general.industry?.name} | {general.businessType.name}
						</p>
					</div>
					<div className="container-card-comments-search-header-text">
						<p>{general.shortDescription}</p>
					</div>
					<div className="container-card-comments-search-header-certificate">
						<div className="container-card-comments-search-header-certificate-likes">
							<Icon name="thumbs up" /> <span>{reactions?.reactionCount?.like || 0 } likes</span>
						</div>
						<div className="container-card-comments-search-header-certificate-images">
							<div>
								<MembershipBadge membershipType={subscription} />
							</div>
							<div>
								{verified ? <img src={verifiedSvg} alt="WBNB2B Verified Badge BUSINESS B2B" /> : null}
							</div>
						</div>
					</div>
				</div>
				<div className="container-card-comments-search-footer">
					<BadgeSectionDesktop>
						<BadgeWrapper>
							<MembershipBadge membershipType={subscription} />
						</BadgeWrapper>
						<BadgeWrapper>
							{verified ? <img src={verifiedSvg} alt="WBNB2B Verified Badge BUSINESS B2B" /> : null}
						</BadgeWrapper>
					</BadgeSectionDesktop>
					<ButtonSection>
						<Button flavor='secondary' onClick={() => history.push(`/business/${businessID}/send-request`)}>Send Request</Button>
						<Button flavor='secondary'>Save Business</Button>
					</ButtonSection>
				</div>
			</div>
		</div>
	);
}

export default ContentCardComponent;
