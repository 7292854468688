import React, { Fragment, useEffect } from 'react';
import { connect } from 'react-redux';
import { actionWatcher } from 'shared/actions';
import { GET_LAST_REGISTERED_WATCHER } from 'store/last-registered/constants';
import HomeWorld from 'components/molecules/wbn-map';
import WBNNetwork from 'components/molecules/wbn-network';
import { HelpSection, SearchSection, HeroSection, HomeBody, CardComments } from 'components';
import styled from 'styled-components';
import './styles.css';
import Helmet from 'react-helmet';

const ContainerLastestBusinesses = styled.div`
	display: flex;
	justify-content: space-around;
	flex-wrap: wrap;
`;
const Home = ({ getLastRegister, lastRegister, history }) => {
	useEffect(() => {
		getLastRegister();
	}, [getLastRegister]);
	return (
		<div className="container-home">
			<Helmet>
				<meta
					name="description"
					content={`${process.env.REACT_APP_SEO_FIRST} Business Guide, ${process.env.REACT_APP_SEO_FIRST} B2B manufacturing suppliers industry, made in ${process.env.REACT_APP_SEO_SECOND} manufacturer vendors for global wholesale B2B distributors, ${process.env.REACT_APP_SEO_FIRST} business guide is part of WBN industrial b2b social network, made in ${process.env.REACT_APP_SEO_SECOND} export products manufacturing business supply, ${process.env.REACT_APP_SEO_FIRST} products for wholesale business distributors in United States, Asia, Africa, Europe, South America, Latin America and Middle East distribution, WBN the industrial b2b social network for wholesalers and manufacturing private label industries, made in ${process.env.REACT_APP_SEO_SECOND} products manufacturer supplier for wholesalers and vendors of the market, industrial products for wholesale worldwide distributors, ${process.env.REACT_APP_SEO_FIRST} products manufacturing for private label buyers and new brand for manufacturer, business to business manufacturing supplies and market distributors, ${process.env.REACT_APP_SEO_FIRST} product manufacturing industry looking for worldwide distributors mainly in Germany, Denmark, Austria, Portugal, Spain, France, Norway, Sweden in Europe, China, Japan, Korea, Vietnam in Asia, California, New York, Florida, Chicago Illinois and all the United States of America, South Arabia, Qatar, Oman, UAE, Middle East, Australia, North of Africa, South America and all Latin America`}
				/>
				<meta
					name="keywords"
					content={`${process.env.REACT_APP_SEO_FIRST} manufacturing, ${process.env.REACT_APP_SEO_FIRST} distributors, ${process.env.REACT_APP_SEO_FIRST} manufacturer, ${process.env.REACT_APP_SEO_FIRST} buyers, ${process.env.REACT_APP_SEO_FIRST} business, ${process.env.REACT_APP_SEO_FIRST} vendors, ${process.env.REACT_APP_SEO_FIRST} social network, ${process.env.REACT_APP_SEO_FIRST} business guide, ${process.env.REACT_APP_SEO_SECOND} manufacturing suppliers`}
				/>
			</Helmet>
			<HelpSection />
			<SearchSection history={history} />
			<HomeBody />
			<HeroSection />
			<ContainerLastestBusinesses>
				<ContentCardCommments lastRegister={lastRegister} />
			</ContainerLastestBusinesses>
			<HomeWorld />
			<WBNNetwork></WBNNetwork>
		</div>
	);
};

const ContentCardCommments = ({ lastRegister }) => {
	let typeOfBusiness = {
		DI: [],
		MA: [],
		EX: [],
		CO: []
	};
	let { data } = lastRegister;
	for (let i = data.length - 1; i > 0; i--) {
		let businessType = data[i].general.businessType.code;
		if (businessType in typeOfBusiness) {
			typeOfBusiness[businessType].push(data[i]);
		}
	}
	return (
		<Fragment>
			<>
				<CardComments
					title="Latest Manufacturers registered in our B2B Social Network"
					typeOfBusiness={typeOfBusiness['MA'].slice(0, 3)}
				/>
				<CardComments
					title="Latest Distributors registered in our B2B Social Network"
					typeOfBusiness={typeOfBusiness['DI'].slice(0, 3)}
				/>
			</>
			<>
				<CardComments
					title="Latest Exporters registered in our B2B Social Network"
					typeOfBusiness={typeOfBusiness['EX'].slice(0, 3)}
				/>
				<CardComments
					title="Latest Consultants registered in our B2B Social Network"
					typeOfBusiness={typeOfBusiness['CO'].slice(0, 3)}
				/>
			</>
		</Fragment>
	);
};

const mapStateToProps = ({ listLastRegister }) => {
	return {
		lastRegister: listLastRegister
	};
};

const mapDispatchToProps = dispatch => {
	return {
		getLastRegister: () => dispatch(actionWatcher(GET_LAST_REGISTERED_WATCHER))
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Home);
