import 'react-day-picker/lib/style.css';
import React from 'react';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import { CaptionContainer } from './style';

const currentYear = new Date().getFullYear();
const fromMonth = new Date(currentYear - 10, 0);
const toMonth = new Date(currentYear + 10, 11);

function YearMonthForm({ date, localeUtils, onChange }) {
	const months = localeUtils.getMonths();

	const years = [];
	for (let i = fromMonth.getFullYear(); i <= toMonth.getFullYear(); i += 1) {
		years.push(i);
	}

	const handleChange = function handleChange(e) {
		const { year, month } = e.target.form;
		onChange(new Date(year.value, month.value));
	};

	return (
		<CaptionContainer>
			<select name="month" onChange={handleChange} value={date.getMonth()}>
				{months.map((month, i) => (
					<option key={month} value={i}>
						{month}
					</option>
				))}
			</select>
			<select name="year" onChange={handleChange} value={date.getFullYear()}>
				{years.map(year => (
					<option key={year} value={year}>
						{year}
					</option>
				))}
			</select>
		</CaptionContainer>
	);
}

export default function Datepicker(props) {
	const [month, setMonth] = React.useState();

	return (
		<DayPickerInput
			{...props}
			dayPickerProps={{
				month,
				fromMonth,
				toMonth,
				captionElement: ({ date, localeUtils }) => (
					<YearMonthForm date={date} localeUtils={localeUtils} onChange={setMonth} />
				),
				...props.dayPickerProps
			}}
		/>
	);
}
