import React, { useState } from 'react';
import { CommentSectionStyle } from './style';
import { TextArea, PostComment } from 'components';
import { postCommentOnEntity } from 'utils/social-api';
import useSWR, { useSWRPages } from 'swr';
import userProfilePlaceholder from 'images/icons/user_profile_placeholder.png';
import sendIcon from 'images/icons/send_2.png';
const createPostComment = async ({
	resourceID,
	commentContent,
	setCommentContent,
	revalidateComments,
	resourceType = 'post'
}) => {
	let content = commentContent.content;
	try {
		setCommentContent({ disabled: true, content: '' });
		let response = await postCommentOnEntity({ content, resourceType, resourceID });
		if (response && response.success) {
			setCommentContent({ disabled: false, content: '' });
			revalidateComments();
		} else {
			setCommentContent({ disabled: false, content: content });
		}
	} catch (error) {
		console.error('Error sending comment', error);
		setCommentContent({ disabled: false, content: content });
	}
};
const size = 5;
const CommentSection = ({ user, resourceID, resourceType }) => {
	const [commentContent, setCommentContent] = useState({ disabled: false, content: '' });
	const [thereAreMoreComments, setThereAreMoreComments] = useState(true);
	const { pages, isLoadingMore, pageSWRs, loadMore } = useSWRPages(
		'post-comments',
		({ offset, withSWR }) => {
			const url = 'social/comments/' + resourceID + `?skip=${offset || 0}&size=${size}`;
			// eslint-disable-next-line react-hooks/rules-of-hooks
			const { data: response } = withSWR(useSWR(url));

			if (!response) return null;

			const { data } = response.data;
			if (data.comments.length === 0) {
				setThereAreMoreComments(false);
			}else{
				setThereAreMoreComments(true);
			}
			return data.comments.map((comment, key) => {
				return <PostComment key={key} user={comment.whoComment} body={comment} />;
			});
		},
		(data, pageSWRs) => {
			return (pageSWRs + 1) * size;
		},
		[]
	);
	const revalidateComments = () => {
		pageSWRs.forEach(e => {
			e.revalidate();
		});
	};
	return (
		<CommentSectionStyle>
			<CommentSectionStyle.HeaderContainer>
				<CommentSectionStyle.Header>
					{user ? (
						<>
							<div style={{ display: 'flex' }}>
								<img src={user.profilePhoto?.url || userProfilePlaceholder} alt="post" />
							</div>
							<CommentSectionStyle.Header.TextArea>
								<TextArea
									rows={1}
									placeholder="Post your comment"
									showCounter={false}
									value={commentContent.content}
									disabled={commentContent.disabled}
									onChange={e => {
										setCommentContent({ disabled: false, content: e.target.value });
									}}
									onKeyPress={e => {
										if (e.key === 'Enter' && !e.shiftKey) {
											e.preventDefault();
											createPostComment({
												resourceID,
												commentContent,
												setCommentContent,
												revalidateComments: revalidateComments,
												resourceType
											});
										}
									}}
								/>
							</CommentSectionStyle.Header.TextArea>
							<CommentSectionStyle.Header.IconSend
								onClick={() => {
									if (!commentContent.disabled && commentContent.content) {
										createPostComment({
											resourceID,
											commentContent,
											setCommentContent,
											revalidateComments: revalidateComments,
											resourceType
										});
									}
								}}
							>
								<img src={sendIcon} alt="WBN Icon create comment - The industrial B2B social network" />
							</CommentSectionStyle.Header.IconSend>
						</>
					) : (
						<div>If you want to comment you must be loged in.</div>
					)}
				</CommentSectionStyle.Header>
			</CommentSectionStyle.HeaderContainer>
			<CommentSectionStyle.Body>
				<div>{pages}</div>
				<div
					style={{ fontSize: '12px', cursor: 'pointer', color: 'blue' }}
					onClick={() => {
						if (!isLoadingMore) {
							loadMore();
						}
					}}
				>
					{isLoadingMore ? 'Loading' : thereAreMoreComments ? 'Load more comments' : null}
				</div>
			</CommentSectionStyle.Body>
		</CommentSectionStyle>
	);
};

const CommentSectionWrapper = ({ resourceID, user, resourceType = 'post' }) => {
	return <CommentSection user={user} resourceID={resourceID} resourceType={resourceType} />;
};

export default CommentSectionWrapper;
