
import styled from 'styled-components';
import {Link} from 'react-router-dom';


export const Actions = styled.div`
  order: 2;
  display: flex;
  height: 32px;
  max-width: 35%;
  @media screen and (min-width: 768px) {
    order: 3;
    max-width: 40%;
  }
`;

export const ActionOption = styled(Link)`
  cursor: pointer;
  color: #61636d;
  & > i {
    font-size: 24px;
  }
`;

export const ActionOptionContainer = styled.div`
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  align-items: center;
  justify-content: center;
  img {
    width: 100%;
    height: 23px;
  }
  > span {
    display: none;
  }
  @media screen and (min-width: 768px) {
    img {
      width: 100%;
      height: 23px;
    }
    > span {
      display: block;
      font-size: 7px;
    }
  }
  @media screen and (min-width: 992px) {
    > span {
      font-size: 9px;
    }
  }
`;

export const ContainerUser = styled(ActionOptionContainer)`
position: relative;
.right {
  position: absolute;
  top: 80%;
}
i{
  margin: 0;
  color: #747373;
}
@media screen and (min-width: 768px) {
  > img {
    position: relative;
    top: -10px;
  }
  .right {
    top: 55%;
  }
}
@media screen and (min-width: 992px) {
  .right {
    top: 60%;
  }
}
`;