import React, { useState, useEffect } from 'react';
import { CSSTransition } from 'react-transition-group';
import { map } from 'lodash';
import { Link } from 'react-router-dom';
import './styles.less';

const SubCategories = ({ showSub, changeSubItem, subCategory, id }) => {
	const [changeSubItems, setChangeSubItem] = useState(changeSubItem);
	const [idCategory, setIdCategory] = useState(id);

	useEffect(() => {
		if (Object.keys(subCategory).length === 0) {
			setChangeSubItem(!changeSubItems);
		} else {
			setChangeSubItem(changeSubItems);
		}
		setChangeSubItem(changeSubItem);
		setIdCategory(id);
	}, [subCategory, changeSubItem, id, changeSubItems]);

	function handleFor(obj) {
		if (obj) {
			return Object.keys(obj).length;
		}
		return [];
	}

	function handleFilterSubcategory(obj) {
		let arrayResult = [];

		map(obj, (item, i) => {
			if (i < 6) {
				arrayResult.push(item);
			}
		});

		return arrayResult;
	}
	return (
		<div
			className="container-sub-categories"
			onMouseEnter={() => showSub('default', idCategory)}
			onMouseLeave={() => showSub('', idCategory)}
		>
			<CSSTransition in={changeSubItems} classNames="sidebar" timeout={300} unmountOnExit>
				<div className="container-sub-categories--main">
					<div className="container-sub-categories--main__grid">
						{map(handleFilterSubcategory(subCategory.categories), ({ id, name, subCategories }) => {
							return (
								<div className="container-sub-categories--main__grid__item" key={id}>
									<h5 className="container-sub-categories--main__grid__item-title">{name}</h5>
									<ul className="container-sub-categories--main__grid__item-list">
										{map(subCategories.slice(0, 6), ({ id, name }) => {
											return (
												<li key={id}>
                          <Link 
                            to={{ pathname: '/search-result', search: `?business=MA&countries=global&search=${encodeURIComponent(name)}` }}
                            onClick={() => showSub('', idCategory)}
                          >{name}</Link>
												</li>
											);
										})}
									</ul>
									{subCategories.length > 6?
										<div className="container-sub-categories--main__grid__item-bottom">
										<Link to="/industries" className="container-sub-categories--main__grid__item-bottom-more">
											View More
										</Link>
									</div>: null
									}
									
								</div>
							);
						})}
					</div>
					{handleFor(subCategory.categories) > 6 && (
						<div className="container-sub-categories--main__grid_button">
							<Link to="/">View More Categories</Link>
						</div>
					)}
				</div>
			</CSSTransition>
		</div>
	);
};

export default SubCategories;
