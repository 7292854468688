import { put, call } from 'redux-saga/effects';
import { map } from 'lodash';
import axios from 'axios';
import { SEARCH_PRODUCTS_SUCCESS, SEARCH_PRODUCTS_FAILED } from './constants';
import { actionSuccess, actionFailed } from 'shared/actions';

function searchProductApi(params) {
	return axios.request({
		url: `${process.env.REACT_APP_API_URL}search/product`,
		method: 'POST',
		data: params
	});
}

export function* searchProduct(action) {
	try {
		const {
			data: { data }
		} = yield call(searchProductApi, action.payload);
		const customData = {};
		customData.results = map(data.results, ({ name, description, industry,category, subCategory, photos, mainImage, businessProfile, specs, productID }) => {
			return {
				name,
				description,
				industry,
				category,
				subCategory,
				photos,
				mainImage,
				businessProfile,
				specs,
				productID
			};
		});
		customData.total_results = data.total_results;
		yield put(actionSuccess(SEARCH_PRODUCTS_SUCCESS, customData));
	} catch (error) {
		yield put(actionFailed(SEARCH_PRODUCTS_FAILED, []));
	}
}
