const textHeader = {
	"Standard": `At WBN we help companies, FOR FREE, to connect with B2B manufacturers, distributors and buyers to start and/or to improve business relationship, confidence, and profit at very low cost, making Import and Export trade available for everyone in few clicks. Anyone, after register and login will become a Free User of the online WBN Inc. technologies, including a full package of services as products showcase, online support, leads from customers, message center, complete Social Network features, and everything necessary to conquer the world market... Enjoy your business`,
	"Gold": `GOLD B2B SERVICES (WBN GOLD PACKAGE) At WBN we understand that many companies need an EXPRESS connection with B2B industries as manufacturers, distributors and buyers to Import or Export trade in a short time, for those companies we have defined our Gold Package of the online WBN Inc. technologies, including all features defined for Standard Package plus up to 20 Products showcase in your Business Profile, Business verification (third party), additional advertising boost, direct professional B2B assistance and much more... Enjoy your Gold`,
	"Premium": `PREMIUM B2B SERVICES (WBN PREMIUM PACKAGE) As industrial consultants WBN understands that more visibility and services is required by some industries, as part of their industrial marketing plan to Export worldwide, for those companies we have defined our Premium Package, including all features defined for Standard Package plus up to 50 Products showcase in your Business Profile, company verification (third party), additional advertising boost, direct professional B2B assistance and much more... Enjoy our Premium business services.`,
	"Platinum": `WBN has designed a very VIP package of B2B services to guarantee the highest visibility possible and customized services for consolidated companies that need to strengthen the business relationship with new distributors and global partners. Our Platinum Package includes full features defined for Standard Package plus up to 100 Products showcase, company verification (by third party), many advertising boost per month, direct professional B2B assistance and much more... Enjoy our VIP Platinum business services.`,
}
const textDABIE = {
	"Standard": "We are very glad to have as main mission to help everyone and everywhere with A GREAT B2B FEATURES FOR FREE to reach Export Goals... Enjoy it",
	"Gold": "Be first in the most Popular Search, increase your Visibility, launch more products, let's help you directly, boost your business and more features including in our GOLD SERVICES... Enjoy it",
	"Premium": "Export worldwide up to 50 products using our PREMIUM PACKAGE to have full visibility, direct B2B support, customized ADS and more WBN Industrial Export Features... Enjoy it",
	"Platinum": "Here we are with our VIP PLATINUM SERVICES to guarantee customized highest visibility and services to reach the most important Global Market AS THE NUMBER ONE to consolidate your business relationship with partners.",
}

const benefitsByMembership = {
	"Standard": [
		`A "User Profile" defining user description, personal information, basic business information, type of job and features to let the market know that you’re in business.`,
		`As User you can contact and send/receive "Business Request" to/from all the WBN Users and Businesses.`,
		`User has a professional "News feed" web page to follow updates from contacts and Businesses.`,
		`Each User will be able to create a "Business Profile".`,
		`A Business Profile will include the following webpages: "Business Profile", "Request Center", "Products", "Membership" and "Company Members".`,
		`The "Products" page will contain up to 07 Products to display in your Business Profile.`,
		`Each Product has a full technical description webpage (including pictures and videos).`,
		`The "Request Center" will be defined in a full professional webpage to manage (receive, answer, store) enquiries from Users or Businesses.`,
		`User can define company members to manage and develop his business.`,
		`User can like, follow, contact any User and Business listed in WBN.`,
		`User can create and manage open and private Business Groups.`,
		`User has access to all WBN Social Network features.`,
		`Online professional customer services`
	],
	"Gold": [
		`The "Gold Package" gives you up to 20 Products to display in your Business Profile`,
		`Each Product has a full technical description webpage (including pictures and videos).`,
		`Business Verification by WBN Inc. and/or Third party`,
		`Priority search results ranking (customized according to your needs)`,
		`Qty. 3 Advertising boost, per month, for your products/company`,
	],
	"Premium": [
		`The "Premium Package" gives you up to 50 Products to display in your Business Profile`,
		`Each Product has a full technical description webpage (including pictures and videos).`,
		`Business Verification by WBN Inc. and/or Third party`,
		`High Priority search results ranking (customized according to your needs)`,
		`Qty. 10 Advertising boost, per month, for your products/company`,
		`Qty. 06 hours of Dedicate "Professional B2B support"`,
	],
	"Platinum": [
		`The “Platinum Package” gives you up to 100 Products to display in your Business Profile`,
		`Each Product has a full technical description webpage (including pictures and videos).`,
		`Business Verification by WBN Inc. and/or Third party`,
		`High Priority search results ranking (customized according to your needs)`,
		`Qty. 20 Advertising boost, per month, for your products/company`,
		`Qty. 12 hours of “Dedicate Professional B2B support”`,
	]
}

export { textDABIE, benefitsByMembership, textHeader }