import { GET_LANGUAGES_WATCHER, GET_LANGUAGES_SUCCESS, GET_LANGUAGES_FAILED } from './constants';

const initialState = {
	isLoadingLanguages: true,
	data: []
};

export default (state = initialState, action) => {
	switch (action.type) {
		case GET_LANGUAGES_WATCHER:
			return {
				isLoadingLanguages: true,
				data: []
			};
		case GET_LANGUAGES_SUCCESS:
			return {
				isLoadingLanguages: false,
				data: action.payload
			};

		case GET_LANGUAGES_FAILED:
			return {
				isLoadingLanguages: false,
				data: []
			};

		default:
			return state;
	}
};
