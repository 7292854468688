import styled from 'styled-components';
const handleColorType = color => {
	switch (color) {
		case 'primary':
			return 'var(--wbn-primary-color)';
		case 'secondary':
			return 'var(--wbn-primary-color_navbar)';
		default:
			return '#b1b1b1';
	}
};
export default styled.div`
	border-radius: 0.5rem;
	border: none;
	color: white;
	display: block;
	font-family: Arial, Helvetica, sans-serif;
	font-size: 14px;
	font-style: normal;
	font-weight: 500;
	line-height: 1em;
	outline: none;
	text-align: center;
	text-decoration: none;
	background-color: ${props => handleColorType(props.flavour)};
	font-size: ${props=> props.fontSize || "14px"};

`;
