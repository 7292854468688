import React from 'react';

const Machineries = ({ fill = "white" }) => {
	return (
		<svg id="Capa_1" data-name="Capa 1"
			xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
			<title>ICONS</title>
			<path style={{fill}} d="M3.76,11.5H2c-.21,0-.25,0-.25-.28v-2c0-.23.05-.29.26-.29H5.56c.16,0,.23.07.23.26v2.06c0,.19-.06.25-.24.25Z" />
			<path style={{fill}} d="M16.21,20H8.87c-.2,0-.2,0-.2-.22V13.6c0-.5.22-.74.66-.74H23.12c.44,0,.65.24.65.73v6.22c0,.23,0,.23-.2.23Zm-3.7-5.74a2.26,2.26,0,0,0-2.28,2.12,2.28,2.28,0,0,0,4.56.18A2.26,2.26,0,0,0,12.51,14.3ZM19,15.53H21.4c.3,0,.3,0,.3-.39,0-.23,0-.23-.17-.23h-5c-.32,0-.31,0-.32.42,0,.17,0,.2.16.2Zm-2.1,2.19h.38c.11,0,.15,0,.15-.15s0-.49,0-.73,0-.15-.13-.15h-.75c-.1,0-.15,0-.15.15s0,.49,0,.74,0,.14.14.14Zm2.21,0h.38c.08,0,.12,0,.12-.12v-.78c0-.09,0-.13-.12-.12h-.78c-.08,0-.12,0-.12.12v.78c0,.09,0,.12.12.12Zm2.62-.51v-.36c0-.11,0-.16-.14-.16h-.73c-.1,0-.14,0-.14.14q0,.38,0,.75c0,.11,0,.14.14.14h.73c.1,0,.15,0,.14-.14Z" />
			<path style={{fill}} d="M16.22,21h7.33c.22,0,.22,0,.22.29v.63c0,.43-.23.7-.57.7H9.25c-.34,0-.57-.27-.57-.71,0-.24,0-.48,0-.72s0-.19.17-.19h7.38Z" />
			<path style={{fill}} d="M16.63,10.39h-4c-.25,0-.25,0-.25-.27s0-.81,0-1.21c0-.17,0-.21.2-.21,2,0,3.69,0,5.72,0,.25,0,.26,0,.29-.26a12,12,0,0,0,0-2.16c-.05-.85-.07-1-.17-1.86a1,1,0,0,1,1-1.24,1,1,0,0,1,.88.76c.08.47.11,1.6.16,2.07a15,15,0,0,0,.39,2.49c0,.21.35.15.38.21a.29.29,0,0,1,0,.16c0,.44,0,.88,0,1.32,0,.17-.06.2-.21.2Zm-3-.43a.51.51,0,1,0,0-1,.52.52,0,0,0-.51.52A.51.51,0,0,0,13.58,10Z" />
			<path style={{fill}} d="M21.84,9.47V7.24c0-.24.05-.29.28-.29h.54c.24,0,.28,0,.29.29,0,.44,0,.88,0,1.32,0,.14,0,.2.19.2h.57c.14,0,.19,0,.19.18,0,.45,0,.9,0,1.34,0,.15-.06.18-.19.17h-.57c-.15,0-.19.05-.19.19,0,.36,0,.72,0,1.08s0,.28-.28.28h-.56c-.23,0-.27,0-.27-.28Z" />
			<path style={{fill}} d="M14.83,7.34a.75.75,0,0,1-.68-.4.62.62,0,0,1,.15-.73c.52-.5,2.53-2.47,3.06-3a.22.22,0,0,1,.35,0c.2.21.39.42.58.64s.07.24,0,.34c-.38.36-2.24,2.2-2.63,2.57l-.37.35A.72.72,0,0,1,14.83,7.34Zm.29-.72a.29.29,0,0,0-.3-.32.31.31,0,0,0-.32.31.33.33,0,0,0,.32.32A.3.3,0,0,0,15.12,6.62Z" />
			<path style={{fill}} d="M11.32,9.61V8.74c0-.11,0-.17.12-.17.37,0,.46,0,.45.54,0,.39,0,.79,0,1.18s-.07.33-.26.33-.31,0-.31-.39Z" />
			<path style={{fill}} d="M14.16,8.24a1,1,0,0,1-.34-.13c-.13-.1-.15-.25-.05-.44s.09-.11,0-.2,0-.16,0-.21.1,0,.15,0a1.41,1.41,0,0,0,.56.21c.05,0,.13,0,.13.08a.13.13,0,0,1-.07.15c-.1,0-.09.1-.11.17S14.34,8.25,14.16,8.24Z" />
			<path style={{fill}} d="M10.86,7.17c0-.16-.06-.23-.23-.23H9.19C9,7,9,7,9,7.2q0,.69,0,1.38c0,.13,0,.18-.18.18h-1c-.14,0-.18,0-.18-.18,0-.36,0-.72,0-1.07a.88.88,0,0,0-.94-.94c-.35,0-5.27,0-5.57,0a.9.9,0,0,0-1,.94c0,2.36,0,4.72,0,7.07v7.16a.83.83,0,0,0,.87.89H6.67a.78.78,0,0,0,.47-.13.94.94,0,0,0,.4-.84v-11c0-.15,0-.22.2-.21h1c.16,0,.2.05.2.2,0,.38,0,.76,0,1.14,0,.15.07.22.23.21h1.47c.15,0,.22-.06.22-.21Zm-9,10.27a.57.57,0,0,1-.57-.58.56.56,0,0,1,.57-.57.57.57,0,0,1,.58.57A.58.58,0,0,1,1.86,17.44Zm3.85-1.08a.67.67,0,0,1-.07.31.35.35,0,0,1-.22.2.56.56,0,0,1,.29.49v.5a1,1,0,0,1-.25.73,1,1,0,0,1-.74.26h-2v-1H4.47c.16,0,.24-.07.24-.23a.28.28,0,0,0-.06-.18.22.22,0,0,0-.18-.08H2.73v-1H4.47c.16,0,.24-.07.24-.23a.33.33,0,0,0-.06-.19.25.25,0,0,0-.18-.08H2.73v-1h2a1,1,0,0,1,.73.26,1,1,0,0,1,.26.74Zm1.21-4.3c0,.19,0,.26-.23.26H.94c-.18,0-.24-.05-.24-.25,0-1.25,0-2.49,0-3.74,0-.22.07-.26.25-.26H6.66c.19,0,.26,0,.26.28C6.91,9.58,6.91,10.82,6.92,12.06Z" />
			<path style={{fill}} d="M1.84,17,1.9,17,2,16.91,2,16.85a.16.16,0,0,0,0-.07.19.19,0,0,0,0-.08A.18.18,0,0,0,2,16.56a.23.23,0,0,0-.16,0,.25.25,0,0,0-.15,0,.24.24,0,0,0-.07.15h.13a.09.09,0,0,1,0-.07l.06,0c.06,0,.09,0,.09.08a.09.09,0,0,1,0,.07.46.46,0,0,1-.1.1L1.72,17a.44.44,0,0,0-.08.11v.09H2.1v-.11H1.8Z" />
			<rect style={{fill}} x="17.05" y="1.26" width="2.41" height="4.05" rx="0.89" ry="0.89" transform="translate(7.67 -11.94) rotate(45)" />
			<path style={{fill: "none"}} d="M5.71,17.86a1,1,0,0,1-.25.73,1,1,0,0,1-.74.26h-2v-1H4.47c.16,0,.24-.07.24-.23a.28.28,0,0,0-.06-.18.22.22,0,0,0-.18-.08H2.73v-1H4.47c.16,0,.24-.07.24-.23a.33.33,0,0,0-.06-.19.25.25,0,0,0-.18-.08H2.73v-1h2a1,1,0,0,1,.73.26,1,1,0,0,1,.26.74v.49a.67.67,0,0,1-.07.31.35.35,0,0,1-.22.2.56.56,0,0,1,.29.49Z" />
			<path style={{fill: "none"}} d="M5.71,17.86a1,1,0,0,1-.25.73,1,1,0,0,1-.74.26h-2v-1H4.47c.16,0,.24-.07.24-.23a.28.28,0,0,0-.06-.18.22.22,0,0,0-.18-.08H2.73v-1H4.47c.16,0,.24-.07.24-.23a.33.33,0,0,0-.06-.19.25.25,0,0,0-.18-.08H2.73v-1h2a1,1,0,0,1,.73.26,1,1,0,0,1,.26.74v.49a.67.67,0,0,1-.07.31.35.35,0,0,1-.22.2.56.56,0,0,1,.29.49Z" />
			<circle style={{fill: "none", stroke: fill,"strokeMiterlimit":10, "strokeWidth":"0.5px"}} cx="12.51" cy="16.52" r="1.49" />
		</svg>
	)
}

export default Machineries;
