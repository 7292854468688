
import styled from 'styled-components';


export const Section = styled.div`
width: 100%;
order: 3;
.ui.selection.dropdown .menu > .item > i {
	margin-right: 2px;
}
@media screen and (min-width: 768px) {
	width: auto;
	order: 2;
}

@media screen and (min-width: 992px) {
	width: 100%;
}
`;

export const Body = styled.div`
  margin: 15px auto;
	width: 100%;
	display: flex;
	justify-content: center;
  flex-direction: column;
  > .ui.icon.input > input {
    width: 100%;
    border-radius: 15px !important;
  }
  @media screen and (min-width: 768px) {
    width: 90%;
  }
  @media screen and (min-width: 992px) {
    flex-direction: row;
		max-width: 763px;
    width: 100%;
    > .ui.icon.input {
      width: 100%;
    }
    > .ui.icon.input > input {
      width: 100%;
      border-radius: 0 15px 15px 0 !important;
    }
  }
`;

export const BodySelect = styled.div`
display: flex;
justify-content: space-between;
margin-bottom: 8px;
> div:first-child {
	border-radius: 15px 0 0 15px;
}
> div:first-child[aria-expanded='true'] {
	border-bottom-left-radius: 0;
	border-bottom-right-radius: 0;
}
> div:last-child {
	border-radius: 0 15px 15px 0;
}
> div:last-child[aria-expanded='true'] {
	border-bottom-left-radius: 0;
	border-bottom-right-radius: 0;
}
> div {
	min-width: 50% !important;
}
@media screen and (min-width: 992px) {
  margin-bottom: 0;
  width: 70%;
  > div {
		height: 100%;
  }
  > div:last-child {
		border-radius: 0 !important;
  }
  > div:first-child, > div:last-child {
    margin-right: 0;
		margin-left: 0;
		border-right: none !important;
  }
}
`;