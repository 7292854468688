
import styled from 'styled-components';


export const Section1 = styled.div`
  margin-bottom: 30px;
`;

export const Secion1Title = styled.p`
  text-align: center;
  font-size: 12px;
`;

export const Section1Description = styled.p`
  text-align: center;
  font-size: 12px;
`;


export const GraphicSection = styled.div`
  margin-bottom: 30px;
`;

export const GraphicTitle = styled.p`
  font-size: 12px;
  text-align: center;
  margin-bottom: 30px;
`;

export const GraphicDescription = styled.p`
  font-size: 12px;
  text-align: center;
  margin-top: 30px;
  margin-bottom: 30px;
`;

export const SectionContainer = styled.div`
  margin-bottom: 30px;
`;

export const SecctionTitle = styled.p`
  padding: 5px 5px;
  margin: 30px auto;
  background-color: var(--wbn-primary-color);
  width: auto;
  min-width: auto;
  height: auto;
  display: flex;
  align-items: center;
  border-radius: 4px;
  color: #fff;
  font-size: 12px;
  font-weight: bold;
  text-align: center;
  justify-content: center;
  max-width: ${props => props.maxWidth || 'none'};
`;

export const SectionBody = styled.div`
  background-color: #efeaf1;
  padding: 30px;
  margin-left: 0px;
`;

export const SectionTwoColumns = styled.div`
  display: flex;

  > :first-child {
    margin-right: 30px;
    flex-grow: 1;
    flex-basis: 0;
  }

  > :last-child {
    margin-left: 30px;
    flex-grow: 1;
    flex-basis: 0;
  }
`;

export const SectionBody1 = styled.div`
  background-color: #efeaf1;
  padding: 30px;
  margin-left: 0px;
  display: flex;
  justify-content: space-between;

  > :first-child {
    flex-grow: 1;
  }

  > :last-child {
    flex-grow: 1;
  }
`;

export const SectionBodyImage = styled.div`
  margin-left: 30px;
  margin-right: 30px;
  width: ${props => props.width || '157px'};
  max-width: ${props => props.width || '157px'};
  height: ${props => props.height || '171px'};
  max-height: ${props => props.height || '171px'};
`;

export const SectionBody2 = styled.div`
  background-color: #efeaf1;
  padding: 30px;
  margin-left: 0px;
  display: flex;
`;

export const SectionBodyImageLeft = styled.div`
  margin-right: 30px;
  width: ${props => props.width || '157px'};
  max-width: ${props => props.width || '157px'};
  height: ${props => props.height || '171px'};
  max-height: ${props => props.height || '171px'};
`;

export const SectionBodyImageRight = styled.div`
  margin-left: 30px;
  width: ${props => props.width || '157px'};
  max-width: ${props => props.width || '157px'};
  height: ${props => props.height || '171px'};
  max-height: ${props => props.height || '171px'};
`;