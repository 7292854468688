import React from 'react';
import { put, call } from 'redux-saga/effects';
import { map } from 'lodash';
import axios from 'axios';
import { GET_COUNTRIES_SUCCESS, GET_COUNTRIES_FAILED } from './constants';
import { actionSuccess, actionFailed } from 'shared/actions';

function getCountriesApi() {
	return axios.request({
		url: `${process.env.REACT_APP_API_URL}ref-data/country`,
		method: 'GET'
	});
}

export function* getCountriesSaga(action) {
	try {
		const {
			data: { data }
		} = yield call(getCountriesApi);
		const customData = map(data, ({ code, countryName, emoji }) => {
			return {
				key: code,
				// text: `${emoji}${countryName}`,
				text: countryName,
				value: code,
				icon: <i>{emoji}</i>
			};
		});

		yield put(actionSuccess(GET_COUNTRIES_SUCCESS, customData));
	} catch (error) {
		yield put(actionFailed(GET_COUNTRIES_FAILED, []));
	}
}
