import React, { useState } from 'react';
import useSWR from 'swr';
import { filter } from 'lodash';
import apiClient from 'api-client';
import { HashLink as Link } from 'react-router-hash-link';
import { Loader, Media, Button, ViewMoreText, SummaryCardBusiness, Icon, InlineIcon } from 'components';

import HomeWorld from 'components/molecules/wbn-map';
import isBusinessEditable from 'utils/is-business-editable';
import { everyIsComplete, someHasError } from 'utils/requests';
import Helmet from 'react-helmet';
import {
	ButtonsContainer,
	HeadingContainer,
	DescriptionContainer,
	TextContainer,
	SummaryCardContainer,
	TextContentContainer,
	SocialIconsContainer,
	CenterBox,
	BusinessVideo
} from './style';

import coverImg from 'images/placeholders/cover-business-profile.svg';
import coverImgEmpty from 'images/placeholders/cover-business-profile_empty.svg';
import usePreDefinedToasts from 'hooks/use-pre-defined-toasts';
import likeIcon from 'images/icons/likes.svg';
import likedIcon from 'images/icons/liked.svg';
import followersIcon from 'images/icons/followers.svg';
import shareIcon from 'images/icons/share.svg';
import commentIcon from 'images/icons/comment.svg';
import pencilIcon from 'images/icons/pencil.svg';
import membershipIcon from 'images/icons/membership.svg';
import followButtonIcon from 'images/icons/circles/followButton.svg';
import followedButtonIcon from 'images/icons/circles/followedButton.svg';
import connectButtonIcon from 'images/icons/circles/connectButton.svg';
/*import connectedButtonIcon from 'images/icons/circles/connectedButton.svg';*/

import Products from './products';
import InformationSections from './information-sections';

function getTitle(businessProfile) {
	const firstPart = businessProfile.general?.companyName;

	const lastPart = filter(
		[businessProfile.contactInfo?.city, businessProfile.contactInfo?.country?.countryName],
		Boolean
	).join(', ');

	if (!lastPart) {
		return firstPart;
	}

	return `${firstPart} / ${lastPart}`;
}

function VisitorMenu({ businessId }) {
	return (
		<ButtonsContainer>
			<Link smooth to="#info">
				<Button>Business Profile</Button>
			</Link>
			<Link to={`/business/${businessId}/send-request`}>
				<Button>Send Request</Button>
			</Link>
			<Link smooth to="#products">
				<Button>Company Products</Button>
			</Link>
		</ButtonsContainer>
	);
}

function UserMenu({ editMode, businessId }) {
	const pencil = <img src={pencilIcon} alt="pencil" height={15} style={{ marginLeft: 5 }} />;
	const membership = <img src={membershipIcon} alt="pencil" height={15} style={{ marginLeft: 5 }} />;

	return (
		<ButtonsContainer>
			<Link smooth to={editMode ? `/business/${businessId}/edit` : '#info'}>
				<Button>
					{editMode ? 'Manage Profile' : 'Business Profile'}
					{editMode && pencil}
				</Button>
			</Link>
			<Link to={`/business/${businessId}/requests`}>
				<Button>Requests</Button>
			</Link>
			<Link smooth to={editMode ? `/business/${businessId}/products` : '#products'}>
				<Button>
					{editMode ? 'Products' : 'Company products'}
					{editMode && pencil}
				</Button>
			</Link>
			{editMode && (
				<Link to={`/business/${businessId}/membership`}>
					<Button>
						Membership
						{membership}
					</Button>
				</Link>
			)}
			{editMode && (
				<Link to={`/business/${businessId}/members`}>
					<Button>
						Members
						{pencil}
					</Button>
				</Link>
			)}
		</ButtonsContainer>
	);
}

function BusinessProfile({
	businessProfile,
	businessSession,
	products,
	social,
	history,
	editMode,
	myReaction,
	follow
}) {
	const { showSuccessToast /*showFailureToast*/ } = usePreDefinedToasts();
	const editable = isBusinessEditable(businessSession);

	const showEditMode = editable && editMode;

	const title = getTitle(businessProfile);
	const [likedBusiness, setLikedBusiness] = useState();
	const [likeCounter, setLikeCounter] = useState(social.reactionCount?.like || 0);
	const [followStatus, setFollowStatus] = useState({
		counter: follow?.summary?.followersCount || 0,
		followed: !!follow?.session
	});
	useState(() => {
		window.scrollTo(0, 0);
		if (myReaction) {
			setLikedBusiness(myReaction?.data && myReaction.data?.reactionType === 'like');
		}
	}, myReaction);
	const handleClickLike = async ({ targetResourceID, businessID, reactionType = 'like' }) => {
		try {
			const data = {
				resourceID: targetResourceID,
				businessID: businessID,
				reactionType: reactionType
			};
			if (likedBusiness) {
				setLikedBusiness(prevState => !prevState);
				setLikeCounter(prevState => prevState - 1);
				try {
					await apiClient.delete(`/social/reaction/react/business`, {
						data: { resourceID: data.resourceID }
					});
				} catch {
					setLikedBusiness(prevState => !prevState);
					setLikeCounter(prevState => prevState + 1);
				}
			} else {
				setLikedBusiness(prevState => !prevState);
				setLikeCounter(prevState => prevState + 1);
				try {
					await apiClient.post(`/social/reaction/react/business`, data);
				} catch {
					console.log('Error, returning back');
					setLikedBusiness(prevState => !prevState);
					setLikeCounter(prevState => prevState - 1);
				}
			}
		} catch (err) {
			console.error('Some error', err);
		}
	};

	const handleClickFollow = async ({ targetResourceID }) => {
		try {
			const data = {
				resourceID: targetResourceID
			};
			if (followStatus.followed) {
				setFollowStatus(prevState => ({ counter: prevState.counter - 1, followed: !prevState.followed }));
				try {
					await apiClient.delete(`/social/follow/${targetResourceID}`);
				} catch {
					setFollowStatus(prevState => ({ counter: prevState.counter + 1, followed: !prevState.followed }));
				}
			} else {
				setFollowStatus(prevState => ({ counter: prevState.counter + 1, followed: !prevState.followed }));
				try {
					await apiClient.post(`/social/follow/business`, data);
				} catch {
					console.log('Error, returning back');
					setFollowStatus(prevState => ({ counter: prevState.counter - 1, followed: !prevState.followed }));
				}
			}
		} catch (err) {
			console.error('Some error', err);
		}
	};
	/*const handleClickConnect = async ({ targetResourceID }) => {
		try {
			const data = {
				resourceID: targetResourceID
			};
			if (followStatus.followed) {
				setFollowStatus(prevState => ({ counter: prevState.counter - 1, followed: !prevState.followed }));
				try {
					await apiClient.delete(`/social/follow/${targetResourceID}`);
				} catch {
					setFollowStatus(prevState => ({ counter: prevState.counter + 1, followed: !prevState.followed }));
				}
			} else {
				setFollowStatus(prevState => ({ counter: prevState.counter + 1, followed: !prevState.followed }));
				try {
					await apiClient.post(`/social/follow/business`, data);
				} catch {
					console.log('Error, returning back');
					setFollowStatus(prevState => ({ counter: prevState.counter - 1, followed: !prevState.followed }));
				}
			}
		} catch (err) {
			console.error('Some error', err);
		}
	};*/
	const handleClickShare = async e => {
		let input = document.createElement('input');
		input.setAttribute('value', window.location.href);
		input.setAttribute('type', 'text');
		document.body.appendChild(input);
		input.select();
		document.execCommand('Copy');
		document.body.removeChild(input);
		showSuccessToast('Business Link copied!');
	};
	const LikeButton = () => {
		let likeStatement = `likes`;
		if (likeCounter === 1) {
			likeStatement = likeStatement.slice(0, -1);
		}
		let icon = likedBusiness ? likedIcon : likeIcon;
		return (
			<InlineIcon
				onClick={() => handleClickLike({ targetResourceID: businessProfile.businessID })}
				src={icon}
				number={likeCounter}
				text={likeStatement}
			/>
		);
	};
	const businessSummaryRendered = (
		<SummaryCardContainer>
			<SummaryCardBusiness businessProfile={businessProfile} businessSession={businessSession} />
		</SummaryCardContainer>
	);

	return (
		<div>
			<Helmet>
        <title>{`${businessProfile.general?.companyName}, ${businessProfile.general?.shortDescription.slice(0, 64)}, ${process.env.REACT_APP_SEO_FIRST}, WBNB2B`}</title>
				<meta
					name="description"
					content={`${businessProfile.general?.companyName} - ${businessProfile.general?.shortDescription} | Business Description | In WBNB2B you will find Manufacturers, Wholesalers, Importers and Exporters`}
				/>
			</Helmet>
			<HeadingContainer>
				<DescriptionContainer>
					{editable ? (
						<UserMenu editMode={showEditMode} businessId={businessProfile.businessID} />
					) : (
						<VisitorMenu businessId={businessProfile.businessID} />
					)}
					<TextContainer>
						<TextContentContainer>
							<p style={{ textAlign: 'center' }}>{title}</p>
							<Media
								xs={
									<ViewMoreText maxPreviewChars={340}>
										{businessProfile.general?.generalDescription}
									</ViewMoreText>
								}
								md={<p style={{ textAlign: 'justify' }}>{businessProfile.general?.generalDescription}</p>}
								lg={<p style={{ textAlign: 'justify' }}>{businessProfile.general?.generalDescription}</p>}
							/>
							<div className="cover-profile-container">
								<img
									src={businessProfile.general?.coverPhoto?.url || (editable ? coverImg : coverImgEmpty)}
									alt="cover profile"
								/>
							</div>
							<SocialIconsContainer>
								<div className="inline-icons-container">
									<LikeButton />
									<InlineIcon src={followersIcon} number={followStatus.counter} text={`Followers`} />
									<InlineIcon src={commentIcon} text="B Comments" />
									<InlineIcon src={shareIcon} text="B Share" onClick={handleClickShare} />
								</div>
								<div className="circle-icons-container">
									{editable ? (
										<Button
											style={{ margin: 0 }}
											onClick={() => {
												history.push(`?editMode=${editMode ? 0 : 1}`);
												window.scrollTo(0, 0);
											}}
										>
											Edit profile
										</Button>
									) : (
										<>
											<Icon
												onClick={() => handleClickLike({ targetResourceID: businessProfile.businessID })}
												src={likeIcon}
												text="Like"
												isActive={likedBusiness}
												activeSrc={likedIcon}
												isCircle={false}
											/>
											<Icon
												onClick={() => handleClickFollow({ targetResourceID: businessProfile.businessID })}
												src={followButtonIcon}
												text="Follow"
												isActive={followStatus.followed}
												activeSrc={followedButtonIcon}
											/>
											<Icon src={connectButtonIcon} text="Connect" />
										</>
									)}
								</div>
							</SocialIconsContainer>
						</TextContentContainer>
						<Media md={businessSummaryRendered} />
					</TextContainer>
				</DescriptionContainer>
				<Media lg={businessSummaryRendered} xs={businessSummaryRendered} />
			</HeadingContainer>
			<CenterBox>{title}</CenterBox>
			<InformationSections id="info" businessProfile={businessProfile} />
			<Products id="products" companyName={businessProfile.general?.companyName} dataSource={products} />
			{businessProfile.general?.video?.url ? (
				<BusinessVideo>
					<video controls src={businessProfile.general?.video?.url} />
				</BusinessVideo>
			) : null}
			<HomeWorld />
		</div>
	);
}

export default function BusinessProfilePage(props) {
  let businessId = props.match.params.id;
  businessId = businessId.slice(Math.max(businessId.length - 36, 0));

	const profile = useSWR(`business/profile/${businessId}`);
	const products = useSWR(`product/by-business?bid=${businessId}`);
	const social = useSWR(`social/reaction/summary/${businessId}`);
  const follow = useSWR(`social/follow/${businessId}/summary`);

	const responses = [profile, products, social];
	const myReaction = useSWR(`social/reaction/check/${businessId}`);
	if (someHasError(responses)) {
		return 'An unexpected error has occurred, please try refreshing the page.';
	}
	if (!everyIsComplete(responses)) {
		return <Loader text="Loading the business profile..." />;
	}
	const urlParams = new URLSearchParams(props.history.location.search);

	return (
		<BusinessProfile
			businessProfile={profile.data.data.data}
			businessSession={profile.data.data.session}
			products={products.data.data.data}
			social={social.data?.data.data}
			myReaction={myReaction?.data?.data}
			follow={follow.data?.data.data}
			history={props.history}
			editMode={!!Number(urlParams.get('editMode')) || false}
		/>
	);
}
