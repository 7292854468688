import React from 'react';


const BeautyCare = ({fill = 'white'}) => {
	return (
	<svg id="Capa_1" data-name="Capa 1"
		xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
		<defs>
			
		</defs>
		<title>ICON_BEAUTY_CARE</title>
		<path style={{fill}} d="M12.06,14.4a2.18,2.18,0,0,1-.65-.09.27.27,0,0,1-.18-.35.29.29,0,0,1,.35-.18,1.41,1.41,0,0,0,1-.07A.52.52,0,0,0,13,13.2a.28.28,0,0,1,.22-.33.29.29,0,0,1,.33.22,1.09,1.09,0,0,1-.6,1.1A1.69,1.69,0,0,1,12.06,14.4Z" />
		<path style={{fill}} d="M11.91,17.94a3.51,3.51,0,0,1-1.05-.15l-.25-.08-.19-.08-.05,0a3.55,3.55,0,0,1-.46-.28.32.32,0,0,1,0-.11.27.27,0,0,1,0-.11.17.17,0,0,1,.14-.06h0c.31.05.62.09,1,.12s.62,0,.89,0H12V17L12,17l-.09,0a.79.79,0,0,0-.27-.1h-.31a2,2,0,0,0-.31.05l-.33.09a2.1,2.1,0,0,1-.75,0,1.77,1.77,0,0,1-.71-.26.14.14,0,0,1-.06-.08.21.21,0,0,1,0-.13.15.15,0,0,1,.15-.07h0a2.3,2.3,0,0,0,.63,0,2.12,2.12,0,0,0,.54-.13l.14-.06.19-.07a1.84,1.84,0,0,1,.42-.1,2.44,2.44,0,0,1,.47,0,1.14,1.14,0,0,1,.25.06h.06l0-11.71c-1.26,1.11-3,.15-4,.49-2.84.78-3,4.31-3,4.31.69,10.75,7,11.45,7,11.45V17.94h-.13ZM7,9a1.26,1.26,0,0,1,.37-.32,1.56,1.56,0,0,1,.79-.27,5.49,5.49,0,0,1,1.07,0l1.19.16a.57.57,0,0,1,.36.16.27.27,0,0,1-.08.44,1.06,1.06,0,0,1-.33.08,2.41,2.41,0,0,1-.72-.09A5.13,5.13,0,0,0,9,9a4.07,4.07,0,0,0-1.73.25.24.24,0,0,1-.23,0A.22.22,0,0,1,7,9Zm1,2a2,2,0,0,1-.75-.6l-.07-.08,0-.05A.28.28,0,0,1,7,10a.21.21,0,0,1,.15-.12.2.2,0,0,1,.18,0l.05,0a3,3,0,0,0,.35.19,2.24,2.24,0,0,0,1.07.16l.26,0a6.66,6.66,0,0,1,.9,0,2.1,2.1,0,0,1,.59.08.29.29,0,0,1,.22.31.29.29,0,0,1-.28.3.78.78,0,0,0-.42.14,1.59,1.59,0,0,1-.78.27h0A3.32,3.32,0,0,1,7.92,11Z" />
		<path style={{fill}} d="M18.42,6.87c0-.1,0-.18,0-.22l-.12-.32c-.05-.12.5-2.44-3.38-2.65l-.24.83c1.66.76,2.46,1.17,2.75,2.29a1,1,0,0,1,.08.24c1.06,6.15-1,10.74-5.81,12.94l.36.8C17.27,18.4,19.54,13.46,18.42,6.87Z" />
		<path style={{fill}} d="M13.68,9.28a2.09,2.09,0,0,0,.72-.09A5,5,0,0,1,15.07,9a3.88,3.88,0,0,1,1.72.23.26.26,0,0,0,.23,0,.21.21,0,0,0,0-.31,1.89,1.89,0,0,0-.37-.32,1.71,1.71,0,0,0-.8-.25,5.52,5.52,0,0,0-1.07,0l-1.19.17a.59.59,0,0,0-.35.18.26.26,0,0,0,.08.43A.89.89,0,0,0,13.68,9.28Z" />
		<path style={{fill}} d="M14.71,11.15A3.06,3.06,0,0,0,16,10.9a1.9,1.9,0,0,0,.71-.56l.11-.13a.12.12,0,0,0,0-.17.09.09,0,0,0-.14,0,3.36,3.36,0,0,1-.42.22,2.42,2.42,0,0,1-1.13.17A10.88,10.88,0,0,0,14,10.33a2.7,2.7,0,0,0-.56.08.17.17,0,0,0-.13.18.15.15,0,0,0,.15.16,1.05,1.05,0,0,1,.49.16A1.38,1.38,0,0,0,14.71,11.15Z" />
		<path style={{fill}} d="M12.48,7.5a2.8,2.8,0,0,0,.49,0h.35l.11,0a7,7,0,0,0,.9-.15l.12,0,.11,0,.23,0,.23,0h.1l.11,0a1.19,1.19,0,0,1,.27-.07,1.34,1.34,0,0,1,.28,0,1.47,1.47,0,0,1,.52.12v0a2.32,2.32,0,0,0-.94.19,4.76,4.76,0,0,1-.95.3,3.69,3.69,0,0,1-1,.09,2,2,0,0,1-1-.26Z" />
		<path style={{fill}} d="M15.08,6.09a.6.6,0,0,1-.06.19l-.1.17a1.28,1.28,0,0,1-.29.28,1.81,1.81,0,0,1-.75.29,2.54,2.54,0,0,1-.39,0h-.1L13.3,7h-.09l-.08,0a2.86,2.86,0,0,1-.67-.26v0a2.74,2.74,0,0,1,.7,0l.35,0h.35a2.16,2.16,0,0,0,.66-.18,1.46,1.46,0,0,0,.54-.41Z" />
		<path style={{fill}} d="M14.52,5.42a.49.49,0,0,1-.14.34.8.8,0,0,1-.29.23,1.52,1.52,0,0,1-.68.17l-.31,0-.29,0L12.52,6l-.3-.08,0,0,.07,0,.08,0,.15,0c.1,0,.21,0,.31,0h.61a2.46,2.46,0,0,0,.6-.07,1.59,1.59,0,0,0,.27-.12.67.67,0,0,0,.21-.2Z" />
		<path style={{fill}} d="M14.54,20.05s-.49,2.6,1.41,3.13c0,0-4.43,1.6-7.35-.1a2.2,2.2,0,0,0,1.25-2.54,4.79,4.79,0,0,0,2.12,1A5.71,5.71,0,0,0,14.54,20.05Z" />
		<path style={{fill}} d="M13.16,1.29c-.49-1-2.43-1.12-3-1.16A5.8,5.8,0,0,0,4.3,3.93c-.94,2.78-.17,3.86-.9,7.22C3.13,12.41,3,13,3.4,12.72a3.25,3.25,0,0,0,1-2.28c0-1.06.23-3.88,1.7-5.31,1.7-1.66,2.69-1,4.48-1C13.39,3.74,13.57,2.1,13.16,1.29ZM10.77,2.37c-3-.7-5.31,2.14-5.31,2.14a4.72,4.72,0,0,1,6.11-2.8C11.83,1.81,13.78,3.07,10.77,2.37Z" />
		<path style={{fill}} d="M4.7,10.84s.7.82.24,3.66C4.69,16,6.73,17.18,7.07,19.73A6.59,6.59,0,0,1,6.48,24s-.11-3.27-1.79-5-1.31-3.28-1-4.92C4,12.7,4.73,12.3,4.7,10.84Z" />
		<path style={{fill}} d="M4.5,2.65s-1.21,1.1-1.21,4c0,1,0,1.75-.13,2.68-.11,1.26-.78,2.26-.32,4.62.34,2.82-1.18,2.69,1.08,5.39s.5,4.08.5,4.08.88-.83-.58-2.33C2.6,19.79,1,18,1.63,15c.44-2.24-.5-3.88.28-5.25S2.88,4.41,4.5,2.65Z" />
		<path style={{fill}} d="M20.57,14.07c-1.2-5.3.16-6.56-1.32-9.55-1.07-2.17-1.82-3.16-5.06-4,0,0,1.4,1.73.44,2.87s-.09,1.24.21,1.41S19,5,18.18,9a20,20,0,0,0-.28,6.17c.1,1.59-3.71,4.4,0,7.24C19.38,23.57,19.51,24,19.51,24s.79-1.06-.82-2.93C17.53,19.73,21.35,17.53,20.57,14.07Z" />
		<path style={{fill: "none"}} d="M14.81,16.62a.16.16,0,0,0-.2-.1,2.2,2.2,0,0,1-.64.09,1.42,1.42,0,0,1-.54-.08l-.06,0-.1-.06a1.51,1.51,0,0,0-.48-.22,1.39,1.39,0,0,0-.54,0,1.11,1.11,0,0,0-.25.05h0a1.14,1.14,0,0,0-.25-.06,2.44,2.44,0,0,0-.47,0,1.84,1.84,0,0,0-.42.1l-.19.07-.14.06a2.12,2.12,0,0,1-.54.13,2.3,2.3,0,0,1-.63,0h0a.15.15,0,0,0-.15.07.21.21,0,0,0,0,.13.14.14,0,0,0,.06.08,1.77,1.77,0,0,0,.71.26,2.1,2.1,0,0,0,.75,0l.33-.09a2,2,0,0,1,.31-.05h.31a.79.79,0,0,1,.27.1L12,17l.07,0a.87.87,0,0,1,.57-.08.94.94,0,0,1,.28.1,1.3,1.3,0,0,0,.38.15,1.1,1.1,0,0,0,.26,0h.06a1.82,1.82,0,0,0,.45-.05,1.91,1.91,0,0,0,.7-.34l.07-.08Z" />
		<path style={{fill: "none"}} d="M13.6,17.18h0Z" />
		<path style={{fill: "none"}} d="M14.81,16.62a.16.16,0,0,0-.2-.1,2.2,2.2,0,0,1-.64.09,1.42,1.42,0,0,1-.54-.08l-.06,0-.1-.06a1.51,1.51,0,0,0-.48-.22,1.39,1.39,0,0,0-.54,0,1.11,1.11,0,0,0-.25.05h0a1.14,1.14,0,0,0-.25-.06,2.44,2.44,0,0,0-.47,0,1.84,1.84,0,0,0-.42.1l-.19.07-.14.06a2.12,2.12,0,0,1-.54.13,2.3,2.3,0,0,1-.63,0h0a.15.15,0,0,0-.15.07.21.21,0,0,0,0,.13.14.14,0,0,0,.06.08,1.77,1.77,0,0,0,.71.26,2.1,2.1,0,0,0,.75,0l.33-.09a2,2,0,0,1,.31-.05h.31a.79.79,0,0,1,.27.1L12,17l.07,0a.87.87,0,0,1,.57-.08.94.94,0,0,1,.28.1,1.3,1.3,0,0,0,.38.15,1.1,1.1,0,0,0,.26,0h.06a1.82,1.82,0,0,0,.45-.05,1.91,1.91,0,0,0,.7-.34l.07-.08Z" />
		<path style={{fill: "none"}} d="M13.6,17.18h0Z" />
		<path style={{fill: "none"}} d="M11.91,17.94a3.51,3.51,0,0,1-1.05-.15l-.25-.08-.19-.08-.05,0a3.55,3.55,0,0,1-.46-.28.32.32,0,0,1,0-.11.27.27,0,0,1,0-.11.17.17,0,0,1,.14-.06h0c.31.05.62.09,1,.12s.62,0,.89,0h1.52a3.19,3.19,0,0,1,.43,0h.05l.06.06a.11.11,0,0,1,0,.11v.08l-.08.05-.15.06-.32.13-.28.09-.23,0-.24.06-.17,0h-.09a3,3,0,0,1-.41,0Z" />
		<path style={{fill: "none"}} d="M11.91,17.94a3.51,3.51,0,0,1-1.05-.15l-.25-.08-.19-.08-.05,0a3.55,3.55,0,0,1-.46-.28.32.32,0,0,1,0-.11.27.27,0,0,1,0-.11.17.17,0,0,1,.14-.06h0c.31.05.62.09,1,.12s.62,0,.89,0h1.52a3.19,3.19,0,0,1,.43,0h.05l.06.06a.11.11,0,0,1,0,.11v.08l-.08.05-.15.06-.32.13-.28.09-.23,0-.24.06-.17,0h-.09a3,3,0,0,1-.41,0Z" />
		<path style={{fill: "none"}} d="M9.31,11.29h0A3.32,3.32,0,0,1,7.92,11a2,2,0,0,1-.75-.6l-.07-.08,0-.05A.28.28,0,0,1,7,10a.21.21,0,0,1,.15-.12.2.2,0,0,1,.18,0l.05,0a3,3,0,0,0,.35.19,2.24,2.24,0,0,0,1.07.16l.26,0a6.66,6.66,0,0,1,.9,0,2.1,2.1,0,0,1,.59.08.29.29,0,0,1,.22.31.29.29,0,0,1-.28.3.78.78,0,0,0-.42.14A1.59,1.59,0,0,1,9.31,11.29Z" />
		<path style={{fill: "none"}} d="M9.31,11.29h0A3.32,3.32,0,0,1,7.92,11a2,2,0,0,1-.75-.6l-.07-.08,0-.05A.28.28,0,0,1,7,10a.21.21,0,0,1,.15-.12.2.2,0,0,1,.18,0l.05,0a3,3,0,0,0,.35.19,2.24,2.24,0,0,0,1.07.16l.26,0a6.66,6.66,0,0,1,.9,0,2.1,2.1,0,0,1,.59.08.29.29,0,0,1,.22.31.29.29,0,0,1-.28.3.78.78,0,0,0-.42.14A1.59,1.59,0,0,1,9.31,11.29Z" />
		
	</svg>
	)
}

export default BeautyCare;