import React from 'react';
import styled from 'styled-components';

import worldWideMap from 'images/worldwide_manufacturing_suppliers_vendors_business_network_map.webp';
import worldWideMap_png from 'images/worldwide_manufacturing_suppliers_vendors_business_network_map.png';

const MapWrapper = styled.div`
	> picture > img {
		width: 100%;
		margin: 0 auto;
	}
`;
const WBNMapImage = () => {
	return (
		<MapWrapper>
			<picture>
				<source srcSet={worldWideMap} type="image/webp" />
				<source srcSet={worldWideMap_png} type="image/jpeg" />
				<img src={worldWideMap_png} alt="world-wide-map" />
			</picture>
		</MapWrapper>
	);
};

export default WBNMapImage;
