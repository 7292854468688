import React, { useContext } from 'react';
import styled from 'styled-components';
import Context from '../context';
const ChooseActorStyle = styled.select`
	width: 150px;
`;
const Options = React.memo(({ user, myBusiness }) => {
	return (
		<>
			<option value={user.userID}>{user.firstName + ' ' + user.lastName}</option>
			{myBusiness.slice(0, 5).map((business, index) => {
				console.log(business);
				return (
					<option key={index} value={business.businessID}>
						{business.business.general.companyName}
					</option>
				);
			})}
		</>
	);
});
const ChooseActor = ({ user, myBusiness }) => {
	const { socialActor, setSocialActor } = useContext(Context);
	return (
		<ChooseActorStyle
			onChange={e => {
				let tempSocialActor = e.target.value;
				localStorage.setItem('socialActor', tempSocialActor);
				setSocialActor(tempSocialActor);
			}}
			value={socialActor}
		>
			<Options user={user} myBusiness={myBusiness}/>
		</ChooseActorStyle>
	);
};
export default ChooseActor;
