import React from 'react';

const FoodAgriculture = ({ fill = "white" }) => {
	return (
		<svg id="Capa_1" data-name="Capa 1"
			xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
			<title>ICON FOOD AGRICULTURE</title>
			<path style={{fill}} d="M20.88,8.55V5.78h-.6V3.36L21,2.69l-.34-.21-.91.91V5.8H18.52V8.61H14.77l0-.32s-.26-3.91-1.66-5.52H3.35V9.6a4.37,4.37,0,0,1,2.56-2V3.56h6.5l.09.19a13,13,0,0,1,1.29,4.53v.36h-5c.4.3.26,1.89.26,1.89a5,5,0,0,1,2.23,1.61,4.32,4.32,0,0,1,.84,3.61h2.91c.27-.83,1.29-3.36,3.64-3.76a4.8,4.8,0,0,1,4.47,1.1V8.55Z" />
			<path style={{fill}} d="M20.24,20.91a3.6,3.6,0,1,1,3.6-3.59A3.59,3.59,0,0,1,20.24,20.91Zm0-5.5a1.91,1.91,0,1,0,1.91,1.91A1.91,1.91,0,0,0,20.24,15.41Z" />
			<path style={{fill}} d="M9.72,14.76a3.12,3.12,0,0,0-1.51-2.17,2.56,2.56,0,0,0-1.87-.35,7.47,7.47,0,0,0-1,.29,1,1,0,0,1-.76,0,2.92,2.92,0,0,0-.38-.14,2.7,2.7,0,0,0-1.4-.19A3,3,0,0,0,.56,13.9a4.26,4.26,0,0,0-.34,2.54A6.71,6.71,0,0,0,1,18.76a5.32,5.32,0,0,0,1.37,1.79,1.41,1.41,0,0,0,1.26.32,5.57,5.57,0,0,0,.79-.25,1.45,1.45,0,0,1,.87-.11c.22.06.43.15.64.23A1.61,1.61,0,0,0,7.7,20.5a5.69,5.69,0,0,0,.83-.93,6.87,6.87,0,0,0,1.3-4C9.8,15.4,9.78,15.08,9.72,14.76Zm-5.54-1.6a3.8,3.8,0,0,0-1.81,3.65S1,14,2.81,12.62C2.81,12.62,4.72,12.34,4.18,13.16Z" />
			<path style={{fill}} d="M5.23,11.73A1.68,1.68,0,0,1,7,9.25a1.15,1.15,0,0,0-.11.79C7,10.44,7.1,12.11,5.23,11.73Z" />
		</svg>
	)
}

export default FoodAgriculture;

