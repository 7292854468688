const businessOptions = [
	{ key: '0', value: 'PR', text: 'Products' },
  { key: 'A', value: 'UR', text: 'Users' },
	{ key: '1', value: 'MA', text: 'Manufacturers' },
	{ key: '2', value: 'DI', text: 'Distributors' },
	{ key: '3', value: 'WS', text: 'Wholesalers' },
	{ key: '4', value: 'IM', text: 'Importers' },
	{ key: '5', value: 'EX', text: 'Exporters' },
	{ key: '6', value: 'RE', text: 'Retailers' },
	{ key: '7', value: 'IN', text: 'Investors' },
	{ key: '8', value: 'CO', text: 'Consultants' },
  { key: '9', value: 'GO', text: 'Government' },
];

export default businessOptions;
