import { put, call } from 'redux-saga/effects';
import { map } from 'lodash';
import axios from 'axios';
import { GET_INDUSTRY_FULL_TREE_SUCCESS, GET_INDUSTRY_FULL_TREE_FAILED } from './constants';
import { actionSuccess, actionFailed } from 'shared/actions';

function listIndustryFullTreeApi() {
	return axios.request({
		url: `${process.env.REACT_APP_API_URL}catsys/industry/full-tree`,
		method: 'GET'
	});
}

export function* listIndustryFullTreeSaga(action) {
	try {
		const {
			data: { data }
		} = yield call(listIndustryFullTreeApi);
		for (let industry of data) {
			for (let i = 0; i < industry.categories.length; i++) {
				industry.categories[i].subCategories.sort(function(a, b) {
					return a.order - b.order;
				});
			}
		}
		const customData = map(data, ({ id, name, categories }) => {
			return {
				key: id,
				text: name,
				value: id,
				categories
			};
		});

		yield put(actionSuccess(GET_INDUSTRY_FULL_TREE_SUCCESS, customData));
	} catch (error) {
		yield put(actionFailed(GET_INDUSTRY_FULL_TREE_FAILED, []));
	}
}
